import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-appointments-list',
  templateUrl: './appointments-list.component.html',
  styleUrls: ['./appointments-list.component.scss']
})
export class AppointmentsListComponent implements OnInit {
  @Input() adminPermissions;
  type: any;
  today: any;
  // Appoinements data @input varible and initial data
  @Input() treatmentsCount;
  pmpIcon: 'pmp';
  mednoteIcon: 'mednote';
  patientDataIcon: 'patient-data';
  appointmentDataIcon: 'appointment-data';
  pdfs = {
    patientIntake: 'patient-data',
    appointment: 'appointment-data',
    medicationNotes: 'note-data',
    pmp: 'pmp-popUp'
  };
  @Input() appointmentList: any = {
   
  };
  showScreen: boolean = false;
  @Output() moreOptionTextClick = new EventEmitter<any>();
  @ViewChild('op', { static: false }) overlay;
  constructor(private observable: ObservableHelperService, private router: Router, private http: HttpService, private activeRoute: ActivatedRoute) { }
  ngOnInit() {
    console.log(this.appointmentList);
    this.activeRoute.queryParams.subscribe(params => {
      this.type = params.type;
    });
    const todayDate = new Date();
    
    const month = ('0' + (todayDate.getMonth() + 1)).slice(-2);
    const day = ('0' + todayDate.getDate()).slice(-2);
    this.today = `${todayDate.getFullYear()}-${month}-${day}`;
    this.appointmentList.actions = this.massageActions(this.appointmentList);
    this.observable.toggleThrobber(true);
    setTimeout(() => { this.showScreen = true; this.observable.toggleThrobber(false); }, 1000);
  }


  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.overlay) {
      this.overlay.hide();
    }
  }


  massageActions(data) {
    let actions;
    if (!data && typeof (data) === 'object') {
      return {};
    } else {
      let appointmentStartDate: any = `${data.appointmentDate}T${data.appointmentTime}`;
      appointmentStartDate = new Date(appointmentStartDate).valueOf();
      let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, data.timestamp)).valueOf();
      let busyInsurance = {
        label: '3 Way Call Insurance',
        id: 'busy_insurance',
        iconName: 'threeWayCall',
        colorIconName: '3_way_call_insurance_updtd'
      };
      let termInsurance = {
        label: 'Terminate Insurance',
        id: 'terminated_insurance',
        iconName: 'termInsurance',
        colorIconName: 'term_insurance_stroke'
      };
      let medicationSent = {
        label: 'Medication Sent',
        id: 'medication_mail',
        iconName: 'medical-prescription-email',
        colorIconName: 'medical-prescription-email'
      };
      const coordinationOfBenefits = {
        label: 'Coordination Of Benefits',
        id: 'coordination_benefits',
        colorIconName: 'mail'
      };
      const pharmacyDiscrepancy = {
        label: 'Pharmacy Discrepancy',
        id: 'pharmacy_discrepancy',
        colorIconName: 'mail'
      };
      if (this.adminPermissions.APPOINTMENT_EDIT) { data.actions.push(busyInsurance, termInsurance, medicationSent, coordinationOfBenefits, pharmacyDiscrepancy); }
      if (data.appointmentStatus === 'A') {
        let removableActions = ['send_reminder', 'reschedule_appointment', 'cancel_appointment'];
        if (currentDate < appointmentStartDate) {
          actions = data.actions;
          if (data.invoiceStatus === 'ungenerated') {
            let generateInvoice = {
              label: 'Generate Invoice',
              iconName: 'admininvoice',
              id: 'generate_invoice',
              colorIconName: 'generate-invoice-color'
            };
            if (this.adminPermissions.INVOICE_EDIT) { actions.unshift(generateInvoice); }
          } else if (data.invoiceStatus === 'paid') {
            let activePaidActions = [
              {
                label: 'Mark Absent',
                iconName: 'mark-absent',
                id: 'mark_absent',
                colorIconName: 'mark-absent-color'
              },
              {
                label: 'Cancel Appointment',
                iconName: 'cancel-appointment-normal',
                id: 'cancel_appointment',
                colorIconName: 'cancel-appointment-color'
              },
              {
                label: 'Reschedule Appointment',
                iconName: '',
                id: 'reschedule_appointment',
                colorIconName: 'mixedReschedule'
              },
              {
                label: 'Send Reminder',
                iconName: 'remainder',
                id: 'send_reminder',
                colorIconName: 'sendReminder'
              }
            ];
            if (this.adminPermissions.APPOINTMENT_EDIT) {
              activePaidActions.forEach(eachAction => {
                actions.unshift(eachAction);
              });
            }
          }
        } else if (data.invoiceStatus === 'ungenerated') {
          let generateInvoice = {
            label: 'Generate Invoice',
            iconName: 'admininvoice',
            id: 'generate_invoice',
            colorIconName: 'generate-invoice-color'
          };
          actions = data.actions.filter(action => !removableActions.includes(action.id));
          if (this.adminPermissions.INVOICE_EDIT) { actions.unshift(generateInvoice); }
        } else {
          actions = data.actions.filter(action => !removableActions.includes(action.id));
        }
      } else if (data.appointmentStatus === 'B') {
        if (data.invoiceStatus === 'unpaid') {
          let invoiceDetails = {
            label: 'Invoice Details',
            id: 'invoice_details',
            iconName: 'Invoice-slick',
            colorIconName: 'Invoice-color'
          };
          actions = data.actions.filter(action => action.id !== 'generate_invoice');
          if (this.adminPermissions.INVOICE_DETAILS) { actions.unshift(invoiceDetails); }
        } else if (data.invoiceStatus === 'paid') {
          actions = data.actions.filter(action => action.id !== 'generate_invoice');
        } else {
          actions = data.actions;
        }
      } else {
        actions = data.actions;
      }
    }
    let appStatus = ['A', 'N', 'C', 'I'];
    let insType = ['busy_insurance', 'terminated_insurance', 'medication_mail', 'coordination_benefits', 'pharmacy_discrepancy'];
    if (!appStatus.includes(data.appointmentStatus)) {
      actions = actions.filter(obj => !insType.includes(obj.id));
    }
    actions = actions.map(eachActions => {
      return {
        ...eachActions,
        label: (eachActions.id === 'block_patient') ? (data.patient.toBlock.length === this.treatmentsCount + 1) ? 'Unblock' : 'Block Patient' : eachActions.label
      };
    });
    return actions;
  }
  // scroll to top
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }
  moreOptionClick(optionValue, appoinmnetData) {
    if (optionValue.id === 'appointment_details') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${appoinmnetData.appointmentId}`]);
    } else if (optionValue.id === 'patient_details') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}/${appoinmnetData.patient.patientId}`]);
    } else if (optionValue.id === 'invoice_details') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}/appt_invoice/${appoinmnetData.invoiceId}`]);
    } else if (optionValue.id === 'reschedule_appointment') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}`], { queryParams: { appointmentId: appoinmnetData.appointmentId, patientId: appoinmnetData.patient.patientId, appointmentDate: appoinmnetData.appointmentDate, appointmentTime: appoinmnetData.appointmentTime, appointmentTypeId: appoinmnetData.appointmentTypeId, locationId: appoinmnetData.locationId, isReshedule: true, offline: appoinmnetData.patient.isOffline } });
    } else if (optionValue.id === 'resend_invoice') {
      this.resendInvoice(appoinmnetData);
    } else if (optionValue.id === 'busy_insurance' || optionValue.id === 'terminated_insurance' || optionValue.id === 'medication_mail' || optionValue.id === 'coordination_benefits' || optionValue.id === 'pharmacy_discrepancy') {
      this.insuranceEmail(optionValue.id, appoinmnetData);
    } else {
      this.moreOptionTextClick.emit({ option: optionValue, appoinmnetData });
    }
  }

  openPdf(type, appointmentData) {
    console.log("appointmentData", appointmentData);
    console.log("type", type);
    let urlToOpen: string
    let message
    if (type === this.pdfs.patientIntake) {
      if (appointmentData.hasOwnProperty("patient_data_signeed_pdf") && appointmentData.patient_data_signeed_pdf !== '') {
        urlToOpen = appointmentData.patient_data_signeed_pdf
      } else {
        message = "Falied to open patient information pdf. Please try again."
      }
    } else if (type === this.pdfs.appointment) {
      if (appointmentData.hasOwnProperty("appoint_data_signeed_pdf") && appointmentData.appoint_data_signeed_pdf !== '') {
        urlToOpen = appointmentData.appoint_data_signeed_pdf
      } else {
        message = "Falied to open patient's appointment pdf. Please try again."
      }
    } else if (type === this.pdfs.medicationNotes) {
      if (appointmentData.hasOwnProperty("notes_data_s3") && appointmentData.notes_data_s3 !== '') {
        urlToOpen = appointmentData.notes_data_s3
      } else {
        message =
          appointmentData.appointmentTypeId === 1
            ? "Falied to open patient's med note pdf. Please try again."
            : "Falied to open patient's tms note pdf. Please try again."
      }
    } else if (type === this.pdfs.pmp) {
      urlToOpen = appointmentData.pmp_sheet_data.pmp_sheet_url
      // let appoinmnetData = appointmentData
      // this.moreOptionTextClick.emit({ option: this.pdfs.pmp, appoinmnetData });
    }

    if (type !== this.pdfs.pmp) {
      let payload = {
        type: HTTPMethods.POST,
        url: API_URLS.GET_SIGNED_URL,
        isDeveloper: true,
        // pathVariables: 
        body: {
          url: urlToOpen
        }
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          console.log("SIGNED_URL res", res);
          let pdfUrl = res.data
          window.open(pdfUrl, '_blank');
        } else {
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    } else if (type === this.pdfs.pmp) {
      let appointmentTypename;
      if (appointmentData.appointmentTypeId === 4) {
        appointmentTypename = 'TMS';
      } else {
        appointmentTypename = appointmentData.appointmentTypeName;
      }
      let payload = {
        type: HTTPMethods.POST,
        url: API_URLS.Excel_To_DataFormat,
        isDeveloper: true,
        // pathVariables: 
        body: {
          appoinmenttype: appointmentTypename,
          patientid: appointmentData.patient.patientId,
          url: urlToOpen
        }
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          let pdfUrl = res.data
          let appoinmnetData = appointmentData
          appoinmnetData.pmp_sheet_data.MEDS = pdfUrl.meds
          appoinmnetData.pmp_sheet_data.PMP = pdfUrl.pmp
          this.moreOptionTextClick.emit({ option: this.pdfs.pmp, appoinmnetData });
          // window.open(pdfUrl, '_blank');
        } else {
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }


  }


  resendInvoice(appoinmnetData) {
    let message = 'Invoice has been re-sent to the customer successfully.';
    let payload = {
      type: 'GET',
      url: 'invoiceResend',
      isDeveloper: true,
      pathVariables: [appoinmnetData.invoiceId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log('Response', res);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
      } else {
        message = 'Failed to resend invoice the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Navigate to Appointment Details page
  navigateDetails(appointment) { // @TODO Samad
    if (appointment && typeof (appointment) === 'object') {
      this.scrollTop();
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${appointment.appointmentId}`]);
    }
  }

  // insurance temaplates
  insuranceEmail(emailType, app) {
    let payload = {
      type: 'POST',
      url: 'insuranceEmail',
      isDeveloper: true,
      body: {
        appointmentId: (emailType !== 'medication_mail') ? app.appointmentId : null,
        patientId: (emailType === 'medication_mail') ? app.patient.patientId : null,
        emailType: emailType === 'busy_insurance' ? 'BUSY_INSURANCE' : (emailType === 'terminated_insurance') ? 'TERMINATED_INSURANCE' : (emailType === 'coordination_benefits') ? 'COORDINATION_OF_BENEFITS' : (emailType === 'pharmacy_discrepancy') ? 'PHARMACY_DISCREPANCY' : 'MEDICATION_MAIL',
        patientIdList: []
      }
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log('Response', res);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Email has been sent to the patient successfully.');
      } else {
        let message = 'Failed to send the email. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

}
