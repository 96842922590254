import { Injectable } from '@angular/core';
import { Filter } from '@app/pages/admin/models/filterModel';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from './http.service'; // Ensure HttpService is imported

@Injectable({
    providedIn: 'root'
})
export class AdminInvoiceFilterService {

    // Initializing a BehaviorSubject to store and manage invoice data state
    private invoiceSubject = new BehaviorSubject<Filter | null>(null);

    // Exposing the invoice data as an observable for other components to subscribe to
    public invoice$ = this.invoiceSubject.asObservable();

    // Injecting the HttpService dependency for making HTTP requests
    constructor(private http: HttpService) { }

    /**
    * Retrieve the access data.
    * Checks if data is already available in the BehaviorSubject (cached);
    * if so, returns it. Otherwise, it makes an API call to fetch it.
    */
    getInvoice(): Observable<Filter> {
        // Check if we already have invoice data in the BehaviorSubject
        if (this.invoiceSubject.value !== null) {
            // If invoice count is already cached, return it as an observable
            return of(this.invoiceSubject.value);
        }

        // If not, make the API call
        const payloadData = {
            type: 'GET',
            url: 'invoiceFilter',
            isDeveloper: true,
        };

        return this.http.makeHttpRequest(payloadData).pipe(
            tap(res => {
                if (this.http.isSuccessfulResponse(res)) {
                    const invoiceData = res;  // Adjust this based on your API response structure
                    this.invoiceSubject.next(invoiceData); // Update BehaviorSubject with new data or updated data
                }
            })
        );
    }

}