import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { TOAST_STATUSES, HTTPMethods } from '@app/common/constants/util.constant';
import { AdminCogntioService } from '@app/common/services/admin-cogntio.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

interface Data{
  id: string,
  firstName: string,
  lastName: string,
  mail: string
}
@Component({
  selector: 'app-user-profile-admin',
  templateUrl: './user-profile-admin.component.html',
  styleUrls: ['./user-profile-admin.component.scss']
})
export class UserProfileAdminComponent implements OnInit {

  
  @Input() data: Data;
  @Output() submitEvent: EventEmitter<String> = new EventEmitter();
  editform: {formGroup: any, formInputs: Array<Record<string, any>>};
  saveFormData: boolean = false;

  constructor(
    private formService: CustomFormHandlerService,
    private adminCognito: AdminCogntioService,
    private http: HttpService,
    private observableHelperService: ObservableHelperService
  ) { }

  ngOnInit() {
    this.fillForm();
    this.editform = this.formService.initForm(this.formService.formTypes.USERPROFILEEDIT);
  }

  /**
   * auto fill values to the form. - admin side
   */
  fillForm() {
    this.formService.userProfileFormEditInputsConfig[0].value = this.data.firstName ? this.data.firstName : '';
    this.formService.userProfileFormEditInputsConfig[1].value = this.data.lastName ? this.data.lastName : '';
    this.formService.userProfileFormEditInputsConfig[2].value = this.data.mail ? this.data.mail : '';
    this.formService.userProfileFormEditInputsConfig[2].showontoggle = true;
    this.formService.userProfileFormEditInputsConfig[3].showontoggle = true;
  }

  /**
   * Form submitted. - admin side
   */
  saveForm() {
    this.saveFormData = true;
  }

  /**
   * Reset form - admin side
   */
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);

    return true;
  }

  /**
   * Form Submitted. - admin side
   * @param event submit event
   */
  getFormData(event) {
    let userDetails = event.value;
    delete userDetails.confirmEmail;
    userDetails.user_id = this.data.id ? this.data.id : '';
    userDetails.firstName = this.data.firstName ? this.data.firstName : '';
    userDetails.lastName = this.data.lastName ? this.data.lastName : '';
    userDetails.email = userDetails.email.toLowerCase();
    this.adminCognito.userProfileUpdateAdmin(userDetails,this.data.id, (data,errMessage) => {
      if(data){
        let body = {
          user_id: this.data.id,
          email: userDetails.email
        };
        let paylod = {
          type: HTTPMethods.PUT,
          body: body,
          isDeveloper: true,
          url: API_URLS.EDIT_POC,
        };
        this.http.makeHttpRequest(paylod).subscribe(res => {
          if(this.http.isSuccessfulResponse(res)){
            this.editform.formGroup.reset();
            this.edited();
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.UPDATED_USER_PROFILE_ADMIN);
            this.submitEvent.emit('success');
          } else {
            let message = TOAST_MESSAGES.UNABLE_TO_UPDATE_PROFILE;
            message = (res.error) ? res.error.message : message;
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
          }
        });
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, errMessage);
        this.submitEvent.emit('close');
      }
    });
  }

  /**
   * Reset form after submitting. - admin side
   */
  edited() {
    this.editform.formInputs.forEach(input => {
      input.value = '';
    });
  }

  /**
   * Cancel click action - admin side
   * @param event click event
   */
  navigateBack(event) {
    this.editform.formInputs.forEach(formInput => {
      formInput.value = '';
    });

    this.submitEvent.emit('close');
    return;
  }

  /**
   * reset form on destroy - admin side
   */
  ngOnDestroy() {
    if (this.editform && this.editform.formGroup) {
      this.editform.formGroup.reset();
    }
  }
}
