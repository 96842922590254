import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonAppModule } from '@app/common-app.module';
import { AboutusRoutingModule } from '@app/pages/aboutus/aboutus-routing.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {TabViewModule} from 'primeng/tabview';

import { AboutusApproachComponent } from './aboutus-approach/aboutus-approach.component';
import { AboutusCardComponent } from './aboutus-card/aboutus-card.component';
import { AboutusMeetComponent } from './aboutus-meet/aboutus-meet.component';
import { AboutusWrapperComponent } from './aboutus-wrapper/aboutus-wrapper.component';


const DECLARATION_COMPONENTS = [
  AboutusWrapperComponent,
  AboutusApproachComponent,
  AboutusMeetComponent,
  AboutusCardComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    AboutusRoutingModule,
    SlickCarouselModule
  ],
  exports: []
})
export class AboutusModule { }
