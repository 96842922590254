import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { adminAppointmentsTabs, locationDetails } from '../common/constants/tabs';
import { AdminAccessService } from '@app/common/services/admin-access.service';

@Component({
  selector: 'app-location-details-wrapper',
  templateUrl: './location-details-wrapper.component.html',
  styleUrls: ['./location-details-wrapper.component.scss']
})
export class LocationDetailsWrapperComponent implements OnInit {
  breadCrumbData = {
    link: '',
    linkText: ''
  };
  tabsData = locationDetails;
  selectedTabIndex = 0;
  tabType = 'details';
  getQueryParameter: any;
  isShowScreen = true;
  locationList = [];
  locationId: string;
  locationDetails: any;
  locationIdData: any;
  isLocation = true;
  permissionsDone = false;
  adminPermissions = {
    LOCATION_EDIT: true
  };
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dataService: DataService,
    private http: HttpService,
    private observable: ObservableHelperService,
    private adminAccessService: AdminAccessService
  ) {
    this.activeRoute.queryParams.subscribe(params => {
      this.locationId = this.router.url.split('/').pop();
    });
  }

  ngOnInit() {
    this.getAdminPermissions();
    this.initialTabView();
    this.getLocationServices();
    this.getLocationDetails();
  }

  initialTabView() {
    this.tabsData.forEach(eachTab => {
      if (eachTab.routingLabel === this.tabType) {
        eachTab.selected = true;
      } else {
        eachTab.selected = false;
      }
    });
  }

  // scroll to top
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }

  // TO get location list API call
  getLocationDetails() {
    this.isShowScreen = false;
    const payloadData = {
      type: 'GET',
      url: 'adminlocationsList',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        // tslint:disable-next-line:no-unused-expression
        res.data && res.data.forEach(loc => {
          if (loc.locationId === this.locationId) {
            this.locationDetails = loc;
          }
        });
      } else {
        if (res.error.message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        } else { this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting location details'); }
      }
    });
  }

  // TO get location details API call
  getLocationServices() {
    this.isShowScreen = false;
    const payloadData = {
      type: 'GET',
      url: 'getLocationDetails',
      pathVariables: [this.locationId],
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.breadCrumbData.linkText = res.data.locationName;
        this.locationIdData = res.data;
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting location details');
      }
      this.isShowScreen = true;
    });
  }

  // This function will set the breadcrumb lable from locations list
  /*extractLocation(list) {
    if (!list && !Array.isArray(list)) {
      return [];
    } else {
      list.forEach(loc => {
        if (loc.locationId === this.currentUrl.split('/').pop()) {
          this.breadCrumbData.linkText = loc.locationName;
        }
      });
    }
  }*/

  // This will return Selected tab details
  selectedTab(tab) {
    if (tab) {
      this.tabType = tab.routingLabel;
      this.tabsData.forEach(eachTab => {
        if (eachTab.id === tab.id) {
          eachTab.selected = true;
        } else {
          eachTab.selected = false;
        }
      });

      setTimeout(() => {
        $('.dashboard__wrapper--section').css({
          margin: 'unset',
          'margin-top': '40px'
        });
      }, 100);
    }
  }

  editLocation(event) {
    event.stopPropagation();
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EDIT_LOCATIONS}/${this.locationDetails.locationId}`]);
  }
  // getting permissions based on user id
  getAdminPermissions() {
    const payloadData = {
      type: 'GET',
      url: 'getPermissions',
      isDeveloper: true
    };
    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let permArr = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
        });
        if (!permArr.includes('LOCATION_DETAILS')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        if (!permArr.includes('LOCATION_EDIT')) {
          this.adminPermissions.LOCATION_EDIT = false;
        }
        console.log(this.adminPermissions);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }
}
