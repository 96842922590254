import { Pipe, PipeTransform } from '@angular/core';


/**
 * Pipe to convert 24hr format value to 12hr format
 * input: 24hr format value of time (just an integer).
 */
@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(time: number): string {
    return ((time % 12) || 12).toString() +' '+ (time >= 12 ? 'PM' : 'AM');
  }

}