import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FORM_KEYS, EMAIL_EDIT_KEYS, KETAMINE_ENROLLMENT_KEYS, KETAMINE_MONITORING_KEYS, TMS_CONSENT_FORM, KETAMINE_CONSENT_FORM, MM_CONSENT_FORM, REQUEST_THERAPIST_DETAILS_KEYS, INTAKE_FORM_KEYS, MEDICAL_SUMMARY_FORM } from '../constants/form-keys.constant';
import { ITimeFormat, IFormType, IValidateField, ICheckBoxPoints, IformConfigI } from '../constants/typeInterfaces.constants';
import { DEFAULT_TIME_ZONE_DIFF, convertDateToSpecifiedOffset, stateDropDownList } from '../constants/util.constant';



@Injectable({
  providedIn: 'root'
})
export class CustomFormHandlerService {
  therapistEmailList = [];
  // not at all likely, not likely, neutral, likely, extremely likely 
  radioRatingOptions: ITimeFormat[] = [
    {
      label: 'Not at all likely',
      value: 'Not at all likely'
    },
    {
      label: 'Not likely',
      value: 'Not likely'
    },
    {
      label: 'Neutral',
      value: 'Neutral'
    },
    {
      label: 'Likely',
      value: 'Likely'
    },
    {
      label: 'Extremely likely',
      value: 'Extremely likely'
    },
  ];
  adhdOptions: ITimeFormat[] = [
    {
      label: 'Never',
      value: 'Never'
    },
    {
      label: 'Rarely',
      value: 'Rarely'
    },
    {
      label: 'Sometimes',
      value: 'Sometimes'
    },
    {
      label: 'Often',
      value: 'Often'
    },
    {
      label: 'Very Often',
      value: 'Very Often'
    },
  ]
  howOften = [
    {
      label: 'Not At All',
      value: 'Not At All'
    },
    {
      label: 'Once in a While',
      value: 'Once in a While'
    },
    {
      label: 'Most of the time',
      value: 'Most of the time'
    },
    {
      label: 'All the time',
      value: 'All the time'
    }
  ];
  howOftenSecondary = [
    {
      label: 'Not At All',
      value: 'Not At All'
    },
    {
      label: 'Slight',
      value: 'Slight'
    },
    {
      label: 'Severe',
      value: 'Severe'
    }];
  qualityMetric = [{
    label: 'Great',
    value: 'Great'
  }, {
    label: 'Good',
    value: 'Good'
  }, {
    label: 'Fair',
    value: 'Fair'
  }, {
    label: 'Poor',
    value: 'Poor'
  }, {
    label: 'Very Poor',
    value: 'Very Poor'
  }];
  satisfactionMetric = [{
    label: 'Very Satisfied',
    value: 'Very Satisfied'
  }, {
    label: 'Somewhat Satisfied',
    value: 'Somewhat Satisfied'
  }, {
    label: 'Neither Satisfied Nor Dissatisfied',
    value: 'Neither satisfied nor dissatisfied'
  }, {
    label: 'Somewhat Dissatisfied',
    value: 'Somewhat dissatisfied'
  }, {
    label: 'Very Dissatisfied',
    value: 'Very dissatisfied'
  }];

  statesList = [
    {
      label: 'Alabama',
      value: 'AL'
    },
    {
      label: 'Alaska',
      value: 'AK'
    },
    {
      label: 'American Samoa',
      value: 'AS'
    },
    {
      label: 'Arizona',
      value: 'AZ'
    },
    {
      label: 'Arkansas',
      value: 'AR'
    },
    {
      label: 'California',
      value: 'CA'
    },
    {
      label: 'Colorado',
      value: 'CO'
    },
    {
      label: 'Connecticut',
      value: 'CT'
    },
    {
      label: 'Delaware',
      value: 'DE'
    },
    {
      label: 'District Of Columbia',
      value: 'DC'
    },
    {
      label: 'Federated States Of Micronesia',
      value: 'FM'
    },
    {
      label: 'Florida',
      value: 'FL'
    },
    {
      label: 'Georgia',
      value: 'GA'
    },
    {
      label: 'Guam',
      value: 'GU'
    },
    {
      label: 'Hawaii',
      value: 'HI'
    },
    {
      label: 'Idaho',
      value: 'ID'
    },
    {
      label: 'Illinois',
      value: 'IL'
    },
    {
      label: 'Indiana',
      value: 'IN'
    },
    {
      label: 'Iowa',
      value: 'IA'
    },
    {
      label: 'Kansas',
      value: 'KS'
    },
    {
      label: 'Kentucky',
      value: 'KY'
    },
    {
      label: 'Louisiana',
      value: 'LA'
    },
    {
      label: 'Maine',
      value: 'ME'
    },
    {
      label: 'Marshall Islands',
      value: 'MH'
    },
    {
      label: 'Maryland',
      value: 'MD'
    },
    {
      label: 'Massachusetts',
      value: 'MA'
    },
    {
      label: 'Michigan',
      value: 'MI'
    },
    {
      label: 'Minnesota',
      value: 'MN'
    },
    {
      label: 'Mississippi',
      value: 'MS'
    },
    {
      label: 'Missouri',
      value: 'MO'
    },
    {
      label: 'Montana',
      value: 'MT'
    },
    {
      label: 'Nebraska',
      value: 'NE'
    },
    {
      label: 'Nevada',
      value: 'NV'
    },
    {
      label: 'New Hampshire',
      value: 'NH'
    },
    {
      label: 'New Jersey',
      value: 'NJ'
    },
    {
      label: 'New Mexico',
      value: 'NM'
    },
    {
      label: 'New York',
      value: 'NY'
    },
    {
      label: 'North Carolina',
      value: 'NC'
    },
    {
      label: 'North Dakota',
      value: 'ND'
    },
    {
      label: 'Northern Mariana Islands',
      value: 'MP'
    },
    {
      label: 'Ohio',
      value: 'OH'
    },
    {
      label: 'Oklahoma',
      value: 'OK'
    },
    {
      label: 'Oregon',
      value: 'OR'
    },
    {
      label: 'Palau',
      value: 'PW'
    },
    {
      label: 'Pennsylvania',
      value: 'PA'
    },
    {
      label: 'Puerto Rico',
      value: 'PR'
    },
    {
      label: 'Rhode Island',
      value: 'RI'
    },
    {
      label: 'South Carolina',
      value: 'SC'
    },
    {
      label: 'South Dakota',
      value: 'SD'
    },
    {
      label: 'Tennessee',
      value: 'TN'
    },
    {
      label: 'Texas',
      value: 'TX'
    },
    {
      label: 'Utah',
      value: 'UT'
    },
    {
      label: 'Vermont',
      value: 'VT'
    },
    {
      label: 'Virgin Islands',
      value: 'VI'
    },
    {
      label: 'Virginia',
      value: 'VA'
    },
    {
      label: 'Washington',
      value: 'WA'
    },
    {
      label: 'West Virginia',
      value: 'WV'
    },
    {
      label: 'Wisconsin',
      value: 'WI'
    },
    {
      label: 'Wyoming',
      value: 'WY'
    },

  ];
  // diagnosis list for MM notes and TMS notes
  DiagnosisList = [
    {
      label: 'ADHD',
      value: 'ADHD'
    },
    {
      label: 'Anxiety',
      value: 'Anxiety'
    },
    {
      label: 'Depression',
      value: 'Depression'
    },
    {
      label: 'Schizophrenia',
      value: 'Schizophrenia'
    },
    {
      label: 'Bipolar',
      value: 'Bipolar'
    },
    {
      label: 'Oppositional Disorder',
      value: 'Oppositional Disorder'
    },
    {
      label: 'Adjustment Disorder',
      value: 'Adjustment Disorder'
    },
    {
      label: 'Autism',
      value: 'Autism'
    },
    {
      label: 'OCD',
      value: 'OCD'
    },
    {
      label: 'Eating Disorder',
      value: 'Eating Disorder'
    }
  ]
  AppointmentTypes = [
    {
      label: 'Initial TMS Note',
      value: 'Initial TMS Note',
    },
    {
      label: 'Follow Up TMS Note',
      value: 'Follow Up TMS Note',
    },{
      label: 'Follow Up TMS Note - Theta-Burst',
      value: 'Follow Up TMS Note - Theta-burst',
    },
  ]

  timeFormatList: Array<ITimeFormat> = [
    {
      label: 'AM',
      value: 'am'
    },
    {
      label: 'PM',
      value: 'pm'
    },
  ];
  

  timeGapValidationMessage = 'Please enter value greater or equal to 90';

  primaryProviders = [...this.statesList];

  secondaryproviders = [...this.statesList];
  signaturValues = [];

  ketamineEnrollmentList: Array<ICheckBoxPoints> = [
    {
      label: 'By signing this form, I understand and acknowledge that:',
      list: [
        'Enroll in the KETAMINE by completing this Patient Enrollment Form with my healthcare provider.',
        'Agree to receive counseling on the risks and the need for monitoring for resolution of sedation and dissociation, and for any changes in my vital signs.'
      ]
    },
    {
      label: 'During treatment I will:',
      list: [
        'Use the KETAMINE treatment myself under the direct observation of a healthcare provider.',
        'Be observed at the healthcare setting where I get KETAMINE for at least 2 hours after each treatment until the healthcare provider determines I am ready to leave the healthcare setting.'
      ]
    }
  ]

  therapistNameList: Array<string> = ['prayaga', 'rama', 'dr. prayaga', 'dr. rama', 'dr. rama prayaga', 'rama prayaga','prayaga rama','prayagarama','ramaprayaga'];
  minDateOfHospitalization: Date = new Date(2000,0,1);

  // Constant purpose
  formTypes = {
    SIGNUP: {
      type: 'signupForm',
      config: 'signupFormInputsConfig'
    },
    LOGIN: {
      type: 'loginForm',
      config: 'loginFormInputsConfig'
    },
    ONLYPASSWORD: {
      type: 'onlyPasswordForm',
      config: 'onlyPasswordFormInputsConfig'
    },
    ONLYEXISTINGPASSWORD: {
      type: 'onlyExistingPasswordForm',
      config: 'onlyExistingPasswordFormInputsConfig'
    },
    RESETPASSWORD: {
      type: 'resetPasswordForm',
      config: 'resetPasswordFormInputsConfig'
    },
    REGISTER: {
      type: 'registerForm',
      config: 'registerFormInputsConfig'
    },
    FORGOTPASSWORD: {
      type: 'forgotPasswordForm',
      config: 'forgotPasswordFormInputsConfig'
    },

    FORGOTDOBPASSWORD: {
      type: 'forgotPasswordDOBForm',
      config: 'forgotPasswordDOBFormInputsConfig'
    },

    FORGOTPASSWORDWITHCODE: {
      type: 'forgotPasswordFormWithCode',
      config: 'forgotPasswordWithCodeFormInputsConfig'
    },
    RESENDMAIL: {
      type: 'resendMailForm',
      config: 'resendMailFormInputsConfig'
    },
    RESENDVERIFICATIONMAIL: {
      type: 'resendVerificationMailForm',
      config: 'resendVerificationMailFormInputsConfig'
    },
    USERPROFILE: {
      type: 'userProfileForm',
      config: 'userProfileFormInputsConfig'
    },
    USERPROFILEEDIT: {
      type: 'userProfileEditForm',
      config: 'userProfileFormEditInputsConfig'
    },
    BASICINFO: {
      type: 'basicInformationForm',
      config: 'basicInformationFormConfig'
    },
    INSURANCEDETAIL: {
      type: 'insuranceDetailForm',
      config: 'insuranceDetailFormConfig'
    },
    MEDICALHISTORY: {
      type: 'medicalHistoryForm',
      config: 'medicalHistoryFormConfig'
    },
    MISCELLAENOUS: {
      type: 'miscellaenousForm',
      config: 'miscellaenousFormConfig'
    },
    NEWPATIENTSUBMIT: {
      type: 'newPatientSubmitForm',
      config: 'newPatientSubmitFormConfig'
    },
    NEWUSER_BASICINFO: {
      type: 'newUserBasicInformationForm',
      config: 'newUserBasicInformationFormConfig'
    },
    NEWUSER_INSURANCEDETAIL: {
      type: 'newUserInsuranceDetailForm',
      config: 'newUserInsuranceDetailFormConfig'
    },
    NEWUSER_MEDICALHISTORY: {
      type: 'newUserMedicalHistoryForm',
      config: 'newUserMedicalHistoryFormConfig'
    },
    NEWUSER_MISCELLAENOUS: {
      type: 'newUserMiscellaenousForm',
      config: 'newUserMiscellaenousFormConfig'
    },
    NEWUSER_NEWPATIENTSUBMIT: {
      type: 'newUserNewPatientSubmitForm',
      config: 'newUserNewPatientSubmitFormConfig'
    },

    EXISTINGUSER_BASICINFO: {
      type: 'existingUserBasicInformationForm',
      config: 'existingUserBasicInformationFormConfig'
    },
    EXISTINGUSER_INSURANCEDETAIL: {
      type: 'existingUserInsuranceDetailForm',
      config: 'existingUserInsuranceDetailFormConfig'
    },
    EXISTINGUSER_MEDICALHISTORY: {
      type: 'existingUserMedicalHistoryForm',
      config: 'existingUserMedicalHistoryFormConfig'
    },
    EXISTINGUSER_MISCELLAENOUS: {
      type: 'existingUserMiscellaenousForm',
      config: 'existingUserMiscellaenousFormConfig'
    },
    EXISTINGUSER_NEWPATIENTSUBMIT: {
      type: 'existingUserNewPatientSubmitForm',
      config: 'existingUserNewPatientSubmitFormConfig'
    },

    MENTALHEALTHDETAIL: {
      type: 'metalHealthDetailForm',
      config: 'metalHealthDetailFormConfig'
    },
    AUTOFILLDETAILS: {
      type: 'autoFillDataForm',
      config: 'autoFilledDataFormConfig'
    },
    AUTOFILLMETALDETAILS:{
      type: 'autoFillMetalDataForm',
      config: 'autoFilledMetalDataFormConfig'
    },
    AUTOFILLMEDICATIONDETAILS :{
      type: 'autoFillMediacationDataForm',
      config: 'autoFilledMedicationDataFormConfig'
    },
    AUTOFILLTELEMEDECINEDETAILS:{
      type: 'autoFillTelemedicineForm',
      config: 'autoFillTelemedicineDataFormConfig'
    },
    ADHD_QUESTIONNAIRE: {
      type: 'adhdQuestionnaireForm',
      config: 'adhdQuestionnaireFormConfig'
    },
    RESCHEDULE: {
      type: 'resheduleForm',
      config: 'resheduleFormConfig'
    },
    FEEDBACK: {
      type: 'feedbackForm',
      config: 'feedbackFormConfig'
    },
    CANCELFEEDBACK: {
      type: 'cancelfeedbackForm',
      config: 'cancelfeedbackFormConfig'
    },
    CONTACTUS: {
      type: 'contactUsForm',
      config: 'contactUsFormConfig'
    },
    MEDICATION: {
      type: 'medicationConsentForm',
      config: 'medicationConsentFormConfig'
    },
    SUBSENTANCE_AGREEMENT: {
      type: 'subSetenceAgreementConsentForm',
      config: 'subSetenceAgreementConsentFormConfig'
    },
    SUBSENTANCE_TREATEMENT:{
      type:'subSentenceTreatmentConsentForm',
      config:'subSentenceTreatmentConsentFormConfig'
    },
    SUB_CONSENT: {
      type: 'subConsentForm',
      config: 'subConsentFormConfig'
    },
    TELEMEDICINE: {
      type: 'telemedicineConsentForm',
      config: 'telemedicineConsentFormConfig'
    },
    TELETHERAPY: {
      type: 'teletherapyConsentForm',
      config: 'teletherapyConsentFormConfig'
    },
    TELETHERAPYFEEDBACK:{
      type: 'teletherapyFeddbackConsentForm',
      config: 'teleFeedbackFormConfig'
    },
    HIPPA: {
      type: 'hippaForm',
      config: 'hippaFormConfig'
    },
    TMS_QUESTIONNAIRE: {
      type: 'tmsQuestionnaireFrom',
      config: 'tmsQuestionnaireFromConfig'
    },
    HAM_QUESTIONNAIRE: {
      type: 'hamQuestionnaireForm',
      config: 'hamQuestionnaireFormConfig'
    },
    GAD_QUESTIONNAIRE: {
      type: 'gadQuestionnaireForm',
      config: 'gadQuestionnaireFormConfig'
    },
    PH_QUESTIONNAIRE: {
      type: 'phQuestionnaireForm',
      config: 'phQuestionnaireFormConfig'
    },  
    SUB_SENTENCE: {
      type: 'subsentenceConsentForm',
      config: 'subsentenceConsentFormCongfig'
    },
    EMPCREATION: {
      type: 'employeeCreation',
      config: 'employeeCreationConfig'
    },
    UPLOADCONFIG: {
      type: 'uploadFiles',
      config: 'uploadConfig'
    },
    CREATEAPPOINTMENT: {
      type: 'appointmentCreation',
      config: 'newAppointmentConfig'
    },
    BOOKSLOT: {
      type: 'bookSlotsData',
      config: 'bookSlotConfig'
    },
    RESHEDULESLOT: {
      type: 'resheduleSlot',
      config: 'resheduleSlotConfig'
    },
    BLOCKEDSLOT: {
      type: 'blockedSlot',
      config: 'blockedSlotConfig'
    },
    LOCATIONCREATION: {
      type: 'createLocation',
      config: 'createLocationConfig'
    },
    LOCATIONSERVICEADD: {
      type: 'createLocationService',
      config: 'createLocationServiceConfig'
    },
    LOCATIONCREATIONPOPUP: {
      type: 'createLocationPopUP',
      config: 'createLocationConfigPopup'
    },
    TMSFEEDBACK: {
      type: 'tmsFeedbackForm',
      config: 'TMSFeedbackFormConfig'
    },
    KETAMINEFEEDBACK: {
      type: 'ketamineFeedbackForm',
      config: 'KETAMINEFeedbackFormConfig'
    },
    KETAMINEENROLLMENT: {
      type: 'ketamineEnrollmentForm',
      config: 'KETAMINEEnrollmentFormConfig'
    },
    KETAMINEMONITORING: {
      type: 'ketamineMonitoringForm',
      config: 'KETAMINEMonitoringFormConfig'
    },
    KETAMINE_MEDICATION:{
      type: 'ketamineMedicationForm',
      config: 'ketamineMedicationFormConfig'
    },
    KETAMINE_TREATMENT_SESSION:{
        type: 'ketamineTreatmentSessionForm',
        config: 'ketamineTreatmentSessionFormConfig'
    },
    REQUEST_THERAPIST_INFO: {
      type: 'requestTherapistInfoForm',
      config: 'requestTherapistInfoFormConfig'
    },
    KETAMINECONSENT: {
      type: 'ketamineConsentConsentForm',
      config: 'ketamineConsentConsentFormConfig'
    },
    TMSCONSENT: {
      type: 'tmsConsentConsentForm',
      config: 'tmsConsentConsentFormConfig'
    },
    INFORMATION_REQUEST: {
      type: 'informationRequestForm',
      config: 'informationRequestFormConfig'
    },
    MEDICATION_FEEDBACK: {
      type: 'medicationFeedbackForm',
      config: 'medicationFeedbackFormConfig'
    },
    MEDICAL_SUMMARY: {
      type: 'medicalSummaryForm',
      config: 'medicalSummaryFormConfig'
    },
    //Mednotes 
    MEDNOTES_FOLLOW_UP_TWENTY_FIVE: {
      type: 'mednoteFollowUpTwentyFive',
      config: 'mednoteFollowUpTwentyFiveConfig'
    },
    MEDNOTES_FOLLOW_UP_FORTY_FIVE:{
      type: 'mednoteFollowUpFortyFive',
      config: 'mednoteFollowUpFortyFiveConfig'
    },
    MEDNOTES_EVAL: {
      type: 'mednotesEval',
      config: 'mednotesEvalConfig'
    },
    //tmsNotes
    TMS_NOTES: {
      type: 'tmsNotes',
      config: 'tmsNoteFormConfig'
    }

  };
  medicationFeedbackFormConfig = [
    {
      type: 'rating',
      label: 'Rate the timeliness in responding to calls(answering and returning calls)',
      labelImage: '',
      required: true,
      key: 'timelinessInCalls',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'Rate the timeliness in responding to emails.',
      labelImage: '',
      required: true,
      key: 'timelinessInEmails',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'How would you rate how issues are resolved?',
      labelImage: '',
      required: true,
      key: 'issuesResolving',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'Please rate the ease of use of online scheduling portal, NewTeledoc.',
      labelImage: '',
      required: true,
      key: 'useOfOnlinePortal',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'On a scale of 1-10, rate the ease of obtaining an appointment.',
      labelImage: '',
      required: true,
      key: 'obtainingAppointment',
      max: '10',
      value: '',
      editable: true,
      controlType: 'rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'How helpful are staff in addressing your concerns?',
      labelImage: '',
      required: true,
      key: 'helpInAddressing',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'Rate the professionalism of staff who you either speak to, email with or directly interact with.',
      labelImage: '',
      required: true,
      key: 'professionalismOfStaff',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'How would you rate your interactions with our employees?',
      labelImage: '',
      required: true,
      key: 'interactionsWithEmployee',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'How likely are you to recommend our services to friends or colleagues?',
      labelImage: '',
      required: true,
      key: 'recommendOurServices',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'How do you rate our customer communication skills?',
      labelImage: '',
      required: true,
      key: 'customerCommunications',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'Rate your overall satisfaction with customer service.',
      labelImage: '',
      required: true,
      key: 'overallSatisfaction',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'How satisfied are you with your experience today?',
      labelImage: '',
      required: true,
      key: 'experienceSatisfactions',
      value: '',
      className: '',
      editable: true,
      controlType: 'radio-rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      fields: this.radioRatingOptions,
      showontoggle: true,
    },
  ]
  
  informationRequestFormConfig = [];

  gadQuestionnaireFormConfig = [
    {
      type: 'header',
      label: `Over the last 2 weeks, how often have you been
      bothered by the following problems?`,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Feeling nervous, anxious, or on edge`,
      labelImage: '',
      required: true,
      key: 'isFeelingNervous',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all sure',
          value: 'Not at all sure'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'Over half the days',
          value: 'Over half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Not being able to stop or control worrying`,
      labelImage: '',
      required: true,
      key: 'isNotBeingAble',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all sure',
          value: 'Not at all sure'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'Over half the days',
          value: 'Over half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Worrying too much about different things`,
      labelImage: '',
      required: true,
      key: 'isWorryingTooMuch',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all sure',
          value: 'Not at all sure'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'Over half the days',
          value: 'Over half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Trouble relaxing`,
      labelImage: '',
      required: true,
      key: 'isTroubleRelaxing',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all sure',
          value: 'Not at all sure'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'Over half the days',
          value: 'Over half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Being so restless that it's hard to sit still`,
      labelImage: '',
      required: true,
      key: 'isBeingSoRestless',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all sure',
          value: 'Not at all sure'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'Over half the days',
          value: 'Over half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Becoming easily annoyed or irritable`,
      labelImage: '',
      required: true,
      key: 'isBecomingEasilyAnnoyed',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all sure',
          value: 'Not at all sure'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'Over half the days',
          value: 'Over half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Feeling afraid as if something awful might
      happen`,
      labelImage: '',
      required: true,
      key: 'isFeelingAfraid',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all sure',
          value: 'Not at all sure'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'Over half the days',
          value: 'Over half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `If you checked off any problems, how difficult have these made it for you to do your work, take
      care of things at home, or get along with other people?`,
      labelImage: '',
      required: true,
      key: 'isIfYouCheckedOff',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not difficult at all',
          value: 'Not difficult at all'
        },
        {
          label: 'Somewhat difficult',
          value: 'Somewhat difficult'
        },
        {
          label: 'Very difficult',
          value: 'Very difficult'
        },
        {
          label: 'Extremely difficult',
          value: 'Extremely difficult'
        }
      ],
      showontoggle: true,
    },

  ];
  phQuestionnaireFormConfig = [
    {
      type: 'header',
      label: `Over the last 2 weeks, how often have you been bothered
      by any of the following problems?`,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Little interest or pleasure in doing things`,
      labelImage: '',
      required: true,
      key: 'isLittleInterest',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Feeling down, depressed, or hopeless`,
      labelImage: '',
      required: true,
      key: 'isFeelingDown',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Trouble falling/staying asleep, sleeping too much`,
      labelImage: '',
      required: true,
      key: 'isTroubleFalling',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Feeling tired or having little energy`,
      labelImage: '',
      required: true,
      key: 'isFeelingTired',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Poor appetite or overeating`,
      labelImage: '',
      required: true,
      key: 'isPoorAppetite',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Feeling bad about yourself or that you are a failure or
      have let yourself or your family down`,
      labelImage: '',
      required: true,
      key: 'isFeelingBad',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Trouble concentrating on things, such as reading the
      newspaper or watching television.`,
      labelImage: '',
      required: true,
      key: 'isTroubleConcentrating',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Moving or speaking so slowly that other people could
      have noticed. Or the opposite; being so fidgety or
      restless that you have been moving around a lot more
      than usual.`,
      labelImage: '',
      required: true,
      key: 'isMovingOrSpeakingSoSlowly',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `Thoughts that you would be better off dead or of hurting
      yourself in some way.`,
      labelImage: '',
      required: true,
      key: 'isDeadThoughts',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Several days',
          value: 'Several days'
        },
        {
          label: 'More than half the days',
          value: 'More than half the days'
        },
        {
          label: 'Nearly every day',
          value: 'Nearly every day'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `If you checked off any problem on this questionnaire so
      far, how difficult have these problems made it for you to do
      your work, take care of things at home, or get along with
      other people?`,
      labelImage: '',
      required: true,
      key: 'isHowDifficult',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not at all',
          value: 'Not at all'
        },
        {
          label: 'Somewhat difficult',
          value: 'Somewhat difficult'
        },
        {
          label: 'Very difficult',
          value: 'Very difficult'
        },
        {
          label: 'Extremely difficult',
          value: 'Extremely difficult'
        }
      ],
      showontoggle: true,
    },
  ];
  hamQuestionnaireFormConfig = [
    {
      type: 'header',
      label: `The HAM-D is designed to rate the severity of depression in patients. Although it contains 21 areas, calculate the patient’s
      score on the first 17 answers.`,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `DEPRESSED MOOD
      (Gloomy attitude, pessimism about the future,
      feeling of sadness, tendency to weep)`,
      labelImage: '',
      required: true,
      key: 'isDepressionMood',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Sadness, etc.',
          value: 'Sadness, etc.'
        },
        {
          label: 'Occasional weeping',
          value: 'Occasional weeping'
        },
        {
          label: 'Frequent weeping',
          value: 'Frequent weeping'
        },
        {
          label: 'Extreme symptom',
          value: 'Extreme symptom'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `FEELINGS OF GUILT`,
      labelImage: '',
      required: true,
      key: 'isFeelingGuilt',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Self-reproach, feels he/she has let people down',
          value: 'Self-reproach, feels he/she has let people down'
        },
        {
          label: 'Ideas of guilt',
          value: 'Ideas of guilt'
        },
        {
          label: 'Present illness is a punishment; delusions of guilt',
          value: 'Present illness is a punishment; delusions of guilt'
        },
        {
          label: 'Hallucinations of guilt',
          value: 'Hallucinations of guilt'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `SUICIDE`,
      labelImage: '',
      required: true,
      key: 'isSuicide',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Feels life is not worth living',
          value: 'Feels life is not worth living'
        },
        {
          label: 'Wishes he/she were dead',
          value: 'Wishes he/she were dead'
        },
        {
          label: 'Suicidal ideas or gestures',
          value: 'Suicidal ideas or gestures'
        },
        {
          label: 'Attempts at suicide',
          value: 'Attempts at suicide'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `INSOMNIA - Initial
      (Difficulty in falling asleep)`,
      labelImage: '',
      required: true,
      key: 'isInsomnia',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Occasional',
          value: 'Occasional'
        },
        {
          label: 'Frequent',
          value: 'Frequent'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `INSOMNIA - Middle
      (Complains of being restless and disturbed
      during the night. Waking during the night.)`,
      labelImage: '',
      required: true,
      key: 'isInsomniaMiddle',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Occasional',
          value: 'Occasional'
        },
        {
          label: 'Frequent',
          value: 'Frequent'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `INSOMNIA - Delayed
      (Waking in early hours of the morning and
      unable to fall asleep again)`,
      labelImage: '',
      required: true,
      key: 'isInsomniaDelayed',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Occasional',
          value: 'Occasional'
        },
        {
          label: 'Frequent',
          value: 'Frequent'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `WORK AND INTERESTS`,
      labelImage: '',
      required: true,
      key: 'isWorkAndInterest',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'No difficulty',
          value: 'No difficulty'
        },
        {
          label: 'Feelings of incapacity, listlessness, indecision and vacillation',
          value: 'Feelings of incapacity, listlessness, indecision and vacillation'
        },
        {
          label: 'Loss of interest in hobbies, decreased social activities',
          value: 'Loss of interest in hobbies, decreased social activities'
        },
        {
          label: 'Productivity decreased',
          value: 'Productivity decreased'
        },
        {
          label: 'Unable to work. Stopped working because of present illness only. (Absence from work after treatment or recovery may rate a lower score).',
          value: 'Unable to work. Stopped working because of present illness only. (Absence from work after treatment or recovery may rate a lower score).'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `RETARDATION
      (Slowness of thought, speech, and activity;
      apathy; stupor.)`,
      labelImage: '',
      required: true,
      key: 'isRetardation',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Slight retardation at interview',
          value: 'Slight retardation at interview'
        },
        {
          label: 'Obvious retardation at interview',
          value: 'Obvious retardation at interview'
        },
        {
          label: 'Interview difficult',
          value: 'Interview difficult'
        },
        {
          label: 'Complete stupor',
          value: 'Complete stupor'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `AGITATION (Restlessness associated with anxiety.)`,
      labelImage: '',
      required: true,
      key: 'isAgitation',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Occasional',
          value: 'Occasional'
        },
        {
          label: 'Frequent',
          value: 'Frequent'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `ANXIETY - PSYCHIC`,
      labelImage: '',
      required: true,
      key: 'isAnxietyPsychic',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'No difficulty',
          value: 'No difficulty'
        },
        {
          label: 'Tension and irritability',
          value: 'Tension and irritability'
        },
        {
          label: 'Worrying about minor matters',
          value: 'Worrying about minor matters'
        },
        {
          label: 'Apprehensive attitude',
          value: 'Apprehensive attitude'
        },
        {
          label: 'Fears',
          value: 'Fears'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `ANXIETY - SOMATIC
      Gastrointestinal, indigestion
      Cardiovascular, palpitation, Headaches
      Respiratory, Genito-urinary, etc.`,
      labelImage: '',
      required: true,
      key: 'isAnxietySomatic',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Mild',
          value: 'Mild'
        },
        {
          label: 'Moderate',
          value: 'Moderate'
        },
        {
          label: 'Severe',
          value: 'Severe'
        },
        {
          label: 'Incapacitating',
          value: 'Incapacitating'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `SOMATIC SYMPTOMS -
      GASTROINTESTINAL
      (Loss of appetite , heavy feeling in abdomen;
      constipation)`,
      labelImage: '',
      required: true,
      key: 'isSomaticSymptomsGastrointestinal',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Mild',
          value: 'Mild'
        },
        {
          label: 'Severe',
          value: 'Severe'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `SOMATIC SYMPTOMS - GENERAL
      (Heaviness in limbs, back or head; diffuse
      backache; loss of energy and fatiguability.`,
      labelImage: '',
      required: true,
      key: 'isSomaticSymptomsGeneral',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Mild',
          value: 'Mild'
        },
        {
          label: 'Severe',
          value: 'Severe'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `GENITAL SYMPTOMS (Loss of libido, menstrual disturbances)`,
      labelImage: '',
      required: true,
      key: 'isGenitalSymptoms',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Mild',
          value: 'Mild'
        },
        {
          label: 'Severe',
          value: 'Severe'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `HYPOCHONDRIASIS`,
      labelImage: '',
      required: true,
      key: 'isHypochondriasis',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Not present',
          value: 'Not present'
        },
        {
          label: 'Self-absorption (bodily)',
          value: 'Self-absorption (bodily)'
        },
        {
          label: 'Preoccupation with health',
          value: 'Preoccupation with health'
        },
        {
          label: 'Querulous attitude',
          value: 'Querulous attitude'
        },
        {
          label: 'Hypochondriacal delusions',
          value: 'Hypochondriacal delusions'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `WEIGHT LOSS`,
      labelImage: '',
      required: true,
      key: 'isWeightLoss',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'No weight loss',
          value: 'No weight loss'
        },
        {
          label: 'Slight',
          value: 'Slight'
        },
        {
          label: 'Obvious or severe',
          value: 'Obvious or severe'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `INSIGHT (Insight must be interpreted in terms of patient’s understanding and background.)`,
      labelImage: '',
      required: true,
      key: 'isInsight',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'No loss',
          value: 'No loss'
        },
        {
          label: 'Partial or doubtfull loss',
          value: 'Partial or doubtfull loss'
        },
        {
          label: 'Loss of insight',
          value: 'Loss of insight'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `DIURNAL VARIATION
      (Symptoms worse in morning or evening.
      Note which it is. )`,
      labelImage: '',
      required: true,
      key: 'isDiurnalVariation',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'No variation',
          value: 'No variation'
        },
        {
          label: 'Mild variation; AM ( ) PM ( )',
          value: 'Mild variation; AM ( ) PM ( )'
        },
        {
          label: 'Severe variation; AM ( ) PM ( )',
          value: 'Severe variation; AM ( ) PM ( )'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `DEPERSONALIZATION AND
      DEREALIZATION
      (feelings of unreality, nihilistic ideas)`,
      labelImage: '',
      required: true,
      key: 'isDepersonalizationAndDerealization',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Mild',
          value: 'Mild'
        },
        {
          label: 'Moderate',
          value: 'Moderate'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `PARANOID SYMPTOMS
      (Not with a depressive quality)`,
      labelImage: '',
      required: true,
      key: 'isParanoidSymptoms',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'None',
          value: 'None'
        },
        {
          label: 'Suspicious',
          value: 'Suspicious'
        },
        {
          label: 'Ideas of reference',
          value: 'Ideas of reference'
        },
        {
          label: 'Delusions of reference and persecution',
          value: 'Delusions of reference and persecution'
        },
        {
          label: 'Hallucinations, persecutory',
          value: 'Hallucinations, persecutory'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      label: `OBSESSIONAL SYMPTOMS
      (Obsessive thoughts and compulsions against
      which the patient struggles`,
      labelImage: '',
      required: true,
      key: 'isObsessionalSymptoms',
      value: '',
      className: 'radio-btn-horizontal',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Absent',
          value: 'Absent'
        },
        {
          label: 'Mild',
          value: 'Mild'
        },
        {
          label: 'Severe',
          value: 'Severe'
        }
      ],
      showontoggle: true,
    },
  ];
  tmsQuestionnaireFromConfig = [
    {
      type: 'header',
      label: `Please read each question carefully, answering accurately and truthfully, and if you have
      any doubt as to how to answer any question please tick ‘yes’.`,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isEpilepsy',
      checkboxlabel: 'Do you have epilepsy or have you ever had a convulsion or a seizure?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isFaintingSpell',
      checkboxlabel: 'Have you ever had a fainting spell? If yes, please describe in which occasion(s)?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Please enter your discription',
      label: 'Provide discription',
      required: true,
      key: 'isFaintingSpell_description',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      className: 'message-box',
      emptyValueMessage: 'Please enter your discription',
      validationValue: 'Please enter valid discription',
      validators: [],
      focus: true,
      count: '150 remaining',
      showontoggle: false,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isSevereTrauma',
      checkboxlabel: 'Have you ever had severe (i.e. followed by loss of consciousness) head trauma?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isHearingProblems',
      checkboxlabel: 'Do you have any hearing problems or ringing in your ears?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isPregnant',
      checkboxlabel: 'Are you pregnant or is there any chance that you might be?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isMetalBrain',
      checkboxlabel: 'Do you have metal in the brain/skull (except titanium)? (e.g. splinters,  fragments, clips, etc.)',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isCochlearImplants',
      checkboxlabel: 'Do you have cochlear implants?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isTattoos',
      checkboxlabel: 'Do you have any tattoos in the head or neck area?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isNeurostimulator',
      checkboxlabel: 'Do you have an implanted neurostimulator? (E.g. DBS, epidural/subdural, VNS)',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isCardiacPacemaker',
      checkboxlabel: 'Do you have a cardiac pacemaker or intracardiac lines or metal in your body?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isMedicationInfusion',
      checkboxlabel: 'Do you have a medication infusion device?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isPsychiatricOrNeuroactive',
      checkboxlabel: 'Are you taking any psychiatric or neuroactive medications? (Please list)',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Please enter your discription',
      label: 'Provide discription',
      required: true,
      key: 'isPsychiatric_Neuroactive_description',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      className: 'message-box',
      emptyValueMessage: 'Please enter your discription',
      validationValue: 'Please enter valid discription',
      validators: [],
      focus: true,
      count: '150 remaining',
      showontoggle: false,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isEverUndergoTMS',
      checkboxlabel: 'Did you ever undergo TMS in the past? If so, were there any problems?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isUndergoMRI',
      checkboxlabel: 'Did you ever undergo MRI in the past? If so, were there any problems?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isHeavyGoodsVehicle',
      checkboxlabel: 'Do you hold a Heavy Goods Vehicle driving license, pilot’s license or bus license?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: 'isExistingMedicalCondition',
      checkboxlabel: 'Do you have any existing medical condition or undertaking treatment for an existing medical condition?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `Affirmative answers to this questionnaire do not represent absolute contraindications to
      TMS, but the risk/benefit ratio should be carefully balanced by the clinician and patient.
      I confirm that I have read and understood the above information and that each question
      has been answered to the best of my knowledge.`,
      controlType: 'checkbox-tik',
      key: 'iAcceptTermsConditions',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
  ];
  contactUsFormConfig = [
    {
      type: 'text',
      placeholder: 'Please enter your name',
      label: 'Name',
      required: true,
      key: 'name',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter your name',
      validationValue: 'Please enter valid name',
      className: 'side__by__side',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter your email id',
      label: 'Email',
      required: true,
      key: 'email',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      className: 'side__by__side',
      editable: true,
      emptyValueMessage: 'Please enter your email id',
      validationValue: 'Please enter valid email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Enter your message',
      label: 'Message',
      required: true,
      key: 'message',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      className: 'message-box',
      emptyValueMessage: 'Please enter your message',
      validationValue: 'Please enter valid message',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'checkbox',
      labeltext: 'I accept',
      controlType: 'checkbox-tik',
      key: 'iacceptTermsConditions',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'link',
      placeholder: '',
      link: 'Terms & Conditions',
      className: 'side__by__side terms-condition',
      href: '/terms',
      editable: true,
      showontoggle: true,
    }
  ];
  hippaFormConfig = [
    {
      type: 'checkbox',
      labeltext: 'I accept the terms and conditions',
      controlType: 'checkbox-tik',
      key: 'iaccepttermscondition ',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    }
  ];
  telemedicineConsentFormConfig = [
    {
      type: 'checkbox',
      labeltext: `I understand that Dr. Prayaga offers the opportunity for drug testing and other vital monitoring procedures such as EKG in an office setting. I acknowledge that by opting to participate in Telemedicine those services are not available to me therefore I am responsible when requested to get the procedures done within the specified timeframe and I agree to assume financial responsibility for such procedures.`,
      controlType: 'checkbox-tik',
      key: 'iamResponsible',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I have read and understand the information provided above regarding telemedicine,have discussed it with my physician or such assistants as may be designated,and all of my Questions have been answered to my satisfaction. I hereby give my informed consent for the use of telemedicine in my medical care.',
      controlType: 'checkbox-tik',
      key: 'iAcknowledge',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I understand that i must provide a 24-hour\'s notification to cancel an appointment. I understand that failure to provide a 24-hour notification or failure to show up for an Appointment will result in a $25.00 cancellation fee. All cancellations will be Assessed and charged at the next office visit.',
      controlType: 'checkbox-tik',
      key: 'iunderstand',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I hereby authorize Dr.Rama Prayaga to use Telemedicine in the course of my diagnosis and treatment.',
      controlType: 'checkbox-tik',
      key: 'iAccept',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I authorize you to check PMP(Prescription Monitoring Program) to avoid duplications, over prescribing and misuse/abuse of medications.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `If any document is requested apart from the medication, separate charges apply and if the requested document is after 4 days of the Appointment, the patient has to schedule a NEW appointment.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Dr Prayaga requests drug screening at CLIA certified lab center. If any guidance is required office will assist.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Dr Prayaga requests to work with newteledoc for coordination of Care with PCP, therapist, or any other providers.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `If the pharmacy is unable to fill the medication because of the time lapse, new Appointment is required to resend the prescriptions.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `When you ask for a change of pharmacy after 10 days of medications being sent, NEW Appointment is required.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `When medication is lost/stolen a NEW appointment is required for the medication to be re-issued.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `In case of any partial fill of the medication by the pharmacy for any reasons, need to schedule new appointment and pay . Also, we will be requiring a fax from the pharmacy that the medication was partially filled.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Newstressclinic/ Dr.Prayaga are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Please be advised to consider nalaxone as safety option for taking controlled substance.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I hereby provided the payment for this date of service is accurate/ genuine and will not be disputed as fraud either to the insurance company nor credit card company.`,
      showontoggle: true,
    },
    {
      type: 'note',
      note: '*NOTE:  All Patients must be physically in the tri-states (VA, DC, MD) during the visit in addition to maintaining legal residence in the tri-states (VA, DC, MD).',
      showontoggle: true,
    },
    {
      type: 'signature',
      label: '',
      placeholder: 'Signature',
      labelImage: '',
      required: true,
      maxLength: '20',
      key: 'telemedicine_signature',
      value: '',
      className: '',
      editable: true,
      controlType: 'signature',
      emptyValueMessage: 'Please enter signature',
      validationValue: 'Please enter same signature',
      validators: [Validators.required, this.validateAplha, this.validateSignature.bind(this)],
      showontoggle: true,
    }
  ];
  teletherapyConsentFormConfig = [
    {
      type: 'checkbox',
      labeltext: `I understand that New stress clinic offers the opportunity for drug testing and other vital monitoring procedures such as EKG in an office setting. I acknowledge that by opting to participate in Teletherapy those services are not available to me therefore I am responsible when requested to get the procedures done within the specified timeframe and I agree to assume financial responsibility for such procedures.`,
      controlType: 'checkbox-tik',
      key: 'iamResponsible',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I have read and understand the information provided above regarding teletherapy,have discussed it with my physician or such assistants as may be designated,and all of my Questions have been answered to my satisfaction. I hereby give my informed consent for the use of teletherapy in my medical care.',
      controlType: 'checkbox-tik',
      key: 'iAcknowledge',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I understand that i must provide a 24-hour\'s notification to cancel an appointment. I understand that failure to provide a 24-hour notification or failure to show up for an Appointment will result in a $25.00 cancellation fee. All cancellations will be Assessed and charged at the next office visit.',
      controlType: 'checkbox-tik',
      key: 'iunderstand',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I hereby authorize New Stress Clinic to use teletherapy in the course of my diagnosis and treatment.',
      controlType: 'checkbox-tik',
      key: 'iAccept',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I authorize you to check PMP(Prescription Monitoring Program) to avoid duplications, over prescribing and misuse/abuse of medications.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `If any document is requested apart from the medication, separate charges apply and if the requested document is after 4 days of the Appointment, the patient has to schedule a NEW appointment.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `New Stress Clinic requests drug screening at CLIA certified lab center. If any guidance is required office will assist.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `New Stress Clinic requests to work with newteledoc for coordination of Care with PCP, therapist, or any other providers.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `If the pharmacy is unable to fill the medication because of the time lapse, new Appointment is required to resend the prescriptions.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `When you ask for a change of pharmacy after 10 days of medications being sent, NEW Appointment is required.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `When medication is lost/stolen a NEW appointment is required for the medication to be re-issued.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `In case of any partial fill of the medication by the pharmacy for any reasons, need to schedule new appointment and pay . Also, we will be requiring a fax from the pharmacy that the medication was partially filled.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Newstressclinic are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Please be advised to consider nalaxone as safety option for taking controlled substance.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I hereby provided the payment for this date of service is accurate/ genuine and will not be disputed as fraud either to the insurance company nor credit card company.`,
      showontoggle: true,
    },
    {
      type: 'note',
      note: '*NOTE:  All Patients must be physically in the tri-states (VA, DC, MD) during the visit in addition to maintaining legal residence in the tri-states (VA, DC, MD).',
      showontoggle: true,
    },
    {
      type: 'signature',
      label: '',
      placeholder: 'Signature',
      labelImage: '',
      required: true,
      maxLength: '20',
      key: 'telemedicine_signature',
      value: '',
      className: '',
      editable: true,
      controlType: 'signature',
      emptyValueMessage: 'Please enter signature',
      validationValue: 'Please enter same signature',
      validators: [Validators.required, this.validateAplha, this.validateSignature.bind(this)],
      showontoggle: true,
    }
  ];
  medicationConsentFormConfig = [
    {
      type: 'confirmation',
      label: '',
      confirmationMsg: `agree to consent for my Psychiatric treatment to include the
      use of medication. I understand the risk of medications includes possible side effects.`,
      placeholder: '',
      labelImage: '',
      required: true,
      // maxLength:'10',
      key: 'medication_confirmation',
      value: '',
      className: '',
      editable: false,
      controlType: 'confirmation',
      emptyValueMessage: 'Please enter the name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required],
      showontoggle: true,
    },
    {
      type: 'content',
      label: 'I understand that three month supply of medications will not be provided. Medications will be filled once monthly only after Appointment.',
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I understand the information provided to me and have been given the opportunity to discuss other treatment and to raise the questions regarding the use of medication. I agree to get my lab work done every 6 months or annually. I will email my clinician promptly for any questions or problems regarding treatment with medications.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I understand that I must provide a 24 hours notification to cancel an appointment. I understand that failure to provide a 24-hour notification or failure to show up for an appointment will result in a $25.00 cancellation fee. All cancellations will be assessed and charged at the next office visit.`,
      showontoggle: true,
    }, {
      type: 'content',
      label: `For any reason, you are not able to fill the medication, you will need to pay an ADMIN fee within 10 days from the date of Appointment, if it is more than 10 days you will have to schedule another appointment.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `No pets are allowed in the office.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `If the prescription/ medication is lost or stolen due to any circumstances, you need to submit a police report and then schedule a follow up appointment. Until then medication will not be called in.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `The medications will only be sent once you provide a copy of your medical records from your previous psychiatrist.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I authorize you to check PMP(Prescription Monitoring Program) to avoid duplications, over prescribing and misuse/abuse of medications.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: 'An admin fee of $10 will be charged for seeking ADMIN help to update the profile, pharmacy, or any other details. This fee does not include Pharmacy change, pharmacy change fee is different from this.',
      showontoggle: true,
    },
    {
      type: 'content',
      label: `If any document is requested apart from the medication, separate charges apply and if the requested document is after 4 days of the Appointment, the patient has to schedule a NEW appointment.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Dr Prayaga requests drug screening at CLIA certified lab center. If any guidance is required office will assist.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Dr Prayaga requests to work with newteledoc for coordination of Care with PCP, therapist, or any other providers.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `If the pharmacy is unable to fill the medication because of the time lapse, new Appointment is required to resend the prescriptions.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `When you ask for a change of pharmacy after 10 days of medications being sent, NEW Appointment is required.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `When medication is lost/stolen a NEW appointment is required for the medication to be re-issued.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `In case of any partial fill of the medication by the pharmacy for any reasons, need to schedule new appointment and pay . Also, we will be requiring a fax from the pharmacy that the medication was partially filled.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Newstressclinic/ Dr.Prayaga are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `Please be advised to consider nalaxone as safety option for taking controlled substance.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `I hereby provided the payment for this date of service is accurate/ genuine and will not be disputed as fraud either to the insurance company nor credit card company.`,
      showontoggle: true,
    },
    {
      type: 'content',
      label: `You will be responsible for the payment of the services done if insurance denies the claims or doesnt pay for whatever the reason.`,
      showontoggle: true,
    },
    {
      type: 'note',
      note: '*NOTE: All Patients must be from tri-states(VA, DC, MD) during the visit.',
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Relationship to patient',
      labelImage: '',
      required: true,
      key: 'feel_satisfied',
      value: '',
      className: 'radio-btn-horizontal',
      editable: false,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Self',
          value: 'Self'
        },
        {
          label: 'Parent',
          value: 'Parent'
        },
        {
          label: 'Legal Guardian',
          value: 'Legal Guardian'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: MM_CONSENT_FORM.MM_CONSENT_HOSPITALIZED_BEFORE,
      value: false,
      checkboxlabel: `Have you been hospitalized in the past one month?`,
      controlType: 'checkbox-slider',
      className: 'consent_form_field',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Enter the name of the hospital',
      label: 'Name of the hospital',
      required: true,
      key: MM_CONSENT_FORM.MM_CONSENT_HOSP_NAME,
      labelImage: '',
      value: '',
      className: 'consent_form_field',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, this.validateName, Validators.minLength(1), this.valideTextArea],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Reason for hospitalization',
      label: 'Reason for hospitalization',
      required: true,
      key: MM_CONSENT_FORM.MM_CONSENT_REASON,
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      className: 'message-box consent_form_field',
      emptyValueMessage: 'Please enter reason',
      validationValue: 'Please enter valid reason',
      validators: [Validators.required, this.valideTextArea, Validators.minLength(1)],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'signature',
      label: '',
      placeholder: 'Signature',
      labelImage: '',
      required: true,
      maxLength: '20',
      key: 'medication_signature',
      value: '',
      className: '',
      editable: true,
      controlType: 'signature',
      emptyValueMessage: 'Please enter signature',
      validationValue: 'Please enter valid signature(only Alphabets)',
      validators: [Validators.required, this.validateAplha],
      showontoggle: true,
    }
  ];
  subConsentFormConfig = [
    {
      type: 'confirmation',
      label: '',
      confirmationMsg: `understand and voluntarily agree that `,
      placeholder: '',
      labelImage: '',
      required: true,
      // maxLength:'10',
      key: 'medication_confirmation',
      value: '',
      className: '',
      editable: false,
      controlType: 'confirmation',
      emptyValueMessage: 'Please enter the name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required],
      showontoggle: true,
    },
  ];
  subSetenceAgreementConsentFormConfig= [
      {
        type: 'confirmation',
        label: '',
        confirmationMsg: `making a commitment to work with you in your efforts to
        get better. To help you in this work, we agree that:`,
        placeholder: '',
        labelImage: '',
        required: true,
        // maxLength:'10',
        key: 'medication_confirmation',
        value: '',
        className: '',
        editable: false,
        controlType: 'confirmationAre',
        emptyValueMessage: 'Please enter the name',
        validationValue: 'Please enter valid name',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'content',
        label: 'We will help you schedule regular appointments for medicine refills. If we have to cancel or change your appointment for any reason, we will make sure you have enough medication to last until your next appointment.',
        showontoggle: true,
      },
      {
        type: 'content',
        label: `We will make sure that this treatment is as safe as possible. We will check regularly to make
        sure you are not having bad side effects.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `We will keep track of your prescriptions and test for drug use regularly to help you feel like you are
        being monitored well.`,
        showontoggle: true,
      }, {
        type: 'content',
        label: `We will keep track of your prescriptions and test for drug use regularly to help you feel like you are
        being monitored well.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `We will help connect you with other forms of treatment to help you with your condition. We `,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `will help set treatment goals and monitor your progress in achieving those goals.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `We will work with any other doctors or providers you are seeing so that they can treat you safely and
        effectively.`,
        showontoggle: true,
      },

      {
        type: 'content',
        label: `If you become addicted to these medications, we will help you get treatment and get off of the
        medications that are causing you problems safely, without getting sick.`,
        showontoggle: true,
      },
  ];
  subSentenceTreatmentConsentFormConfig=[   
    {
      type: 'checkbox',
      labeltext: 'I accept the terms and conditions',
      controlType: 'checkbox-tik',
      key: 'iaccepttermscondition ',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    }, 
    {  
    type: 'signature',
    label: '',
    placeholder: 'Signature',
    labelImage: '',
    required: true,
    maxLength: '20',
    key: 'telemedicine_signature',
    value: '',
    className: '',
    editable: true,
    controlType: 'signature',
    emptyValueMessage: 'Please enter signature',
    validationValue: 'Please enter same signature',
    validators: [Validators.required, this.validateAplha, this.validateSignature.bind(this)],
    showontoggle: true,
  }
  ];
  feedbackFormConfig = [
    {
      type: 'header',
      label: 'Ease of getting care',
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'How easy was it for you to enter & meet the doctor?',
      labelImage: '',
      required: true,
      key: 'meet_doctor',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'What do you feel about the operation timings of the Clinic?',
      labelImage: '',
      required: true,
      key: 'operation_timing',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Convenience of Clinic’s location',
      labelImage: '',
      required: true,
      key: 'convenience_clinic_location',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Time in waiting room',
      labelImage: '',
      required: true,
      key: 'waiting_room',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Provider/Physician',
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'How was your response to medication management by Dr. Prayaga?',
      labelImage: '',
      required: true,
      key: 'medication_management',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'sideeffects_from_meds',
      checkboxlabel: 'Did you have any side effects from meds prescribed by Dr. Prayaga',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      id: 12,
      // idName:'legal_issue_description_parent',
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Provide details',
      required: true,
      key: 'sideeffects_from_meds_description',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: 'email_communicate',
      value: false,
      checkboxlabel: 'Did Dr. Prayaga make you aware to communicate in email after visit?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'alternate_treatment',
      checkboxlabel: 'Did Dr. Prayaga discuss with you alternate treatment programs such as TMS Therapy\ror Ketamine Treatment?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'hippa_compliant',
      checkboxlabel: 'Did Dr. Prayaga discuss HIPAA Compliant Telemedicine visits with you? ',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Do you feel that you were satisfied with Dr. Prayaga’s treatment?',
      labelImage: '',
      required: true,
      key: 'feel_satisfied',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal-space',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.satisfactionMetric,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'What is the likelihood of Referring your friends and relatives to us?',
      labelImage: '',
      required: true,
      key: 'rating',
      max: "10",
      value: '',
      editable: true,
      controlType: 'rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Suggestion',
      // required: true,
      key: 'suggestion_box',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: "Please add description",
      validators: [],
      className: '',
      editable: true,
      count: '150 remaining',
      showRange: true,
      showontoggle: true,
    },
  ];
  teleFeedbackFormConfig = [
    {
      type: 'header',
      label: 'Ease of getting care',
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'How easy was it for you to enter & meet the doctor?',
      labelImage: '',
      required: true,
      key: 'meet_doctor',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'What do you feel about the operation timings of the Clinic?',
      labelImage: '',
      required: true,
      key: 'operation_timing',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Convenience of Clinic’s location',
      labelImage: '',
      required: true,
      key: 'convenience_clinic_location',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Time in waiting room',
      labelImage: '',
      required: true,
      key: 'waiting_room',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Provider/Physician',
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'How was your response to Theletherapy by New Stress Clinic?',
      labelImage: '',
      required: true,
      key: 'medication_management',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.qualityMetric,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'sideeffects_from_meds',
      checkboxlabel: 'Did you have any side effects from meds prescribed by New Stress Clinic',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      id: 12,
      // idName:'legal_issue_description_parent',
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Provide details',
      required: true,
      key: 'sideeffects_from_meds_description',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: 'email_communicate',
      value: false,
      checkboxlabel: 'Did New Stress Clinic make you aware to communicate in email after visit?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'alternate_treatment',
      checkboxlabel: 'Did New Stress Clinic discuss with you alternate treatment programs such as TMS Therapy\ror Ketamine Treatment?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'hippa_compliant',
      checkboxlabel: 'Did New Stress Clinic discuss HIPAA Compliant Telemedicine visits with you? ',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Do you feel that you were satisfied with New Stress Clinic treatment?',
      labelImage: '',
      required: true,
      key: 'feel_satisfied',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal-space',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.satisfactionMetric,
      showontoggle: true,
    },
    {
      type: 'rating',
      label: 'What is the likelihood of Referring your friends and relatives to us?',
      labelImage: '',
      required: true,
      key: 'rating',
      max: "10",
      value: '',
      editable: true,
      controlType: 'rating',
      emptyValueMessage: 'Please rate our service',
      validators: [Validators.required],
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Suggestion',
      // required: true,
      key: 'suggestion_box',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: "Please add description",
      validators: [],
      className: '',
      editable: true,
      count: '150 remaining',
      showRange: true,
      showontoggle: true,
    },
  ];
  adhdQuestionnaireFormConfig: IformConfigI[] = [
    {
      id: 0,
      type: 'radio',
      label: `How often do you have trouble wrapping up the final details of a project,
      once the challenging parts have been done?`,
      labelImage: '',
      required: true,
      key: 'projectWrappingupTrouble',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 1,
      type: 'radio',
      label: `How often do you have difficulty getting things in order when you have to do
      a task that requires organization?`,
      labelImage: '',
      required: true,
      key: 'thingsOrganizationDifficulty',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 2,
      type: 'radio',
      label: `How often do you have problems remembering appointments or obligations?`,
      labelImage: '',
      required: true,
      key: 'rememberingAppointmentsOrObligations',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 3,
      type: 'radio',
      label: `When you have a task that requires a lot of thought, how often do you avoid
      or delay getting started?`,
      labelImage: '',
      required: true,
      key: 'avoidOrDelayGettingStarted',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 4,
      type: 'radio',
      label: `How often do you fidget or squirm with your hands or feet when you have
      to sit down for a long time?`,
      labelImage: '',
      required: true,
      key: 'fidgetWhenSitForLongTime',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 5,
      type: 'radio',
      label: `How often do you feel overly active and compelled to do things, like you
      were driven by a motor?`,
      labelImage: '',
      required: true,
      key: 'feelOverlyActive',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 6,
      type: 'radio',
      label: `How often do you make careless mistakes when you have to work on a boring or
      difficult project?`,
      labelImage: '',
      required: true,
      key: 'carelessMistakes',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 7,
      type: 'radio',
      label: `How often do you have difficulty keeping your attention when you are doing boring
      or repetitive work?`,
      labelImage: '',
      required: true,
      key: 'difficultyOnKeepingAttention',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 8,
      type: 'radio',
      label: `How often do you have difficulty concentrating on what people say to you,
      even when they are speaking to you directly?`,
      labelImage: '',
      required: true,
      key: 'difficultyOnConcentration',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 9,
      type: 'radio',
      label: `How often do you misplace or have difficulty finding things at home or at work?`,
      labelImage: '',
      required: true,
      key: 'difficultyOnFindingThings',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 10,
      type: 'radio',
      label: `How often are you distracted by activity or noise around you?`,
      labelImage: '',
      required: true,
      key: 'distractedByActivity',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 11,
      type: 'radio',
      label: `How often do you leave your seat in meetings or other situations in which
      you are expected to remain seated?`,
      labelImage: '',
      required: true,
      key: 'leaveSeatInMeetings',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 12,
      type: 'radio',
      label: `How often do you feel restless or fidgety?`,
      labelImage: '',
      required: true,
      key: 'restlessOrFidgety',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 13,
      type: 'radio',
      label: `How often do you have difficulty unwinding and relaxing when you have time
      to yourself?`,
      labelImage: '',
      required: true,
      key: 'difficultyOnUnwinding',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 14,
      type: 'radio',
      label: `How often do you find yourself talking too much when you are in social situations?`,
      labelImage: '',
      required: true,
      key: 'talkingTooMuch',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 15,
      type: 'radio',
      label: `When you’re in a conversation, how often do you find yourself finishing
      the sentences of the people you are talking to, before they can finish
      them themselves?`,
      labelImage: '',
      required: true,
      key: 'finishingConversationYourself',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 16,
      type: 'radio',
      label: `How often do you have difficulty waiting your turn in situations when
      turn taking is required?`,
      labelImage: '',
      required: true,
      key: 'difficultyOnWaiting',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      id: 17,
      type: 'radio',
      label: `How often do you interrupt others when they are busy?`,
      labelImage: '',
      required: true,
      key: 'interrruptOthers',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.adhdOptions,
      showontoggle: true,
    },
    {
      type: 'submit',
      required: true,
      text: 'Cancel',
      key: '',
      value: 'cancel',
      className: 'cancel-block',
      controlType: 'cancelButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
    },
    {
      type: 'submit',
      required: true,
      text: 'Submit',
      key: '',
      value: 'submit',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      className: 'newemployee'
    }
  ]
  subsentenceConsentFormCongfig = [
    {
      type: 'checkbox',
      labeltext: `I will keep (and be on time for) all my scheduled appointments with the doctor and other members of the treatment team`,
      controlType: 'checkbox-tik',
      key: 'iamResponsible',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will participate in all other types of treatment that I am asked to participate in',
      controlType: 'checkbox-tik',
      key: 'iAcknowledge',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will participate in all other types of treatment that I am asked to participate in.',
      controlType: 'checkbox-tik',
      key: 'iunderstand',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will keep the medicine safe, secure and out of the reach of children. If the medicine is lost or stolen, I understand it will not be replaced until my next appointment, and may not be replaced at all.',
      controlType: 'checkbox-tik',
      key: 'iAccepted_secure',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will take my medication as instructed and not change the way I take it without first talking to the doctor or other member of the treatment team.',
      controlType: 'checkbox-tik',
      key: 'iAccept_medicaiton',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will not call between appointments, or at night or on the weekends looking for refills. I understand that prescriptions will be filled only during scheduled office visits with the treatment team.',
      controlType: 'checkbox-tik',
      key: 'iAccept_call',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will make sure I have an appointment for refills. If I am having trouble making an appointment, I will tell a member of the treatment team immediately',
      controlType: 'checkbox-tik',
      key: 'iAccept_appoitement',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will treat the staff at the office respectfully at all times. I understand that if I am disrespectful to staff or disrupt the care of other patients my treatment will be stopped.',
      controlType: 'checkbox-tik',
      key: 'iAccept_staff',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will not sell this medicine or share it with others. I understand that if I do, my treatment will be stopped.',
      controlType: 'checkbox-tik',
      key: 'iAccept_share',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will sign a release form to let the doctor speak to all other doctors or providers that I see.',
      controlType: 'checkbox-tik',
      key: 'iAccept_realease',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will tell the doctor all other medicines that I take, and let him/her know right away if I have a prescription for a new medicine',
      controlType: 'checkbox-tik',
      key: 'iAccept_others',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will tell the doctor all other medicines that I take, and let him/her know right away if I have a prescription for a new medicine.',
      controlType: 'checkbox-tik',
      key: 'iAccept_doctor',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will use only one pharmacy to get all on my medicines:',
      controlType: 'checkbox-tik',
      key: 'iAccept_pharmacy',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will not get any opioid pain medicines or other medicines that can be addictive such as benzodiazepines (klonopin, xanax, valium) or stimulants (ritalin, amphetamine) without telling a member of the treatment team before I fill that prescription. I understand that the only exception to this is if I need pain medicine for an emergency at night or on the weekends.',
      controlType: 'checkbox-tik',
      key: 'iAccept_medicines',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will not use illegal drugs such as heroin, cocaine, marijuana, or amphetamines. I nderstand that if I do, my treatment may be stopped.',
      controlType: 'checkbox-tik',
      key: 'iAccept_drug',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I will come in for drug testing and counting of my pills within 24 hours of being called. I understand that I must make sure the office has current contact information in order to reach me, and that any missed tests will be considered positive for drugs',
      controlType: 'checkbox-tik',
      key: 'iAccept_test',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `I will keep up to date with any bills from the office and tell the doctor or member of the treatment team immediately if I lose my insurance or can't pay for treatment anymore.`,
      controlType: 'checkbox-tik',
      key: 'iAccept_data',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I understand that I may lose my right to treatment in this office if I break any part of this agreement.',
      controlType: 'checkbox-tik',
      key: 'iAccept_treatment',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
    },
    {
      type: 'content',
      label: 'Stimulants/Controlled Substances Treatment Program Statement',
      showontoggle: true,
    },
    {
      type: 'signature',
      label: '',
      placeholder: 'Signature',
      labelImage: '',
      required: true,
      maxLength: '20',
      key: 'medication_signature',
      value: '',
      className: '',
      editable: true,
      controlType: 'signature',
      emptyValueMessage: 'Please enter signature',
      validationValue: 'Please enter valid signature(only Alphabets)',
      validators: [Validators.required, this.validateAplha],
      showontoggle: true,
    }
  ];
  autoFilledDataFormConfig = [
    {
      id: 0,
      type: 'checkbox-slider',
      key:'saness-testing',
      value: true,
      checkboxlabel: 'Do you want to auto fill the data based on the previous appointment',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    }
  ];
  autoFilledMetalDataFormConfig= [
    {
      id: 0,
      type: 'checkbox-slider',
      key:'sadness-testing',
      value: true,
      checkboxlabel: 'Do you want to auto fill the data based on the previous appointment',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    }
  ];
  autoFillTelemedicineDataFormConfig= [
    {
      id: 0,
      type: 'checkbox-slider',
      key:'saness-telemedicine',
      value: true,
      checkboxlabel: 'Do you want to auto fill the data based on the previous appointment',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    }
  ];
  autoFilledMedicationDataFormConfig = [
    {
      id: 0,
      type: 'checkbox-slider',
      key:'sadness-testing',
      value: true,
      checkboxlabel: 'Do you want to auto fill the data based on the previous appointment',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    }
  ];
  metalHealthDetailFormConfig = [
    {
      id: 0,
      type: 'checkbox-slider',
      key: 'sadness',
      value: false,
      checkboxlabel: 'Are you going through overwhelming sadness, grief or depression?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    },
    {
      id: 1,
      type: 'radio',
      label: 'Are you Experiencing  feelings of guilt?',
      labelImage: '',
      required: true,
      key: 'feeling_guilt',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 2,
      type: 'radio',
      label: 'Have you been feeling suicidal or do you get thoughts about it?',
      labelImage: '',
      required: true,
      key: 'feeling_sucidal',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 3,
      type: 'radio',
      label: 'Are you experiencing lack of sleep, insomnia?',
      labelImage: '',
      required: true,
      key: 'insomnia',
      value: '',
      editable: true,
      className: 'radio-btn-horizontal',
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      validatorsData: [Validators.required],
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 4,
      type: 'radio',
      label: 'Are you experiencing low interest at work?',
      labelImage: '',
      required: true,
      key: 'low_interest',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 5,
      type: 'radio',
      label: 'Are you going through anxiety or experiencing anxiety-Somatic?',
      labelImage: '',
      required: true,
      key: 'anxiety',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 6,
      type: 'radio',
      label: 'Are you Experiencing low Libido?',
      labelImage: '',
      required: true,
      key: 'libido',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 7,
      type: 'radio',
      label: 'Are you experiencing feelings of unreality, nihilistic ideas, depersonalization or derealization?',
      labelImage: '',
      required: true,
      key: 'deperesion_derealization',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 8,
      type: 'radio',
      label: 'Have you been facing issues with concentration or complete lack of concentration?',
      labelImage: '',
      required: true,
      key: 'lack_concentration',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 9,
      type: 'radio',
      label: 'Are you feeling paranoid?',
      labelImage: '',
      required: true,
      key: 'feeling_paranoid',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOften,
      showontoggle: true,
    },
    {
      id: 10,
      type: 'radio',
      label: 'Are you Experiencing  any weight loss or weight gain?',
      labelImage: '',
      required: true,
      key: 'weight_less_gain',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'radio-btn-horizontal',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: this.howOftenSecondary,
      showontoggle: true,
    },
    {
      id: 11,
      type: 'checkbox-slider',
      key: 'legal_issue',
      value: false,
      checkboxlabel: 'Are you facing any legal issues?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    },
    {
      id: 12,
      idName: 'legal_issue_description_parent',
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Provide details',
      required: true,
      key: 'legal_issue_description',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      id: 13,
      type: 'checkbox-slider',
      key: 'medicines_side_effects',
      value: false,
      checkboxlabel: 'Are you having any side effects of medicines?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    },
    {
      id: 14,
      idName: 'medicines_side_effects_description_parent',
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Provide details',
      required: true,
      key: 'medicines_side_effects_description',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      id: 15,
      type: 'checkbox-slider',
      key: 'outbust_allergies',
      value: false,
      checkboxlabel: 'Are you having any Outburst of allergies?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      editable: true,
      showontoggle: true,
    },
    {
      id: 16,
      idName: 'outbust_allergies_description_parent',
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Provide details',
      required: true,
      key: 'outbust_allergies_description',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    }
  ];
  //Normal User
  insuranceDetailFormConfig = [
    {
      type: 'header',
      label: 'Name of the insurance company',
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your primary insurance Provider',
      label: 'Primary Insurance',
      required: false,
      key: 'primaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter primary insurance provider',
      label: 'Please enter primary insurance provider',
      required: true,
      key: 'primaryHolderText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter primary insurance provider',
      validationValue: 'Please enter valid primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your secondary insurance provider',
      label: 'Secondary Insurance',
      required: false,
      key: 'secondaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your secondary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'note',
      note: '*NOTE: If the patient has secondary insurance please choose your insurance or else choose N/A from the dropdown',
      showontoggle: true,
      key: 'note'
    },
    {
      type: 'text',
      placeholder: 'Please enter secondary insurance provider',
      label: 'Please enter secondary insurance provider',
      required: true,
      key: 'secondaryHolderText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter secondary insurance provider',
      validationValue: 'Please enter valid secondary insurance provider',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'header',
      label: 'Policy holder\'s information',
      key: 'policyHolderHeader',
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'Same as patient details',
      controlType: 'checkbox-tik',
      key: 'sameAsPatient',
      required: false,
      value: false,
      className: 'same-patient-details__checkbox',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please Enter your full name',
      label: 'Full Name',
      required: false,
      key: 'policyHolderName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter full name',
      validationValue: 'Please enter valid name',
      validators: [this.validateName],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of Birth',
      required: true,
      key: 'policyHolderDOB',
      labelImage: '',
      value: '',
      isDob: true,
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Primary Insurance Member Id of the patient',
      label: 'Primary Insurance Member Id of the patient',
      required: true,
      key: 'policyHolderMemberId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter member id',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Secondary Insurance Member Id of the patient',
      label: 'Secondary Insurance Member Id of the patient',
      required: false,
      key: 'secondaryPolicyHolderMemberId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter member id',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Provide a copy of the front and back of the insurance cards',
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'policyHoldersUploadFront',
      uploadlabel: 'Front copy(Primary)',
      readableLabel: 'Front copy of Primary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: false,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select primary insurance front image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'policyHoldersUploadBack',
      uploadlabel: 'Back copy(Primary)',
      readableLabel: 'Back copy of Primary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: false,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select primary insurance back image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'secondaryPolicyHoldersUploadFront',
      uploadlabel: 'Front copy(Secondary)',
      readableLabel: 'Front copy of Secondary Insurance card',

      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: false ,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select secondary insurance front image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'secondaryPolicyHoldersUploadBack',
      uploadlabel: 'Back copy(Secondary)',
      readableLabel: 'Back copy of Secondary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: false,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select secondary insurance back image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Group number of the patient',
      label: 'Group number of the patient',
      required: true,
      key: 'policyHolderGroupNumber',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter patient group number',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone number of mental health or provider services',
      labelImage: 'mobile',
      bottomText: '(At the back of the card)',
      required: true,
      key: 'policyHolderMentalHealthNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },

    {
      type: 'text',
      placeholder: 'The employer of the patient',
      label: 'Employer of the patient',
      required: true,
      key: 'policyHolderEmployer',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      className: 'insurance-valid-msg',
      emptyValueMessage: 'Please enter employer name of the policy holder',
      validationValue: 'Please enter valid employer name of the policy holder',
      validators: [this.valideTextArea],
      focus: true,
      showontoggle: true,
    }
  ];

  basicInformationFormConfig = [

    {
      type: 'radio',
      label: 'User relationship to patient',
      labelImage: '',
      required: true,
      key: 'userRelationToPatient',
      value: 'S',
      className: 'relationship',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Self',
          value: 'S'
        },
        {
          label: 'Parent',
          value: 'P'
        },
        {
          label: 'Legal Guardian',
          value: 'G'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s name as it appears on insurance card',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter your first name',
      label: 'First Name',
      required: true,
      key: 'firstName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter first name',
      validationValue: 'Please enter valid first name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter your last name',
      label: 'Last Name',
      required: true,
      key: 'lastName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s Date of Birth',
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of Birth',
      required: true,
      key: 'dob',
      isDob: true,
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: 'Patient height',
      required: true,
      key: 'heightFt',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [Validators.required, this.validateHeightFt],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Ft'
    },
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: '',
      required: true,
      key: 'heightInch',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [Validators.required, this.validateHeightInches],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      noLabel: true,
      placeholderVal: 'Inch'
    },
    {
      type: 'number',
      placeholder: 'Enter patient weight',
      label: 'Patient weight',
      required: true,
      key: 'weight',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter weight',
      validationValue: 'Please enter valid weight',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'lbs'
    },
    {
      type: 'header',
      label: 'Patient\'s Gender',
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'Gender',
      labelImage: '',
      required: true,
      key: 'gender',
      value: '',
      editable: true,
      className: 'gender',
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select gender',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Male',
          value: 'male',
          // checked: true,
        },
        {
          label: 'Female',
          value: 'female',
          // checked: false,

        },
        {
          label: 'Others',
          value: 'others',
          // checked: false,
        }
      ],
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s contact details',
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: true,
      key: 'phoneNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter patient\'s email id',
      label: 'Email Id',
      required: true,
      key: 'email',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      className: 'email__scroll',
      emptyValueMessage: 'Please enter your email id',
      validationValue: 'Please enter valid email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Emergency contact details',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter name',
      label: 'Name',
      subtitle: '*',
      required: true,
      key: 'emergencyContactName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter name',
      validationValue: 'Please enter valid name',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter relation with patient',
      label: 'Relation with patient',
      subtitle: '*',
      required: true,
      key: 'relationWithPatient',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter relation',
      validationValue: 'Please enter valid relation',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      subtitle: '*',
      required: true,
      key: 'emergencyContactNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Address',
      label: 'Address',
      subtitle: '*',
      required: false,
      key: 'emergencyContactAddress',
      value: '',
      controlType: 'textarea',
      validationValue: 'Please enter valid Address',
      validators: [this.valideTextArea],
      editable: true,
      updateOn: 'blur',
      maxLength: 150,
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'header',
      label: 'Patient\'s id proof (Passport / Driving License / Student ID)',
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Provide a copy of the front and back of the card',
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'idProofUploadImage1',
      uploadlabel: 'Front copy',
      readableLabel: 'Font copy of Id Proof',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'idProofUploadImage2',
      uploadlabel: 'Back copy',
      readableLabel: 'Back copy of Id Proof',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s residential mailing address',
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Enter your street Address 01',
      label: 'Street Address 01',
      required: true,
      key: 'streetAddress1',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      // emptyValueMessage: 'Please enter your street Address 01',
      validationValue: 'Please enter valid street Address 01',
      validators: [this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'textarea',
      placeholder: 'Enter your street address 02',
      label: 'Street Address 02',
      required: false,
      key: 'streetAddress2',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      emptyValueMessage: '',
      validationValue: 'Please enter valid street address 02',
      validators: [],
      // validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter city name',
      label: 'City',
      required: true,
      key: 'city',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter city name',
      validationValue: 'Please enter valid city name',
      validators: [this.valideTextArea],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select state',
      label: 'Select State',
      required: false,
      key: 'state',
      labelImage: 'user',
      value: '',
      list: this.statesList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select state',
      validationValue:'Select State from Maryland, New York, Virginia, Washington',
      validators: [this.validateState],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter zip code',
      label: 'Zip Code',
      required: true,
      key: 'zipCode',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      focus: true,
      max: 5,
      emptyValueMessage: 'Please enter zip code',
      validationValue: 'Please enter valid 5 digits zip code',
      // validationValue1: 'Please enter valid zip code for selected state',
      validators: [this.validateZIP],
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '999-99-9999',
      placeholder: 'XXX-XX-XXXX',
      label: 'Social Security Number',
      subtitle: '*',
      labelImage: 'mobile',
      required: true,
      key: 'socialSecurityNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter social security number',
      validationValue: 'Please enter valid social security number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
  ];

  medicalHistoryFormConfig = [
    {
      type: 'checkbox-slider',
      key: 'inTherapy',
      value: false,
      checkboxlabel: 'Have you been in therapy before',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter therapist name',
      label: 'Therapist Name',
      required: true,
      key: INTAKE_FORM_KEYS.THERAPIST_NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter therapist name',
      validationValue: 'Please enter valid name',
      validationValue1: 'Please enter therapist name other than Dr. Prayaga',
      validators: [this.valideTextArea, this.validateTherapistName.bind(this)],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: FORM_KEYS.IS_THERAPIST_EMAIL_ID,
      value: false,
      checkboxlabel: 'Do you have therapist email Id?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      label: 'Email Id',
      placeholder: 'Therapist Email Id',
      required: true,
      key: INTAKE_FORM_KEYS.THERAPIST_EMAIL,
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter email id',
      validationValue: 'Please enter valid email id',
      validationValue1: 'Please enter therapist email other than your email id or office email id\'s',
      validators: [this.validateEmail, this.validateTherapistEmail.bind(this)],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'email__id temail__scroll'
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Last therapy date',
      required: false,
      key: 'lastTherapyDate',
      labelImage: '',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.IS_THERAPIST_DOCS,
      value: false,
      checkboxlabel: 'Do you have the Therapist Notes/Documents with you?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'documentUpload',
      placeholder: 'Select date',
      label: 'Therapist Notes/Documents',
      required: false,
      key: INTAKE_FORM_KEYS.THERAPIST_DOCS,
      labelImage: '',
      value: [],
      controlType: 'documentUpload',
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `I authorize the release of any and all personal medical information, health records, progress notes, laboratory, diagnostic and /or surgical procedures to Dr. Rama Prayaga at Fax - (866) 528 -6229. Please include all diagnostic studies, physician findings and/or diagnoses.`,
      controlType: 'checkbox-tik',
      key: INTAKE_FORM_KEYS.SEND_THERAPIST,
      required: false,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: false,
    },
    {
      type: 'checkbox-slider',
      key: 'psychiatricSlider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Are you taking any psychiatric medications',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'medications',
      formInputs: [[
        {
          type: 'header',
          // idName: 'psychiatric-header',
          label: 'Provide following details',
          showontoggle: true,
          key: 'psychiatricheader',
          editable: true,
        },
        {
          type: 'text',
          // idName: 'psychiatric-medicine-name',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          // emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [this.valideTextArea],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          // idName: 'psychiatric-dosage-and-direction',
          placeholder: '',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          maxLength: 150,
          editable: true,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
        {
          type: 'radio',
          placeholder: '',
          label: 'What type of medication is it?',
          labelImage: '',
          required: false,
          key: 'medicationType',
          value: '',
          editable: true,
          controlType: 'custom-radio',
          emptyValueMessage: 'Please select one option',
          className: 'radio-btn-horizontal formarray-radio',
          validationValue: '',
          validators: [],
          fields: [
            {
              label: 'Current',
              value: 'current',
            },
            {
              label: 'Lifetime',
              value: 'lifetime',
            },
          ],
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Start Date',
          required: false,
          key: 'prescriptionStartDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          validators: [this.validateStartDate],
          className: 'side__by__side',
          focus: true,
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'End Date',
          required: false,
          key: 'prescriptionEndDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          className: 'side__by__side',
          emptyValueMessage: 'Please select date',
          validationValue: 'End date should be greater than start date',
          validators: [this.validateEndDate],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'checkbox-slider',
          key: 'discontinued',
          checkboxlabel: 'Have you discontinued it?',
          controlType: 'checkbox-slider',
          value: false,
          leftLable: true,
          validators: [],
          editable: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Reason details',
          required: false,
          key: 'discontinuedReasion',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'MEDICALHISTORY',
      selected: false,
      editable: true,
      showClose: false,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'psychiatricAddNew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Currently or in past have you used benzodiazepines?',
      key: 'benzodiazepinesSlider',
      controlType: 'checkbox-slider',
      selected: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'benzodiazepines',
      formInputs: [[
        {
          type: 'header',
          label: 'Provide following details',
          showontoggle: true,
          editable: true,
          key: 'benzodiazepinesheader'
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          // emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [this.valideTextArea],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
        {
          type: 'radio',
          placeholder: '',
          label: 'What type of medication is it?',
          labelImage: '',
          required: false,
          key: 'medicationType',
          value: '',
          editable: true,
          controlType: 'custom-radio',
          emptyValueMessage: 'Please select one option',
          className: 'radio-btn-horizontal formarray-radio',
          validationValue: '',
          validators: [],
          fields: [
            {
              label: 'Current',
              value: 'Current',
            },
            {
              label: 'Lifetime',
              value: 'Lifetime',
            },
          ],
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Start Date',
          required: false,
          key: 'prescriptionStartDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          validators: [this.validateStartDate],
          className: 'side__by__side',
          focus: true,
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'End Date',
          key: 'prescriptionEndDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          className: 'side__by__side',
          validationValue: 'End date should be greater than start date',
          emptyValueMessage: 'Please select date',
          validators: [this.validateEndDate],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'checkbox-slider',
          key: 'discontinued',
          checkboxlabel: 'Have you discontinued it?',
          controlType: 'checkbox-slider',
          value: false,
          leftLable: true,
          validators: [],
          editable: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Reason details',
          required: false,
          key: 'discontinuedReasion',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        }
      ]
      ],
      controlType: 'formArray',
      formtag: 'MEDICALHISTORY',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'benzodiazepinesaddnew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Specifically, are you currently taking or have you in the past 6 months taken Adderall or other stimulants',
      key: 'medicaladderallstimulants',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'medicaladderal',
      controlType: 'formArray',
      formInputs: [[
        {
          type: 'header',
          label: 'If yes,please Provide',
          showontoggle: true,
          editable: true,
          key: 'medicaladderallheader'
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          // emptyValueMessage: 'Please enter medicine',
          validationValue: 'Please enter valid medicine name',
          validators: [this.valideTextArea],
          focus: true,
          className: 'side__by__side',
          showontoggle: true,
          fieldsToDelete: 3,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Last prescription date',
          required: false,
          key: 'lastPrescriptionDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          className: 'side__by__side',
          validators: [],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          className: 'remaining-number',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
      ]
      ],
      formtag: 'MEDICALHISTORY',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'medicalAdderallAddNew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: INTAKE_FORM_KEYS.LAB_WORKS_DOCS,
      label: 'Provide the lab work docs',
      uploadlabel: 'Lab Work Docs',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'documentUpload',
      placeholder: 'Provide a copy of the lab work docs',
      required: false,
      labelImage: '',
      value: [],
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Please list any known allergies',
      required: false,
      key: 'knownAllergies',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'disabilityBenefits',
      checkboxlabel: 'Are you in the process of filing for disability benefits?',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      checkboxlabel: 'Do you have any present or past history of drug or alcohol abuse ?',
      key: 'drugAlcoholAbuse',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: 'alcoholDrugIn3Months',
      value: false,
      checkboxlabel: 'Have you used any alcohol or drug within the last 3 months?',
      controlType: 'checkbox-slider',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: 'selfMedicate',
      value: '',
      checkboxlabel: 'Do you self-medicate with alcohol or abuse any non-medical stimulants?',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Please provide any other pertinent information that may be helpful for the physician in treating you',
      required: false,
      key: 'medicalKnownAllergies',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number lenght-text',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.MEDICAL_DISORDERS,
      checkboxlabel: 'Do you have any medical disorders?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.MEDICAL_DISORDERS_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.PAST_HISTORY,
      checkboxlabel: 'Have you had past history of (alcohol / marijuana/ cocaine)?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.PAST_HISTORY_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.NON_PSYCH_MEDS,
      checkboxlabel: 'Do u take non-psych meds?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.NON_PSYCH_MEDS_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.ANY_OPIOIDS,
      checkboxlabel: 'Do u take any opioids?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.ANY_OPIOIDS_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Previous prescriber details',
      showontoggle: true,
      key: INTAKE_FORM_KEYS.HEADER,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.PREVIOUS_PRESC,
      checkboxlabel: 'Do you have a previous prescriber?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'prescriber name',
      label: 'Name',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter name',
      validationValue: 'Please enter valid name',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_PH,
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      label: 'Email Id',
      placeholder: 'prescriber Email Id',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_EMAIL,
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter email id',
      validationValue: 'Please enter valid email id',
      validationValue1: 'Please enter email id other than your email id or office email id\'s',
      validators: [this.validateEmail, this.validateTherapistEmail.bind(this)],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'email__id pemail__scroll'
    },
    {
      type: 'textarea',
      placeholder: 'Address',
      label: 'Address',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_ADDR,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter valid address',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.IS_PRESCRIBER_DOCS,
      value: false,
      checkboxlabel: 'Do you have the Prescriber Notes/Documents with you?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'documentUpload',
      placeholder: 'Select date',
      label: 'Prescriber Notes/Documents',
      required: false,
      key: INTAKE_FORM_KEYS.PRESCRIBER_DOCS,
      labelImage: '',
      value: [],
      controlType: 'documentUpload',
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `I authorize the release of any and all personal medical information, health records, progress notes, laboratory, diagnostic and /or surgical procedures to Dr. Rama Prayaga at Fax - (866) 528 -6229. Please include all diagnostic studies, physician findings and/or diagnoses.`,
      controlType: 'checkbox-tik',
      key: INTAKE_FORM_KEYS.SEND_PRESCRIBER,
      required: false,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: false,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.HOSPITALIZED_BEFORE,
      checkboxlabel: 'Have you been hospitalized before?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Hospital name',
      label: 'Hospital Name',
      required: false,
      key: INTAKE_FORM_KEYS.HOSPITAL_NAME,
      value: '',
      controlType: 'inputText',
      // emptyValueMessage: 'Please enter hospital name',
      validationValue: 'Please enter valid hospital name',
      validators: [this.validateName],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of hospitalization',
      required: false,
      key: INTAKE_FORM_KEYS.YEAR,
      labelImage: '',
      value: '',
      minDate: this.minDateOfHospitalization,
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [],
      focus: true,
      showontoggle: true,
      className: ''
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Reason for hospitalization',
      required: false,
      key: INTAKE_FORM_KEYS.REASON,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please enter reason',
      validationValue: 'Please enter valid reason',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
  ];

  miscellaenousFormConfig = [
    {
      type: 'checkbox-slider',
      key: 'legalIssues',
      checkboxlabel: 'Do you have any pending or ongoing legal issues ?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: 'miscellaenousKnownAllergies',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Please provide your current pharmacy details',
      showontoggle: true,
      key: 'currentPharmacyHeader'
    },
    {
      type: 'dropdown',
      placeholder: 'Please select pharmacy name',
      label: 'Pharmacy Name',
      required: false,
      key: 'pharmacyName',
      labelImage: '',
      list: this.statesList,
      controlType: 'dropdown',
      className: 'pharmacy-section',
      editable: true,
      emptyValueMessage: 'Please select pharmacy name',
      validators: [],
      focus: true,
      showontoggle: true,
    }, {
      type: 'text',
      placeholder: 'Please Enter Pharmacy Name',
      label: 'Please Enter Pharmacy Name',
      required: true,
      key: 'pharmacyNameText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please Enter pharmacy name',
      validationValue: 'Please enter valid pharmacy name',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: false,
      key: 'pharmacyPhoneNo',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Email id',
      label: 'Email Id',
      required: false,
      key: 'pharmacyEmailId',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: '',
      validationValue: 'Please enter valid email id',
      // validators: [],
      validators: [this.validateEmail],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Fax Number',
      required: false,
      key: 'pharmacyFaxNo',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter fax number',
      validationValue: 'Please enter valid fax number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Address 1',
      required: false,
      key: 'pharmacyAddress1',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add address 1',
      validationValue: 'Please add valid address1',
      validators: [this.valideTextArea],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Address 2',
      required: false,
      key: 'pharmacyAddress2',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: '',
      validators: [],
      // validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter city name',
      label: 'City',
      required: true,
      key: 'pharmacyCity',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter city name',
      validationValue: 'Please enter valid city name',
      validators: [this.valideTextArea],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select state',
      label: 'Select State',
      required: false,
      key: INTAKE_FORM_KEYS.PHARMACY_STATE,
      labelImage: 'user',
      list: this.statesList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select state',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter zip code',
      label: 'Zip Code',
      required: true,
      key: 'pharmacyZipcode',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      focus: true,
      max: 5,
      emptyValueMessage: 'Please enter zip code',
      validationValue: 'Please enter valid 5 digits zip code',
      validators: [this.validateZIP],
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'How did you hear about Dr. Prayaga?',
      labelImage: '',
      required: true,
      key: 'knowDoctor',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'about-radio',
      emptyValueMessage: 'Please select one option',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Website',
          value: 'website',
          checked: true,
        },
        {
          label: 'Advertisement',
          value: 'advertisement',
          checked: false,

        },
        {
          label: 'Social Media',
          value: 'socialmedia',
          checked: false,
        },
        {
          label: 'Referral',
          value: 'referral',
          checked: false,
        },
        {
          label: 'Others',
          value: 'Others',
          checked: false,
        }
      ],
      showontoggle: true,
    },

  ];

  newPatientSubmitFormConfig = [
    {
      type: 'checkbox',
      labeltext: 'I hereby acknowledge that the information provided is accurate',
      controlType: 'checkbox-tik',
      key: 'iAcknowledge',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox check-disable',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [this.validateCheckBox],
      focus: true,
      selected: false,
      editable: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I accept the terms and conditions of New Tele Doc',
      controlType: 'checkbox-tik',
      key: 'iAccept',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox check-disable',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [this.validateCheckBox],
      focus: true,
      selected: false,
      editable: false,
      showontoggle: true,
    },
    {
      type: 'link',
      placeholder: '',
      link: 'Terms & Conditions',
      href: '/terms',
      editable: false,
      className: 'link-disable',
      showontoggle: true,
    },
    {
      type: 'signature',
      label: '',
      placeholder: 'Signature',
      labelImage: '',
      required: true,
      maxLength: '20',
      key: INTAKE_FORM_KEYS.PATIENT_SIGN,
      value: '',
      className: '',
      editable: false,
      controlType: 'signature',
      emptyValueMessage: 'Please enter signature',
      validationValue: 'Please enter valid signature',
      validators: [this.validateAplha],
      showontoggle: true,
    }
  ];
  //New User
  newUserInsuranceDetailFormConfig = [
    {
      type: 'header',
      label: 'Name of the insurance company',
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your primary insurance Provider',
      label: 'Primary Insurance',
      subtitle: '*',
      required: true,
      key: 'primaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your primary insurance provider',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter primary insurance provider',
      label: 'Please enter primary insurance provider',
      required: true,
      key: 'primaryHolderText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter primary insurance provider',
      validationValue: 'Please enter valid primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your secondary insurance provider',
      label: 'Secondary Insurance',
      subtitle: '*',
      required: true,
      key: 'secondaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your secondary insurance provider',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter secondary insurance provider',
      label: 'Please enter secondary insurance provider',
      required: true,
      key: 'secondaryHolderText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter secondary insurance provider',
      validationValue: 'Please enter valid secondary insurance provider',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'header',
      label: 'Policy holder\'s information',
      key: 'policyHolderHeader',
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'Same as patient details',
      controlType: 'checkbox-tik',
      key: 'sameAsPatient',
      required: false,
      value: false,
      className: 'same-patient-details__checkbox',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please Enter your full name',
      label: 'Full Name',
      subtitle: '*',
      required: true,
      key: 'policyHolderName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter full name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of Birth',
      subtitle: '*',
      required: true,
      key: 'policyHolderDOB',
      labelImage: '',
      value: '',
      isDob: true,
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Primary Insurance Member Id of the patient',
      label: 'Primary Insurance Member Id of the patient',
      subtitle: '*',
      required: true,
      key: 'policyHolderMemberId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter member id',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Secondary Insurance Member Id of the patient',
      label: 'Secondary Insurance Member Id of the patient',
      subtitle: '*',
      required: true,
      key: 'secondaryPolicyHolderMemberId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter member id',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Provide a copy of the front and back of the insurance cards',
      subtitle: '*',
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'policyHoldersUploadFront',
      uploadlabel: 'Front copy(Primary)',
      subtitle: '*',
      readableLabel: 'Front copy of Primary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select primary insurance front image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'policyHoldersUploadBack',
      uploadlabel: 'Back copy(Primary)',
      subtitle: '*',
      readableLabel: 'Back copy of Primary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select primary insurance back image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'secondaryPolicyHoldersUploadFront',
      uploadlabel: 'Front copy(Secondary)',
      subtitle: '*',
      readableLabel: 'Front copy of Secondary Insurance card',

      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select secondary insurance front image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'secondaryPolicyHoldersUploadBack',
      uploadlabel: 'Back copy(Secondary)',
      subtitle: '*',
      readableLabel: 'Back copy of Secondary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select secondary insurance back image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Group number of the patient',
      label: 'Group number of the patient',
      required: true,
      key: 'policyHolderGroupNumber',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter patient group number',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone number of mental health or provider services',
      subtitle: '*',
      labelImage: 'mobile',
      bottomText: '(At the back of the card)',
      required: true,
      key: 'policyHolderMentalHealthNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },

    {
      type: 'text',
      placeholder: 'The employer of the patient',
      label: 'Employer of the patient',
      subtitle: '*',
      required: true,
      key: 'policyHolderEmployer',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      className: 'insurance-valid-msg',
      emptyValueMessage: 'Please enter employer name of the policy holder',
      validationValue: 'Please enter valid employer name of the policy holder',
      validators: [Validators.required, this.valideTextArea],
      focus: true,
      showontoggle: true,
    }
  ];

  newUserBasicInformationFormConfig = [

    {
      type: 'radio',
      label: 'User relationship to patient',
      labelImage: '',
      subtitle: '*',
      required: true,
      key: 'userRelationToPatient',
      value: 'S',
      className: 'relationship',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Self',
          value: 'S'
        },
        {
          label: 'Parent',
          value: 'P'
        },
        {
          label: 'Legal Guardian',
          value: 'G'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s name as it appears on insurance card',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter your first name',
      label: 'First Name',
      subtitle: '*',
      required: true,
      key: 'firstName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter first name',
      validationValue: 'Please enter valid first name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter your last name',
      label: 'Last Name',
      subtitle: '*',
      required: true,
      key: 'lastName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s Date of Birth',
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of Birth',
      subtitle: '*',
      required: true,
      key: 'dob',
      isDob: true,
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: 'Patient height',
      subtitle: '*',
      required: true,
      key: 'heightFt',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [Validators.required, this.validateHeightFt],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Ft'
    },
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: '',
      required: true,
      key: 'heightInch',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [Validators.required, this.validateHeightInches],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      noLabel: true,
      placeholderVal: 'Inch'
    },
    {
      type: 'number',
      placeholder: 'Enter patient weight',
      label: 'Patient weight',
      subtitle: '*',
      required: true,
      key: 'weight',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter weight',
      validationValue: 'Please enter valid weight',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'lbs'
    },
    {
      type: 'header',
      label: 'Patient\'s Gender',
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'Gender',
      subtitle: '*',
      labelImage: '',
      required: true,
      key: 'gender',
      value: '',
      editable: true,
      className: 'gender',
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select gender',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Male',
          value: 'male',
          // checked: true,
        },
        {
          label: 'Female',
          value: 'female',
          // checked: false,

        },
        {
          label: 'Others',
          value: 'others',
          // checked: false,
        }
      ],
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s contact details',
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      subtitle: '*',
      required: true,
      key: 'phoneNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter patient\'s email id',
      label: 'Email Id',
      subtitle: '*',
      required: true,
      key: 'email',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      className: 'email__scroll',
      emptyValueMessage: 'Please enter your email id',
      validationValue: 'Please enter valid email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Emergency contact details',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter name',
      label: 'Name',
      subtitle: '*',
      required: true,
      key: 'emergencyContactName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required,this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter relation with patient',
      label: 'Relation with patient',
      subtitle: '*',
      required: true,
      key: 'relationWithPatient',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter relation',
      validationValue: 'Please enter valid relation',
      validators: [Validators.required,this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      subtitle: '*',
      required: true,
      key: 'emergencyContactNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Address',
      label: 'Address',
      subtitle: '*',
      required: true,
      key: 'emergencyContactAddress',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter valid Address',
      validators: [ Validators.required,Validators.minLength(1), this.valideTextArea],
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'header',
      label: 'Patient\'s id proof (Passport / Driving License / Student ID)',
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Provide a copy of the front and back of the card',
      subtitle: '*',
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'idProofUploadImage1',
      uploadlabel: 'Front copy',
      readableLabel: 'Font copy of Id Proof',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [Validators.required, this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'idProofUploadImage2',
      uploadlabel: 'Back copy',
      readableLabel: 'Back copy of Id Proof',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [Validators.required, this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s residential mailing address',
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Enter your street Address',
      label: 'Street Address 01',
      subtitle: '*',
      required: true,
      key: 'streetAddress1',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      emptyValueMessage: 'Please enter your street Address',
      validationValue: 'Please enter valid street Address',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'textarea',
      placeholder: 'Enter your street address 02',
      label: 'Street Address 02',
      required: false,
      key: 'streetAddress2',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      emptyValueMessage: '',
      validationValue: 'Please enter valid street address 02',
      validators: [],
      // validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter city name',
      label: 'City',
      subtitle: '*',
      required: true,
      key: 'city',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter city name',
      validationValue: 'Please enter valid city name',
      validators: [Validators.required, this.valideTextArea],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select state',
      label: 'Select State',
      subtitle: '*',
      required: true,
      key: 'state',
      labelImage: 'user',
      value: '',
      list: this.statesList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select state',
      validationValue:'Select State from Maryland, New York, Virginia, Washington',
      validators: [Validators.required,this.validateState],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter zip code',
      label: 'Zip Code',
      subtitle: '*',
      required: true,
      key: 'zipCode',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      focus: true,
      max: 5,
      emptyValueMessage: 'Please enter zip code',
      validationValue: 'Please enter valid 5 digits zip code',
      // validationValue1: 'Please enter valid zip code for selected state',
      validators: [Validators.required, this.validateZIP],
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '999-99-9999',
      placeholder: 'XXX-XX-XXXX',
      label: 'Social Security Number',
      subtitle: '*',
      labelImage: 'mobile',
      required: true,
      key: 'socialSecurityNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter social security number',
      validationValue: 'Please enter valid social security number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
  ];

  newUserMedicalHistoryFormConfig = [
    {
      type: 'checkbox-slider',
      key: 'inTherapy',
      value: false,
      checkboxlabel: 'Have you been in therapy before',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter therapist name',
      label: 'Therapist Name',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.THERAPIST_NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter therapist name',
      validationValue: 'Please enter valid name',
      validationValue1: 'Please enter therapist name other than Dr. Prayaga',
      validators: [Validators.required, this.valideTextArea, this.validateTherapistName.bind(this)],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: FORM_KEYS.IS_THERAPIST_EMAIL_ID,
      value: false,
      checkboxlabel: 'Do you have therapist email Id?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      label: 'Email Id',
      subtitle: '*',
      placeholder: 'Therapist Email Id',
      required: true,
      key: INTAKE_FORM_KEYS.THERAPIST_EMAIL,
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter email id',
      validationValue: 'Please enter valid email id',
      validationValue1: 'Please enter email id other than your email id or office email id\'s',
      validators: [Validators.required, this.validateEmail, this.validateTherapistEmail.bind(this)],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'email__id temail__scroll'
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Last therapy date',
      subtitle: '*',
      required: true,
      key: 'lastTherapyDate',
      labelImage: '',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.IS_THERAPIST_DOCS,
      value: false,
      checkboxlabel: 'Do you have the Therapist Notes/Documents with you?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'documentUpload',
      placeholder: 'Select date',
      label: 'Therapist Notes/Documents',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.THERAPIST_DOCS,
      labelImage: '',
      value: [],
      controlType: 'documentUpload',
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validators: [this.validateFilesUpload],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `I authorize the release of any and all personal medical information, health records, progress notes, laboratory, diagnostic and /or surgical procedures to Dr. Rama Prayaga at Fax - (866) 528 -6229. Please include all diagnostic studies, physician findings and/or diagnoses.`,
      subtitle: '*',
      controlType: 'checkbox-tik',
      key: INTAKE_FORM_KEYS.SEND_THERAPIST,
      required: false,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: false,
    },
    {
      type: 'checkbox-slider',
      key: 'psychiatricSlider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Are you taking any psychiatric medications',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'medications',
      formInputs: [[
        {
          type: 'header',
          // idName: 'psychiatric-header',
          label: 'Provide following details',
          showontoggle: true,
          key: 'psychiatricheader',
          editable: true,
        },
        {
          type: 'text',
          // idName: 'psychiatric-medicine-name',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          subtitle: '*',
          required: true,
          key: 'medicine',
          className: 'medicinename',
          value: '',
          controlType: 'inputText',
          editable: true,
          emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          // idName: 'psychiatric-dosage-and-direction',
          placeholder: '',
          label: 'Dosage and Direction',
          subtitle: '*',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          className: 'remaining-number',
          maxLength: 150,
          editable: true,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
        {
          type: 'radio',
          placeholder: '',
          label: 'What type of medication is it?',
          subtitle: '*',
          labelImage: '',
          required: true,
          key: 'medicationType',
          value: '',
          editable: true,
          controlType: 'custom-radio',
          emptyValueMessage: 'Please select one option',
          className: 'radio-btn-horizontal formarray-radio',
          validationValue: '',
          validators: [Validators.required],
          fields: [
            {
              label: 'Current',
              value: 'current',
            },
            {
              label: 'Lifetime',
              value: 'lifetime',
            },
          ],
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Start Date',
          subtitle: '*',
          required: true,
          key: 'prescriptionStartDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          validators: [Validators.required, this.validateStartDate],
          className: 'side__by__side',
          focus: true,
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'End Date',
          required: false,
          key: 'prescriptionEndDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          className: 'side__by__side',
          emptyValueMessage: 'Please select date',
          validationValue: 'End date should be greater than start date',
          validators: [this.validateEndDate],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'checkbox-slider',
          key: 'discontinued',
          checkboxlabel: 'Have you discontinued it?',
          controlType: 'checkbox-slider',
          value: false,
          leftLable: true,
          validators: [],
          editable: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Reason details',
          subtitle: '*',
          required: false,
          key: 'discontinuedReasion',
          value: '',
          controlType: 'textarea',
          emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'NEWUSER_MEDICALHISTORY',
      selected: false,
      editable: true,
      showClose: false,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'psychiatricAddNew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'NEWUSER_MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Currently or in past have you used benzodiazepines?',
      key: 'benzodiazepinesSlider',
      controlType: 'checkbox-slider',
      selected: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'benzodiazepines',
      formInputs: [[
        {
          type: 'header',
          label: 'Provide following details',
          showontoggle: true,
          editable: true,
          key: 'benzodiazepinesheader'
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          subtitle: '*',
          required: true,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          className: 'benzodiazepinesheadermedicinename',
          editable: true,
          emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Dosage and Direction',
          subtitle: '*',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
        {
          type: 'radio',
          placeholder: '',
          label: 'What type of medication is it?',
          subtitle: '*',
          labelImage: '',
          required: true,
          key: 'medicationType',
          value: '',
          editable: true,
          controlType: 'custom-radio',
          emptyValueMessage: 'Please select one option',
          className: 'radio-btn-horizontal formarray-radio',
          validationValue: '',
          validators: [Validators.required],
          fields: [
            {
              label: 'Current',
              value: 'Current',
            },
            {
              label: 'Lifetime',
              value: 'Lifetime',
            },
          ],
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Start Date',
          subtitle: '*',
          required: true,
          key: 'prescriptionStartDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          validators: [Validators.required, this.validateStartDate],
          className: 'side__by__side',
          focus: true,
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'End Date',
          key: 'prescriptionEndDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          className: 'side__by__side',
          validationValue: 'End date should be greater than start date',
          emptyValueMessage: 'Please select date',
          validators: [this.validateEndDate],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'checkbox-slider',
          key: 'discontinued',
          checkboxlabel: 'Have you discontinued it?',
          controlType: 'checkbox-slider',
          value: false,
          leftLable: true,
          validators: [],
          editable: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Reason details',
          subtitle: '*',
          required: false,
          key: 'discontinuedReasion',
          value: '',
          controlType: 'textarea',
          emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        }
      ]
      ],
      controlType: 'formArray',
      formtag: 'NEWUSER_MEDICALHISTORY',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'benzodiazepinesaddnew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'NEWUSER_MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Specifically, are you currently taking or have you in the past 6 months taken Adderall or other stimulants',
      key: 'medicaladderallstimulants',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'medicaladderal',
      controlType: 'formArray',
      formInputs: [[
        {
          type: 'header',
          label: 'If yes,please Provide',
          showontoggle: true,
          editable: true,
          key: 'medicaladderallheader'
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          subtitle: '*',
          required: true,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          emptyValueMessage: 'Please enter medicine',
          validationValue: 'Please enter valid medicine name',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          focus: true,
          className: 'side__by__side',
          showontoggle: true,
          fieldsToDelete: 3,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Last prescription date',
          subtitle: '*',
          required: true,
          key: 'lastPrescriptionDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          className: 'side__by__side',
          validators: [Validators.required],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Dosage and Direction',
          subtitle: '*',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          className: 'remaining-number',
          emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
      ]
      ],
      formtag: 'NEWUSER_MEDICALHISTORY',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'medicalAdderallAddNew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'NEWUSER_MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: INTAKE_FORM_KEYS.LAB_WORKS_DOCS,
      label: 'Provide the lab work docs',
      uploadlabel: 'Lab Work Docs',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'documentUpload',
      placeholder: 'Provide a copy of the lab work docs',
      required: false,
      labelImage: '',
      value: [],
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Please list any known allergies',
      subtitle: '*',
      required: false,
      key: 'knownAllergies',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'disabilityBenefits',
      checkboxlabel: 'Are you in the process of filing for disability benefits?',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      checkboxlabel: 'Do you have any present or past history of drug or alcohol abuse ?',
      key: 'drugAlcoholAbuse',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: 'alcoholDrugIn3Months',
      value: false,
      checkboxlabel: 'Have you used any alcohol or drug within the last 3 months?',
      controlType: 'checkbox-slider',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: 'selfMedicate',
      value: '',
      checkboxlabel: 'Do you self-medicate with alcohol or abuse any non-medical stimulants?',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Please provide any other pertinent information that may be helpful for physician in treating you',
      subtitle: '*',
      required: false,
      key: 'medicalKnownAllergies',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: 'remaining-number lenght-text',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.MEDICAL_DISORDERS,
      checkboxlabel: 'Do you have any medical disorders?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.MEDICAL_DISORDERS_DESC,
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required,this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      focus: false,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.PAST_HISTORY,
      checkboxlabel: 'Have you had past history of (alcohol / marijuana/ cocaine)?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.PAST_HISTORY_DESC,
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.NON_PSYCH_MEDS,
      checkboxlabel: 'Do u take non-psych meds?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.NON_PSYCH_MEDS_DESC,
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.ANY_OPIOIDS,
      checkboxlabel: 'Do u take any opioids?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.ANY_OPIOIDS_DESC,
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Previous prescriber details',
      showontoggle: true,
      key: INTAKE_FORM_KEYS.HEADER,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.PREVIOUS_PRESC,
      checkboxlabel: 'Do you have a previous prescriber?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'prescriber name',
      label: 'Name',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_PH,
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      label: 'Email Id',
      subtitle: '*',
      placeholder: 'prescriber Email Id',
      required: true,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_EMAIL,
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter email id',
      validationValue: 'Please enter valid email id',
      validationValue1: 'Please enter email id other than your email id or office email id\'s',
      validators: [Validators.required, this.validateEmail, this.validateTherapistEmail.bind(this)],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'email__id pemail__scroll'
    },
    {
      type: 'textarea',
      placeholder: 'Address',
      label: 'Address',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_ADDR,
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter valid address',
      validators: [Validators.required, this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.IS_PRESCRIBER_DOCS,
      value: false,
      checkboxlabel: 'Do you have the Prescriber Notes/Documents with you?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'documentUpload',
      placeholder: 'Select date',
      label: 'Prescriber Notes/Documents',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.PRESCRIBER_DOCS,
      labelImage: '',
      value: [],
      controlType: 'documentUpload',
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validators: [this.validateFilesUpload],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `I authorize the release of any and all personal medical information, health records, progress notes, laboratory, diagnostic and /or surgical procedures to Dr. Rama Prayaga at Fax - (866) 528 -6229. Please include all diagnostic studies, physician findings and/or diagnoses.`,
      subtitle: '*',
      controlType: 'checkbox-tik',
      key: INTAKE_FORM_KEYS.SEND_PRESCRIBER,
      required: false,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: false,
    },
    {
      type: 'note',
      note: '*NOTE: If you are visiting Dr. Prayaga for the first time, and have never taken psychotropic medicines, and if psychotropic meds are started by Dr. Prayaga I assure you to communicate back n forth HIPPA compliant emails.',
      showontoggle: true,
      key: 'note'
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.HOSPITALIZED_BEFORE,
      checkboxlabel: 'Have you been hospitalized before?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Hospital name',
      label: 'Hospital Name',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.HOSPITAL_NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter hospital name',
      validationValue: 'Please enter valid hospital name',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of hospitalization',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.YEAR,
      labelImage: '',
      value: '',
      minDate: this.minDateOfHospitalization,
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: ''
    },
    {
      type: 'textarea',
      placeholder: 'Reason',
      label: 'Reason for hospitalization',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.REASON,
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter reason',
      validationValue: 'Please enter valid reason',
      validators: [Validators.required, this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
  ];

  newUserMiscellaenousFormConfig = [
    {
      type: 'checkbox-slider',
      key: 'legalIssues',
      checkboxlabel: 'Do you have any pending or ongoing legal issues ?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      subtitle: '*',
      required: false,
      key: 'miscellaenousKnownAllergies',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Please provide your current pharmacy details',
      showontoggle: true,
      key: 'currentPharmacyHeader'
    },
    {
      type: 'dropdown',
      placeholder: 'Please select pharmacy name',
      label: 'Pharmacy Name',
      subtitle: '*',
      required: true,
      key: 'pharmacyName',
      labelImage: '',
      list: this.statesList,
      controlType: 'dropdown',
      className: 'pharmacy-section',
      editable: true,
      emptyValueMessage: 'Please select pharmacy name',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    }, {
      type: 'text',
      subtitle: '*',
      placeholder: 'Please Enter Pharmacy Name',
      label: 'Please Enter Pharmacy Name',
      required: true,
      key: 'pharmacyNameText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please Enter pharmacy name',
      validationValue: 'Please enter valid pharmacy name',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: false,
      key: 'pharmacyPhoneNo',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Email id',
      label: 'Email Id',
      required: false,
      key: 'pharmacyEmailId',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: '',
      validationValue: 'Please enter valid email id',
      // validators: [],
      validators: [this.validateEmail],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Fax Number',
      required: false,
      key: 'pharmacyFaxNo',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter fax number',
      validationValue: 'Please enter valid fax number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Address 1',
      subtitle: '*',
      required: false,
      key: 'pharmacyAddress1',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add address 1',
      validationValue: 'Please add valid address1',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Address 2',
      required: false,
      key: 'pharmacyAddress2',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: '',
      validators: [],
      // validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter city name',
      label: 'City',
      subtitle: '*',
      required: true,
      key: 'pharmacyCity',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter city name',
      validationValue: 'Please enter valid city name',
      validators: [Validators.required, this.valideTextArea],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select state',
      label: 'Select State',
      subtitle: '*',
      required: true,
      key: INTAKE_FORM_KEYS.PHARMACY_STATE,
      labelImage: 'user',
      list: this.statesList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select state',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter zip code',
      label: 'Zip Code',
      subtitle: '*',
      required: true,
      key: 'pharmacyZipcode',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      focus: true,
      max: 5,
      emptyValueMessage: 'Please enter zip code',
      validationValue: 'Please enter valid 5 digits zip code',
      validators: [Validators.required, this.validateZIP],
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'How did you hear about Dr. Prayaga?',
      subtitle: '*',
      labelImage: '',
      required: true,
      key: 'knowDoctor',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'about-radio',
      emptyValueMessage: 'Please select one option',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Website',
          value: 'website',
          checked: true,
        },
        {
          label: 'Advertisement',
          value: 'advertisement',
          checked: false,

        },
        {
          label: 'Social Media',
          value: 'socialmedia',
          checked: false,
        },
        {
          label: 'Referral',
          value: 'referral',
          checked: false,
        },
        {
          label: 'Others',
          value: 'Others',
          checked: false,
        }
      ],
      showontoggle: true,
    },

  ];
  newUserNewPatientSubmitFormConfig = [
    {
      type: 'checkbox',
      labeltext: 'I hereby acknowledge that the information provided is accurate',
      subtitle: '*',
      controlType: 'checkbox-tik',
      key: 'iAcknowledge',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I accept the terms and conditions of New Tele Doc',
      subtitle: '*',
      controlType: 'checkbox-tik',
      key: 'iAccept',
      required: true,
      value: false,
      className: 'iaccept__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [Validators.requiredTrue, this.validateCheckBox],
      focus: true,
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'link',
      placeholder: '',
      link: 'Terms & Conditions',
      href: '/terms',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'signature',
      label: '',
      placeholder: 'Signature',
      labelImage: '',
      required: true,
      maxLength: '20',
      key: INTAKE_FORM_KEYS.PATIENT_SIGN,
      value: '',
      className: '',
      editable: true,
      controlType: 'signature',
      emptyValueMessage: 'Please enter signature',
      validationValue: 'Please enter valid signature',
      validators: [Validators.required, this.validateAplha],
      showontoggle: true,
    }
  ];

  //Existing User
  existingUserInsuranceDetailFormConfig = [
    {
      type: 'header',
      label: 'Name of the insurance company',
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your primary insurance Provider',
      label: 'Primary Insurance',
      required: false,
      key: 'primaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter primary insurance provider',
      label: 'Please enter primary insurance provider',
      required: true,
      key: 'primaryHolderText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter primary insurance provider',
      validationValue: 'Please enter valid primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your secondary insurance provider',
      label: 'Secondary Insurance',
      required: false,
      key: 'secondaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your secondary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter secondary insurance provider',
      label: 'Please enter secondary insurance provider',
      required: true,
      key: 'secondaryHolderText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter secondary insurance provider',
      validationValue: 'Please enter valid secondary insurance provider',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'header',
      label: 'Policy holder\'s information',
      key: 'policyHolderHeader',
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'Same as patient details',
      controlType: 'checkbox-tik',
      key: 'sameAsPatient',
      required: false,
      value: false,
      className: 'same-patient-details__checkbox',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please Enter your full name',
      label: 'Full Name',
      required: false,
      key: 'policyHolderName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter full name',
      validationValue: 'Please enter valid name',
      validators: [this.validateName],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of Birth',
      required: true,
      key: 'policyHolderDOB',
      labelImage: '',
      value: '',
      isDob: true,
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Primary Insurance Member Id of the patient',
      label: 'Primary Insurance Member Id of the patient',
      required: true,
      key: 'policyHolderMemberId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter member id',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Secondary Insurance Member Id of the patient',
      label: 'Secondary Insurance Member Id of the patient',
      required: true,
      key: 'secondaryPolicyHolderMemberId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter member id',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Provide a copy of the front and back of the insurance cards',
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'policyHoldersUploadFront',
      uploadlabel: 'Front copy(Primary)',
      readableLabel: 'Front copy of Primary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: false,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select primary insurance front image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'policyHoldersUploadBack',
      uploadlabel: 'Back copy(Primary)',
      readableLabel: 'Back copy of Primary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: false,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select primary insurance back image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'secondaryPolicyHoldersUploadFront',
      uploadlabel: 'Front copy(Secondary)',
      readableLabel: 'Front copy of Secondary Insurance card',

      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: false,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select secondary insurance front image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'secondaryPolicyHoldersUploadBack',
      uploadlabel: 'Back copy(Secondary)',
      readableLabel: 'Back copy of Secondary Insurance card',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: false,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select secondary insurance back image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Group number of the patient',
      label: 'Group number of the patient',
      required: true,
      key: 'policyHolderGroupNumber',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter patient group number',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone number of mental health or provider services',
      labelImage: 'mobile',
      bottomText: '(At the back of the card)',
      required: true,
      key: 'policyHolderMentalHealthNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },

    {
      type: 'text',
      placeholder: 'The employer of the patient',
      label: 'Employer of the patient',
      required: true,
      key: 'policyHolderEmployer',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      className: 'insurance-valid-msg',
      emptyValueMessage: 'Please enter employer name of the policy holder',
      validationValue: 'Please enter valid employer name of the policy holder',
      validators: [this.valideTextArea],
      focus: true,
      showontoggle: true,
    }
  ];

  existingUserBasicInformationFormConfig = [

    {
      type: 'radio',
      label: 'User relationship to patient',
      labelImage: '',
      required: true,
      key: 'userRelationToPatient',
      value: 'S',
      className: 'relationship',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Self',
          value: 'S'
        },
        {
          label: 'Parent',
          value: 'P'
        },
        {
          label: 'Legal Guardian',
          value: 'G'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s name as it appears on insurance card',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter your first name',
      label: 'First Name',
      required: true,
      key: 'firstName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter first name',
      validationValue: 'Please enter valid first name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter your last name',
      label: 'Last Name',
      required: true,
      key: 'lastName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s Date of Birth',
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of Birth',
      required: true,
      key: 'dob',
      isDob: true,
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: 'Patient height',
      required: true,
      key: 'heightFt',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [Validators.required, this.validateHeightFt],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Ft'
    },
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: '',
      required: true,
      key: 'heightInch',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [Validators.required, this.validateHeightInches],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      noLabel: true,
      placeholderVal: 'Inch'
    },
    {
      type: 'number',
      placeholder: 'Enter patient weight',
      label: 'Patient weight',
      required: true,
      key: 'weight',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter weight',
      validationValue: 'Please enter valid weight',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'lbs'
    },
    {
      type: 'header',
      label: 'Patient\'s Gender',
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'Gender',
      labelImage: '',
      required: true,
      key: 'gender',
      value: '',
      editable: true,
      className: 'gender',
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select gender',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Male',
          value: 'male',
          // checked: true,
        },
        {
          label: 'Female',
          value: 'female',
          // checked: false,

        },
        {
          label: 'Others',
          value: 'others',
          // checked: false,
        }
      ],
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s contact details',
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: true,
      key: 'phoneNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter patient\'s email id',
      label: 'Email Id',
      required: true,
      key: 'email',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      className: 'email__scroll',
      emptyValueMessage: 'Please enter your email id',
      validationValue: 'Please enter valid email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Emergency contact details',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter name',
      label: 'Name',
      subtitle: '*',
      required: true,
      key: 'emergencyContactName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter name',
      validationValue: 'Please enter valid name',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter relation with patient',
      label: 'Relation with patient',
      subtitle: '*',
      required: true,
      key: 'relationWithPatient',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter relation',
      validationValue: 'Please enter valid relation',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      subtitle: '*',
      required: true,
      key: 'emergencyContactNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Address',
      label: 'Address',
      subtitle: '*',
      required: false,
      key: 'emergencyContactAddress',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      validationValue: 'Please enter valid Address',
      validators: [this.valideTextArea],
      editable: true,
      updateOn: 'blur',
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'header',
      label: 'Patient\'s id proof (Passport / Driving License / Student ID)',
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Provide a copy of the front and back of the card',
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'idProofUploadImage1',
      uploadlabel: 'Front copy',
      readableLabel: 'Font copy of Id Proof',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: 'idProofUploadImage2',
      uploadlabel: 'Back copy',
      readableLabel: 'Back copy of Id Proof',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the back of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient\'s residential mailing address',
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Enter your street Address 01',
      label: 'Street Address 01',
      required: true,
      key: 'streetAddress1',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      // emptyValueMessage: 'Please enter your street Address 01',
      validationValue: 'Please enter valid street Address 01',
      validators: [this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'textarea',
      placeholder: 'Enter your street address 02',
      label: 'Street Address 02',
      required: false,
      key: 'streetAddress2',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      emptyValueMessage: '',
      validationValue: 'Please enter valid street address 02',
      validators: [],
      // validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter city name',
      label: 'City',
      required: true,
      key: 'city',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter city name',
      validationValue: 'Please enter valid city name',
      validators: [this.valideTextArea],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select state',
      label: 'Select State',
      required: false,
      key: 'state',
      labelImage: 'user',
      value: '',
      list: this.statesList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select state',
      validationValue:'Select State from Maryland, New York, Virginia, Washington',
      validators: [this.validateState],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter zip code',
      label: 'Zip Code',
      required: true,
      key: 'zipCode',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      focus: true,
      max: 5,
      emptyValueMessage: 'Please enter zip code',
      validationValue: 'Please enter valid 5 digits zip code',
      // validationValue1: 'Please enter valid zip code for selected state',
      validators: [this.validateZIP],
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '999-99-9999',
      placeholder: 'XXX-XX-XXXX',
      label: 'Social Security Number',
      subtitle: '*',
      labelImage: 'mobile',
      required: true,
      key: 'socialSecurityNumber',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter social security number',
      validationValue: 'Please enter valid social security number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
  ];

  existingUserMedicalHistoryFormConfig = [
    {
      type: 'checkbox-slider',
      key: 'inTherapy',
      value: false,
      checkboxlabel: 'Have you been in therapy before',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter therapist name',
      label: 'Therapist Name',
      required: true,
      key: INTAKE_FORM_KEYS.THERAPIST_NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter therapist name',
      validationValue: 'Please enter valid name',
      validationValue1: 'Please enter therapist name other than Dr. Prayaga',
      validators: [this.valideTextArea, this.validateTherapistName.bind(this)],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: FORM_KEYS.IS_THERAPIST_EMAIL_ID,
      value: false,
      checkboxlabel: 'Do you have therapist email Id?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      label: 'Email Id',
      placeholder: 'Therapist Email Ids',
      required: true,
      key: INTAKE_FORM_KEYS.THERAPIST_EMAIL,
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter email id',
      validationValue: 'Please enter valid email id',
      validationValue1: 'Please enter therapist email other than your email id or office email id\'s',
      validators: [this.validateEmail, this.validateTherapistEmail.bind(this)],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'email__id temail__scroll'
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Last therapy date',
      required: true,
      key: 'lastTherapyDate',
      labelImage: '',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.IS_THERAPIST_DOCS,
      value: false,
      checkboxlabel: 'Do you have the Therapist Notes/Documents with you?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'documentUpload',
      placeholder: 'Select date',
      label: 'Therapist Notes/Documents',
      required: false,
      key: INTAKE_FORM_KEYS.THERAPIST_DOCS,
      labelImage: '',
      value: [],
      controlType: 'documentUpload',
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `I authorize the release of any and all personal medical information, health records, progress notes, laboratory, diagnostic and /or surgical procedures to Dr. Rama Prayaga at Fax - (866) 528 -6229. Please include all diagnostic studies, physician findings and/or diagnoses.`,
      controlType: 'checkbox-tik',
      key: INTAKE_FORM_KEYS.SEND_THERAPIST,
      required: false,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: false,
    },
    {
      type: 'checkbox-slider',
      key: 'psychiatricSlider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Are you taking any psychiatric medications',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'medications',
      formInputs: [[
        {
          type: 'header',
          // idName: 'psychiatric-header',
          label: 'Provide following details',
          showontoggle: true,
          key: 'psychiatricheader',
          editable: true,
        },
        {
          type: 'text',
          // idName: 'psychiatric-medicine-name',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          // emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [this.valideTextArea],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          // idName: 'psychiatric-dosage-and-direction',
          placeholder: '',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          maxLength: 150,
          editable: true,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
        {
          type: 'radio',
          placeholder: '',
          label: 'What type of medication is it?',
          labelImage: '',
          required: false,
          key: 'medicationType',
          value: '',
          editable: true,
          controlType: 'custom-radio',
          emptyValueMessage: 'Please select one option',
          className: 'radio-btn-horizontal formarray-radio',
          validationValue: '',
          validators: [],
          fields: [
            {
              label: 'Current',
              value: 'current',
            },
            {
              label: 'Lifetime',
              value: 'lifetime',
            },
          ],
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Start Date',
          required: false,
          key: 'prescriptionStartDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          validators: [this.validateStartDate],
          className: 'side__by__side',
          focus: true,
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'End Date',
          required: false,
          key: 'prescriptionEndDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          className: 'side__by__side',
          emptyValueMessage: 'Please select date',
          validationValue: 'End date should be greater than start date',
          validators: [this.validateEndDate],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'checkbox-slider',
          key: 'discontinued',
          checkboxlabel: 'Have you discontinued it?',
          controlType: 'checkbox-slider',
          value: false,
          leftLable: true,
          validators: [],
          editable: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Reason details',
          required: false,
          key: 'discontinuedReasion',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'EXISTINGUSER_MEDICALHISTORY',
      selected: false,
      editable: true,
      showClose: false,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'psychiatricAddNew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'EXISTINGUSER_MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Currently or in past have you used benzodiazepines?',
      key: 'benzodiazepinesSlider',
      controlType: 'checkbox-slider',
      selected: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'benzodiazepines',
      formInputs: [[
        {
          type: 'header',
          label: 'Provide following details',
          showontoggle: true,
          editable: true,
          key: 'benzodiazepinesheader'
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          // emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [this.valideTextArea],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
        {
          type: 'radio',
          placeholder: '',
          label: 'What type of medication is it?',
          labelImage: '',
          required: false,
          key: 'medicationType',
          value: '',
          editable: true,
          controlType: 'custom-radio',
          emptyValueMessage: 'Please select one option',
          className: 'radio-btn-horizontal formarray-radio',
          validationValue: '',
          validators: [],
          fields: [
            {
              label: 'Current',
              value: 'Current',
            },
            {
              label: 'Lifetime',
              value: 'Lifetime',
            },
          ],
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Start Date',
          required: false,
          key: 'prescriptionStartDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          validators: [this.validateStartDate],
          className: 'side__by__side',
          focus: true,
          showontoggle: true,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'End Date',
          key: 'prescriptionEndDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          className: 'side__by__side',
          validationValue: 'End date should be greater than start date',
          emptyValueMessage: 'Please select date',
          validators: [this.validateEndDate],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'checkbox-slider',
          key: 'discontinued',
          checkboxlabel: 'Have you discontinued it?',
          controlType: 'checkbox-slider',
          value: false,
          leftLable: true,
          validators: [],
          editable: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Reason details',
          required: false,
          key: 'discontinuedReasion',
          value: '',
          controlType: 'textarea',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        }
      ]
      ],
      controlType: 'formArray',
      formtag: 'EXISTINGUSER_MEDICALHISTORY',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'benzodiazepinesaddnew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'EXISTINGUSER_MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      isToggle: true,
      checkboxlabel: 'Specifically, are you currently taking or have you in the past 6 months taken Adderall or other stimulants',
      key: 'medicaladderallstimulants',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'medicaladderal',
      controlType: 'formArray',
      formInputs: [[
        {
          type: 'header',
          label: 'If yes,please Provide',
          showontoggle: true,
          editable: true,
          key: 'medicaladderallheader'
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          // emptyValueMessage: 'Please enter medicine',
          validationValue: 'Please enter valid medicine name',
          validators: [this.valideTextArea],
          focus: true,
          className: 'side__by__side',
          showontoggle: true,
          fieldsToDelete: 3,
        },
        {
          type: 'calendar',
          placeholder: 'Select date',
          label: 'Last prescription date',
          required: false,
          key: 'lastPrescriptionDate',
          labelImage: '',
          value: '',
          controlType: 'calendar',
          editable: true,
          emptyValueMessage: 'Please select date',
          className: 'side__by__side',
          validators: [],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          className: 'remaining-number',
          // emptyValueMessage: 'Please add description',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          editable: true,
          maxLength: 150,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        },
      ]
      ],
      formtag: 'EXISTINGUSER_MEDICALHISTORY',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'medicalAdderallAddNew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'EXISTINGUSER_MEDICALHISTORY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'upload-image',
      key: INTAKE_FORM_KEYS.LAB_WORKS_DOCS,
      label: 'Provide the lab work docs',
      uploadlabel: 'Lab Work Docs',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'documentUpload',
      placeholder: 'Provide a copy of the lab work docs',
      required: false,
      labelImage: '',
      value: [],
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Please list any known allergies',
      required: false,
      key: 'knownAllergies',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      key: 'disabilityBenefits',
      checkboxlabel: 'Are you in the process of filing for disability benefits?',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: false,
      checkboxlabel: 'Do you have any present or past history of drug or alcohol abuse ?',
      key: 'drugAlcoholAbuse',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: 'alcoholDrugIn3Months',
      value: false,
      checkboxlabel: 'Have you used any alcohol or drug within the last 3 months?',
      controlType: 'checkbox-slider',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      key: 'selfMedicate',
      value: '',
      checkboxlabel: 'Do you self-medicate with alcohol or abuse any non-medical stimulants?',
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Please provide any other pertinent information that may be helpful for the physician in treating you',
      required: false,
      key: 'medicalKnownAllergies',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number lenght-text',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.MEDICAL_DISORDERS,
      checkboxlabel: 'Do you have any medical disorders?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.MEDICAL_DISORDERS_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.PAST_HISTORY,
      checkboxlabel: 'Have you had past history of (alcohol / marijuana/ cocaine)?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.PAST_HISTORY_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.NON_PSYCH_MEDS,
      checkboxlabel: 'Do u take non-psych meds?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.NON_PSYCH_MEDS_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.ANY_OPIOIDS,
      checkboxlabel: 'Do u take any opioids?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: INTAKE_FORM_KEYS.ANY_OPIOIDS_DESC,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Previous prescriber details',
      showontoggle: true,
      key: INTAKE_FORM_KEYS.HEADER,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.PREVIOUS_PRESC,
      checkboxlabel: 'Do you have a previous prescriber?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'prescriber name',
      label: 'Name',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter name',
      validationValue: 'Please enter valid name',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_PH,
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      label: 'Email Id',
      placeholder: 'prescriber Email Id',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_EMAIL,
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter email id',
      validationValue: 'Please enter valid email id',
      validationValue1: 'Please enter email id other than your email id or office email id\'s',
      validators: [this.validateEmail, this.validateTherapistEmail.bind(this)],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'email__id pemail__scroll'
    },
    {
      type: 'textarea',
      placeholder: 'Address',
      label: 'Address',
      required: false,
      key: INTAKE_FORM_KEYS.PREV_PRESCRIBER_ADDR,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter valid address',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.IS_PRESCRIBER_DOCS,
      value: false,
      checkboxlabel: 'Do you have the Prescriber Notes/Documents with you?',
      isToggle: true,
      controlType: 'checkbox-slider',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'documentUpload',
      placeholder: 'Select date',
      label: 'Prescriber Notes/Documents',
      required: false,
      key: INTAKE_FORM_KEYS.PRESCRIBER_DOCS,
      labelImage: '',
      value: [],
      controlType: 'documentUpload',
      editable: true,
      emptyValueMessage: 'Please upload minimum 1 document',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: `I authorize the release of any and all personal medical information, health records, progress notes, laboratory, diagnostic and /or surgical procedures to Dr. Rama Prayaga at Fax - (866) 528 -6229. Please include all diagnostic studies, physician findings and/or diagnoses.`,
      controlType: 'checkbox-tik',
      key: INTAKE_FORM_KEYS.SEND_PRESCRIBER,
      required: false,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: false,
    },
    {
      type: 'checkbox-slider',
      key: INTAKE_FORM_KEYS.HOSPITALIZED_BEFORE,
      checkboxlabel: 'Have you been hospitalized before?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Hospital name',
      label: 'Hospital Name',
      required: false,
      key: INTAKE_FORM_KEYS.HOSPITAL_NAME,
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter hospital name',
      validationValue: 'Please enter valid hospital name',
      validators: [this.validateName],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of hospitalization',
      required: false,
      key: INTAKE_FORM_KEYS.YEAR,
      labelImage: '',
      value: '',
      controlType: 'calendar',
      minDate: this.minDateOfHospitalization,
      editable: true,
      // emptyValueMessage: 'Please select date',
      validators: [],
      focus: true,
      showontoggle: true,
      className: ''
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Reason for hospitalization',
      required: false,
      key: INTAKE_FORM_KEYS.REASON,
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please enter reason',
      validationValue: 'Please enter valid reason',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
  ];

  existingUserMiscellaenousFormConfig = [
    {
      type: 'checkbox-slider',
      key: 'legalIssues',
      checkboxlabel: 'Do you have any pending or ongoing legal issues ?',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      validators: [],
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Provide details',
      required: false,
      key: 'miscellaenousKnownAllergies',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Please provide your current pharmacy details',
      showontoggle: true,
      key: 'currentPharmacyHeader'
    },
    {
      type: 'dropdown',
      placeholder: 'Please select pharmacy name',
      label: 'Pharmacy Name',
      required: false,
      key: 'pharmacyName',
      labelImage: '',
      list: this.statesList,
      controlType: 'dropdown',
      className: 'pharmacy-section',
      editable: true,
      emptyValueMessage: 'Please select pharmacy name',
      validators: [],
      focus: true,
      showontoggle: true,
    }, {
      type: 'text',
      placeholder: 'Please Enter Pharmacy Name',
      label: 'Please Enter Pharmacy Name',
      required: true,
      key: 'pharmacyNameText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please Enter pharmacy name',
      validationValue: 'Please enter valid pharmacy name',
      validators: [],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: false,
      key: 'pharmacyPhoneNo',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Email id',
      label: 'Email Id',
      required: false,
      key: 'pharmacyEmailId',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: '',
      validationValue: 'Please enter valid email id',
      // validators: [],
      validators: [this.validateEmail],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Fax Number',
      required: false,
      key: 'pharmacyFaxNo',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter fax number',
      validationValue: 'Please enter valid fax number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Address 1',
      required: false,
      key: 'pharmacyAddress1',
      value: '',
      controlType: 'textarea',
      // emptyValueMessage: 'Please add address 1',
      validationValue: 'Please add valid address1',
      validators: [this.valideTextArea],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Address 2',
      required: false,
      key: 'pharmacyAddress2',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: '',
      validators: [],
      // validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter city name',
      label: 'City',
      required: true,
      key: 'pharmacyCity',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter city name',
      validationValue: 'Please enter valid city name',
      validators: [this.valideTextArea],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please select state',
      label: 'Select State',
      required: false,
      key: INTAKE_FORM_KEYS.PHARMACY_STATE,
      labelImage: 'user',
      list: this.statesList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select state',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter zip code',
      label: 'Zip Code',
      required: true,
      key: 'pharmacyZipcode',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      focus: true,
      max: 5,
      emptyValueMessage: 'Please enter zip code',
      validationValue: 'Please enter valid 5 digits zip code',
      validators: [this.validateZIP],
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'How did you hear about Dr. Prayaga?',
      labelImage: '',
      required: true,
      key: 'knowDoctor',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      className: 'about-radio',
      emptyValueMessage: 'Please select one option',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Website',
          value: 'website',
          checked: true,
        },
        {
          label: 'Advertisement',
          value: 'advertisement',
          checked: false,

        },
        {
          label: 'Social Media',
          value: 'socialmedia',
          checked: false,
        },
        {
          label: 'Referral',
          value: 'referral',
          checked: false,
        },
        {
          label: 'Others',
          value: 'Others',
          checked: false,
        }
      ],
      showontoggle: true,
    },

  ];


  existingUserNewPatientSubmitFormConfig = [
    {
      type: 'checkbox',
      labeltext: 'I hereby acknowledge that the information provided is accurate',
      controlType: 'checkbox-tik',
      key: 'iAcknowledge',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox check-disable',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [this.validateCheckBox],
      focus: true,
      selected: false,
      editable: false,
      showontoggle: true,
    },
    {
      type: 'checkbox',
      labeltext: 'I accept the terms and conditions of New Tele Doc',
      controlType: 'checkbox-tik',
      key: 'iAccept',
      required: true,
      value: false,
      className: 'iacknowledge__checkbox termsandcondition__checkbox check-disable',
      emptyValueMessage: 'Please accept this to proceed',
      validators: [this.validateCheckBox],
      focus: true,
      selected: false,
      editable: false,
      showontoggle: true,
    },
    {
      type: 'link',
      placeholder: '',
      link: 'Terms & Conditions',
      href: '/terms',
      editable: false,
      className: 'link-disable',
      showontoggle: true,
    },
    {
      type: 'signature',
      label: '',
      placeholder: 'Signature',
      labelImage: '',
      required: true,
      maxLength: '20',
      key: INTAKE_FORM_KEYS.PATIENT_SIGN,
      value: '',
      className: '',
      editable: false,
      controlType: 'signature',
      emptyValueMessage: 'Please enter signature',
      validationValue: 'Please enter valid signature',
      validators: [this.validateAplha],
      showontoggle: true,
    }
  ];


  signupFormInputsConfig = [
    {
      type: 'text',
      placeholder: 'Please enter your full name',
      label: 'Name',
      required: true,
      key: 'userName',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter your name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
    }, // text field
    {
      type: 'number',
      placeholder: 'Enter your mobile number',
      label: 'Mobile Number',
      labelImage: 'mobile',
      required: true,
      key: 'mobileNumber',
      value: '',
      editable: true,
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mobile number',
      validationValue: 'Please enter valid mobile number',
      validators: [Validators.required, this.validateMobile],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
    }, // number field
    {
      type: 'password',
      placeholder: 'Set your 6-digit PIN',
      label: 'Consent Pin',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'consentPin',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter login PIN',
      validationValue: 'Please enter 6 digit number',
      validators: [Validators.required, this.validatePassword],
      showontoggle: true,
    }, // password field
    {
      type: 'checkbox',
      placeholder: '',
      label: 'I agree to Onemoney Terms and Conditions',
      required: true,
      key: 'acceptTerms',
      value: false,
      controlType: 'checkbox',
      emptyValueMessage: 'Please select \'Terms and conditions\'',
      leftLable: true,
      validators: [Validators.requiredTrue],
      selected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Create',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'Format Type',
      labelImage: '',
      required: true,
      key: 'formatType',
      value: '',
      editable: true,
      controlType: 'radio',
      emptyValueMessage: 'Please select format type',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Value',
          value: 'Value'
        },
        {
          label: 'Range',
          value: 'Range'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      label: 'Description',
      required: false,
      key: 'description',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: 'radio-btn',
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    }
  ];

  loginFormInputsConfig = [
    {
      type: 'text',
      placeholder: 'Enter your registered Email id',
      label: 'Email id',
      required: true,
      key: 'emailId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter your registered Email id',
      validationValue: 'Please enter valid registered Email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      className: 'emailid',
      showontoggle: true,
    },
    {
      type: 'password',
      placeholder: 'Enter your password',
      label: 'Password',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'password',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter password',
      validationValue: 'Please enter correct password',
      validators: [Validators.required, this.validatePassword],
      className: 'password',
      showontoggle: true,
      path: 'fp'
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Login',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  onlyPasswordFormInputsConfig = [
    {
      type: 'password',
      placeholder: 'Enter your new password',
      label: 'New Password',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'password',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter your new password',
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      validators: [Validators.required, this.validatePassword],
      className: 'password',
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Submit',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  onlyExistingPasswordFormInputsConfig = [
    {
      type: 'password',
      placeholder: 'Enter your password',
      label: 'Old Password',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'password',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter your password',
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      validators: [Validators.required, this.validatePassword],
      className: 'password',
      showontoggle: true,
      path: 'dob'
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Submit',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  resendMailFormInputsConfig = [
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Resend',
      key: '',
      value: 'resend',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      className: 'resend-btn',
      showontoggle: true,
    }
  ];
  resendVerificationMailFormInputsConfig = [{
    type: 'submit',
    placeholder: '',
    required: true,
    text: 'Resend',
    key: '',
    value: 'resend',
    controlType: 'submitButton',
    validators: [],
    callback: '',
    className: 'resend-btn',
    showontoggle: true,
  }];

  userProfileFormEditInputsConfig = [
    {
      type: 'text',
      placeholder: 'Enter your First name',
      label: 'First Name',
      required: true,
      key: 'firstName',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter first name',
      validationValue: 'Please enter valid first name',
      validators: [Validators.required, this.validateName],
      focus: true,
      className: 'firstName',
      showontoggle: false,
    },
    {
      type: 'text',
      placeholder: 'Enter your Last name',
      label: 'Last Name',
      required: true,
      key: 'lastName',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      className: 'lastName',
      showontoggle: false,
    },
    {
      type: 'text',
      placeholder: 'Email',
      label: 'Email',
      required: true,
      key: 'email',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter your email id',
      validationValue: 'Please enter valid email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      showontoggle: false,
    },
    {
      type: 'text',
      placeholder: 'Confirm Email',
      label: 'Confirm Email',
      required: true,
      key: 'confirmEmail',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please re-enter your email id',
      validationValue: 'Please enter valid email id',
      matchMessage: 'Email and confirm email should match',
      validators: [Validators.required, this.validateEmail, this.validateConfirmEmail],
      focus: true,
      blockCopy: true,
      showontoggle: false,
    }
  ];

  userProfileFormInputsConfig = [
    {
      type: 'password',
      placeholder: 'Old Password',
      label: '',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'oldPassword',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter old password',
      matchMessage: 'Password is not same as above',
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      validators: [Validators.required, this.validatePassword],
      className: 'password',
      showontoggle: false,
    },
    {
      type: 'password',
      placeholder: 'New Password',
      label: '',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'newPassword',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter your new password',
      // validationValue: "Password is not same as above",
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      matchMessage: 'Password is not same as above',
      validators: [Validators.required, this.validatePassword],
      className: 'password',
      showontoggle: false,
    },
  ];
  resetPasswordFormInputsConfig = [
    {
      type: 'password',
      placeholder: 'Enter your New password',
      label: 'Password',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'reset',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter new password',
      matchMessage: 'Password is not same as above',
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      validators: [Validators.required, this.validatePassword, this.resetConfirmPassword],
      className: 'password',
      showontoggle: true,
    },
    {
      type: 'password',
      placeholder: 'Re-enter your new password',
      label: 'Confirm Password',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'confirm',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please Re-enter your new password',
      validationValue: 'Password is not same as above',
      // validationValue: "Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter",
      matchMessage: 'Password is not same as above',
      validators: [Validators.required, this.validatePassword, this.resetConfirmPassword],
      className: 'password',
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Submit',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  registerFormInputsConfig = [
    {
      type: 'text',
      placeholder: 'Enter your First name',
      label: 'First Name',
      required: true,
      key: 'firstName',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter first name',
      validationValue: 'Please enter valid first name',
      validators: [Validators.required, this.validateName],
      focus: true,
      className: 'firstName',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Enter your Last name',
      label: 'Last Name',
      required: true,
      key: 'lastName',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      className: 'lastName',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Enter your Email id',
      label: 'Email id',
      required: true,
      key: 'emailId',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter your Email id',
      validationValue: 'Please enter valid Email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      className: 'emailid',
      showontoggle: true,
    },
    {
      type: 'password',
      placeholder: 'Enter your password',
      label: 'Password',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'newPassword',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please enter new password',
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      matchMessage: 'Password is not same as below',
      validators: [Validators.required, this.validatePassword, this.validateConfirmPassword],
      className: 'password',
      showontoggle: true,
    },
    {
      type: 'password',
      placeholder: 'Re-enter your new password',
      label: 'Confirm Password',
      labelImage: 'show-password',
      isShowpassword: false,
      required: true,
      key: 'newConfirmPassword',
      passwordImage: 'show-password',
      value: '',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Please Re-enter your new password',
      matchMessage: 'Password is not same as above',
      // validationValue: "Password is not same as above",
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      validators: [Validators.required, this.validatePassword, this.validateConfirmPassword],
      className: 'password',
      showontoggle: true
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Register',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  forgotPasswordFormInputsConfig = [
    {
      type: 'text',
      placeholder: 'Enter your registered email id',
      label: 'Email id',
      required: true,
      key: 'userName',
      labelImage: 'user',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter your registered email id',
      validationValue: 'Please enter valid registered email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      className: 'emailid',
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Submit',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  forgotPasswordDOBFormInputsConfig = [
    {
      type: 'calendar',
      placeholder: 'Please enter primary account holder DOB',
      label: 'Primary account holder date of birth',
      required: true,
      key: 'dob',
      isDob: true,
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Submit',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  forgotPasswordWithCodeFormInputsConfig = [
    {
      type: 'password',
      placeholder: 'Enter the new password',
      label: 'New Password',
      required: true,
      key: 'newConfirmPassword',
      labelImage: 'show-password',
      value: '',
      passwordImage: 'show-password',
      editable: true,
      controlType: 'inputTextPassword',
      emptyValueMessage: 'Enter a new password',
      validationValue: 'Password must contain at least 8 characters \ with at least one special character,one digit,one small letter and one capital letter',
      validators: [Validators.required, this.validatePassword],
      focus: true,
      className: 'password',
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Submit',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      showontoggle: true,
    }
  ];

  employeeCreationConfig = [
    {
      type: 'header',
      label: 'Basic Details',
      showontoggle: true,
    },
    {
      type: 'text',
      label: 'First Name',
      required: true,
      key: 'firstName',
      placeholder: '',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter first name',
      validationValue: 'Please enter valid first name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'text',
      label: 'Last Name',
      required: true,
      key: 'lastName',
      placeholder: '',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'dropdown',
      label: 'Designation',
      required: true,
      key: 'designation',
      labelImage: 'user',
      placeholder: 'Please select',
      value: '',
      list: [
        {
          label: 'Doctor',
          value: 'doctor'
        },
        {
          label: 'Technician',
          value: 'technician'
        }
      ],
      controlType: 'dropdown',
      emptyValueMessage: 'Please select designation',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'number',
      label: 'Employee ID',
      required: true,
      placeholder: '',
      key: 'empId',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter employee id',
      validationValue: 'Please enter valid employee id',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date of Birth',
      required: true,
      key: 'dob',
      isDob: true,
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date of birth',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: 'Phone Number',
      required: true,
      key: 'mobile',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'text',
      label: 'Email ID',
      placeholder: '',
      required: true,
      key: 'email',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter email id',
      validationValue: 'Please enter valid email id',
      validators: [Validators.required, this.validateEmail],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'email__id'
    },
    {
      type: 'custom-check',
      categoryLabel: 'location',
      label: 'Working Location:',
      controlType: 'custom-check',
      key: 'workingLocation',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
      ],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
      className: 'working__location',
    },
    {
      type: 'dropdown',
      label: 'Residing In',
      required: true,
      key: 'country',
      labelImage: ' ',
      placeholder: 'Please select',
      value: '',
      list: [
        {
          label: 'US',
          value: 'US'
        },
        {
          label: 'India',
          value: 'India'
        }
      ],
      controlType: 'dropdown',
      emptyValueMessage: 'Please select residing in',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'number',
      mask: '999-99-9999',
      placeholder: 'XXX-XX-XXXX',
      label: 'Social Security Number',
      labelImage: 'mobile',
      required: true,
      key: 'ssn',
      value: '',
      editable: true,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter social security number',
      validationValue: 'Please enter valid social security number',
      validators: [Validators.required],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'text',
      label: 'Driving Licence Number',
      required: true,
      key: 'drivingLicense',
      placeholder: '',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter driving licence number',
      validationValue: 'Please enter valid driving licence number',
      validators: [Validators.required, this.validateAplhaNumeric],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'

    },
    {
      type: 'number',
      mask: '9999-9999-9999',
      placeholder: 'XXXX-XXXX-XXXX',
      label: 'Aadhar Card Number',
      required: true,
      key: 'aadhar',
      labelImage: ' ',
      value: '',
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter aadhar card',
      validationValue: 'Please enter valid aadhar card',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'text',
      mask: 'aaaaa9999a',
      placeholder: 'XXXXXXXXXX',
      label: 'Pan card Number',
      required: true,
      key: 'pan',
      labelImage: ' ',
      value: '',
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter pan number',
      validationValue: 'Please enter valid pan number',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'

    },
    {
      type: 'text',
      placeholder: '',
      label: 'ZIP Code',
      required: true,
      key: 'zip',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter zip code',
      validationValue: 'Please enter valid zip Code',
      validators: [Validators.required, this.validateZIP],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },
    {
      type: 'text',
      label: 'Mailing Address',
      required: true,
      key: 'address',
      placeholder: '',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter valid address',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'mailling__address'
    },
    {
      type: 'header',
      label: 'Bank Details',
      showontoggle: true,
      className: 'bank--heding'
    },
    {
      type: 'text',
      label: 'Account Holder Name',
      required: true,
      key: 'accHolderName',
      placeholder: '',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter account holder name',
      validationValue: 'Please enter valid account holder name',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'

    },
    {
      type: 'text',
      label: 'Account Number',
      required: true,
      key: 'accNo',
      labelImage: ' ',
      placeholder: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter account number',
      validationValue: 'Please enter valid account number',
      validators: [Validators.required, this.validateAplhaNumeric],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'

    },
    {
      type: 'text',
      label: 'Bank Name',
      placeholder: '',
      required: true,
      key: 'bankName',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter bank name',
      validationValue: 'Please enter valid bank name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'

    },
    {
      type: 'text',
      label: 'Branch',
      placeholder: '',
      required: true,
      key: 'branchName',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter branch name',
      validationValue: 'Please enter valid branch name',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'

    },
    {
      type: 'text',
      label: 'IFSC Code',
      placeholder: '',
      required: true,
      key: 'IFSC',
      labelImage: ' ',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter IFSC code',
      validationValue: 'Please enter valid IFSC code',
      validators: [Validators.required, this.validateAplhaNumeric],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'

    },
    {
      type: 'upload',
      controlType: 'uploadsDocuments',
      key: 'uploads',
      label: 'Documents (ID Proof)',
      editable: true,
      isEditAndView: false,
      showClose: false,
      showontoggle: true,
      validationValue: 'Please upload at least one document',
      value: [],
      validators: [Validators.required],
    },
    {
      type: 'header',
      label: 'Permissions',
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Appointments',
      controlType: 'groupCheckbox',
      key: 'appointments',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Patients',
      controlType: 'groupCheckbox',
      key: 'patients',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Locations',
      controlType: 'groupCheckbox',
      key: 'locations',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Employees',
      controlType: 'groupCheckbox',
      key: 'employees',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Invoices',
      controlType: 'groupCheckbox',
      key: 'invoices',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Email Templates',
      controlType: 'groupCheckbox',
      key: 'templates',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Request Documents',
      controlType: 'groupCheckbox',
      key: 'requetsDocuments',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Patient Request Documents',
      controlType: 'groupCheckbox',
      key: 'patientDocuments',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Manage User Accounts',
      controlType: 'groupCheckbox',
      key: 'manageUserAccounts',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'profile',
      key: 'picture',
      uploadlabel: '',
      text: 'select file',
      controlType: 'profile',
      placeholder: 'Provide a copy of the front of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select profile picture',
      validators: [Validators.required],
      focus: true,
      showImage: true,
      showontoggle: true,
      isEditAndView: false,
      initialImage: 'assets/images/pngs/empty-profile-picture.png',
    },
    {
      type: 'submit',
      required: true,
      text: 'Cancel',
      key: '',
      value: 'cancel',
      controlType: 'cancelButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
    },
    {
      type: 'submit',
      required: true,
      text: 'Apply',
      key: '',
      value: 'apply',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      className: 'newemployee'
    }
  ];
  uploadConfig = [
    {
      type: 'header',
      label: '',
      showontoggle: false,
    },
    {
      type: 'upload-image',
      key: 'upload',
      uploadlabel: '',
      // label: "Provide a copy of the front and back of the card",
      // fileName: "",
      text: 'select file',
      controlType: 'upload-image',
      placeholder: 'Provide a copy of the front of the card',
      required: true,
      labelImage: '',
      value: '',
      editable: true,
      emptyValueMessage: 'Please select primary insurance front image',
      validationValue: '(Only jpg, png, gif, jpeg, tiff, psd, pdf, esp, ai, indd, raw, heic, heif files are allowed)',
      validationSize: 'File size should be less than 2MB, Please compress file and upload',
      validators: [this.validateFileUpload],
      focus: true,
      showImage: true,
      showontoggle: true,
    },
  ];
  newAppointmentConfig = [
    {
      type: 'radio',
      placeholder: '',
      labelImage: '',
      required: true,
      value: 'personalReason',
      editable: true,
      controlType: 'custom-radio',
      categoryLabel: 'treatments',
      label: 'Treatments',
      key: 'treatments',
      validationValue: 'Please select one of the treatment',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
      ],
      showontoggle: true,
    },
    {
      type: 'radio',
      categoryLabel: 'location',
      label: 'Location',
      controlType: 'custom-radio',
      key: 'location',
      required: true,
      value: '',
      isIntermediate: false,
      placeholder: '',
      labelImage: '',
      editable: true,
      validationValue: 'Please select one of the location',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
      ],
      showontoggle: true,
      className: 'working__location',
    },
    {
      type: 'autosearch',
      label: 'Patient Name',
      required: true,
      key: 'patientSearch',
      labelImage: 'user',
      value: '',
      placeholder: 'Please Search for the patient name',
      emptyValueMessage: 'Please select the patient name',
      results: [
      ],
      controlType: 'autoSearch',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side'
    },

    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date',
      required: true,
      key: 'date',
      isDob: false,
      labelImage: 'user',
      value: '',
      isFutureDate: true,
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select a valid date',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      validationValue: 'Please select slots availability date',
      className: 'side__by__side'
    },
    {
      type: 'dropdown',
      label: 'Timing',
      required: true,
      key: 'timeSlots',
      placeholder: 'Please select a time slot',
      labelImage: 'user',
      value: '',
      list: [
      ],
      controlType: 'dropdown',
      emptyValueMessage: 'Please select a time slot',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      className: 'side__by__side timeDropdown'
    },
    {
      type: 'lastVisit',
      key: '',
      value: '',
      controlType: 'lastVisit',
      showontoggle: false,
    },
    {
      type: 'submit',
      required: true,
      text: 'Cancel',
      key: '',
      value: 'cancel',
      controlType: 'cancelButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
    },
    {
      type: 'submit',
      required: true,
      text: 'Schedule',
      key: '',
      value: 'schedule',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      className: 'newemployee'
    }
  ];
  bookSlotConfig = [
    {
      type: 'autosearch',
      label: 'Patient Name',
      required: true,
      key: 'patientSearch',
      labelImage: 'user',
      value: '',
      placeholder: 'Please Search for the patient name',
      emptyValueMessage: 'Please select the patient name',
      results: [
      ],
      controlType: 'autoSearch',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date',
      required: true,
      key: 'date',
      isDob: false,
      labelImage: 'user',
      value: '',
      isFutureDate: true,
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select a valid date',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      validationValue: 'Please select slots availability date'
    },
    {
      type: 'dropdown',
      label: 'Timing',
      required: true,
      key: 'timeSlots',
      labelImage: 'user',
      value: '',
      list: [
      ],
      controlType: 'dropdown',
      emptyValueMessage: 'Please select time slot',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'lastVisit',
      key: '',
      value: '',
      controlType: 'lastVisit',
      showontoggle: false,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'submit',
      required: true,
      text: 'Block this slot',
      key: '',
      value: 'cancel',
      controlType: 'cancelButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      className: 'green'
    },
    {
      type: 'submit',
      required: true,
      text: 'Schedule',
      key: '',
      value: 'schedule',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      className: 'newemployee'
    },
  ];
  resheduleSlotConfig = [
    {
      type: 'dropdown',
      label: 'Locations',
      required: true,
      key: 'locations',
      labelImage: '',
      value: '',
      list: [
      ],
      controlType: 'dropdown',
      emptyValueMessage: 'Please select location',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: 'Date',
      required: true,
      key: 'date',
      isFutureDate: true,
      isDob: false,
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      validationValue: 'Please select slots availability date',
    },
    {
      type: 'dropdown',
      label: 'Timing',
      placeholder: 'Please select slot',
      required: true,
      key: 'timeSlots',
      labelImage: 'user',
      value: '',
      list: [
      ],
      controlType: 'dropdown',
      emptyValueMessage: 'Please select time slot',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'lastVisit',
      key: '',
      value: '',
      controlType: 'lastVisit',
      showontoggle: false,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'Why would you like to reschedule your current appointment?',
      labelImage: '',
      required: true,
      key: 'reason_for_reschedule',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select reason for reschedule',
      validationValue: '',
      className: 'radio-btn-horizontal',
      validators: [Validators.required],
      fields: [
        {
          label: 'Medicine side effects',
          value: 'medicine_side_effects',
          checked: false,
        },
        {
          label: 'Travel',
          value: 'travel',
          checked: false,
        },
        {
          label: 'Other',
          value: 'other',
          checked: false,
        }
      ],
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Please enter comments here',
      label: 'Provide details',
      required: true,
      key: 'reasonDetails',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      className: 'remaining-number',
      emptyValueMessage: 'Please enter reason for reschedule',
      // validationValue: 'Please add valid reason',
      validators: [Validators.required, this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'submit',
      required: true,
      text: 'Cancel',
      key: '',
      value: 'cancel',
      controlType: 'cancelButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
    },
    {
      type: 'submit',
      required: true,
      text: 'Schedule',
      key: '',
      value: 'schedule',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      className: 'newemployee'
    },
  ];
  blockedSlotConfig = [
    {
      type: 'header',
      label: 'Date',
      showontoggle: true,
      editable: true,
      key: 'date'
    },
    {
      type: 'calendar',
      placeholder: 'Select date',
      label: '',
      required: true,
      key: 'fromDate',
      isDob: false,
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      isFutureDate: true,
      emptyValueMessage: 'Please start select date',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      validationValue: 'Please select valid  date',
    },
    {
      type: 'calendar',
      placeholder: 'End Date',
      isTime: false,
      isDob: false,
      required: false,
      key: 'toDate',
      labelImage: 'user',
      value: '',
      controlType: 'calendar',
      editable: true,
      isFutureDate: true,
      emptyValueMessage: 'Please select end date',
      validationValue: 'End date should be greater than start date',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Time',
      showontoggle: true,
      editable: true,
      key: 'time'
    },
    {
      type: 'calendar',
      isDob: false,
      placeholder: 'Start Time',
      required: true,
      key: 'fromTime',
      labelImage: '',
      isTime: true,
      value: '',
      controlType: 'timer',
      editable: true,
      emptyValueMessage: 'Please select Start Time',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
    },
    {
      isDob: false,
      type: 'calendar',
      placeholder: 'End Time',
      isTime: true,
      required: false,
      key: 'toTime',
      labelImage: '',
      value: '',
      controlType: 'timer',
      editable: true,
      emptyValueMessage: 'Please select End Time',
      validationValue: 'End time should be greater than start time',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'custom-check',
      categoryLabel: 'Treatments',
      label: 'Treatments',
      controlType: 'custom-check',
      key: 'treatments',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
      ],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
      className: 'trements-list',
    },
    {
      type: 'submit',
      required: true,
      text: 'Block slots',
      key: '',
      value: 'block slots',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      className: 'blockslot__button'
    },
  ];
  cancelfeedbackFormConfig = [
    {
      type: 'header',
      label: 'Please let us know your reason for cancelling the appointment',
      showontoggle: true,
    },
    {
      type: 'radio',
      placeholder: '',
      label: '',
      labelImage: '',
      required: true,
      key: 'reason',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select reason for cancelling',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Personal Reason',
          value: 'personalReason',
          checked: false,
        },
        {
          label: 'Insurance',
          value: 'insurance',
          checked: false,
        },
        {
          label: 'Travel',
          value: 'travel',
          checked: false,
        },
        {
          label: 'Other',
          value: 'other',
          checked: false,
        }
      ],
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Please enter reason for cancellation',
      label: 'Please provide details below',
      required: true,
      key: 'reasonDetails',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      emptyValueMessage: 'Please enter reason for cancellation',
      validationValue: 'Please add valid reason',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    }
  ];
  resheduleFormConfig = [
    {
      type: 'radio',
      placeholder: '',
      label: 'Why would you like to reschedule your current appointment?',
      labelImage: '',
      required: true,
      key: 'reason_for_reschedule',
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select reason for reschedule',
      validationValue: '',
      className: 'radio-btn-horizontal',
      validators: [Validators.required],
      fields: [
        {
          label: 'Medicine side effects',
          value: 'medicine_side_effects',
          checked: false,
        },
        {
          label: 'Travel',
          value: 'travel',
          checked: false,
        },
        {
          label: 'Other',
          value: 'other',
          checked: false,
        }
      ],
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: '',
      maxLength: 150,
      label: 'Provide details',
      required: true,
      key: 'reshedule_details',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: '',
      editable: true,
      count: '150 remaining',
      showRange: true,
      showontoggle: true,
    },
  ];

  createLocationConfig = [
    {
      type: 'header',
      label: `Details`,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter location name',
      label: 'Location Name',
      required: true,
      key: 'locName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter location name',
      validationValue: 'Please enter valid location name',
      className: 'side__by__side',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'radio',
      label: 'Location Type',
      required: true,
      key: 'locType',
      value: '',
      className: 'side__by__side',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select atleast one option',
      validators: [Validators.required],
      fields: [
        {
          label: 'Online',
          value: 'online'
        },
        {
          label: 'Offline',
          value: 'offline'
        }
      ],
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter location url',
      label: 'Location Url',
      required: false,
      key: 'locUrl',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter location url',
      validationValue: 'Please enter valid url',
      className: 'side__by__side',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    /*{
      type: 'text',
      placeholder: 'Please enter employees in location',
      label: 'Employees in Location',
      required: true,
      key: 'empinLoc',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter employees in location',
      validationValue: 'Please enter valid value',
      className: 'side__by__side',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
    },*/
    {
      type: 'textarea',
      placeholder: 'Please enter address',
      label: 'Address',
      required: true,
      key: 'address',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: true,
      maxLength: 150,
      className: 'message-box',
      emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter valid address',
      validators: [Validators.required],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
  ];

  createLocationServiceConfig = [
    {
      type: 'text',
      placeholder: 'Please enter treatment',
      label: 'Treatment',
      required: true,
      key: 'treatment',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter treatment',
      validationValue: 'Please enter valid treatment',
      className: 'side__by__side',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'dropdown',
      placeholder: 'Please enter assigned physician',
      label: 'Assigned Physician',
      required: true,
      key: 'physician',
      labelImage: '',
      value: '',
      controlType: 'dropdown',
      emptyValueMessage: 'Please select assigned physician',
      validationValue: 'Please enter valid value',
      className: 'side__by__side',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      results: [
      ],
    },
    {
      type: 'text',
      placeholder: 'Please enter session duration',
      label: 'Session Duration',
      required: true,
      key: 'sessionDuration',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter session duration',
      validationValue: 'Please enter valid value',
      className: 'side__by__side',
      validators: [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter booking per slot',
      label: 'Bookings per slot',
      required: true,
      key: 'bookingsPerSlot',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter booking per slot',
      validationValue: 'Please enter valid value',
      className: 'side__by__side',
      validators: [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter Intakes per day',
      label: 'Intakes per day',
      required: true,
      key: 'intakesPerDay',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter Intakes per day',
      validationValue: 'Please enter valid value',
      className: 'side__by__side',
      validators: [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter session Interval',
      label: 'Session Interval',
      required: true,
      key: 'sessionInterval',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter session Interval',
      validationValue: 'Please enter valid value',
      className: 'side__by__side',
      validators: [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*$')],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter video conference link',
      label: 'Video Conference Link',
      required: true,
      key: 'doxyUrl',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter video conference link',
      validationValue: 'Please enter a valid link',
      className: 'side__by__side',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter password for video link',
      label: 'Password',
      required: false,
      key: 'doxyPwd',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter a password',
      validationValue: 'Please enter a valid password',
      className: 'side__by__side',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: false,
    },
    {
      showontoggle: false,
      key: 'defaultTreatment',
      validators: []
    },
    {
      showontoggle: false,
      key: 'defaultTreatmentId',
      validators: []
    }
  ];
  createLocationConfigPopup = [    {
    type: 'dropdown',
    placeholder: 'Please select',
    label: 'From',
    required: true,
    key: 'fromDate',
    labelImage: 'user',
    value: '',
    list: [],
    controlType: 'dropdown',
    emptyValueMessage: 'Please select',
    validators: [],
    focus: true,
    editable: true,
    showontoggle: true,
  },
  {
    type: 'dropdown',
    placeholder: 'Please select',
    label: 'To',
    required: true,
    key: 'toDate',
    labelImage: 'user',
    list: [],
    value: '',
    controlType: 'dropdown',
    emptyValueMessage: 'Please select',
    validators: [],
    focus: true,
    editable: true,
    showontoggle: true,
  }, ];
  editAndViewData = {
    userId: 'John_Doe',
    patientId: '12344',
    firstName: 'John Doe',
    lastName: 'John',
    dob: '12/03/1995',
    gender: 'others',
    userRelationToPatient: 'S',
    phoneNumber: '7989526272',
    email: 'anji@divami.com',
    emergencyContactName: 'Jane',
    relationWithPatient: 'wife',
    emergencyContactNumber: '3456789678',
    idProofUploadFront: 'fornt.jpg',
    idProofUploadBack: 'back.jpg',
    streetAddress1: 'Hyderabad',
    streetAddress2: 'Miyapur',
    city: 'Hyderabad',
    state: 'AL',
    zipcode: 52331,
    socialSecurityNumber: '3456789678',
    primaryInsuranceProvider: 'AL',
    secondaryInsuranceProvider: 'AL',
    sameAsPatient: true,
    policyHolderName: 'John Doe',
    policyHolderDOB: '12/03/1995',
    policyHolderMemberId: 7678,
    policyholderemployer: 'employer',
    // "primaryholderText":""
    policyHoldersUploadFront: 'front.jpg',
    policyHoldersUploadBack: 'back.jpg',
    policyHolderGroupNumber: 878,
    policyHolderMentalHealthNumber: '3456789678',
    policyHolderEmployer: 'Jon',
    inTherapy: true,
    therapistName: 'mic',
    lastTherapyDate: '12/03/1995',
    psychiatric: true,
    psychiatricmedicine: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    psychiatricdosagedirection: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    benzodiazepinesSlider: true,
    medicinename: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    dosagedirection: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    typeOfMedication: 'typeOfMedicationcurrent',
    startDate: '12/03/1995',
    endDate: '12/03/1995',
    discontinued: false,
    medicaladderalladdnew: true,
    discontinuedReasion: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    adderallstimulants: true,
    medicine: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    calendar: '12.03.1995',
    medicaldosagedirection: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    benzodiazepinesTypeOfMedication: 'benzodiazepinesCurrent',
    benzodiazepinesStartDate: '12/03/1995',
    benzodiazepinesEndDate: '12/03/1995',
    benzodiazepinesDiscontinued: false,
    benzodiazepinesDiscontinuedReasion: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    benzodiazepinesaddnew: true,
    medicaladderallstimulants: false,
    lastPrescriptionDate: '12/03/1995',
    psychiatricaddnew: true,
    knownAllergies: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    miscellaenousKnownAllergies: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    patientEmailId: 'divami1@gmail.com',
    knownaallergies: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    disabilityBenefits: false,
    drugAlcoholAbuse: false,
    alcoholDrugIn3Months: false,
    selfMedicate: false,
    medicalKnownAllergies: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    miscellaenousknownallergies: false,
    legalIssues: false,
    pharmacyName: 'AL',
    pharmacyPhoneNo: '3456789678',
    pharmacyEmailId: 'anji@divami.com',
    pharmacyFaxNo: '123456789012',
    pharmacyAddress1: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    pharmacyAddress2: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    pharmacyCity: 'Guntur',
    pharmacyState: 'AL',
    pharmacyZipcode: 52331,
    knowDoctor: 'website',
    iAcknowledge: true,
    iAccept: true
  }
    ;

    TMSFeedbackFormConfig = [
      {
        type: 'header',
        label: 'Ease of getting care',
        showontoggle: true,
      },
      {
        type: 'radio',
        label: 'How was your response to TMS treatment by Dr. Prayaga?',
        labelImage: '',
        required: true,
        key: FORM_KEYS.REPONSE_TO_TMS,
        value: '',
        editable: true,
        controlType: 'custom-radio',
        className: 'radio-btn-horizontal',
        emptyValueMessage: 'Please select one option',
        validators: [Validators.required],
        fields: this.qualityMetric,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        value: false,
        key: FORM_KEYS.SIDEEFFECTS_DURING_TMS,
        checkboxlabel: 'Did you experience any negative or adverse side effects during TMS treatment?',
        controlType: 'checkbox-slider',
        className: 'flex-checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        id: 12,
        type: 'textarea',
        placeholder: '',
        maxLength: 150,
        label: 'Provide details',
        required: true,
        key: FORM_KEYS.SIDEEFFECTS_DURING_TMS_DESCRIPTION,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add description',
        validationValue: 'Please add valid description',
        validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
        className: '',
        editable: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'checkbox-slider',
        value: false,
        key: FORM_KEYS.SIDEEFFECTS_AFTER_TMS,
        checkboxlabel: 'Did you experience any side eﬀects after receiving TMS Treatment?',
        controlType: 'checkbox-slider',
        className: 'flex-checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        id: 12,
        type: 'textarea',
        placeholder: '',
        maxLength: 150,
        label: 'Provide details',
        required: true,
        key: FORM_KEYS.SIDEEFFECTS_AFTER_TMS_DESCRIPTION,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add description',
        validationValue: 'Please add valid description',
        validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
        className: '',
        editable: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'checkbox-slider',
        value: false,
        key: FORM_KEYS.MONITORED_THROUGHOUT_SESSION,
        checkboxlabel: 'Were you monitored and checked on throughout your treatment session?',
        controlType: 'checkbox-slider',
        className: 'flex-checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        id: 12,
        type: 'textarea',
        placeholder: '',
        maxLength: 150,
        label: 'Provide details',
        required: true,
        key: FORM_KEYS.MONITORED_THROUGHOUT_SESSION_DESCRIPTION,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add description',
        validationValue: 'Please add valid description',
        validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
        className: '',
        editable: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'radio',
        label: 'Do you feel that you were satisfied with Dr. Prayaga’s treatment?',
        labelImage: '',
        required: true,
        key: FORM_KEYS.FEEL_SATISFIED,
        value: '',
        editable: true,
        controlType: 'custom-radio',
        className: 'radio-btn-horizontal-space',
        emptyValueMessage: 'Please select one option',
        validators: [Validators.required],
        fields: this.satisfactionMetric,
        showontoggle: true,
      },
      {
        type: 'rating',
        label: 'How would you rate your overall experience with TMS treatment?',
        labelImage: '',
        required: true,
        key: FORM_KEYS.RATING,
        value: '',
        max: "5",
        editable: true,
        controlType: 'rating',
        emptyValueMessage: 'Please rate our service',
        validators: [Validators.required],
        showontoggle: true,
      },
    ];

    KETAMINEFeedbackFormConfig = [
      {
        type: 'header',
        label: 'Ease of getting care',
        showontoggle: true,
      },
      {
        type: 'radio',
        label: 'How was your response to KETAMINE treatment by Dr. Prayaga?',
        labelImage: '',
        required: true,
        key: FORM_KEYS.REPONSE_TO_KETAMINE,
        value: '',
        editable: true,
        controlType: 'custom-radio',
        className: 'radio-btn-horizontal',
        emptyValueMessage: 'Please select one option',
        validators: [Validators.required],
        fields: this.qualityMetric,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        value: false,
        key: FORM_KEYS.SIDEEFFECTS_DURING_KETAMINE,
        checkboxlabel: 'Did you experience any negative or adverse side effects during KETAMINE treatment?',
        controlType: 'checkbox-slider',
        className: 'flex-checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        id: 12,
        // idName:'legal_issue_description_parent',
        type: 'textarea',
        placeholder: '',
        maxLength: 150,
        label: 'Provide details',
        required: true,
        key: FORM_KEYS.SIDEEFFECTS_DURING_KETAMINE_DESCRIPTION,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add description',
        validationValue: 'Please add valid description',
        validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
        className: '',
        editable: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'checkbox-slider',
        value: false,
        key: FORM_KEYS.SIDEEFFECTS_AFTER_KETAMINE,
        checkboxlabel: 'Did you experience any side eﬀects after receiving KETAMINE Treatment?',
        controlType: 'checkbox-slider',
        className: 'flex-checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        id: 12,
        type: 'textarea',
        placeholder: '',
        maxLength: 150,
        label: 'Provide details',
        required: true,
        key: FORM_KEYS.SIDEEFFECTS_AFTER_KETAMINE_DESCRIPTION,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add description',
        validationValue: 'Please add valid description',
        validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
        className: '',
        editable: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'checkbox-slider',
        value: false,
        key: FORM_KEYS.MONITORED_THROUGHOUT_SESSION,
        checkboxlabel: 'Were you monitored and checked on throughout your treatment session?',
        controlType: 'checkbox-slider',
        className: 'flex-checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        id: 12,
        type: 'textarea',
        placeholder: '',
        maxLength: 150,
        label: 'Provide details',
        required: true,
        key: FORM_KEYS.MONITORED_THROUGHOUT_SESSION_DESCRIPTION,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add description',
        validationValue: 'Please add valid description',
        validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
        className: '',
        editable: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'radio',
        label: 'Do you feel that you were satisfied with Dr. Prayaga’s treatment?',
        labelImage: '',
        required: true,
        key: FORM_KEYS.FEEL_SATISFIED,
        value: '',
        editable: true,
        controlType: 'custom-radio',
        className: 'radio-btn-horizontal-space',
        emptyValueMessage: 'Please select one option',
        validators: [Validators.required],
        fields: this.satisfactionMetric,
        showontoggle: true,
      },
      {
        type: 'rating',
        label: 'How would you rate your overall experience with KETAMINE treatment?',
        labelImage: '',
        required: true,
        key: FORM_KEYS.RATING,
        value: '',
        max: "5",
        editable: true,
        controlType: 'rating',
        emptyValueMessage: 'Please rate our service',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'checkbox',
        labeltext: `I agree that Dr. Prayaga provided literature information on Ketamine treatment`,
        controlType: 'checkbox-tik',
        key: FORM_KEYS.LITERATURE_INFORMATION,
        required: true,
        value: false,
        className: 'iacknowledge__checkbox termsandcondition__checkbox',
        emptyValueMessage: 'Please accept this to proceed',
        validators: [Validators.requiredTrue, this.validateCheckBox],
        focus: true,
        editable: true,
        selected: false,
        showontoggle: true,
      },
      {
        type: 'checkbox',
        labeltext: `I fully understand that ketamine sessions can result in a profound change in mental state and may result in unusual psychological and physiological effects.`,
        controlType: 'checkbox-tik',
        key: FORM_KEYS.FULLY_UNDERSTAND,
        required: true,
        value: false,
        className: 'iacknowledge__checkbox termsandcondition__checkbox',
        emptyValueMessage: 'Please accept this to proceed',
        validators: [Validators.requiredTrue, this.validateCheckBox],
        focus: true,
        editable: true,
        selected: false,
        showontoggle: true,
      },
    ];

    KETAMINEEnrollmentFormConfig: Array<Record<string, any>> = [
      {
        type: 'checkbox-slider',
        value: false,
        key: KETAMINE_ENROLLMENT_KEYS.TREATED_PREVIOUSLY,
        checkboxlabel: 'Has the patient previously been treated with ketamine for treatment-resistant depression, pain syndromes or any other condition?*',
        controlType: 'checkbox-slider',
        className: 'flex-checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'labelValue',
        key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_LABEL,
        isToggle: true,
        value: 'List concomitant medications (e.g., adjunctive depression medications, sedative hypnotics, psychostimulants, monoamine oxidase inhibitors (MAOIs))',
        controlType: 'labelValue',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'formArray',
        key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_MEDICATION,
        formInputs: [[
          {
            type: 'header',
            // idName: 'psychiatric-header',
            label: 'Provide following details',
            showontoggle: true,
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_HEADER,
            editable: true,
          },
          {
            type: 'text',
            placeholder: 'Medicine name',
            label: 'Medicine name',
            required: false,
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_MEDICINE,
            value: '',
            controlType: 'inputText',
            editable: true,
            emptyValueMessage: 'Please enter medicine name',
            validationValue: 'Please enter valid medicine name',
            validators: [this.valideTextArea],
            focus: true,
            showontoggle: true,
          },
          {
            type: 'textarea',
            placeholder: '',
            label: 'Dosage and Direction',
            required: false,
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DOSAGE,
            value: '',
            controlType: 'textarea',
            emptyValueMessage: 'Please add description',
            validationValue: 'Please add valid description',
            validators: [this.valideTextArea],
            className: 'remaining-number',
            maxLength: 150,
            editable: true,
            count: '150 remaining',
            showontoggle: true,
            showRange: true,
          },
          {
            type: 'radio',
            placeholder: '',
            label: 'What type of medication is it?',
            labelImage: '',
            required: false,
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_MED_TYPE,
            value: '',
            editable: true,
            controlType: 'custom-radio',
            emptyValueMessage: 'Please select one option',
            className: 'radio-btn-horizontal formarray-radio',
            validationValue: '',
            validators: [],
            fields: [
              {
                label: 'Current',
                value: 'current',
              },
              {
                label: 'Lifetime',
                value: 'lifetime',
              },
            ],
            showontoggle: true,
          },
          {
            type: 'calendar',
            placeholder: 'Select date',
            label: 'Start Date',
            required: false,
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_STARTDATE,
            labelImage: '',
            value: '',
            controlType: 'calendar',
            editable: true,
            emptyValueMessage: 'Please select date',
            validators: [this.validateStartDate],
            className: 'side__by__side',
            focus: true,
            showontoggle: true,
          },
          {
            type: 'calendar',
            placeholder: 'Select date',
            label: 'End Date',
            required: false,
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_ENDDATE,
            labelImage: '',
            value: '',
            controlType: 'calendar',
            editable: true,
            className: 'side__by__side',
            emptyValueMessage: 'Please select date',
            validationValue: 'End date should be greater than start date',
            validators: [this.validateEndDate],
            focus: true,
            showontoggle: true,
          },
          {
            type: 'checkbox-slider',
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DISCONTINUED,
            checkboxlabel: 'Have you discontinued it?',
            controlType: 'checkbox-slider',
            value: false,
            leftLable: true,
            validators: [],
            editable: true,
            showontoggle: true,
          },
          {
            type: 'textarea',
            placeholder: '',
            label: 'Reason details',
            required: false,
            key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DISCONTINUED_RSN,
            value: '',
            controlType: 'textarea',
            emptyValueMessage: 'Please add description',
            validationValue: 'Please add valid description',
            validators: [this.valideTextArea],
            className: 'remaining-number',
            editable: true,
            maxLength: 150,
            count: '150 remaining',
            showontoggle: true,
            showRange: true,
          }]
        ],
        controlType: 'formArray',
        formtag: KETAMINE_ENROLLMENT_KEYS.KETAMINE_FORMTAG,
        selected: false,
        editable: true,
        showClose: false,
        showontoggle: true,
      },
      {
        type: 'addnew',
        placeholder: '',
        label: '',
        key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_ADDNEW,
        controlType: 'addnew',
        labeltext: 'Add new medicine',
        formtag: 'KETAMINEENROLLMENT',
        editable: true,
        showontoggle: true,
      },
      {
        type: 'content',
        label: 'At the New stress clinic Ketamine treatment is being offered. The specific treatment being conducted is Oral Ketamine. It is a non-FDA approved treatment for Anxiety and Depression. This treatment is being used as an alternative to FDA approved SPRAVATO (esketamine). I was made aware of this treatment benefits, risks, and side effects.',
        showontoggle: true,
      },
      {
        type: 'checkbox',
        labeltext: `I understand sedation and dissociation can result from treatment with KETAMINE and I must stay after each treatment. Until these effects resolve, I may feel sleepy and/or disconnected from myself, my thoughts, feelings and things around me. \n
        I should make arrangements to safely leave the healthcare setting and get home. \n
        I should not drive or use heavy machinery for the rest of the day on which I receive KETAMINE. \n
        I should contact my doctor or inform him/her at my next visit if I believe I have a side effect or reaction from KETAMINE. \n
        I will receive this treatment under the care of Rama Prayaga M.D.`,
        controlType: 'checkbox-tik',
        key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_ACCEPT,
        required: true,
        list: this.ketamineEnrollmentList,
        value: false,
        className: 'iacknowledge__checkbox termsandcondition__checkbox',
        emptyValueMessage: 'Please accept this to proceed',
        validators: [Validators.requiredTrue, this.validateCheckBox],
        focus: true,
        editable: true,
        selected: false,
        showontoggle: true,
      },
      {
        type: 'currentDate',
        label: 'Date',
        placeholder: 'Date',
        staticData: true,
        labelImage: '',
        required: true,
        maxLength: '20',
        key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DATE,
        value: '30/09/2021',
        className: 'side__by__side',
        editable: true,
        controlType: 'currentDate',
        emptyValueMessage: 'Please enter signature',
        validationValue: 'Please enter same signature',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'signature',
        label: '',
        placeholder: 'Signature',
        labelImage: '',
        required: true,
        maxLength: '20',
        key: KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_SIGN,
        value: '',
        className: 'side__by__side',
        editable: true,
        controlType: 'signature',
        emptyValueMessage: 'Please enter signature',
        validationValue: 'Please enter same signature',
        validators: [Validators.required, this.validateAplha, this.validateSignature.bind(this)],
        showontoggle: true,
      }
    ]

    emailTemplateEditConfig: Array<Record<string, any>> = [
      {
        id: 12,
        type: 'text',
        placeholder: '',
        maxLength: 150,
        label: 'Email Subject',
        required: true,
        key: EMAIL_EDIT_KEYS.TEMPLATE_EDIT_HEADER,
        value: '',
        controlType: 'inputField',
        emptyValueMessage: 'Please enter subject',
        validationValue: 'Please enter valid subject',
        validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
        className: 'input-and-close',
        editable: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        id: 12,
        type: 'text',
        placeholder: '',
        maxLength: 150,
        label: 'Trigger Condition',
        required: false,
        key: EMAIL_EDIT_KEYS.TEMPLATE_EDIT_SUMMARY,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please enter description',
        validationValue: 'Please enter valid description',
        validators: [],
        className: 'input-and-close',
        editable: false,
        count: '',
        showontoggle: true,
        showRange: false,
      },
      {
        type: 'number',
        placeholder: 'Enter sendout time',
        maxLength: 150,
        label: 'Sending time',
        required: true,
        key: EMAIL_EDIT_KEYS.TEMPLATE_EDIT_TIME,
        value: '',
        controlType: 'timeInput',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Enter value between 1-12',
        validators: [Validators.required, this.validateTimeValue],
        className: 'radio-btn-horizontal',
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: '',
        maxLength: 150,
        emptyLabel: true,
        label: ' ',
        required: true,
        key: EMAIL_EDIT_KEYS.TEMPLATE_EDIT_TIME_FORMAT,
        value: 'am',
        controlType: 'dropdown',
        emptyValueMessage: 'Please enter description',
        validationValue: 'Please enter valid description',
        validators: [Validators.required],
        className: 'radio-btn-horizontal',
        editable: true,
        list: this.timeFormatList,
        showontoggle: true,
      },
      {
        type: 'number',
        label: 'Sending time',
        placeholder: 'Enter sendout time',
        labelImage: '',
        required: true,
        units: 'Mins',
        key: EMAIL_EDIT_KEYS.TEMPLATE_INFO_SEND_TIME,
        value: '',
        editable: true,
        controlType: 'inputFieldNumber',
        className: 'radio-btn-horizontal',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Enter value between 1-1000',
        validators: [Validators.required, this.valideteFrequencyValue],
        showontoggle: false,
      },
      {
        type: 'number',
        label: 'Email frequency',
        placeholder: 'Enter email frequency',
        labelImage: '',
        required: true,
        units: 'Days',
        key: EMAIL_EDIT_KEYS.TEMPLATE_EDIT_FREQUENCY,
        value: '',
        editable: true,
        controlType: 'inputFieldNumber',
        className: 'radio-btn-horizontal',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Enter value between 1-1000',
        validators: [Validators.required, this.valideteFrequencyValue],
        showontoggle: false,
      },
      {
        type: 'number',
        label: 'Repeat frequency',
        labelImage: '',
        required: true,
        units: 'Days',
        placeholder: 'Enter repeat frequency',
        key: EMAIL_EDIT_KEYS.TEMPLATE_EDIT_REP_FREQUENCY,
        value: '',
        editable: true,
        controlType: 'inputFieldNumber',
        className: 'radio-btn-horizontal',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Enter value between 1-1000',
        validators: [Validators.required, this.valideteFrequencyValue],
        fields: '',
        showontoggle: false,
      },
    ]

    KETAMINEMonitoringFormConfig: Array<Record<string, any>> = [
      {
        type: 'checkbox',
        labeltext: `I may not drive a car, operate hazardous equipment, or engage in hazardous activities for 24 hours after each treatment as reflexes may be slow or impaired. Another adult will drive me home. \n
        I feel alert and fine and my blood pressure is also normal as I am leaving the office after treatment. \n
        I understand this treatment is OFF treatment and Dr.Prayaga explained it is OFF the label for Anxiety and Depression.`,
        controlType: 'checkbox-tik',
        key: KETAMINE_MONITORING_KEYS.KETAMINE_MNT_AGREE,
        required: true,
        value: false,
        className: 'iacknowledge__checkbox termsandcondition__checkbox',
        emptyValueMessage: 'Please accept this to proceed',
        validators: [Validators.requiredTrue, this.validateCheckBox],
        focus: true,
        editable: true,
        selected: false,
        showontoggle: true,
      }
    ]
    ketamineMedicationFormConfig: IformConfigI[] = [
      {
        type: 'checkbox-slider',
        value: true,
        key: 'benzodiazepines',
        checkboxlabel: 'Benzodiazepines',
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        value: true,
        checkboxlabel: 'Non-benzodiazepine sedative hypnotics',
        key: 'nonBenzodiazepine',
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: 'psychostimulants',
        value: true,
        checkboxlabel: 'Psychostimulants',
        controlType: 'checkbox-slider',
        editable: true,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: 'monoamineOxidaseInhibitors',
        value: true,
        checkboxlabel: 'Monoamine oxidase inhibitors (MAOIs)',
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      }
    ];
    ketamineTreatmentSessionFormConfig: IformConfigI[] = [
      {
        type: 'currentDate',
        label: 'Date',
        placeholder: 'Date',
        labelImage: '',
        required: true,
        maxLength: '20',
        key: 'appointmentDate',
        value: '28th Sep, 2021',
        className: 'side__by__side',
        editable: true,
        controlType: 'currentDate',
        emptyValueMessage: '',
        validationValue: '',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'number',
        placeholder: '50',
        maxLength: 150,
        label: 'Actual Dose Administered',
        required: true,
        key: 'actualDoseAdministered',
        value: '50',
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter actual dose',
        validationValue: 'Please enter value greater than 0',
        validators: [Validators.required,this.validateNonZero],
        className: 'side__by__side mmhg-input',
        editable: true,
        count: '',
        showontoggle: true,
        showRange: false,
        confirmationMsg: `mg`,
      },

      {
        type: 'number',
        mask:"99:99",
        placeholder: "00:00",
        label: 'Time at Start of Administration(from 1st device use)',
        required: true,
        key: 'startTime',
        labelImage: '',
        value: '6:30',
        controlType: 'p-Input',
        emptyValueMessage: 'Please enter start time',
        validationValue: 'Please enter valid start time.',
        validationValue1:'Please enter start time less than end time.',
        className: 'time__field label-nowrap',
        validators: [Validators.required,this.validateTime,this.startTime.bind(this)],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'dropdown',
        label: '',
        required: true,
        key: 'startTimeSuffix',
        labelImage: '',
        placeholder: '',
        value: 'am',
        list: [
          {
            label: 'AM',
            value: 'AM'
          },
          {
            label: 'PM',
            value: 'PM'
          }
        ],
        controlType: 'dropdown',
        emptyValueMessage: '',
        validators: [Validators.required,this.startTimeSuffix.bind(this)],
        focus: true,
        editable: true,
        showontoggle: true,
        className: 'time__field__suffix',
        noLabel: true
      },
      {
        type: 'number',
        mask:"99:99",
        placeholder: "00:00",
        label: 'Time When Patient Completed Treatment Session',
        required: true,
        key: 'endTime',
        labelImage: '',
        value: '',
        controlType: 'p-Input',
        emptyValueMessage: 'Please enter end time',
        validationValue: 'Please enter valid end time.',
        validationValue1:'Please enter end time greater than start time.',
        className: 'time__field label-nowrap',
        validators: [Validators.required,this.validateTime,this.endTime.bind(this)],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'dropdown',
        label: '',
        required: true,
        key: 'endTimeSuffix',
        labelImage: '',
        placeholder: '',
        value: 'am',
        list: [
          {
            label: 'AM',
            value: 'AM'
          },
          {
            label: 'PM',
            value: 'PM'
          }
        ],
        controlType: 'dropdown',
        emptyValueMessage: '',
        validators: [Validators.required,this.endTimeSuffix.bind(this)],
        focus: true,
        editable: true,
        showontoggle: true,
        className: 'time__field__end__suffix',
        noLabel: true
      },
      {
        type: 'checkbox-slider',
        key: 'confirmKetamineAdministration',
        value: false,
        checkboxlabel: `I confirm vital signs (BP, HR, RR) were in an acceptable
        range prior to "KETAMINE" administration.*`,
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: 'confirmReadyToLeave',
        value: false,
        checkboxlabel: `I confirm vital signs were in an acceptable range
        prior to patient ready to leave.*`,
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: 'areMonitoredThroughoutSession',
        value: false,
        checkboxlabel: `Are you being monitored throughout the session?`,
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: '120',
        label: 'BP Prior to Administration*',
        confirmationMsg: `mmHg`,
        required: true,
        key: 'bpPriorToAdministration',
        labelImage: '',
        value: '120',
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter value greater than 0',
        className: 'side__by__side mmhg-input',
        validators: [Validators.required,this.validateBPValue],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: '120',
        label: 'BP 40 Minutes Post Administration*',
        confirmationMsg: `mmHg`,
        required: true,
        key: 'bpPostAdministration',
        labelImage: '',
        value: '120',
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter value greater than 0',
        className: 'side__by__side mmhg-input',
        validators: [Validators.required,this.validateBPValue],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: '120',
        label: 'BP Prior to Patient Ready to Leave*',
        confirmationMsg: `mmHg`,
        required: true,
        key: 'bpReadyToLeave',
        labelImage: '',
        value: '120',
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter value greater than 0',
        className: 'side__by__side mmhg-input',
        validators: [Validators.required,this.validateBPValue],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'number',
        placeholder: '84',
        label: 'Pulse Rate Prior to Administration*',
        confirmationMsg: `bpm`,
        required: true,
        key: 'pulseRatePriorToAdministration',
        labelImage: '',
        value: '84',
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter value greater than 0',
        className: 'side__by__side mmhg-input',
        validators: [Validators.required,this.validateBPValue],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'number',
        placeholder: '84',
        label: 'Pulse Rate 40 Minutes Post Administration*',
        confirmationMsg: `bpm`,
        required: true,
        key: 'pulseRatePostAdministration',
        labelImage: '',
        value: '84',
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter value greater than 0',
        className: 'side__by__side mmhg-input',
        validators: [Validators.required,this.validateBPValue],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'number',
        placeholder: '84',
        label: 'Pulse Rate Prior to Patient Ready to Leave*',
        confirmationMsg: `bpm`,
        required: true,
        key: 'pulseRateReadyToLeave',
        labelImage: '',
        value: '84',
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter value greater than 0',
        className: 'side__by__side mmhg-input',
        validators: [Validators.required,this.validateBPValue],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: 'clinicallyReadyToLeave',
        value: false,
        checkboxlabel: `Was the patient clinically ready to leave prior
        to the required 2 hours?`,
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'number',
        label: 'When was the patient ready to leave?*',
        confirmationMsg: `minutes from start of the administration`,
        placeholder: '90',
        labelImage: '',
        required: true,
        // maxLength:'10',
        key: 'readyToLeave',
        value: '90',
        className: 'line__text__field',
        editable: true,
        controlType: 'inlineText',
        emptyValueMessage: 'Please enter the value',
        validationValue: 'Please enter value between 1-1000',
        validationValue1: this.timeGapValidationMessage,
        validators: [Validators.required, this.validateTimeGap.bind(this), this.validateNonZero],
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: '',
        maxLength: 150,
        label: 'Use the below sections to describe as appropriate.',
        required: false,
        key: 'sedationOrDissociationQuestion',
        value: 'Did the patient experience sedation or dissociation?',
        controlType: 'inputText',
        emptyValueMessage: 'Please enter description',
        validationValue: 'Please enter valid description',
        validators: [],
        className: 'input-and-close',
        editable: false,
        count: '',
        showontoggle: true,
        showRange: false,
      },
      {
        type: 'checkbox-slider',
        key: 'sedation',
        value: false,
        checkboxlabel: `Sedation*`,
        controlType: 'checkbox-slider',
        className: 'sedation',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: 'dissociation',
        value: true,
        checkboxlabel: `Dissociation*`,
        controlType: 'checkbox-slider',
        className: 'sedation',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: '',
        maxLength: 150,
        label: 'Serious Adverse Effects',
        required: false,
        key: 'seriousAdverseEffects',
        value: 'NA',
        controlType: 'inputText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter valid value',
        validators: [],
        className: 'input-and-close',
        editable: false,
        count: '',
        showontoggle: true,
        showRange: false,
      },
      {
        type: '',
        label: `Patient Consent`,
        controlType:'labelValue',
        key:'patientKetamineMonitoringConsent',
        value:`I may not drive a car, operate hazardous equipment, or engage in hazardous activities for 24 hours after each treatment as reflexes may be
        slow or impaired. Another adult will drive me home.\n
        I feel alert and fine and my blood pressure is also normal as I am leaving the office after treatment.\n
        I understand this treatment is OFF treatment and Dr.Prayaga explained it is OFF the label for Anxiety and Depression.`,
        className:'ketamine-consent step',
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: '',
        maxLength: 150,
        label: 'Date',
        required: false,
        key: 'date',
        value: '28th Sep, 2021',
        controlType: 'inputText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter valid value',
        validators: [],
        className: 'input-and-close side__by__side',
        editable: false,
        count: '',
        showontoggle: true,
        showRange: false,
      },
      {
        type: 'text',
        placeholder: '',
        maxLength: 150,
        label: 'Signature',
        required: false,
        key: 'signature',
        value: 'Peter Parker',
        controlType: 'inputText',
        emptyValueMessage: 'Please enter value',
        validationValue: 'Please enter valid value',
        validators: [],
        className: 'input-and-close side__by__side display-right',
        editable: false,
        count: '',
        showontoggle: true,
        showRange: false,
      },
    ];

    ketamineConsentConsentFormConfig:IformConfigI[] = [
      {
        type: 'confirmation',
        label: '',
        confirmationMsg: `agree to consent for my 
        Psychiatric treatment the use of ketamine therapy. I understand the risk of ketamine therapy and 
        possible side effects.`,
        placeholder: '',
        labelImage: '',
        required: true,
        key: KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_AGREE,
        value: '',
        className: '',
        editable: false,
        controlType: 'confirmation',
        emptyValueMessage: 'Please enter the name',
        validationValue: 'Please enter valid name',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'content',
        label: 'I understand the information provided to me and have been given the opportunity to discuss other treatments and to raise the questions regarding the use of medication.',
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I agree to get my lab work done every 3 months or 
        annually. I will call my clinician promptly for any questions or problems regarding treatment with medications.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I understand that I must provide a 24 hour’s notification to cancel an appointment. I understand that failure to 
        provide a 24 hour notification or failure to show up for an appointment will result in a $25.00 cancellation fee. All 
        cancellations will be assessed and charged at the next office visit.`,
        showontoggle: true,
      }, 
      {
        type: 'content',
        label: `If the prescription/ medication is lost or stolen due to any circumstances, you need to submit a police report and then schedule a follow up appointment. Until then medication will not be called in.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I authorize you to check PMP(Prescription Monitoring Program) to avoid duplications, over prescribing and misuse/abuse of medications.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `If any document is requested apart from the medication, separate charges apply and if the requested document is after 4 days of the Appointment, the patient has to schedule a NEW appointment.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Dr Prayaga requests drug screening at CLIA certified lab center. If any guidance is required office will assist.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Dr Prayaga requests to work with newteledoc for coordination of Care with PCP, therapist, or any other providers.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `If the pharmacy is unable to fill the medication because of the time lapse, new Appointment is required to resend the prescriptions.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `When you ask for a change of pharmacy after 10 days of medications being sent, NEW Appointment is required.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `When medication is lost/stolen a NEW appointment is required for the medication to be re-issued.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: 'An admin fee of $10 will be charged for seeking ADMIN help to update the profile, pharmacy, or any other details. This fee does not include Pharmacy change, pharmacy change fee is different from this.',
        showontoggle: true,
      },
      {
        type: 'content',
        label: `In case of any partial fill of the medication by the pharmacy for any reasons, need to schedule new appointment and pay . Also, we will be requiring a fax from the pharmacy that the medication was partially filled.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Newstressclinic/ Dr.Prayaga are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `For ketamine treatment liver function tests are required.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Please be advised to consider nalaxone as safety option for taking controlled substance.`,
        showontoggle: true,
      },
      {
        type: 'note',
        note: '*NOTE: All Patients must be from tri-states(VA, DC, MD) during the visit.',
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I hereby provided the payment for this date of service is accurate/ genuine and will not be disputed as fraud either to the insurance company nor credit card company.`,
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_HOSPITALIZED_BEFORE,
        value: false,
        checkboxlabel: `Have you been hospitalized in the past one month?`,
        controlType: 'checkbox-slider',
        className: 'consent_form_field',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: 'Enter the name of the hospital',
        label: 'Name of the hospital',
        required: true,
        key: KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_HOSP_NAME,
        labelImage: '',
        value: '',
        className: 'consent_form_field',
        controlType: 'inputText',
        editable: true,
        emptyValueMessage: 'Please enter full name',
        validationValue: 'Please enter valid name',
        validators: [Validators.required, this.validateName,this.valideTextArea, Validators.minLength(1)],
        focus: true,
        showontoggle: true,
      },
      {
        type: 'textarea',
        placeholder: 'Reason for hospitalization',
        label: 'Reason for hospitalization',
        required: true,
        key: KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_REASON,
        labelImage: '',
        value: '',
        controlType: 'textarea',
        editable: true,
        maxLength: 150,
        className: 'message-box consent_form_field',
        emptyValueMessage: 'Please enter reason',
        validationValue: 'Please enter valid reason',
        validators: [Validators.required, this.valideTextArea, Validators.minLength(1)],
        focus: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'currentDate',
        label: 'Date',
        placeholder: 'Date',
        staticData: true,
        labelImage: '',
        required: true,
        maxLength: '20',
        key: KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_DATE,
        value: this.currentDate(),
        className: 'side__by__side signature-field',
        editable: true,
        controlType: 'currentDate',
        emptyValueMessage: 'Please enter signature',
        validationValue: 'Please enter same signature',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'signature',
        label: '',
        placeholder: 'Signature',
        labelImage: '',
        required: true,
        maxLength: '20',
        key: KETAMINE_CONSENT_FORM.KETAMINE_CONSENT_SIGNATURE,
        value: '',
        className: 'side__by__side signature-field',
        editable: true,
        controlType: 'signature',
        emptyValueMessage: 'Please enter signature',
        validationValue: 'Please enter same signature',
        validators: [Validators.required, this.validateAplha, this.validateSignature.bind(this)],
        showontoggle: true,
      },
    ];

    tmsConsentConsentFormConfig:IformConfigI[] = [
      {
        type: 'confirmation',
        label: '',
        confirmationMsg: `agree to consent for my 
        Psychiatric treatment the use of TMS therapy. I understand the risk of TMS therapy and 
        possible side effects.`,
        placeholder: '',
        labelImage: '',
        required: true,
        // maxLength:'10',
        key: TMS_CONSENT_FORM.TMS_CONSENT_AGREE,
        value: '',
        className: '',
        editable: false,
        controlType: 'confirmation',
        emptyValueMessage: 'Please enter the name',
        validationValue: 'Please enter valid name',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'content',
        label: 'I understand the information provided to me and have been given the opportunity to discuss other treatments and to raise the questions regarding the use of medication.',
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I will call my clinician promptly for any questions or problems regarding treatment with medications.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I understand that I must provide a 24 hour’s notification to cancel an appointment. I understand that failure to 
        provide a 24 hour notification or failure to show up for an appointment will result in a $25.00 cancellation fee. All 
        cancellations will be assessed and charged at the next office visit.`,
        showontoggle: true,
      }, 
      {
        type: 'content',
        label: `If the prescription/ medication is lost or stolen due to any circumstances, you need to submit a police report and then schedule a follow up appointment. Until then medication will not be called in.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I authorize you to check PMP(Prescription Monitoring Program) to avoid duplications, over prescribing and misuse/abuse of medications.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: 'An admin fee of $10 will be charged for seeking ADMIN help to update the profile, pharmacy, or any other details. This fee does not include Pharmacy change, pharmacy change fee is different from this.',
        showontoggle: true,
      },
      {
        type: 'content',
        label: `If any document is requested apart from the medication, separate charges apply and if the requested document is after 4 days of the Appointment, the patient has to schedule a NEW appointment.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Dr Prayaga requests drug screening at CLIA certified lab center. If any guidance is required office will assist.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Dr Prayaga requests to work with newteledoc for coordination of Care with PCP, therapist, or any other providers.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `If the pharmacy is unable to fill the medication because of the time lapse, new Appointment is required to resend the prescriptions.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `When you ask for a change of pharmacy after 10 days of medications being sent, NEW Appointment is required.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `When medication is lost/stolen a NEW appointment is required for the medication to be re-issued.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `In case of any partial fill of the medication by the pharmacy for any reasons, need to schedule new appointment and pay . Also, we will be requiring a fax from the pharmacy that the medication was partially filled.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Newstressclinic/ Dr.Prayaga are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Dr.Prayaga has informed that TMS protocol requires 5 TMS sessions every week for approximately 6 - 8 weeks  continuously and will need to notify in case of an emergency if the client is unable to come in advance to avoid scheduling conflicts.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `Please be advised to consider nalaxone as safety option for taking controlled substance.`,
        showontoggle: true,
      },
      {
        type: 'content',
        label: `I hereby provided the payment for this date of service is accurate/ genuine and will not be disputed as fraud either to the insurance company nor credit card company.`,
        showontoggle: true,
      },
      {
        type: 'note',
        note: '*NOTE: All Patients must be from tri-states(VA, DC, MD) during the visit.',
        showontoggle: true,
      },
      {
        type: 'checkbox-slider',
        key: TMS_CONSENT_FORM.TMS_CONSENT_HOSPITALIZED_BEFORE,
        value: false,
        checkboxlabel: `Have you been hospitalized in the past one month?`,
        controlType: 'checkbox-slider',
        className: 'consent_form_field',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: 'Enter the name of the hospital',
        label: 'Name of the hospital',
        required: true,
        key: TMS_CONSENT_FORM.TMS_CONSENT_HOSP_NAME,
        labelImage: '',
        value: '',
        className: 'consent_form_field',
        controlType: 'inputText',
        editable: true,
        emptyValueMessage: 'Please enter full name',
        validationValue: 'Please enter valid name',
        validators: [Validators.required, this.validateName, Validators.minLength(1),this.valideTextArea],
        focus: true,
        showontoggle: true,
      },
      {
        type: 'textarea',
        placeholder: 'Reason for hospitalization',
        label: 'Reason for hospitalization',
        required: true,
        key: TMS_CONSENT_FORM.TMS_CONSENT_REASON,
        labelImage: '',
        value: '',
        controlType: 'textarea',
        editable: true,
        maxLength: 150,
        className: 'message-box consent_form_field',
        emptyValueMessage: 'Please enter reason',
        validationValue: 'Please enter valid reason',
        validators: [Validators.required, this.valideTextArea, Validators.minLength(1)],
        focus: true,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'currentDate',
        label: 'Date',
        placeholder: 'Date',
        staticData: true,
        labelImage: '',
        required: true,
        maxLength: '20',
        key: TMS_CONSENT_FORM.TMS_CONSENT_DATE,
        value: this.currentDate(),
        className: 'side__by__side',
        editable: true,
        controlType: 'currentDate',
        emptyValueMessage: 'Please enter signature',
        validationValue: 'Please enter same signature',
        validators: [Validators.required],
        showontoggle: true,
      },
      {
        type: 'signature',
        label: '',
        placeholder: 'Signature',
        labelImage: '',
        required: true,
        maxLength: '20',
        key: TMS_CONSENT_FORM.TMS_CONSENT_SIGNATURE,
        value: '',
        className: 'side__by__side',
        editable: true,
        controlType: 'signature',
        emptyValueMessage: 'Please enter signature',
        validationValue: 'Please enter same signature',
        validators: [Validators.required, this.validateAplha, this.validateSignature.bind(this)],
        showontoggle: true,
      },
    ]

    requestTherapistInfoFormConfig: IformConfigI[] = [
      {
        type: 'checkbox-slider',
        value: false,
        checkboxlabel: 'Are you seeing any therapist?',
        key: REQUEST_THERAPIST_DETAILS_KEYS.CONSULTING_THERAPIST,
        controlType: 'checkbox-slider',
        selected: false,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: 'Please enter therapist name',
        label: 'Therapist Name',
        required: true,
        key: REQUEST_THERAPIST_DETAILS_KEYS.THERAPIST_NAME,
        labelImage: '',
        value: '',
        controlType: 'inputText',
        emptyValueMessage: 'Please enter therapist name',
        validationValue: 'Please enter valid name',
        className: '',
        validators: [Validators.required, this.validateName],
        focus: true,
        editable: true,
        showontoggle: true,
      },
      {
        type: 'textarea',
        placeholder: 'Notes',
        label: 'Notes',
        required: true,
        key: REQUEST_THERAPIST_DETAILS_KEYS.THERAPIST_NOTES,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add description',
        validationValue: 'Please add valid description',
        validators: [this.valideTextArea],
        className: 'remaining-number',
        editable: true,
        maxLength: 150,
        count: '150 remaining',
        showontoggle: true,
        showRange: true,
      },
      {
        type: 'textarea',
        placeholder: 'Comments',
        label: 'Comments',
        required: false,
        key: REQUEST_THERAPIST_DETAILS_KEYS.THERAPIST_COMMENTS,
        value: '',
        controlType: 'textarea',
        emptyValueMessage: 'Please add comments',
        validationValue: 'Please add valid comments',
        validators: [],
        className: 'remaining-number',
        editable: true,
        maxLength: 150,
        count: '150 remaining',
        showontoggle: false,
        showRange: true,
      },
      {
        type: 'submit',
        required: true,
        text: 'Cancel',
        key: '',
        value: 'cancel',
        className: 'cancel-block',
        controlType: 'cancelButton',
        validators: [],
        callback: '',
        cencelBtn: 'Clear',
        showontoggle: true,
      },
      {
        type: 'submit',
        required: true,
        text: 'Submit',
        key: '',
        value: 'submit',
        controlType: 'submitButton',
        validators: [],
        callback: '',
        cencelBtn: 'Clear',
        showontoggle: true,
        className: 'newemployee'
      }
    ]
  // form config

  medicalSummaryFormConfig: any[] = [
    {
      type: 'text',
      placeholder: 'Please enter patient name',
      label: 'Patient Name',
      required: true,
      key: MEDICAL_SUMMARY_FORM.NAME,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: false,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter patient DOB',
      label: 'DOB',
      required: true,
      key: MEDICAL_SUMMARY_FORM.DOB,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient DOB',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter patient gender',
      label: 'Sex',
      required: true,
      key: MEDICAL_SUMMARY_FORM.GENDER,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient gender',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter PCP name',
      label: 'PCP',
      required: true,
      key: MEDICAL_SUMMARY_FORM.PCP,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter PCP name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Please enter Address',
      label: 'Address',
      required: true,
      key: MEDICAL_SUMMARY_FORM.ADDRESS,
      labelImage: '',
      value: '',
      controlType: 'textarea',
      editable: false,
      maxLength: 150,
      className: 'message-box consent_form_field',
      emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter valid address',
      validators: [Validators.required, this.valideTextArea, Validators.minLength(1)],
      focus: true,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Active Medications',
      showontoggle: true,
    },
    {
      type: 'formArray',
      key: 'medication',
      formInputs: [[
        {
          type: 'header',
          label: 'Provide following details',
          showontoggle: true,
          editable: true,
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          validationValue: 'Please enter valid medicine name',
          validators: [this.valideTextArea],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'textarea',
          placeholder: '',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'textarea',
          validationValue: 'Please add valid description',
          validators: [this.valideTextArea],
          className: 'remaining-number',
          maxLength: 150,
          editable: true,
          count: '150 remaining',
          showontoggle: true,
          showRange: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'MEDICAL_SUMMARY',
      selected: false,
      editable: true,
      showClose: false,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'activeMedicationAddNew',
      controlType: 'addnew',
      labeltext: 'Add new medicine',
      formtag: 'MEDICAL_SUMMARY',
      editable: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Subjective',
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Please enter subjective',
      label: 'Subjective',
      required: true,
      key: MEDICAL_SUMMARY_FORM.SUBJECTIVE,
      labelImage: '',
      value: 'Client has been a patient of my psychiatric practice since YEAR TO BE MENTION. \nClient has been diagnosed with Attention-deficit hyperactivity disorder. \nClient has not been hospitalized for Attention-deficit hyperactivity disorder.',
      controlType: 'textarea',
      editable: true,
      maxLength: 500,
      className: 'message-box consent_form_field',
      emptyValueMessage: 'Please enter subjective',
      validationValue: 'Please enter valid subjective',
      validators: [Validators.required, this.valideTextArea, Validators.minLength(1)],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Medical History',
      showontoggle: true,
    },
    {
      type: 'textarea',
      placeholder: 'Enter allergies',
      label: 'Allergies',
      required: false,
      key: MEDICAL_SUMMARY_FORM.ALLERGIES,
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add allergies',
      validationValue: 'Please add valid input',
      validators: [Validators.minLength(1), this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 150,
      count: '150 remaining',
      showontoggle: true,
      showRange: true,
    },
    {
      type: 'header',
      label: 'Health Background',
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Social Data:',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Please enter occupation',
      label: 'Occupation',
      required: true,
      key: MEDICAL_SUMMARY_FORM.OCCUPATION,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter occupation',
      className: '',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Tobacco',
      label: 'Tobacco',
      required: true,
      key: MEDICAL_SUMMARY_FORM.TOBACCO,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      className: '',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Alcohol',
      label: 'Alcohol',
      required: true,
      key: MEDICAL_SUMMARY_FORM.ALCOHOL,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      className: '',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'subheader',
      label: 'Exam:',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'General',
      label: 'General',
      required: true,
      key: MEDICAL_SUMMARY_FORM.GENERAL,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      className: '',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Ms',
      label: 'Ms',
      required: true,
      key: MEDICAL_SUMMARY_FORM.MS,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      className: '',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Psych',
      label: 'Psych',
      required: true,
      key: MEDICAL_SUMMARY_FORM.PSYCH,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      className: '',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Assessment & Plan',
      label: 'Assessment & Plan',
      required: true,
      key: MEDICAL_SUMMARY_FORM.ASSESSMENT,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      className: '',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Patient Survey',
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Enter value',
      label: '1) Do you have any of the following common health conditions? (select all that apply: Anxiety or panic disorder, Arthritis, Asthma, Cancer, COPD, Depression, Diabetes, Drug and/or alcohol dependence, heart disease, high blood pressure, kidney, liver) Other health conditions',
      required: true,
      key: MEDICAL_SUMMARY_FORM.QUERY,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Enter value',
      label: '2) Have you had any of the following major surgeries? (select all that apply: appendix removal, c-section, cardiac bypass, gallbladder removal, heart valve, joint replacement, spine surgery) Surgical history',
      required: true,
      key: MEDICAL_SUMMARY_FORM.QUERY+'2',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: MEDICAL_SUMMARY_FORM.QUERY+'3',
      checkboxlabel: '3) In the past year, have you needed to stay in the hospital for an illness or unplanned surgery?',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Enter value',
      label: '4) How many days per week do you exercise for 30 minutes or more (hard enough to make you sweat)?',
      required: true,
      key: MEDICAL_SUMMARY_FORM.QUERY+'4',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'checkbox-slider',
      value: true,
      key: MEDICAL_SUMMARY_FORM.QUERY+'5',
      checkboxlabel: '5) First degree relative with any of the following? (select all that apply): Alcohol/drug addiction, Breast CA, Colon CA, MI/CVA, before age 50, Prostate CA, SCD, Melanoma',
      controlType: 'checkbox-slider',
      className: 'flex-checkbox-slider',
      selected: true,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'text',
      placeholder: 'Enter value',
      label: '6) Knowing your race and ethnicity helps us understand what health issues you might be more likely to have. How do you identify yourself?',
      required: true,
      key: MEDICAL_SUMMARY_FORM.QUERY+'6',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
    }
  ]
  //mednotes forms
  mednoteFollowUpTwentyFiveConfig: any[] = [
     //patientName - 0
    {
      type: 'text',
      placeholder: 'Please enter patient name',
      label: 'Patient Name',
      required: true,
      key: 'followUp25_PatientName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey: "PatientName"
    },
    //dateOfBirth - 1
    {
      type: 'text',
      placeholder: 'Please enter patient DOB',
      label: 'Date of Birth',
      required: true,
      key: 'followUp25_dob',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient DOB',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey: "dob"
    },
    //Duration of visit - 2
    {
      type: 'number',
      placeholder: `Please enter patient's duration of visit`,
      label: 'Duration of Visit',
      required: true,
      key: 'followUp25_DurationOfVisit',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: `Please enter patient's duration of visit`,
      validationValue: 'Please enter valid text',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "DurationOfVisit",
      // Added 'hidden-field' class to hide the "Duration of Visit" field as per client request - 11-11-2024
      className: 'side__by__side placeholder_right hidden-field',
      placeholderVal: 'Minutes'
    },
    //code - 3
    {
      type: 'text',
      placeholder: 'Please enter Mednote code',
      label: 'Code',
      required: true,
      key: 'followUp25_Code',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      // className: 'side__by__side placeholder_left',
      editAccessKey: "Code"
    },
    {
      type: 'dropdown',
      placeholder: 'Please select the diagnosis',
      label: 'Diagnosis',
      required: false,
      key: 'Diagnosis',
      labelImage: '',
      value: '',
      list: this.DiagnosisList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select the diagnosis',
      validationValue: 'Please enter valid diagnosis',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Diagnosis'
    },
    //ChiefComplain - 4
    {
      type: 'text',
      placeholder: 'Please enter chief complain',
      label: 'Chief Complaint',
      required: true,
      key: 'followUp25_ChiefComplain',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter chief complain detail',
      validationValue: 'Please enter valid text',
      validators: [Validators.required],
      showontoggle: true,
      showRange: true,
      editAccessKey: "ChiefComplainDetails"
    },
    // - 5
    {
      type: 'header',
      label: `Information Request Questions` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'formArray',
      key: 'informationRequestQuestions',
      formInputs: [],
      controlType: 'formArray',
      formtag: 'MEDNOTES_FOLLOW_UP_TWENTY_FIVE',
      selected: false,
      editable: true,
      showontoggle: true,
      editAccessKey: "ChiefComplaintDescription.InformationResquestForm.questions"
    },
    //Doctor'sNotes
    {
      type: 'textarea',
      placeholder: `Please enter doctor's notes`,
      label: `Doctor's Note`,
      required: true,
      key: 'followUp25_DoctorNotes',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'textarea',
      editable: true,
      maxLength: 10000,
      emptyValueMessage: `Please enter doctor's notes details`,
      validationValue: 'Please enter valid text',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      count: '10000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: "ChiefComplaintDescription.DoctorNotes"
    },
    //Additional Information
    {
      type: 'header',
      label: `Patient's Additional Information for Doctor's Notes` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'text',
      placeholder: `Please enter patient's therapy details`,
      label: 'Therapy Details',
      required: true,
      key: 'followUp25_therapyDetails',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.therapy_details"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's pertinent information`,
      label: 'Pertinent Information',
      required: true,
      key: 'followUp25_pertinentInfo',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.pertinent_information"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's medical disorder`,
      label: 'Medical Disorder',
      required: true,
      key: 'followUp25_medicalDisorder',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.medical_disorder"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's non-psych meds`,
      label: 'Non-psych Meds',
      required: true,
      key: 'followUp25_nonPsychMeds',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.non_psych_meds"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's past history of alcohol/ marijuana/ cocain`,
      label: 'Past history of alcohol/ marijuana/ cocain',
      required: true,
      key: 'followUp25_pastHistory',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.past_historyOf_alcohol"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's legal issue`,
      label: 'Legal Issue',
      required: true,
      key: 'followUp25_legalIssue',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.legal_issue"
    },
    {
      type: 'textarea',
      placeholder: `Please enter patient's hositalization information`,
      label: `Hospitalization Information:`,
      required: true,
      key: 'followUp25_HospitalInfo',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'textarea',
      editable: true,
      maxLength: 1000,
      emptyValueMessage: `Please enter additional infomation details`,
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: "AdditionalInfo.hospitalization_info"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's sucidal thoughts`,
      label: 'Sucidal',
      required: true,
      key: 'followUp25_sucidal',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.sucidal"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's paranoid thoughts`,
      label: 'Paranoid',
      required: true,
      key: 'followUp25_paranoid',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.paranoid"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's libido`,
      label: 'Libido',
      required: true,
      key: 'followUp25_libibo',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.libido"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's lack of sleep/ insomia`,
      label: 'Lack of sleep/ insomina',
      required: true,
      key: 'followUp25_Insomina',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.lack_of_sleep"
    },
    {
      type: 'header',
      label: `Objective` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: 'Vitals: ',
      showontoggle: true,
      editable: false,
    },
    //height
    {
      type: 'text',
      placeholder: 'Enter patient height',
      label: `Patient's Height`,
      required: true,
      key: 'followUp25_heightFt',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [this.validateHeightFt],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Ft',
      editAccessKey: "Objective.Vitals.Height"
    },
    {
      type: 'text',
      placeholder: 'Enter patient height',
      label: '',
      required: true,
      key: 'followUp25_heightInch',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [this.validateHeightInches],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      noLabel: true,
      placeholderVal: 'Inch',
      editAccessKey: "Objective.Vitals.Height"
    },
    //weight
    {
      type: 'number',
      placeholder: 'Enter patient weight',
      label: `Patient's Weight`,
      required: true,
      key: 'followUp25_weight',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter weight',
      validationValue: 'Please enter valid weight',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'lbs',
      editAccessKey: "Objective.Vitals.Weight"
    },
    //blood pressure
    {
      type: 'text',
      placeholder: 'Enter patient BP',
      label: `Patient's BP`,
      required: false,
      key: 'followUp25_BP',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter BP',
      validationValue: 'Please enter valid BP',
      validators: [this.validateBloodPressure],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'mmHg',
      editAccessKey: "Objective.Vitals.BP"
    },

    {
      type: 'number',
      placeholder: 'Enter patient pulse rate',
      label: `Patient's Pulse`,
      required: false,
      key: 'followUp25_Pulse',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter pulse rate',
      validationValue: 'Please enter valid pulse rate',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'bpm',
      editAccessKey: "Objective.Vitals.Pulse"
    },

    {
      type: 'header',
      label: `MENTAL STATUS EXAM` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: 'Appearance: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Well Groomed',
      controlType: 'checkbox-tik',
      key: 'followUp25_WellGroomed',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Appearance.Well Groomed"
    },
    {
      type: 'checkbox',
      labeltext: 'Neat/Clean',
      controlType: 'checkbox-tik',
      key: 'followUp25_Clean',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Appearance.Clean"
    },
    {
      type: 'checkbox',
      labeltext: 'Disheveled',
      controlType: 'checkbox-tik',
      key: 'followUp25_Disheveled',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Appearance.Disheveled"
    },
    {
      type: 'checkbox',
      labeltext: 'Poor Hygiene',
      controlType: 'checkbox-tik',
      key: 'followUp25_PoorHygiene_Appearance',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Appearance.Poor Hygiene"
    },
    {
      type: 'checkbox',
      labeltext: 'Other',
      controlType: 'checkbox-tik',
      key: 'followUp25_Other',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Appearance.Other"
    },

    {
      type: 'subheader',
      label: 'Orientation: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Person',
      controlType: 'checkbox-tik',
      key: 'followUp25_Person',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Orientation.Person"
    },
    {
      type: 'checkbox',
      labeltext: 'Place',
      controlType: 'checkbox-tik',
      key: 'followUp25_Place',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Orientation.Place"
    },
    {
      type: 'checkbox',
      labeltext: 'Time',
      controlType: 'checkbox-tik',
      key: 'followUp25_Time',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Orientation.Time"
    },
    {
      type: 'checkbox',
      labeltext: 'Purpose',
      controlType: 'checkbox-tik',
      key: 'followUp25_Purpose',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Orientation.Purpose"
    },

    {
      type: 'subheader',
      label: 'Speech: ',
      showontoggle: true,
      editable: false,
      editAccessKey:"Speech"
    },
    {
      type: 'checkbox',
      labeltext: 'Normal',
      controlType: 'checkbox-tik',
      key: 'followUp25_Normal',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Speech.Normal"
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized',
      controlType: 'checkbox-tik',
      key: 'followUp25_Disorganized_speech',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Speech.Disorganized"
    },
    {
      type: 'checkbox',
      labeltext: 'Pressured',
      controlType: 'checkbox-tik',
      key: 'followUp25_Pressured',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Speech.Pressured"
    },
    {
      type: 'checkbox',
      labeltext: 'Slurred',
      controlType: 'checkbox-tik',
      key: 'followUp25_Slurred',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Speech.Slurred"
    },

    {
      type: 'subheader',
      label: 'Attitude: ',
      showontoggle: true,
      editable: false,
      editAccessKey: "Attitude"
    },
    {
      type: 'checkbox',
      labeltext: 'Cooperative',
      controlType: 'checkbox-tik',
      key: 'followUp25_Cooperative',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Attitude.Cooperative"
    },
    {
      type: 'checkbox',
      labeltext: 'Uncooperative',
      controlType: 'checkbox-tik',
      key: 'followUp25_Uncooperative',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Attitude.Uncooperative"
    },
    {
      type: 'checkbox',
      labeltext: 'Guarded',
      controlType: 'checkbox-tik',
      key: 'followUp25_Guarded',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Attitude.Guarded"
    },
    {
      type: 'checkbox',
      labeltext: 'Friendly',
      controlType: 'checkbox-tik',
      key: 'followUp25_Friendly',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Attitude.Friendly"
    },
    {
      type: 'checkbox',
      labeltext: 'Threatening',
      controlType: 'checkbox-tik',
      key: 'followUp25_Threatening',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Attitude.Threatening"
    },
    {
      type: 'checkbox',
      labeltext: 'Angry',
      controlType: 'checkbox-tik',
      key: 'followUp25_Angry_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Attitude.Angry"
    },


    {
      type: 'subheader',
      label: 'Psychomotor Activity: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Ambulatory',
      controlType: 'checkbox-tik',
      key: 'followUp25_Ambulatory',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Psychomotor Activity.Ambulatory"
    },
    {
      type: 'checkbox',
      labeltext: 'Wheel Chair',
      controlType: 'checkbox-tik',
      key: 'followUp25_WheelChair',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Psychomotor Activity.WheelChair"
    },
    {
      type: 'checkbox',
      labeltext: 'Bed Ridden',
      controlType: 'checkbox-tik',
      key: 'followUp25_BedRidden',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: "MentalStatusExam.Psychomotor Activity.BedRidden"
    },

    {
      type: 'subheader',
      label: 'Mood: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Euthymic ',
      controlType: 'checkbox-tik',
      key: 'followUp25_Euthymic',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Euthymic'
    },
    {
      type: 'checkbox',
      labeltext: 'Euphoric',
      controlType: 'checkbox-tik',
      key: 'followUp25_Euphoric',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Euphoric'
    },
    {
      type: 'checkbox',
      labeltext: 'Dysphoric',
      controlType: 'checkbox-tik',
      key: 'followUp25_Dysphoric',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Dysphoric'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxious',
      controlType: 'checkbox-tik',
      key: 'followUp25_Anxious',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Anxious'
    },
    {
      type: 'checkbox',
      labeltext: 'Angry',
      controlType: 'checkbox-tik',
      key: 'followUp25_Angry_mood',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Angry'
    },
    {
      type: 'checkbox',
      labeltext: 'Irritable',
      controlType: 'checkbox-tik',
      key: 'followUp25_Irritable',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Irritable'
    },
    {
      type: 'checkbox',
      labeltext: 'Depressed/Sad',
      controlType: 'checkbox-tik',
      key: 'followUp25_Depressed',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Depressed/Sad'
    },

    {
      type: 'subheader',
      label: 'Affect: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Full ',
      controlType: 'checkbox-tik',
      key: 'followUp25_Full',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Full'
    },
    {
      type: 'checkbox',
      labeltext: 'Constricted',
      controlType: 'checkbox-tik',
      key: 'followUp25_Constricted',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Constricted'
    },
    {
      type: 'checkbox',
      labeltext: 'Labile',
      controlType: 'checkbox-tik',
      key: 'followUp25_Labile',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Labile'
    },
    {
      type: 'checkbox',
      labeltext: 'Blunted',
      controlType: 'checkbox-tik',
      key: 'followUp25_Blunted',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Blunted'
    },
    {
      type: 'checkbox',
      labeltext: 'Flat',
      controlType: 'checkbox-tik',
      key: 'followUp25_Flat',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Flat'
    },

    {
      type: 'subheader',
      label: 'Thought Process: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Goal Directed and Logical',
      controlType: 'checkbox-tik',
      key: 'followUp25_GoalDirectedAndLogical',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Goal Directed and Logical'
    },
    {
      type: 'checkbox',
      labeltext: 'Appropriate',
      controlType: 'checkbox-tik',
      key: 'followUp25_Appropriate_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: true,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Appropriate'
    },
    {
      type: 'checkbox',
      labeltext: 'Tangential ',
      controlType: 'checkbox-tik',
      key: 'followUp25_Tangential',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Tangential'
    },
    {
      type: 'checkbox',
      labeltext: 'Flight of Ideas',
      controlType: 'checkbox-tik',
      key: 'followUp25_FlightOfIdeas',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Flight of Ideas'
    },
    {
      type: 'checkbox',
      labeltext: 'Circumstantial',
      controlType: 'checkbox-tik',
      key: 'followUp25_Circumstantial',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Circumstantial'
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized',
      controlType: 'checkbox-tik',
      key: 'followUp25_Disorganized_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Disorganized'
    },
    {
      type: 'checkbox',
      labeltext: 'Blocking',
      controlType: 'checkbox-tik',
      key: 'followUp25_Blocking',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Blocking'
    },
    {
      type: 'checkbox',
      labeltext: 'Loose Associations',
      controlType: 'checkbox-tik',
      key: 'followUp25_LooseAssociations',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Loose Associations'
    },
    {
      type: 'checkbox',
      labeltext: 'Perseveration ',
      controlType: 'checkbox-tik',
      key: 'followUp25_Perseveration',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Perseveration'
    },
    {
      type: 'checkbox',
      labeltext: 'Ideas of Reference',
      controlType: 'checkbox-tik',
      key: 'followUp25_IdeasOfReference',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Ideas of Reference'
    },
    {
      type: 'checkbox',
      labeltext: 'Poverty of thought',
      controlType: 'checkbox-tik',
      key: 'followUp25_PovertyOfThought',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Poverty of Thought'
    },

    {
      type: 'subheader',
      label: 'Thought Content: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Appropriate',
      controlType: 'checkbox-tik',
      key: 'followUp25_Appropriate_TC',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Appropriate'
    },
    {
      type: 'checkbox',
      labeltext: 'Homicidal Ideation ',
      controlType: 'checkbox-tik',
      key: 'followUp25_HomicidalIdeation',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Homicidal Ideation'
    },
    {
      type: 'checkbox',
      labeltext: 'Suicidal Ideation  ',
      controlType: 'checkbox-tik',
      key: 'followUp25_SuicidalIdeation',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Suicidal Ideation'
    },
    {
      type: 'checkbox',
      labeltext: 'Hallucinations: Auditory, Visual, Olfactory, Command, Tactile',
      controlType: 'checkbox-tik',
      key: 'followUp25_Hallucinations',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Hallucinations: Auditory, Visual, Olfactory, Command, Tactile'
    },
    {
      type: 'checkbox',
      labeltext: 'Persecutory Delusions',
      controlType: 'checkbox-tik',
      key: 'followUp25_PersecutoryDelusions',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Persecutory Delusions'
    },
    {
      type: 'checkbox',
      labeltext: 'Pre-occupation with illness',
      controlType: 'checkbox-tik',
      key: 'followUp25_PreOccupation_TC',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Preoccupation with illness'
    },
 

   {
    type: 'subheader',
    label: 'Memory Session: ',
    showontoggle: true,
    editable: false,
  },
    //short memory
    {
      type: 'radio',
      placeholder: '',
      label: 'Short Term Memory: ',
      labelImage: '',
      required: false,
      key: "followUp25_ShortTerm",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Intact',
          value: 'Intact',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Impaired',
          value: 'Impaired',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.ShortTerm'
    },
    //Long Term
    {
      type: 'radio',
      placeholder: '',
      label: 'Long Term Memory: ',
      labelImage: '',
      required: false,
      key: "followUp25_LongTerm",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Intact',
          value: 'Intact',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Impaired',
          value: 'Impaired',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.LongTerm'
    },
    //Insight
    {
      type: 'radio',
      placeholder: '',
      label: 'Insight: ',
      labelImage: '',
      required: false,
      key: "followUp25_Insight",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Fair to Poor',
          value: 'Fair to Poor',
        },
        {
          label: 'Poor',
          value: 'Poor',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Insight'
    },
    //Judgement
    {
      type: 'radio',
      placeholder: '',
      label: 'Judgment: ',
      labelImage: '',
      required: false,
      key: "followUp25_Judgment",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Fair to Poor',
          value: 'Fair to Poor',
        },
        {
          label: 'Poor',
          value: 'Poor',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Judgment'
    },

    {
      type: 'textarea',
      placeholder: 'Please enter side effects',
      label: 'Side Effects:',
      required: true,
      key: 'sideEffectsDetails',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      focus: false,
      maxLength: 1000,
      count: '1000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: 'sideEffects_description'
    },

    //Allergies
    {
      type: 'textarea',
      placeholder: 'Please enter allergies',
      label: 'Allergies: ',
      required: true,
      key: 'followUp25_allergies_description',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add details',
      validationValue: 'Please add valid details',
      validators: [Validators.required,this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      focus: false,
      maxLength: 1000,
      count: '1000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: 'allergies_description'
    },
    //Assessment
    {
      type: 'text',
      placeholder: 'Please enter Assessment',
      label: 'Assessment',
      required: true,
      key: 'followUp25_assessment',
      labelImage: '',
      value: 'DSM-V',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Assessment'
    },

    {
      type: 'subheader',
      label: `AXIS I` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: `Primary Diagnosis:`,
      showontoggle: true,
      editable: false,
      showMessage: false,
      Message: 'Only one option can be selected in primary diagnosis'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxiety (F41.9)',
      controlType: 'checkbox-tik-one',
      key: 'Anxiety25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.Anxiety Disorder (F41.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'Schizoaffective disorder (F25.9)',
      controlType: 'checkbox-tik-one',
      key: 'Schizophrenia25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.Schizoaffective disorder (F25.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'BI-POLAR II (F31.81)',
      controlType: 'checkbox-tik-one',
      key: 'Bipolar25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.BI-POLAR II (F31.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.9)',
      controlType: 'checkbox-tik-one',
      key: 'Depression25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.MDD (F33.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.2)',
      controlType: 'checkbox-tik-one',
      key: 'Depression25_axisI_I',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.MDD (F33.2)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADHD (F90.0)',
      controlType: 'checkbox-tik-one',
      key: 'ADHD25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.ADHD (F90.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADJUSTMENT DISORDER(F43.20)',
      controlType: 'checkbox-tik-one',
      key: 'AdjustmentDisorder25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.ADJUSTMENT DISORDER (F43.20)'
    },
    {
      type: 'checkbox',
      labeltext: 'OPPOSITIONAL DISORDER (F91.3)',
      controlType: 'checkbox-tik-one',
      key: 'OppositionalDisorder25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.OPPOSITIONAL DISORDER (F91.3)'
    },
    {
      type: 'checkbox',
      labeltext: 'AUTISM (F84.0)',
      controlType: 'checkbox-tik-one',
      key: 'Autism25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.AUTISM (F84.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'EATING DISORDER (F50.9)',
      controlType: 'checkbox-tik-one',
      key: 'EatingDisorder25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.EATING DISORDER (F50.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'OCD (F42.9)',
      controlType: 'checkbox-tik-one',
      key: 'OcdDisorder25_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.OCD (F42.9)'
    },
    {
      type: 'subheader',
      label: `Secondary Diagnosis:` ,
      showontoggle: true,
      editable: false,
      showMessage: false,
      Message: 'Only one option can be selected in secondary diagnosis'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxiety (F41.9)',
      controlType: 'checkbox-tik-one',
      key: 'Anxiety25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.Anxiety Disorder (F41.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'Schizoaffective disorder (F25.9)',
      controlType: 'checkbox-tik-one',
      key: 'Schizophrenia25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.Schizoaffective disorder (F25.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'BI-POLAR II (F31.81)',
      controlType: 'checkbox-tik-one',
      key: 'Bipolar25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.BI-POLAR II (F31.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.9)',
      controlType: 'checkbox-tik-one',
      key: 'Depression25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.MDD (F33.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.2)',
      controlType: 'checkbox-tik-one',
      key: 'Depression25_axisI_SD_I',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.MDD (F33.2)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADHD (F90.0)',
      controlType: 'checkbox-tik-one',
      key: 'ADHD25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.ADHD (F90.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADJUSTMENT DISORDER(F43.20)',
      controlType: 'checkbox-tik-one',
      key: 'AdjustmentDisorder25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.ADJUSTMENT DISORDER (F43.20)'
    },
    {
      type: 'checkbox',
      labeltext: 'OPPOSITIONAL DISORDER (F91.3)',
      controlType: 'checkbox-tik-one',
      key: 'OppositionalDisorder25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.OPPOSITIONAL DISORDER (F91.3)'
    },
    {
      type: 'checkbox',
      labeltext: 'AUTISM (F84.0)',
      controlType: 'checkbox-tik-one',
      key: 'Autism25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.AUTISM (F84.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'EATING DISORDER (F50.9)',
      controlType: 'checkbox-tik-one',
      key: 'EatingDisorder25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.EATING DISORDER (F50.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'OCD (F42.9)',
      controlType: 'checkbox-tik-one',
      key: 'OcdDisorder25_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.OCD (F42.9)'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-II detail',
      label: 'AXIS II',
      required: true,
      key: 'followUp25_axisTwo',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_II'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-III detail',
      label: 'AXIS III',
      required: true,
      key: 'followUp25_axisThree',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_III'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-IV detail',
      label: 'AXIS IV',
      required: true,
      key: 'followUp25_axisFour',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_IV'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-V detail',
      label: 'AXIS V',
      required: true,
      key: 'followUp25_axisFive',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_V'
    },
    
    {
      type: 'subheader',
      label: 'Informed consent signed for use of Psychotropic medication ',
      className:'heading-form',
      showontoggle: true,
      editable: false,
    },
    //plan
    {
      type: 'textarea',
      placeholder: 'Please enter plan for medication',
      label: 'PLAN',
      required: true,
      key: 'followUp25_plan',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter valid text',
      validationValue: 'Please enter valid text',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      maxLength: 1000,
      count: '1000 remaining',
      showRange: true,
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Plan.Plan'
    },

    {
      type: 'text',
      placeholder: 'Please enter plan for medication',
      label: 'Duration of medication',
      required: true,
      key: 'followUp25_durationMedication',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter valid text',
      validationValue: 'Please enter valid text',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Plan.Duration'
    },



    {
      type: 'formArray',
      key: 'diagnosisMedications2',
      formInputs: [[
        {
          type: 'close-icon',
          showontoggle: true,
          showClose: true,
          editable: false
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: false,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          className: 'side__by__side placeholder_right',
          emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [Validators.required],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'text',
          placeholder: 'Dosage and Direction',
          label: 'Dosage and Direction',
          required: false,
          key: 'dosage',
          value: '',
          controlType: 'inputText',
          emptyValueMessage: 'Please add dosage and direction',
          validationValue: 'Please add valid dosage and direction',
          validators: [Validators.required],
          className: 'side__by__side placeholder_right',
          editable: true,
          showontoggle: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'MEDNOTES_FOLLOW_UP_TWENTY_FIVE',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'diagnosisMedicationsAddNew',
      controlType: 'addnew',
      labeltext: 'Add Medications',
      formtag: 'MEDNOTES_FOLLOW_UP_TWENTY_FIVE',
      editable: true,
      showontoggle: true,
    },

 

    {
      type: 'calendar',
      placeholder: 'Please enter date',
      label: 'Date:',
      required: true,
      key: 'followUp25_Date',
      // isDob: false,
      labelImage: 'user',
      value: '',
      // isFutureDate: true,
      controlType: 'calendarEval',
      emptyValueMessage: 'Please select a valid date',
      validationValue: 'Please select valid date',
      validators: [Validators.required],
      editable: true,
      focus: true,
      showontoggle: true,
      className: 'side__by__side',
      editAccessKey: 'evaluationDate'
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your primary insurance Provider',
      label: 'Primary Insurance',
      required: true,
      key: 'primaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey: 'primaryInsuranceProvider'
    },
    
  ]

  mednoteFollowUpFortyFiveConfig: any[] = [
     //patientName - 0
     {
      type: 'text',
      placeholder: 'Please enter patient name',
      label: 'Patient Name',
      required: true,
      key: 'followUp45_PatientName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient name',
      validationValue: 'Please enter valid name',
      validators: [Validators.required, this.validateName],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey: "PatientName"
    },
    //dateOfBirth - 1
    {
      type: 'text',
      placeholder: 'Please enter patient DOB',
      label: 'Date of Birth',
      required: true,
      key: 'followUp45_dob',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient DOB',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey: 'dob'
    },
    //Duration of visit - 2
    {
      type: 'number',
      placeholder: `Please enter patient's duration of visit`,
      label: 'Duration of Visit',
      required: true,
      key: 'followUp45_DurationOfVisit',
      labelImage: '',
      value: '45 Mintues',
      controlType: 'inputText',
      emptyValueMessage: `Please enter patient's duration of visit`,
      validationValue: 'Please enter valid text',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      placeholderVal: 'Minutes',
      // Added 'hidden-field' class to hide the "Duration of Visit" field as per client request - 11-11-2024
      className: 'side__by__side placeholder_right hidden-field',
      editAccessKey: 'DurationOfVisit'
    },
    //code - 3
    {
      type: 'text',
      placeholder: 'Please enter Mednote code',
      label: 'Code',
      required: true,
      key: 'followUp45_Code',
      labelImage: '',
      value: '99214',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Code',
      // className: 'side__by__side placeholder_left',
    },
    {
      type: 'dropdown',
      placeholder: 'Please select the diagnosis',
      label: 'Diagnosis',
      required: false,
      key: 'Diagnosis',
      labelImage: '',
      value: '',
      list: this.DiagnosisList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select the diagnosis',
      validationValue: 'Please enter valid diagnosis',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Diagnosis'
    },
    //ChiefComplain - 4
    {
      type: 'text',
      placeholder: 'Please enter chief complain',
      label: 'Chief Complaint',
      required: true,
      key: 'followUp45_ChiefComplain',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter chief complain detail',
      validationValue: 'Please enter valid text',
      validators: [Validators.required],
      showontoggle: true,
      showRange: true,
      editAccessKey: 'ChiefComplainDetails'
    },
    // // - 5
    {
      type: 'header',
      label: `Information Request Questions` ,
      showontoggle: true,
      editable: false,
    },
    // // -6
    {
      type: 'formArray',
      key: 'informationRequestQuestions',
      formInputs: [],
      controlType: 'formArray',
      formtag: 'MEDNOTES_FOLLOW_UP_FORTY_FIVE',
      selected: false,
      editable: true,
      showontoggle: true,
      editAccessKey: 'ChiefComplaintDescription.InformationResquestForm.questions'
    },
    //Doctor'sNotes
    {
      type: 'textarea',
      placeholder: `Please enter doctor's notes`,
      label: `Doctor's Note `,
      required: true,
      key: 'followUp45_DoctorNote',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'textarea',
      editable: true,
      maxLength: 10000,
      emptyValueMessage: `Please enter doctor's notes details`,
      validationValue: 'Please enter valid text',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      count: '10000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: 'ChiefComplaintDescription.DoctorNotes'
    },
    {
      type: 'header',
      label: `Patient's Additional Information for Doctor's Notes` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'text',
      placeholder: `Please enter patient's therapy details`,
      label: 'Therapy Details',
      required: true,
      key: 'followUp45_therapyDetails',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.therapy_details"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's pertinent information`,
      label: 'Pertinent Information',
      required: true,
      key: 'followUp45_pertinentInfo',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.pertinent_information"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's medical disorder`,
      label: 'Medical Disorder',
      required: true,
      key: 'followUp45_medicalDisorder',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.medical_disorder"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's non-psych meds`,
      label: 'Non-psych Meds',
      required: true,
      key: 'followUp45_nonPsychMeds',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.non_psych_meds"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's past history of alcohol/ marijuana/ cocain`,
      label: 'Past history of alcohol/ marijuana/ cocain',
      required: true,
      key: 'followUp45_pastHistory',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.past_historyOf_alcohol"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's legal issue`,
      label: 'Legal Issue',
      required: true,
      key: 'followUp45_legalIssue',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: "AdditionalInfo.legal_issue"
    },
    {
      type: 'textarea',
      placeholder: `Please enter patient's hositalization information`,
      label: `Hospitalization Information:`,
      required: true,
      key: 'followUp45_HospitalInfo',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'textarea',
      editable: true,
      maxLength: 1000,
      emptyValueMessage: `Please enter additional infomation details`,
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: "AdditionalInfo.hospitalization_info"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's sucidal thoughts`,
      label: 'Sucidal',
      required: true,
      key: 'followUp45_sucidal',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.sucidal"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's paranoid thoughts`,
      label: 'Paranoid',
      required: true,
      key: 'followUp45_paranoid',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.paranoid"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's libido`,
      label: 'Libido',
      required: true,
      key: 'followUp45_libibo',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.libido"
    },
    {
      type: 'text',
      placeholder: `Please enter patient's lack of sleep/ insomia`,
      label: 'Lack of sleep/ insomina',
      required: true,
      key: 'followUp45_Insomina',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter mednote code ',
      validationValue: 'Please enter valid text',
      validators: [this.valideTextArea],
      focus: true,
      editable: true,
      className: 'side__by__side placeholder_right',
      showontoggle: true,
      editAccessKey: "AdditionalInfo.lack_of_sleep"
    },
    {
      type: 'header',
      label: `Objective` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: 'Vitals: ',
      showontoggle: true,
      editable: false,
    },
    //height
    {
      type: 'text',
      placeholder: 'Enter patient height',
      label: `Patient's Height`,
      required: true,
      key: 'followUp45_heightFt',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [ this.validateHeightFt],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Ft',
    },
    {
      type: 'text',
      placeholder: 'Enter patient height',
      label: '',
      required: true,
      key: 'followUp45_heightInch',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [ this.validateHeightInches],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      noLabel: true,
      placeholderVal: 'Inch'
    },
    //weight
    {
      type: 'number',
      placeholder: 'Enter patient weight',
      label: `Patient's Weight`,
      required: true,
      key: 'followUp45_weight',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter weight',
      validationValue: 'Please enter valid weight',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'lbs',
      editAccessKey: 'Objective.Vitals.Weight'
    },
    //blood pressure
    {
      type: 'text',
      placeholder: 'Enter patient BP',
      label: `Patient's BP`,
      required: false,
      key: 'followUp45_BP',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter BP',
      validationValue: 'Please enter valid BP',
      validators: [this.validateBloodPressure],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'mmHg',
      editAccessKey: 'Objective.Vitals.BP'
    },

    {
      type: 'number',
      placeholder: 'Enter patient pulse rate',
      label: `Patient's Pulse`,
      required: false,
      key: 'followUp45_Pulse',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter pulse rate',
      validationValue: 'Please enter valid pulse rate',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'bpm',
      editAccessKey: 'Objective.Vitals.Pulse'
    },

    {
      type: 'header',
      label: `MENTAL STATUS EXAM` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: 'Appearance: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Appears stated age',
      controlType: 'checkbox-tik',
      key: 'followUp45_statedAge',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Appearance.Appears stated age'
    },
    {
      type: 'checkbox',
      labeltext: 'Well Groomed',
      controlType: 'checkbox-tik',
      key: 'followUp45_WellGroomed',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Appearance.Well Groomed'
    },
    {
      type: 'checkbox',
      labeltext: 'Neat/Clean',
      controlType: 'checkbox-tik',
      key: 'followUp45_Clean',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Appearance.Clean'
    },
    {
      type: 'checkbox',
      labeltext: 'Disheveled',
      controlType: 'checkbox-tik',
      key: 'followUp45_Disheveled',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Appearance.Disheveled'
    },
    {
      type: 'checkbox',
      labeltext: 'Poor Hygiene',
      controlType: 'checkbox-tik',
      key: 'followUp45_PoorHygiene_Appearance',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Appearance.Poor Hygiene'
    },
    {
      type: 'checkbox',
      labeltext: 'Other',
      controlType: 'checkbox-tik',
      key: 'followUp45_Other',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Appearance.Other'
    },

    {
      type: 'subheader',
      label: 'Orientation: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Person',
      controlType: 'checkbox-tik',
      key: 'followUp45_Person',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Orientation.Person'
    },
    {
      type: 'checkbox',
      labeltext: 'Place',
      controlType: 'checkbox-tik',
      key: 'followUp45_Place',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Orientation.Place'
    },
    {
      type: 'checkbox',
      labeltext: 'Time',
      controlType: 'checkbox-tik',
      key: 'followUp45_Time',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Orientation.Time'
    },
    {
      type: 'checkbox',
      labeltext: 'Purpose',
      controlType: 'checkbox-tik',
      key: 'followUp45_Purpose',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Orientation.Purpose'
    },

    {
      type: 'subheader',
      label: 'Speech: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Normal',
      controlType: 'checkbox-tik',
      key: 'followUp45_Normal',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Speech.Normal'
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized',
      controlType: 'checkbox-tik',
      key: 'followUp45_Disorganized_speech',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Speech.Disorganized'
    },
    {
      type: 'checkbox',
      labeltext: 'Pressured',
      controlType: 'checkbox-tik',
      key: 'followUp45_Pressured',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Speech.Pressured'
    },
    {
      type: 'checkbox',
      labeltext: 'Slurred',
      controlType: 'checkbox-tik',
      key: 'followUp45_Slurred',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Speech.Slurred'
    },

    {
      type: 'subheader',
      label: 'Attitude: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Cooperative',
      controlType: 'checkbox-tik',
      key: 'followUp45_Cooperative',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Attitude.Cooperative'
    },
    {
      type: 'checkbox',
      labeltext: 'Uncooperative',
      controlType: 'checkbox-tik',
      key: 'followUp45_Uncooperative',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Attitude.Uncooperative'
    },
    {
      type: 'checkbox',
      labeltext: 'Guarded',
      controlType: 'checkbox-tik',
      key: 'followUp45_Guarded',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Attitude.Guarded'
    },
    {
      type: 'checkbox',
      labeltext: 'Friendly',
      controlType: 'checkbox-tik',
      key: 'followUp45_Friendly',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Attitude.Friendly'
    },
    {
      type: 'checkbox',
      labeltext: 'Threatening',
      controlType: 'checkbox-tik',
      key: 'followUp45_Threatening',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Attitude.Threatening'
    },
    {
      type: 'checkbox',
      labeltext: 'Angry',
      controlType: 'checkbox-tik',
      key: 'followUp45_Angry_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Attitude.Angry'
    },


    {
      type: 'subheader',
      label: 'Psychomotor Activity: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Normal movements',
      controlType: 'checkbox-tik',
      key: 'followUp45_NormalMovements',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Psychomotor Activity.Normal movements'
    },
    {
      type: 'checkbox',
      labeltext: 'Ambulatory',
      controlType: 'checkbox-tik',
      key: 'followUp45_Ambulatory',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Psychomotor Activity.Ambulatory'
    },
    {
      type: 'checkbox',
      labeltext: 'Wheel Chair',
      controlType: 'checkbox-tik',
      key: 'followUp45_WheelChair',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Psychomotor Activity.WheelChair'
    },
    {
      type: 'checkbox',
      labeltext: 'Bed Ridden',
      controlType: 'checkbox-tik',
      key: 'followUp45_BedRidden',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Psychomotor Activity.BedRidden'
    },

    {
      type: 'subheader',
      label: 'Mood: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Euthymic ',
      controlType: 'checkbox-tik',
      key: 'followUp45_Euthymic',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Euthymic'
    },
    {
      type: 'checkbox',
      labeltext: 'Euphoric',
      controlType: 'checkbox-tik',
      key: 'followUp45_Euphoric',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Euphoric'
    },
    {
      type: 'checkbox',
      labeltext: 'Dysphoric',
      controlType: 'checkbox-tik',
      key: 'followUp45_Dysphoric',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Dysphoric'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxious',
      controlType: 'checkbox-tik',
      key: 'followUp45_Anxious',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Anxious'
    },
    {
      type: 'checkbox',
      labeltext: 'Angry',
      controlType: 'checkbox-tik',
      key: 'followUp45_Angry_mood',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Angry'
    },
    {
      type: 'checkbox',
      labeltext: 'Irritable',
      controlType: 'checkbox-tik',
      key: 'followUp45_Irritable',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Irritable'
    },
    {
      type: 'checkbox',
      labeltext: 'Depressed/Sad',
      controlType: 'checkbox-tik',
      key: 'followUp45_Depressed',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Mood.Depressed/sad'
    },
    
    {
      type: 'subheader',
      label: 'Affect: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Full ',
      controlType: 'checkbox-tik',
      key: 'followUp45_Full',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Full'
    },
    {
      type: 'checkbox',
      labeltext: 'Constricted',
      controlType: 'checkbox-tik',
      key: 'followUp45_Constricted',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Constricted'
    },
    {
      type: 'checkbox',
      labeltext: 'Labile',
      controlType: 'checkbox-tik',
      key: 'followUp45_Labile',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Labile'
    },
    {
      type: 'checkbox',
      labeltext: 'Blunted',
      controlType: 'checkbox-tik',
      key: 'followUp45_Blunted',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Blunted'
    },
    {
      type: 'checkbox',
      labeltext: 'Flat',
      controlType: 'checkbox-tik',
      key: 'followUp45_Flat',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Affect.Flat'
    },

    {
      type: 'subheader',
      label: 'Thought Process: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Goal Directed and Logical',
      controlType: 'checkbox-tik',
      key: 'followUp45_GoalDirectedAndLogical',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Goal Directed and Logical'
    },
    {
      type: 'checkbox',
      labeltext: 'Appropriate',
      controlType: 'checkbox-tik',
      key: 'followUp45_Appropriate_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: true,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Appropriate'
    },
    {
      type: 'checkbox',
      labeltext: 'Tangential ',
      controlType: 'checkbox-tik',
      key: 'followUp45_Tangential',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Tangential'
    },
    {
      type: 'checkbox',
      labeltext: 'Flight of Ideas',
      controlType: 'checkbox-tik',
      key: 'followUp45_FlightOfIdeas',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Flight of Ideas'
    },
    {
      type: 'checkbox',
      labeltext: 'Circumstantial',
      controlType: 'checkbox-tik',
      key: 'followUp45_Circumstantial',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Circumstantial'
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized',
      controlType: 'checkbox-tik',
      key: 'followUp45_Disorganized_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Disorganized'
    },
    {
      type: 'checkbox',
      labeltext: 'Blocking',
      controlType: 'checkbox-tik',
      key: 'followUp45_Blocking',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Blocking'
    },
    {
      type: 'checkbox',
      labeltext: 'Loose Associations',
      controlType: 'checkbox-tik',
      key: 'followUp45_LooseAssociations',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Loose Associations'
    },
    {
      type: 'checkbox',
      labeltext: 'Perseveration ',
      controlType: 'checkbox-tik',
      key: 'followUp45_Perseveration',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Perseveration'
    },
    {
      type: 'checkbox',
      labeltext: 'Ideas of Reference',
      controlType: 'checkbox-tik',
      key: 'followUp45_IdeasOfReference',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Ideas of Reference'
    },
    {
      type: 'checkbox',
      labeltext: 'Poverty of thought',
      controlType: 'checkbox-tik',
      key: 'followUp45_PovertyOfThought',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Poverty of Thought'
    },
    {
      type: 'checkbox',
      labeltext: 'Logical and coherent',
      controlType: 'checkbox-tik',
      key: 'followUp45_LogicalandCoherent',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Process.Logical and coherent'
    },
    
    {
      type: 'subheader',
      label: 'Thought Content: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Appropriate',
      controlType: 'checkbox-tik',
      key: 'followUp45_Appropriate_TC',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Appropriate'
    },
    {
      type: 'checkbox',
      labeltext: 'Homicidal Ideation ',
      controlType: 'checkbox-tik',
      key: 'followUp45_HomicidalIdeation',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Homicidal Ideation'
    },
    {
      type: 'checkbox',
      labeltext: 'Suicidal Ideation  ',
      controlType: 'checkbox-tik',
      key: 'followUp45_SuicidalIdeation',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Suicidal Ideation'
    },
    {
      type: 'checkbox',
      labeltext: 'Hallucinations: Auditory, Visual, Olfactory, Command, Tactile',
      controlType: 'checkbox-tik',
      key: 'followUp45_Hallucinations',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Hallucinations: Auditory, Visual, Olfactory, Command, Tactile'
    },
    {
      type: 'checkbox',
      labeltext: 'Persecutory Delusions',
      controlType: 'checkbox-tik',
      key: 'followUp45_PersecutoryDelusions',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Persecutory Delusions'
    },
    {
      type: 'checkbox',
      labeltext: 'Delusions on EX',
      controlType: 'checkbox-tik',
      key: 'followUp45_DelusionsOnEX',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Delusions on EX'
    },
    {
      type: 'checkbox',
      labeltext: 'Preoccupation with illness',
      controlType: 'checkbox-tik',
      key: 'followUp45_PreoccupationWithIllness',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Thought Content.Preoccupation with illness'
    },

    {
      type: 'subheader',
      label: 'Behaviour: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Cooperative',
      controlType: 'checkbox-tik',
      key: 'followUp45_CooperativeBehaviour',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Behavior.Cooperative'
    },
    {
      type: 'checkbox',
      labeltext: 'Good eye contact',
      controlType: 'checkbox-tik',
      key: 'followUp45_GoodEyeContact',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Behavior.Good eye contact'
    },

    //SI/HI
    {
      type: 'radio',
      placeholder: '',
      label: 'SI/HI: ',
      labelImage: '',
      required: false,
      key: "followUp45_SIorHI",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Yes',
          value: 'Yes',
        },
        {
          label: 'No',
          value: 'No',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.SI/HI'
    },
    //Alertness
    {
      type: 'radio',
      placeholder: '',
      label: 'Alertness: ',
      labelImage: '',
      required: false,
      key: "followUp45_Alertness",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Attentive',
          value: 'Attentive',
        },
        {
          label: 'Inattentive',
          value: 'Inattentive',
        }
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Alertness'
    },
    //Intellectual level
    {
      type: 'radio',
      placeholder: '',
      label: 'Intellectual level: ',
      labelImage: '',
      required: false,
      key: "followUp45_IntellectualLevel",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Average',
          value: 'Average',
        },
        {
          label: 'Poor',
          value: 'Poor',
        }
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Intellectual level'
    },
    
    //Cognitive function
    {
      type: 'radio',
      placeholder: '',
      label: 'Cognitive function: ',
      labelImage: '',
      required: false,
      key: "followUp45_CognitiveFunction",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Intact',
          value: 'Intact',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Impaired',
          value: 'Impaired',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Cognitive function'
    },
    //Long term
    {
      type: 'radio',
      placeholder: '',
      label: 'Long Term Memory: ',
      labelImage: '',
      required: false,
      key: "followUp45_LongTMemory",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
          {
            label: 'Intact',
            value: 'Intact',
          },
          {
            label: 'Fair',
            value: 'Fair',
          },
          {
            label: 'Impaired',
            value: 'Impaired',
          },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.LongTerm'
    },
    //short term
    {
      type: 'radio',
      placeholder: '',
      label: 'Short Term Memory: ',
      labelImage: '',
      required: false,
      key: "followUp45_ShortTMemory",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
          {
            label: 'Intact',
            value: 'Intact',
          },
          {
            label: 'Fair',
            value: 'Fair',
          },
          {
            label: 'Impaired',
            value: 'Impaired',
          },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.ShortTerm'
    },
    //Recent Memory
    {
      type: 'radio',
      placeholder: '',
      label: 'Recent Memory: ',
      labelImage: '',
      required: false,
      key: "followUp45_RecentMemory",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
          {
            label: 'Good',
            value: 'Good',
          },
          {
            label: 'Average',
            value: 'Average',
          },
          {
            label: 'Normal',
            value: 'Normal',
          },
          {
            label: 'Poor',
            value: 'Poor',
          },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Recent memory'
    },
    //Insight
    {
      type: 'radio',
      placeholder: '',
      label: 'Insight: ',
      labelImage: '',
      required: false,
      key: "followUp45_Insight",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Fair to Poor',
          value: 'Fair to Poor',
        },
        {
          label: 'Poor',
          value: 'Poor',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Insight'
    },
    //Judgement
    {
      type: 'radio',
      placeholder: '',
      label: 'Judgment: ',
      labelImage: '',
      required: false,
      key: "followUp45_Judgment",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Fair to Poor',
          value: 'Fair to Poor',
        },
        {
          label: 'Poor',
          value: 'Poor',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Judgment'
    },
    //Impluse Control
    {
      type: 'radio',
      placeholder: '',
      label: 'Impulse Control: ',
      labelImage: '',
      required: false,
      key: "followUp45_ImpulseControl",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Fair to Poor',
          value: 'Fair to Poor',
        },
        {
          label: 'Poor',
          value: 'Poor',
        },
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Impulse Control'
    },
    //Attentation
    {
      type: 'radio',
      placeholder: '',
      label: 'Attentation/Concentration:',
      labelImage: '',
      required: false,
      key: "followUp45_AttentationorConcentration",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Intact',
          value: 'Intact',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Impaired',
          value: 'Impaired',
        },
        {
          label: 'Partially impaired',
          value: 'Partially impaired',
        },
        
      ],
      showontoggle: true,
      editAccessKey: 'MentalStatusExam.Attention/ Concentration'
    },

    {
      type: 'textarea',
      placeholder: 'Please enter side effects',
      label: 'Side Effects',
      required: true,
      key: 'sideEffectsDetails',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [ this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      focus: false,
      maxLength: 1000,
      count: '1000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: 'sideEffects_description'
    },
    //Allergies
    {
      type: 'textarea',
      placeholder: 'Please enter allergies',
      label: 'Allergies',
      required: true,
      key: 'followUp45_Allergies',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add details',
      validationValue: 'Please add valid details',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      focus: false,
      maxLength: 1000,
      count: '1000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: 'allergies_description'
    },

    //Assessment
    {
      type: 'text',
      placeholder: 'Please enter Assessment',
      label: 'Assessment',
      required: true,
      key: 'followUp45_assessment',
      labelImage: '',
      value: 'DSM-V',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Assessment'
    },
    {
      type: 'subheader',
      label: `AXIS I` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: `Primary Diagnosis:`, //PD
      showontoggle: true,
      editable: false,
      showMessage: false,
      Message: 'Only one option can be selected in primary diagnosis'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxiety Disorder (F41.9)',
      controlType: 'checkbox-tik-one',
      key: 'Anxiety45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.Anxiety Disorder (F41.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'SCHIZOPHRENIFORM DISORDER (F20.81)',
      controlType: 'checkbox-tik-one',
      key: 'Schizophrenia45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.SCHIZOPHRENIFORM DISORDER (F20.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'BI-POLAR II (F31.81)',
      controlType: 'checkbox-tik-one',
      key: 'Bipolar45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.BI-POLAR II (F31.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.9)',
      controlType: 'checkbox-tik-one',
      key: 'Depression45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.MDD (F33.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.2)',
      controlType: 'checkbox-tik-one',
      key: 'Depression45_axisI_I',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.MDD (F33.2)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADHD (F90.0)',
      controlType: 'checkbox-tik-one',
      key: 'ADHD45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.ADHD (F90.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADJUSTMENT DISORDER(F43.20)',
      controlType: 'checkbox-tik-one',
      key: 'AdjustmentDisorder45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.ADJUSTMENT DISORDER (F43.20)'
    },
    {
      type: 'checkbox',
      labeltext: 'OPPOSITIONAL DISORDER (F91.3)',
      controlType: 'checkbox-tik-one',
      key: 'OppositionalDisorder45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.OPPOSITIONAL DISORDER (F91.3)'
    },
    {
      type: 'checkbox',
      labeltext: 'AUTISM (F84.0)',
      controlType: 'checkbox-tik-one',
      key: 'Autism45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.AUTISM (F84.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'EATING DISORDER (F50.9)',
      controlType: 'checkbox-tik-one',
      key: 'EatingDisorder45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.EATING DISORDER (F50.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'OCD (F42.9)',
      controlType: 'checkbox-tik-one',
      key: 'Ocd45_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.OCD (F42.9)'
    },
    {
      type: 'subheader',
      label: `Secondary Diagnosis:`, //SD
      showontoggle: true,
      editable: false,
      showMessage: false,
      Message: 'Only one option can be selected in secondary diagnosis'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxiety Disorder (F41.9)',
      controlType: 'checkbox-tik-one',
      key: 'Anxiety45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.Anxiety Disorder (F41.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'SCHIZOPHRENIFORM DISORDER (F20.81)',
      controlType: 'checkbox-tik-one',
      key: 'Schizophrenia45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.SCHIZOPHRENIFORM DISORDER (F20.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'BI-POLAR II (F31.81)',
      controlType: 'checkbox-tik-one',
      key: 'Bipolar45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.BI-POLAR II (F31.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.9)',
      controlType: 'checkbox-tik-one',
      key: 'Depression45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.MDD (F33.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.2)',
      controlType: 'checkbox-tik-one',
      key: 'Depression45_axisI_SD_I',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.MDD (F33.2)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADHD (F90.0)',
      controlType: 'checkbox-tik-one',
      key: 'ADHD45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.ADHD (F90.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADJUSTMENT DISORDER(F43.20)',
      controlType: 'checkbox-tik-one',
      key: 'AdjustmentDisorder45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.ADJUSTMENT DISORDER (F43.20)'
    },
    {
      type: 'checkbox',
      labeltext: 'OPPOSITIONAL DISORDER (F91.3)',
      controlType: 'checkbox-tik-one',
      key: 'OppositionalDisorder45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.OPPOSITIONAL DISORDER (F91.3)'
    },
    {
      type: 'checkbox',
      labeltext: 'AUTISM (F84.0)',
      controlType: 'checkbox-tik-one',
      key: 'Autism45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.AUTISM (F84.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'EATING DISORDER (F50.9)',
      controlType: 'checkbox-tik-one',
      key: 'EatingDisorder45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.EATING DISORDER (F50.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'OCD (F42.9)',
      controlType: 'checkbox-tik-one',
      key: 'Ocd45_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.OCD (F42.9)'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-II detail',
      label: 'AXIS II',
      required: true,
      key: 'followUp45_axisTwo',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_II'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-III detail',
      label: 'AXIS III',
      required: true,
      key: 'followUp45_axisThree',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_III'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-IV detail',
      label: 'AXIS IV',
      required: true,
      key: 'followUp45_axisFour',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_IV'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-V detail',
      label: 'AXIS V',
      required: true,
      key: 'followUp45_axisFive',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Axis_V'
    },

    //Procedure /Type of Visit: 
    {
      type: 'textarea',
      placeholder: `Please enter patient's procedure /type of visit: `,
      label: 'Procedure/Type of Visit',
      required: true,
      key: 'followUp45_typeOfVisit',
      labelImage: '',
      value: 'CPT 99214: Established outpatient, office visit, moderate complexity, Detailed history, and physical exam.',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey: 'Procedure/Type of Visit'
    },
    // Potential Side Effects:   
    {
      type: 'textarea',
      placeholder: `Please enter patient's potential side effects:    `,
      label: 'Potential Side Effects',
      required: true,
      key: 'followUp45_potentialSideEffect',
      labelImage: '',
      value: 'The patient has been informed of the potential side effects the medication can give and instructed to contact us immediately if conditions get worse. \nPatient was instructed to contact the office or myself if any problems should arise.',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey: 'Potential Side Effects'
    },
    {
      type: 'subheader',
      label: 'Informed consent signed for use of Psychotropic medication ',
      className:'heading-form',
      showontoggle: true,
      editable: false,
    },

    //medication
    {
      type: 'textarea',
      placeholder: 'Please enter plan ',
      label: 'PLAN',
      required: true,
      key: 'followUp45_plan',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter valid text',
      validationValue: 'Please enter valid text',
      validators: [Validators.required,  Validators.minLength(1), this.valideTextArea],
      maxLength: 1000,
      count: '1000 remaining',
      showRange: true,
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Plan.Plan'
    },
    {
      type: 'textarea',
      placeholder: 'Please enter Co-ordination of care ',
      label: 'Co-ordination of care:',
      required: true,
      key: 'followUp45_coordinationOfCare',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter valid text',
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      maxLength: 1000,
      count: '1000 remaining',
      showRange: true,
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Plan.Coordination'
    },
    //duration of medication
    {
      type: 'text',
      placeholder: 'Please enter duration for medication',
      label: 'Duration of medications',
      required: true,
      key: 'followUp45_durationOfMedication',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter valid text',
      validationValue: 'Please enter valid text',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Plan.Duration'
    },



    {
      type: 'formArray',
      key: 'diagnosisMedications2',
      formInputs: [[
        {
          type: 'close-icon',
          showontoggle: true,
          showClose: true,
          editable: false
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: true,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          className: 'side__by__side placeholder_right',
          emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [Validators.required],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'text',
          placeholder: 'Dosage and Direction',
          label: 'Dosage and Direction',
          required: true,
          key: 'dosage',
          value: '',
          controlType: 'inputText',
          emptyValueMessage: 'Please add dosage and direction',
          validationValue: 'Please add valid dosage and direction',
          validators: [Validators.required],
          className: 'side__by__side placeholder_right',
          editable: true,
          showontoggle: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'MEDNOTES_FOLLOW_UP_FORTY_FIVE',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'diagnosisMedicationsAddNew',
      controlType: 'addnew',
      labeltext: 'Add Medications',
      formtag: 'MEDNOTES_FOLLOW_UP_FORTY_FIVE',
      editable: true,
      showontoggle: true,
    },


    {
      type: 'calendar',
      placeholder: 'Please enter date',
      label: 'Date',
      required: true,
      key: 'followUp45_Date',
      // isDob: false,
      labelImage: 'user',
      value: '',
      // isFutureDate: true,
      controlType: 'calendarEval',
      emptyValueMessage: 'Please select a valid date',
      validationValue: 'Please select valid date',
      validators: [Validators.required],
      editable: true,
      focus: true,
      showontoggle: true,
      className: 'side__by__side',
      editAccessKey: 'evaluationDate'
    },
    {
      type: 'dropdown',
      placeholder: 'Please select your primary insurance Provider',
      label: 'Primary Insurance',
      required: false,
      key: 'primaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey: 'primaryInsuranceProvider'
    },
    
  ]

  mednotesEvalConfig: any[] = [
    
    //evaluation date - 0
    {
      type: 'calendar',
      placeholder: 'Please enter date',
      label: 'Evaluation Date',
      required: true,
      key: 'evaluationDate',
      // isDob: true,
      labelImage: 'user',
      value: '',
      // isFutureDate: true,
      controlType: 'calendarEval',
      emptyValueMessage: 'Please select a valid date',
      validationValue: 'Please select valid date',
      validators: [Validators.required],
      editable: true,
      focus: true,
      showontoggle: true,
      className: 'side__by__side',
      editAccessKey: 'evaluationDate'
    },
    //location - 1
    {
      type: 'text',
      placeholder: 'Please enter location ',
      label: 'Location',
      required: true,
      key: 'locName',
      value: 'NEW STRESS CLINIC',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter location name',
      validationValue: 'Please enter valid location name',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey: 'location'
    },
    //patient's name - 2
    {
      type: 'text',
      placeholder: `Please enter patient's last name`,
      label: `Last Name`,
      required: true,
      key: 'lastName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: false,
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
      editAccessKey:'PatientInfo.lastName'
    },
    // 3
    {
      type: 'text',
      placeholder: `Please enter patient's first name`,
      label: `First Name`,
      required: true,
      key: 'firstName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: false,
      emptyValueMessage: 'Please enter first name',
      validationValue: 'Please enter valid first name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
      editAccessKey:'PatientInfo.firstName'
    },
    //4
    {
      type: 'text',
      placeholder: 'Please enter MI',
      label: 'MI',
      required: true,
      key: 'mi',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter MI',
      validationValue: 'Please enter valid MI',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey:'PatientInfo.MI'
    },
    //age - 5
    {
      type: 'number',
      placeholder: 'Please enter age ',
      label: 'Age',
      required: true,
      key: 'age',
      isDob: true,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: false,
      emptyValueMessage: 'Please enter age',
      validationValue: 'Please enter age',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      editAccessKey:'PatientInfo.age'
    },
    //dob - 6
    {
      type: 'text',
      placeholder: 'Please enter patient DOB',
      label: 'Date of Birth',
      required: true,
      key: 'dob',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient DOB',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey:'PatientInfo.dob'
    },
    //race - 7
    {
      type: 'text',
      placeholder: 'Please enter race',
      label: 'Race',
      required: true,
      key: 'race',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter race',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'PatientInfo.race'
    },
    //gender - 8
    {
      type: 'text',
      placeholder: `Please enter patient's gender`,
      label: 'Sex',
      required: true,
      key: 'Gender',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient gender',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey:'PatientInfo.sex'
    },
    {
      type: 'dropdown',
      placeholder: 'Please select the diagnosis',
      label: 'Diagnosis',
      required: false,
      key: 'Diagnosis',
      labelImage: '',
      value: '',
      list: this.DiagnosisList,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select the diagnosis',
      validationValue: 'Please enter valid diagnosis',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'Diagnosis'
    },
    //ICD-10 CODE - 9
    {
      type: 'text',
      placeholder: 'Please enter Mednote code',
      label: 'ICD-10 CODE',
      required: true,
      key: 'mednote_Code',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter Mednote code ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Code'
    },
    //employer - 10
    {
      type: 'text',
      placeholder: 'Please enter employer details',
      label: 'Employer',
      required: true,
      key: 'employer',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter employer details',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'PatientInfo.Employer'
    },
    //education
    {
      type: 'text',
      placeholder: 'Please enter education details',
      label: 'Education',
      required: true,
      key: 'education',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter education details',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'PatientInfo.Education'
    },
    //Sibling
    {
      type: 'text',
      placeholder: 'Please enter education details',
      label: 'Sibling',
      required: true,
      key: 'sibling',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter sibling details',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'sibling'
    },
    //Reasons to visit
    {
      type: 'header',
      label: 'Reasons to Visit? ' ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Initial Psychiatric Evaluation',
      controlType: 'checkbox-tik',
      key: 'psychiatricEvaluation',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Initial Psychiatric Evaluation'
    },
    {
      type: 'checkbox',
      labeltext: 'Medication Review',
      labelImage: '',
      required: false,
      key: 'medicationReview',
      value: false,
      focus: true,
      editable: true,
      selected: false,
      controlType: 'checkbox-tik',
      className: 'checkbox-column-right',
      validators: [this.validateCheckBox],
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Medication Review'
    },
    {
      type: 'checkbox',
      labeltext: 'Diagnostic Assessment',
      labelImage: '',
      required: false,
      key: 'diagnosticAssessment',
      value: false,
      focus: true,
      editable: true,
      selected: false,
      controlType: 'checkbox-tik',
      className: 'checkbox-column-left',
      validators: [this.validateCheckBox],
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Diagnostic Assessment'
    },
    {
      type: 'checkbox',
      labeltext: 'Chart Review',
      controlType: 'checkbox-tik',
      key: 'chartReview',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Chart Review'
    },
    {
      type: 'checkbox',
      labeltext: 'Family Interview',
      controlType: 'checkbox-tik',
      key: 'familyInterview',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Family Interview',
    },
    {
      type: 'checkbox',
      labeltext: 'Hospital Record Review ',
      controlType: 'checkbox-tik',
      key: 'hospitalRecordReview',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Hospital Record Review'
    },
    {
      type: 'checkbox',
      labeltext: 'Lab Review',
      controlType: 'checkbox-tik',
      key: 'labReview',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Lab Review'
    },
    {
      type: 'checkbox',
      labeltext: 'Annual Psychiatric Evaluation',
      controlType: 'checkbox-tik',
      key: 'annualPsychiatricEvaluation',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'ReasonForVisit.Annual Psychiatric Evaluation'
    },
    //ChiefComplain -20
    {
      type: 'textarea',
      placeholder: 'Please enter chief complain',
      label: 'Chief Complaint',
      required: true,
      key: 'chiefComplain',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter chief complain',
      validationValue: 'Please enter valid text',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      showontoggle: true,
      showRange: true,
      editAccessKey:'chiefComplaint'
    },
    //Information Request Questions
    {
      type: 'header',
      label: `Information Request Questions` ,
      showontoggle: true,
      editable: false,
    },
    // 
    {
      type: 'formArray',
      key: 'informationRequestQuestions',
      formInputs: [],
      controlType: 'formArray',
      formtag: 'MEDNOTES_EVAL',
      selected: false,
      editable: true,
      showontoggle: true,
      editAccessKey:'ChiefComplaintDescription.InformationResquestForm.questions'
    },
    // Doctor'sNotes
    {
      type: 'textarea',
      placeholder: `Please enter doctor's notes`,
      label: `Doctor's Note `,
      required: true,
      key: 'DoctorNote',
      labelImage: '',
      value: '',
      focus: false,
      controlType: 'textarea',
      editable: true,
      maxLength: 10000,
      emptyValueMessage: `Please enter doctor's notes details`,
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '10000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey:'ChiefComplaintDescription.DoctorNotes'
    },
    {
      type: 'header',
      label: 'PSYCHIATRIC EVALUATION',
      showontoggle: true,
      editable: false,
    },
    //Purpose of visit
    {
      type: 'textarea',
      placeholder: 'Please enter the purpose of visit',
      label: 'Purpose Of Visit',
      required: true,
      key: 'purposeOfVisit',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      count: '1000 remaining',
      emptyValueMessage: 'Please enter the purpose of visit',
      validationValue: 'Please enter valid purpose of visit',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      focus: false,
      editable: true,
      showontoggle: true,
      showRange: true,
      editAccessKey: 'PurposeOfVisit'
    },
    
    //observations
    {
      type: 'subheader',
      label: 'Observations ',
      showontoggle: true,
      editable: false,
    },
    //heigth
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: `Patient's Height`,
      required: false,
      key: 'heightFt',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [ this.validateHeightFt],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Ft',
      editAccessKey:'Observations.heightFT'
    },
    {
      type: 'number',
      placeholder: 'Enter patient height',
      label: '',
      required: false,
      key: 'heightInch',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [ this.validateHeightInches],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      noLabel: true,
      placeholderVal: 'Inch',
      editAccessKey:'Observations.heightInch'
    },
    //weight
    {
      type: 'number',
      placeholder: 'Enter patient weight',
      label: `Patient's Weight`,
      required: true,
      key: 'weight',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter weight',
      validationValue: 'Please enter valid weight',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'lbs',
      editAccessKey:'Observations.weight',
    },
    //blood pressure
    {
      type: 'text',
      placeholder: 'Enter patient blood pressure',
      label: `Patient's BP`,
      required: false,
      key: 'bloodPressure',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter blood pressure',
      validationValue: 'Please enter valid blood pressure',
      validators: [this.validateBloodPressure],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'mmHg',
      editAccessKey:'Observations.BP'
    },
    //pulse
    {
      type: 'number',
      placeholder: `Enter patient's pulse rate`,
      label: `Patient's Pulse`,
      required: false,
      key: 'pulseEval',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter pulse rate',
      validationValue: 'Please enter pulse rate',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'bpm',
      editAccessKey:'Observations.Pulse'
    },
    //alertness
    {
      type: 'text',
      placeholder: `Palease enter patient's alertness`,
      label: `Alertness`,
      required: true,
      key: 'alertness_obs',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter alertness observation',
      validationValue: 'Please enter valid text',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Observations.Alertness'
    },   
    //ambulatory
    {
      type: 'text',
      placeholder: `Palease enter patient's ambulatory`,
      label: `Ambulatory`,
      required: true,
      key: 'ambulatory_obs',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter ambulatory observation',
      validationValue: 'Please enter valid text',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Observations.Ambulatory',
    }, 
    //mood
    {
      type: 'text',
      placeholder: `Palease enter patient's mood`,
      label: `Mood`,
      required: true,
      key: 'mood_obs',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter alertness mood',
      validationValue: 'Please enter valid text',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Observations.mood'
    }, 
    //add observations
    {
      type: 'textarea',
      placeholder: 'Enter any additional observations/findings',
      label: 'Additional Observations',
      required: true,
      key: 'addObservations',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      count: '1000 remaining',
      emptyValueMessage: 'Please enter observations/findings',
      validationValue: 'Please enter valid observations/findings',
      validators: [ Validators.minLength(1), this.valideTextArea],
      focus: false,
      editable: true,
      showontoggle: true,
      showRange: true,
      editAccessKey: 'Observations.addObservations'
    },
    
    {
      type: 'header',
      label: 'SYMPTOMS',
      showontoggle: true,
      editable: false,
    },
    //two 
    //here DS -> Depressive Symptoms, MS -> Manic Symptoms, PS -> Psychotic Symptoms, PTSD -> PTSD Symptoms, AS -> Anxiety Symptoms, ADS -> Attention Deficit Symptoms
    {
      type: 'subheader',
      label: `Depressive Symptoms:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'None',
      controlType: 'checkbox-tik',
      key: 'none_DS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.None',
    },
    {
      type: 'checkbox',
      labeltext: 'Sleep Increase or Decrease',
      controlType: 'checkbox-tik',
      key: 'sleep_DS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Sleep Increase or Decrease'
    },
    {
      type: 'checkbox',
      labeltext: 'Depressed Mood',
      controlType: 'checkbox-tik',
      key: 'depressedMood_DS',
      required: true,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Depressed Mood'
    },
    {
      type: 'checkbox',
      labeltext: 'Decreased Concentration',
      controlType: 'checkbox-tik',
      key: 'decreasedConcentration_DS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Decreased Concentration'
    },
    {
      type: 'checkbox',
      labeltext: 'Decreased memory',
      controlType: 'checkbox-tik',
      key: 'decreasedMemory_DS',
      required: true,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Decreased Memory'
    },
    {
      type: 'checkbox',
      labeltext: 'Anhedonia',
      controlType: 'checkbox-tik',
      key: 'anhedonia_DS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Anhedonia'
    },
    {
      type: 'checkbox',
      labeltext: 'Hopelessness',
      controlType: 'checkbox-tik',
      key: 'hopelessness_DS',
      required: true,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Hopelessness'
    },
    {
      type: 'checkbox',
      labeltext: 'Suicidal Plan',
      controlType: 'checkbox-tik',
      key: 'suicidal_DS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Sucidal Plan'
    },
    {
      type: 'checkbox',
      labeltext: 'Ability to Carry-out Plan',
      controlType: 'checkbox-tik',
      key: 'carryOutplan_DS',
      required: true,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Ability to Carry-out Plan'
    },
    {
      type: 'checkbox',
      labeltext: 'Guilt',
      controlType: 'checkbox-tik',
      key: 'guilt_DS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Guilt'
    },
    {
      type: 'checkbox',
      labeltext: 'Suicidal Ideation or Thoughts of Death',
      controlType: 'checkbox-tik',
      key: 'suicidalIdea_DS',
      required: true,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Sucidal Ideation or Thoughts of Death'
    },
    {
      type: 'checkbox',
      labeltext: 'Psychomotor Agitation/Retardation',
      controlType: 'checkbox-tik',
      key: 'psychomotor_DS',
      required: true,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Psychomotor Agitation/Retardation'
    },
    {
      type: 'checkbox',
      labeltext: 'Appetite Increase or Decrease',
      controlType: 'checkbox-tik',
      key: 'appetite_DS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Appetite Increase or Decrease'
    },
    {
      type: 'checkbox',
      labeltext: 'Suicidal Gesture ',
      controlType: 'checkbox-tik',
      key: 'suicidalGesture_DS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.Suicidal Gesture'
    },
    {
      type: 'checkbox',
      labeltext: '> 2 Weeks Duration',
      controlType: 'checkbox-tik',
      key: 'twoWeeks_DS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Depressive Symptoms.> 2 Weeks Duration',
    },

    {
      type: 'subheader',
      label: `Manic Symptoms:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'None',
      controlType: 'checkbox-tik',
      key: 'none_MS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.None'
    },
    {
      type: 'checkbox',
      labeltext: 'Decreased Sleep and/or Need for Sleep',
      controlType: 'checkbox-tik',
      key: 'needOfSleep_MS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Decreased and/or Need for sleep'
    },
    {
      type: 'checkbox',
      labeltext: 'Inflated Self Esteem and/or Grandiosity',
      controlType: 'checkbox-tik',
      key: 'selfEsteem_MS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Inflated Self Esteem and/or Grandiosity'
    },
    {
      type: 'checkbox',
      labeltext: 'Flight of Ideas or Racing Thoughts',
      controlType: 'checkbox-tik',
      key: 'flightOfIdeas_MS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Flight of Ideas or racing Thoughts'
    },
    {
      type: 'checkbox',
      labeltext: 'Pressured speech',
      controlType: 'checkbox-tik',
      key: 'speech_MS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Pressured speech'
    },
    {
      type: 'checkbox',
      labeltext: 'Increased Goal Directed Activity',
      controlType: 'checkbox-tik',
      key: 'increasedGoal_MS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Increased Goal Directed Activity'
    },
    {
      type: 'checkbox',
      labeltext: 'Excessive Pleasurable Activity with Painful Consequences ',
      controlType: 'checkbox-tik',
      key: 'pleasurableActivity_MS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Excessive Pleasurable Activity with Painful Consequences'
    },
    {
      type: 'checkbox',
      labeltext: 'Elevated or Expansive Mood',
      controlType: 'checkbox-tik',
      key: 'elevatedMood_MS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Elevated or Expansive Mood'
    },
    {
      type: 'checkbox',
      labeltext: 'Distractibility ',
      controlType: 'checkbox-tik',
      key: 'distractibility_MS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Distractibility'
    },
    {
      type: 'checkbox',
      labeltext: 'Irritable Mood',
      controlType: 'checkbox-tik',
      key: 'irritableMood_MS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Irritable Mood'
    },
    {
      type: 'checkbox',
      labeltext: 'Severe Decrease in Functioning',
      controlType: 'checkbox-tik',
      key: 'Functioning_MS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.Severe Decrease in Functioning'
    },
    {
      type: 'checkbox',
      labeltext: '> 7 Days Duration',
      controlType: 'checkbox-tik',
      key: 'sevenDays_MS',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Manic Symptoms.> 7 Days Duration'
    },

    {
      type: 'subheader',
      label: `Psychotic Symptoms:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'None',
      controlType: 'checkbox-tik',
      key: 'none_PS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.None'
    },
    {
      type: 'checkbox',
      labeltext: 'Negative Symptoms',
      controlType: 'checkbox-tik',
      key: 'negativeSymptoms_PS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.Negative Symptoms'
    },
    {
      type: 'checkbox',
      labeltext: 'Delusions: Bizarre (Persecutory, Grandiose Ideas of Reference, Thought Insertion) ',
      controlType: 'checkbox-tik',
      key: 'delusionsBizarre_PS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.Delusions: bizarre'
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized Speech',
      controlType: 'checkbox-tik',
      key: 'disorganizedSpeech_PS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:"symptoms.Psychotic Symptoms.Disorganized Speech"
    },
    {
      type: 'checkbox',
      labeltext: 'Hallucinations: Auditory, Visual, Olfactory, Gustatory',
      controlType: 'checkbox-tik',
      key: 'hallucinations_PS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.Hallucinations: Auditory, Visual, Olfactory, Command, Tactile'
    },
    {
      type: 'checkbox',
      labeltext: 'Delusions: Non-Bizarre (Erotomanic, Jealous, Persecutory, Somatic)',
      controlType: 'checkbox-tik',
      key: 'delusionsNonBizarre_PS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.Delusions: nonBizarre'
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized Behavior (Grossly)',
      controlType: 'checkbox-tik',
      key: 'disorganizedBehavior_PS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.Disorganized Behavior'
    },
    {
      type: 'checkbox',
      labeltext: 'Thought Disorder',
      controlType: 'checkbox-tik',
      key: 'thoughtDisorder_PS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.Thought Disorder'
    },
    {
      type: 'checkbox',
      labeltext: '> 30 Days Duration',
      controlType: 'checkbox-tik',
      key: 'thirtyDays_PS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.> 30 Days Duration'
    },
    {
      type: 'checkbox',
      labeltext: '> 1 Month and < 6 Months Duration   ',
      controlType: 'checkbox-tik',
      key: 'oneMonthandSixMonth_PS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.> 1 Month and < 6 Months Duration'
    },
    {
      type: 'checkbox',
      labeltext: '> 6 Months Duration',
      controlType: 'checkbox-tik',
      key: 'sixMonths_PS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Psychotic Symptoms.> 6 Months Duration'
    },

    {
      type: 'subheader',
      label: `PTSD Symptoms:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'None',
      controlType: 'checkbox-tik',
      key: 'none_PTSD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.None'
    },
    {
      type: 'checkbox',
      labeltext: 'Nightmares',
      controlType: 'checkbox-tik',
      key: 'nightmares_PTSD',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Nightmares'
    },
    {
      type: 'checkbox',
      labeltext: 'Hyper vigilance',
      controlType: 'checkbox-tik',
      key: 'hyperVigilance_PTSD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Hyper Vigilance'
    },
    {
      type: 'checkbox',
      labeltext: 'Avoidance Stimuli ',
      controlType: 'checkbox-tik',
      key: 'avoidanceStimuli_PTSD',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Avoidance Stimuli'
    },
    {
      type: 'checkbox',
      labeltext: 'Flashbacks',
      controlType: 'checkbox-tik',
      key: 'flashbacks_PTSD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Flashbacks'
    },
    {
      type: 'checkbox',
      labeltext: 'Exaggerated startle response ',
      controlType: 'checkbox-tik',
      key: 'exaggeratedStartleResponse_PTSD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Exaggerated startle response'
    },
    {
      type: 'checkbox',
      labeltext: 'Feelings of detachment ',
      controlType: 'checkbox-tik',
      key: 'feelingsOfDetachment_PTSD',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Feelings of Detachment'
    },
    {
      type: 'checkbox',
      labeltext: 'Poor impulse control',
      controlType: 'checkbox-tik',
      key: 'poorImpulseControl_PTSD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Poor Impulse Control'
    },
    {
      type: 'checkbox',
      labeltext: 'Feelings of aggression ',
      controlType: 'checkbox-tik',
      key: 'feelingsOfAggression_PTSD',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.PTSD Symptoms.Feelings of Aggression'
    },

    {
      type: 'subheader',
      label: `Anxiety Symptoms:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'None',
      controlType: 'checkbox-tik',
      key: 'none_AS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Anxiety Symptoms.None'
    },
    {
      type: 'checkbox',
      labeltext: 'Palpitations',
      controlType: 'checkbox-tik',
      key: 'palpitations_AS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Anxiety Symptoms.Palpitations'
    },
    {
      type: 'checkbox',
      labeltext: 'Sweating',
      controlType: 'checkbox-tik',
      key: 'sweating_AS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Anxiety Symptoms.Sweating'
    },
    {
      type: 'checkbox',
      labeltext: 'Excessive worrying',
      controlType: 'checkbox-tik',
      key: 'excessiveWorrying_AS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Anxiety Symptoms.Excessive Worrying'
    },
    {
      type: 'checkbox',
      labeltext: 'Muscle tension or muscle aches',
      controlType: 'checkbox-tik',
      key: 'muscleTension_AS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Anxiety Symptoms.Muscle tension or muscle aches'
    },
    {
      type: 'checkbox',
      labeltext: 'Chest Pain or Discomfort',
      controlType: 'checkbox-tik',
      key: 'chestPain_AS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Anxiety Symptoms.Chest Pain or Discomfort'
    },
    {
      type: 'checkbox',
      labeltext: 'Fear of Dying ',
      controlType: 'checkbox-tik',
      key: 'fearOfDying_AS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Anxiety Symptoms.Fear of Dying'
    },

    {
      type: 'subheader',
      label: `Attention Deficit Symptoms` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'None',
      controlType: 'checkbox-tik',
      key: 'none_ADS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.None'
    },
    {
      type: 'checkbox',
      labeltext: 'Failure to pay close attention',
      controlType: 'checkbox-tik',
      key: 'failureToPayCloseAttention_ADS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Failure to pay close attention'
    },
    {
      type: 'checkbox',
      labeltext: 'Makes careless mistakes often',
      controlType: 'checkbox-tik',
      key: 'makesCarelessMistakesOften_ADS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Makes careless mistakes often'
    },
    {
      type: 'checkbox',
      labeltext: 'Easily Distracted',
      controlType: 'checkbox-tik',
      key: 'easilyDistracted_ADS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Easily Distracted'
    },
    {
      type: 'checkbox',
      labeltext: 'Difficulty sustaining attention in tasks',
      controlType: 'checkbox-tik',
      key: 'difficultySustainingAttentionInTasks_ADS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Difficulty sustaining attention in tasks'
    },
    {
      type: 'checkbox',
      labeltext: 'Fidgets/Squirm ',
      controlType: 'checkbox-tik',
      key: 'fidgets_ADS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Fidgets/Squirm'
    },
    {
      type: 'checkbox',
      labeltext: 'Forgetful of daily activities',
      controlType: 'checkbox-tik',
      key: 'forgetfulOfDailyActivities_ADS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Forgetful of daily activities'
    },
    {
      type: 'checkbox',
      labeltext: 'Procrastination/Last Minute starts on tasks',
      controlType: 'checkbox-tik',
      key: 'procrastination_ADS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Procrastination/Last Minute starts on tasks'
    },
    {
      type: 'checkbox',
      labeltext: 'Excessive talking ',
      controlType: 'checkbox-tik',
      key: 'excessiveTalking_ADS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Excessive talking'
    },
    {
      type: 'checkbox',
      labeltext: 'Demanding',
      controlType: 'checkbox-tik',
      key: 'demanding_ADS',
      required: true,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Demanding'
    },
    {
      type: 'checkbox',
      labeltext: 'Frustration',
      controlType: 'checkbox-tik',
      key: 'frustration_ADS',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'symptoms.Attention Deficit Symptoms.Frustration'
    },

    //three
    {
      type: 'header',
      label: `MENTAL STATUS EXAM` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: 'Appearance: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Well Groomed',
      controlType: 'checkbox-tik',
      key: 'wellGroomed_Appearance',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Appearance.Well Groomed'
    },
    {
      type: 'checkbox',
      labeltext: 'Neat/Clean',
      controlType: 'checkbox-tik',
      key: 'Neat_Appearance',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Appearance.Clean'
    },
    {
      type: 'checkbox',
      labeltext: 'Disheveled',
      controlType: 'checkbox-tik',
      key: 'disheveled_Appearance',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Appearance.Disheveled'
    },
    {
      type: 'checkbox',
      labeltext: 'Poor Hygiene',
      controlType: 'checkbox-tik',
      key: 'poorHygiene_Appearance',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Appearance.Poor Hygiene'
    },
    {
      type: 'checkbox',
      labeltext: 'Other',
      controlType: 'checkbox-tik',
      key: 'other_Appearance',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Appearance.Other'
    },

    {
      type: 'subheader',
      label: 'Orientation: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Person',
      controlType: 'checkbox-tik',
      key: 'person_Orientation',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Orientation.Person'
    },
    {
      type: 'checkbox',
      labeltext: 'Place',
      controlType: 'checkbox-tik',
      key: 'place_Orientation',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Orientation.Place'
    },
    {
      type: 'checkbox',
      labeltext: 'Time',
      controlType: 'checkbox-tik',
      key: 'time_Orientation',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Orientation.Time'
    },
    {
      type: 'checkbox',
      labeltext: 'Purpose',
      controlType: 'checkbox-tik',
      key: 'purpose_Orientation',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Orientation.Purpose'
    },

    {
      type: 'subheader',
      label: 'Speech: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Normal',
      controlType: 'checkbox-tik',
      key: 'normal_Speech',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Speech.Normal'
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized',
      controlType: 'checkbox-tik',
      key: 'disorganized_Speech',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Speech.Disorganized'
    },
    {
      type: 'checkbox',
      labeltext: 'Pressured',
      controlType: 'checkbox-tik',
      key: 'pressured_Speech',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Speech.Pressured'
    },
    {
      type: 'checkbox',
      labeltext: 'Slurred',
      controlType: 'checkbox-tik',
      key: 'slurred_Speech',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Speech.Slurred'
    },

    {
      type: 'subheader',
      label: 'Attitude: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Cooperative',
      controlType: 'checkbox-tik',
      key: 'cooperative_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Attitude.Cooperative'
    },
    {
      type: 'checkbox',
      labeltext: 'Uncooperative',
      controlType: 'checkbox-tik',
      key: 'uncooperative_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Attitude.Uncooperative'
    },
    {
      type: 'checkbox',
      labeltext: 'Guarded',
      controlType: 'checkbox-tik',
      key: 'guarded_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Attitude.Guarded'
    },
    {
      type: 'checkbox',
      labeltext: 'Friendly',
      controlType: 'checkbox-tik',
      key: 'friendly_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Attitude.Friendly'
    },
    {
      type: 'checkbox',
      labeltext: 'Threatening',
      controlType: 'checkbox-tik',
      key: 'threatening_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Attitude.Threatening'
    },
    {
      type: 'checkbox',
      labeltext: 'Angry',
      controlType: 'checkbox-tik',
      key: 'angry_Attitude',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Attitude.Angry'
    },


    {
      type: 'subheader',
      label: 'Psychomotor Activity: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Ambulatory',
      controlType: 'checkbox-tik',
      key: 'ambulatory_PA',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Psychomotor Activity.Ambulatory'
    },
    {
      type: 'checkbox',
      labeltext: 'Wheel Chair',
      controlType: 'checkbox-tik',
      key: 'wheelChair_PA',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Psychomotor Activity.WheelChair'
    },
    {
      type: 'checkbox',
      labeltext: 'Bed Ridden',
      controlType: 'checkbox-tik',
      key: 'bedRidden_PA',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Psychomotor Activity.BedRidden'
    },

    {
      type: 'subheader',
      label: 'Mood: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Euthymic ',
      controlType: 'checkbox-tik',
      key: 'euthymic_Mood',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Mood.Euthymic'
    },
    {
      type: 'checkbox',
      labeltext: 'Euphoric',
      controlType: 'checkbox-tik',
      key: 'euphoric_Mood',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Mood.Euphoric'
    },
    {
      type: 'checkbox',
      labeltext: 'Dysphoric',
      controlType: 'checkbox-tik',
      key: 'dysphoric_Mood',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Mood.Dysphoric'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxious',
      controlType: 'checkbox-tik',
      key: 'anxious_Mood',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Mood.Anxious'
    },
    {
      type: 'checkbox',
      labeltext: 'Angry',
      controlType: 'checkbox-tik',
      key: 'angry_Mood',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Mood.Angry'
    },
    {
      type: 'checkbox',
      labeltext: 'Irritable',
      controlType: 'checkbox-tik',
      key: 'irritable_Mood',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Mood.Irritable'
    },
    {
      type: 'checkbox',
      labeltext: 'Depressed',
      controlType: 'checkbox-tik',
      key: 'depressed_Mood',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Mood.Depressed'
    },

    {
      type: 'subheader',
      label: 'Affect: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Full ',
      controlType: 'checkbox-tik',
      key: 'full_Affect',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Affect.Full'
    },
    {
      type: 'checkbox',
      labeltext: 'Constricted',
      controlType: 'checkbox-tik',
      key: 'constricted_Affect',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Affect.Constricted'
    },
    {
      type: 'checkbox',
      labeltext: 'Labile',
      controlType: 'checkbox-tik',
      key: 'labile_Affect',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Affect.Labile'
    },
    {
      type: 'checkbox',
      labeltext: 'Blunted',
      controlType: 'checkbox-tik',
      key: 'blunted_Affect',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Affect.Blunted'
    },
    {
      type: 'checkbox',
      labeltext: 'Flat',
      controlType: 'checkbox-tik',
      key: 'flat_Affect',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Affect.Flat'
    },

    {
      type: 'subheader',
      label: 'Thought Process: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Goal Directed and Logical',
      controlType: 'checkbox-tik',
      key: 'goalDirectedAndLogical_TP',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Goal Directed and Logical'
    },
    {
      type: 'checkbox',
      labeltext: 'Appropriate',
      controlType: 'checkbox-tik',
      key: 'appropriate_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Appropriate'
    },
    {
      type: 'checkbox',
      labeltext: 'Tangential ',
      controlType: 'checkbox-tik',
      key: 'tangential_TP',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Tangential'
    },
    {
      type: 'checkbox',
      labeltext: 'Flight of Ideas',
      controlType: 'checkbox-tik',
      key: 'flightOfIdeas_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Flight of Ideas'
    },
    {
      type: 'checkbox',
      labeltext: 'Circumstantial',
      controlType: 'checkbox-tik',
      key: 'circumstantial_TP',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Circumstantial'
    },
    {
      type: 'checkbox',
      labeltext: 'Disorganized',
      controlType: 'checkbox-tik',
      key: 'disorganized_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Disorganized'
    },
    {
      type: 'checkbox',
      labeltext: 'Blocking',
      controlType: 'checkbox-tik',
      key: 'blocking_TP',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Blocking'
    },
    {
      type: 'checkbox',
      labeltext: 'Loose Associations',
      controlType: 'checkbox-tik',
      key: 'looseAssociations_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Loose Associations'
    },
    {
      type: 'checkbox',
      labeltext: 'Perseveration ',
      controlType: 'checkbox-tik',
      key: 'perseveration_TP',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Perseveration'
    },
    {
      type: 'checkbox',
      labeltext: 'Ideas of Reference',
      controlType: 'checkbox-tik',
      key: 'ideasOfReference_TP',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Ideas of Reference'
    },
    {
      type: 'checkbox',
      labeltext: 'Poverty of thought',
      controlType: 'checkbox-tik',
      key: 'povertyOfThought_TP',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Process.Poverty of Thought'
    },

    {
      type: 'subheader',
      label: 'Thought Content: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Appropriate',
      controlType: 'checkbox-tik',
      key: 'appropriate_TC',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Content.Appropriate'
    },
    {
      type: 'checkbox',
      labeltext: 'Homicidal Ideation ',
      controlType: 'checkbox-tik',
      key: 'homicidalIdeation_TC',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Content.Homicidal Ideation'
    },
    {
      type: 'checkbox',
      labeltext: 'Suicidal Ideation  ',
      controlType: 'checkbox-tik',
      key: 'suicidalIdeation_TC',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Content.Suicidal Ideation'
    },
    {
      type: 'checkbox',
      labeltext: 'Hallucinations: Auditory, Visual, Olfactory, Command, Tactile ',
      controlType: 'checkbox-tik',
      key: 'hallucination_TC',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Content.Hallucinations: Auditory, Visual, Olfactory, Command, Tactile'
    },
    {
      type: 'checkbox',
      labeltext: 'Persecutory Delusions',
      controlType: 'checkbox-tik',
      key: 'persecutoryDelusions_TC',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Content.Persecutory Delusions'
    },
    {
      type: 'checkbox',
      labeltext: 'Pre-occupation with illness',
      controlType: 'checkbox-tik',
      key: 'illness_TC',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Thought Content.PreOccupation With Illness'
    },


    //Short Term
    {
      type: 'subheader',
      label: 'Memory Session: ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'Short Term Memory: ',
      labelImage: '',
      required: false,
      key: "ShortTerm",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Intact',
          value: 'Intact',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Impaired',
          value: 'Impaired',
        },
      ],
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Short Term Memory'
    },
    //Long Term
    {
      type: 'radio',
      placeholder: '',
      label: 'Long Term Memory: ',
      labelImage: '',
      required: false,
      key: "LongTerm",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Intact',
          value: 'Intact',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Impaired',
          value: 'Impaired',
        },
      ],
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Long Term Memory'
    },

    //Insight
    {
      type: 'radio',
      placeholder: '',
      label: 'Insight: ',
      labelImage: '',
      required: false,
      key: "Insight",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Fair to Poor',
          value: 'Fair to Poor',
        },
        {
          label: 'Poor',
          value: 'Poor',
        },
      ],
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Insight'
    },
    //Judgement
    {
      type: 'radio',
      placeholder: '',
      label: 'Judgment: ',
      labelImage: '',
      required: false,
      key: "Judgment",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [Validators.required],
      fields: [
        {
          label: 'Good',
          value: 'Good',
        },
        {
          label: 'Fair',
          value: 'Fair',
        },
        {
          label: 'Fair to Poor',
          value: 'Fair to Poor',
        },
        {
          label: 'Poor',
          value: 'Poor',
        },
      ],
      showontoggle: true,
      editAccessKey:'MentalStatusExam.Judgment'
    },

    //allergies
    {
      type: 'textarea',
      placeholder: `Please enter patient's allergies`,
      label: 'ALLERGIES',
      required: true,
      key: 'allergies',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter allergies ',
      validationValue: 'Please enter valid observations/findings',
      validators: [Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'allergies'
    },
    //PAST PSYCHIATRIC Hx
    {
      type: 'textarea',
      placeholder: `Please enter patient's past psychiatrics`,
      label: 'PAST PSYCHIATRIC Hx',
      required: true,
      key: 'pastPsychiatric',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter past psychiatrics ',
      validationValue: 'Please enter valid text',
      validators: [Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'PastPsychiatricHx'
    },
    {
      type: 'text',
      placeholder: 'Please enter details of any emotional abuse',
      label: 'Emotional Abuse:',
      required: true,
      key: 'emotionalAbuse',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'emotionalAbuse'
    },
    {
      type: 'text',
      placeholder: 'Please enter details of physical abuse',
      label: 'Physical Abuse:',
      required: true,
      key: 'physicalAbuse',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'physicalAbuse'
    },
    {
      type: 'text',
      placeholder: 'Please enter details of suicide attempt',
      label: 'Suicide Attempt:',
      required: true,
      key: 'suicideAbuse',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'suicideAttempts'
    },

    {
      type: 'textarea',
      placeholder: `Please enter patient's past medical`,
      label: 'PAST MEDICAL Hx',
      required: true,
      key: 'pastMedical',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter past medical ',
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'pastMedicalHx'
    },
    {
      type: 'textarea',
      placeholder: `Please enter patient's medications`,
      label: 'PAST MEDICATIONS',
      required: true,
      key: 'medications',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter medications ',
      validationValue: 'Please enter valid text',
      validators: [Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'pastMedications'
    },
    {
      type: 'textarea',
      placeholder: `Please enter patient's past surg`,
      label: 'PAST SURG Hx',
      required: true,
      key: 'pastSurg',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'pastSurgHx'
    },
    {
      type: 'textarea',
      placeholder: `Please enter patient's substance abuse`,
      label: 'SUBSTANCE ABUSE Hx',
      required: true,
      key: 'substanceAbuse',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'substanceAbsueHx'
    },
    {
      type: 'textarea',
      placeholder: `Please enter patient's family psych`,
      label: 'FAMILY PSYCH Hx',
      required: true,
      key: 'familyPsych',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'familyHx'
    },
    {
      type: 'subheader',
      label: 'SOCIAL Hx ',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'text',
      placeholder: 'Please enter education detail',
      label: 'Education:',
      required: true,
      key: 'socalEducation',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Education_soicalHx'
    },
    {
      type: 'text',
      placeholder: 'Please enter siblings detail',
      label: 'Siblings:',
      required: true,
      key: 'socalSiblings',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Siblings'
    },
    {
      type: 'text',
      placeholder: 'Please enter Employment detail',
      label: 'Employed:',
      required: true,
      key: 'socalEmployed',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Employed'
    },
    {
      type: 'text',
      placeholder: 'Please enter Tobacco use detail',
      label: 'Tobacco use:',
      required: true,
      key: 'socalTobaccoUse',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'TobaccoUse'
    },

    {
      type: 'textarea',
      placeholder: `Please enter patient's Development Hx`,
      label: 'Development Hx',
      required: true,
      key: 'development',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'DevelopmentHx'
    },

    {
      type: 'textarea',
      placeholder: `Please enter patient's Military Hx`,
      label: 'Military Hx',
      required: true,
      key: 'military',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'MilitaryHx'
    },

    {
      type: 'textarea',
      placeholder: `Please enter patient's Legal Hx`,
      label: 'Legal Hx',
      required: true,
      key: 'legal',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 1000,
      emptyValueMessage: 'Please enter valid text ',
      validationValue: 'Please enter valid text',
      validators: [ Validators.minLength(1), this.valideTextArea],
      count: '1000 remaining',
      editable: true,
      focus: false,
      showontoggle: true,
      showRange: true,
      editAccessKey:'legalHx'
    },

    //five
    {
      type: 'header',
      label: `DIAGONSIS` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: `AXIS I` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'subheader',
      label: `Primary Diagnosis:` , //PD
      showontoggle: true,
      editable: false,
      showMessage: false,
      Message: 'Only one option can be selected in primary diagnosis'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxiety Disorder (F41.9)',
      controlType: 'checkbox-tik-one',
      key: 'Anxiety_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.Anxiety Disorder (F41.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'Autism Spectrum Disorder (F84.0)',
      controlType: 'checkbox-tik-one',
      key: 'Autism_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.Autism Spectrum Disorder (F84.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'EATING DISORDER (F50.9)',
      controlType: 'checkbox-tik-one',
      key: 'EatingDisorder_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.EATING DISORDER (F50.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'OCD (F42.9)',
      controlType: 'checkbox-tik-one',
      key: 'OCD_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.OCD (F42.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'BIPOLAR (F3181)',
      controlType: 'checkbox-tik-one',
      key: 'Bipolar_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.BI-POLAR (F31.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.2)',
      controlType: 'checkbox-tik-one',
      key: 'Depression_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.MDD (F33.2)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.9)',
      controlType: 'checkbox-tik-one',
      key: 'Depression_axisI_I',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.MDD (F33.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADHD (F90.0)',
      controlType: 'checkbox-tik-one',
      key: 'ADHD_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.ADHD (F90.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'ODD (F91.3)',
      controlType: 'checkbox-tik-one',
      key: 'OppositionalDisorder_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.ODD (F91.3)'
    },
    {
      type: 'checkbox',
      labeltext: 'Schizophrenia (F20.9)',
      controlType: 'checkbox-tik-one',
      key: 'Schizophrenia_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.Schizophrenia (F20.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'Adjustment Disorder (F43.23)',
      controlType: 'checkbox-tik-one',
      key: 'AdjustmentDisorder_axisI',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Primary",
      editAccessKey:'Axis_I.Adjustment Disorder (F43.23)'
    },
    {
      type: 'subheader',
      label: `Secondary Diagnosis:`, //SD
      showontoggle: true,
      editable: false,
      showMessage: false,
      Message: 'Only one option can be selected in secondary diagnosis'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxiety Disorder (F41.9)',
      controlType: 'checkbox-tik-one',
      key: 'Anxiety_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.Anxiety Disorder (F41.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'Autism Spectrum Disorder (F84.0)',
      controlType: 'checkbox-tik-one',
      key: 'Autism_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.Autism Spectrum Disorder (F84.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'EATING DISORDER (F50.9)',
      controlType: 'checkbox-tik-one',
      key: 'EatingDisorder_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.EATING DISORDER (F50.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'OCD (F42.9)',
      controlType: 'checkbox-tik-one',
      key: 'OCD_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.OCD (F42.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'BIPOLAR (F3181)',
      controlType: 'checkbox-tik-one',
      key: 'Bipolar_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.BI-POLAR (F31.81)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.2)',
      controlType: 'checkbox-tik-one',
      key: 'Depression_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.MDD (F33.2)'
    },
    {
      type: 'checkbox',
      labeltext: 'MDD (F33.9)',
      controlType: 'checkbox-tik-one',
      key: 'Depression_axisI_SD_I',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.MDD (F33.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'ADHD (F90.0)',
      controlType: 'checkbox-tik-one',
      key: 'ADHD_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.ADHD (F90.0)'
    },
    {
      type: 'checkbox',
      labeltext: 'ODD (F91.3)',
      controlType: 'checkbox-tik-one',
      key: 'OppositionalDisorder_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.ODD (F91.3)'
    },
    {
      type: 'checkbox',
      labeltext: 'Schizophrenia (F20.9)',
      controlType: 'checkbox-tik-one',
      key: 'Schizophrenia_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.Schizophrenia (F20.9)'
    },
    {
      type: 'checkbox',
      labeltext: 'Adjustment Disorder (F43.23)',
      controlType: 'checkbox-tik-one',
      key: 'AdjustmentDisorder_axisI_SD',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      axis: "Secondary",
      showValidationMsg: false,
      ValidationMsg: 'This options is selected in Primary Diagnosis',
      editAccessKey:'Axis_I_Secondary.Adjustment Disorder (F43.23)'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-II detail',
      label: 'AXIS II',
      required: true,
      key: 'axisTwo',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Axis_II'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-III detail',
      label: 'AXIS III',
      required: true,
      key: 'axisThree',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Axis_III'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-IV detail',
      label: 'AXIS IV',
      required: false,
      key: 'axisFour',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Axis_IV'
    },
    {
      type: 'text',
      placeholder: 'Please enter AXIS-V detail',
      label: 'AXIS V',
      required: false,
      key: 'axisFive',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter value ',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
    editAccessKey:'Axis_V'
    },

    {
      type: 'textarea',
      placeholder: 'Please enter plan',
      label: 'PLAN',
      required: true,
      key: 'plan',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter valid text',
      validationValue: 'Please enter valid text',
      validators: [Validators.required, Validators.minLength(1), this.valideTextArea],
      maxLength: 1000,
      count: '1000 remaining',
      showRange: true,
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey:'Plan'
    },

    {
      type: 'formArray',
      key: 'diagnosisMedications2',
      formInputs: [[
        {
          type: 'close-icon',
          showontoggle: true,
          showClose: true,
          editable: false
        },
        {
          type: 'text',
          placeholder: 'Medicine name',
          label: 'Medicine name',
          required: true,
          key: 'medicine',
          value: '',
          controlType: 'inputText',
          editable: true,
          className: 'side__by__side placeholder_right',
          emptyValueMessage: 'Please enter medicine name',
          validationValue: 'Please enter valid medicine name',
          validators: [Validators.required],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'text',
          placeholder: 'Dosage and Direction',
          label: 'Dosage and Direction',
          required: true,
          key: 'dosage',
          value: '',
          controlType: 'inputText',
          emptyValueMessage: 'Please add dosage and direction',
          validationValue: 'Please add valid dosage and direction',
          validators: [Validators.required],
          className: 'side__by__side placeholder_right',
          editable: true,
          showontoggle: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'MEDNOTES_EVAL',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'diagnosisMedicationsAddNew',
      controlType: 'addnew',
      labeltext: 'Add Medications',
      formtag: 'MEDNOTES_EVAL',
      editable: true,
      showontoggle: true,
    },

    {
      type: 'dropdown',
      placeholder: 'Please select your primary insurance Provider',
      label: 'Primary Insurance',
      required: false,
      key: 'primaryInsuranceProvider',
      labelImage: '',
      list: [],
      controlType: 'dropdown',
      className: 'insurance-valid-msg',
      editable: true,
      emptyValueMessage: 'Please select your primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey: 'primaryInsuranceProvider'
    } 
  ]
  //tmsNotes
  tmsNoteFormConfig: any [] = [
    { //date of appt
      type: 'calendar',
      placeholder: 'Please enter appointment date',
      label: 'Date of Appointment',
      required: true,
      key: 'AppointmnetDate',
      // isDob: false,
      labelImage: 'user',
      value: '',
      // isFutureDate: true,
      controlType: 'calendarEval',
      emptyValueMessage: 'Please select a valid date',
      validationValue: 'Please select valid date',
      validators: [Validators.required],
      editable: true,
      focus: true,
      showontoggle: true,
      className: 'side__by__side',
      // editAccessKey: 'apptDate'
    },
    {
      type: 'header',
      label: `Patient's Basic Information`,
      showontoggle: true,
      editable: false,
    },
    { //patient'd first name plus last name 
      type: 'text',
      placeholder: `Please enter patient's name`,
      label: `Patient's Name`,
      required: true,
      key: 'patientName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: false,
      emptyValueMessage: 'Please enter last name',
      validationValue: 'Please enter valid last name',
      validators: [Validators.required, this.validateName],
      focus: true,
      showontoggle: true,
      editAccessKey: 'PatientName',
    },
    { // patient's date of birth
      type: 'text',
      placeholder: 'Please enter dob',
      label: 'Date of Birth',
      required: true,
      key: 'dob',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient DOB',
      validators: [Validators.required],
      focus: true,
      editable: false,
      showontoggle: true,
      editAccessKey: 'patientDob',
    }, 
    { // age
      type: 'number',
      placeholder: 'Please enter age ',
      label: 'Age',
      required: true,
      key: 'age',
      isDob: true,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: false,
      emptyValueMessage: 'Please enter age',
      validationValue: 'Please enter age',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      editAccessKey:'patientAge'
    },
    { // gender
      type: 'text',
      placeholder: 'Please enter gender ',
      label: 'Gender',
      required: true,
      key: 'patientGender',
      isDob: true,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: false,
      emptyValueMessage: 'Please enter gender',
      validationValue: 'Please enter gender',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey:'patientGender'
    },

    { // ssn id
      type: 'text',
      placeholder: 'Please enter Social Security Number',
      label: 'Social Security Number',
      required: true,
      key: 'socialSecurityNumber',
      isDob: true,
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: false,
      emptyValueMessage: 'Please enter patient id',
      validationValue: 'Please enter patient id',
      validators: [],
      focus: true,
      showontoggle: true,
      // editAccessKey:''
    },
    { //Address
      type: 'textarea',
      placeholder: 'Please enter address',
      label: `Patient's Address`,
      // subtitle: '*',
      required: false,
      key: 'Address',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter address',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: false,
      maxLength: 150,
      // count: '150 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey:'address'
    },
    { //Phone number
      type: 'number',
      mask: '(999) 999-9999',
      // subtitle: '*',
      placeholder: '(XXX) XXX-XXXX',
      label: `Patient's Phone Number`,
      required: true,
      key: 'phoneNumber',
      value: '',
      editable: false,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
      editAccessKey:'phoneNumber'
    },
    { // Primary Insurance Name
      type: 'text',
      placeholder: 'Please enter primary insurance provider',
      label: 'Primary Insurance Provider',
      required: true,
      key: 'primaryHolderText',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter primary insurance provider',
      validationValue: 'Please enter valid primary insurance provider',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey:'primaryInsuranceName'
    },
    { // Primary Insurance ID
      type: 'text',
      placeholder: 'Primary Insurance Member ',
      label: 'Primary Insurance Member Id ',
      required: true,
      key: 'primaryInsuranceId',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter primary insurance member id',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey:'primaryInsuranceMemberId'
    },
    { //Allergies
      type: 'textarea',
      placeholder: 'Please enter list any known allergies',
      label: 'Known allergies',
      // subtitle: '*',
      required: false,
      key: 'knownAllergies',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 1000,
      count: '1000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey:'knownAllergies'
    },
    //Diagnosis
    {
      type: 'subheader',
      label: `Diagnosis:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'checkbox',
      labeltext: 'Depression',
      controlType: 'checkbox-tik',
      key: 'Depression',
      required: false,
      value: false,
      className: 'checkbox-column-right',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'Depression'
    },
    {
      type: 'checkbox',
      labeltext: 'Anxiety',
      controlType: 'checkbox-tik',
      key: 'Anxiety',
      required: false,
      value: false,
      className: 'checkbox-column-left',
      validators: [ this.validateCheckBox],
      focus: true,
      editable: true,
      selected: false,
      showontoggle: true,
      editAccessKey:'Anxiety'
    },
    
    { // type of appointment
      type: 'dropdown',
      placeholder: 'Please select type of appointment',
      label: 'Type of appointment',
      required: false,
      key: 'tmsType',
      labelImage: '',
      value: '',
      list: this.AppointmentTypes,
      controlType: 'dropdown',
      emptyValueMessage: 'Please select the appointment type',
      validationValue: 'Please enter valid appointment',
      validators: [Validators.required],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'AppointmentType'
    },
    {
      type: 'header',
      label: `Physician's Information`,
      showontoggle: true,
      editable: false,
    },
    { // Name of Physician/Consultant
      type: 'text',
      placeholder: 'Please enter Name of Physician/Consultant',
      label: 'Name of Physician/Consultant',
      required: true,
      key: 'PhysicianName',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient Name of Physician/Consultant',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'PhysicianName'
    }, 
    { //npi
      type: 'text',
      placeholder: 'Please enter Name of Physician/Consultant',
      label: 'NPI',
      required: true,
      key: 'npi',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      emptyValueMessage: 'Please enter patient Name of Physician/Consultant',
      validators: [],
      focus: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'NPI'
    }, 
    { //Address
      type: 'textarea',
      placeholder: 'Please enter address',
      label: `Clinic's Address`,
      // subtitle: '*',
      required: false,
      key: 'addressPhysician',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please enter address',
      validationValue: 'Please enter address',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: false,
      maxLength: 150,
      // count: '150 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: 'PhysicianAddress'
    },
    { //phone number
      type: 'number',
      mask: '(999) 999-9999',
      placeholder: '(XXX) XXX-XXXX',
      label: `Clinic's Phone Number`,
      required: true,
      key: 'phoneNumbePhysician',
      value: '',
      editable: false,
      controlType: 'p-Input',
      emptyValueMessage: 'Please enter phone number',
      validationValue: 'Please enter valid phone number',
      validators: [],
      updateOn: 'blur',
      digitOnly: 10,
      showontoggle: true,
      editAccessKey:'PhysicianPhoneNumber'
    },
    {
      type: 'header',
      label: `TMS THERAPY INITIAL VISIT` ,
      showontoggle: true,
      editable: false,
      key: 'tmsTherapyVisit'
    },
    { //Purpose Of Visits
      type: 'textarea',
      placeholder: `Please enter purpose of visit `,
      label: 'Purpose of Visit',
      // subtitle: '*',
      required: false,
      key: 'purposeOfVisit',
      value: '',
      controlType: 'textarea',
      emptyValueMessage: 'Please add description',
      validationValue: 'Please add valid description',
      validators: [this.valideTextArea],
      className: 'remaining-number',
      editable: true,
      maxLength: 10000,
      count: '10000 remaining',
      showontoggle: true,
      showRange: true,
      editAccessKey: 'purposeOfVisit'
    },
    {
      type: 'header',
      label: 'Observations ',
      showontoggle: true,
      editable: false,
    },
    //height
    {
      type: 'number',
      placeholder: 'Enter height',
      label: `Patient's Height`,
      required: false,
      key: 'heightFt',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [ this.validateHeightFt],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Ft',
    },
    {
      type: 'number',
      placeholder: 'Enter height',
      label: '',
      required: false,
      key: 'heightInch',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter height',
      validationValue: 'Please enter valid height',
      validators: [ this.validateHeightInches],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      noLabel: true,
      placeholderVal: 'Inch',
    },
    //weight
    {
      type: 'number',
      placeholder: 'Enter weight',
      label: `Patient's Weight`,
      required: true,
      key: 'weight',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter weight',
      validationValue: 'Please enter valid weight',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'lbs',
      editAccessKey:'Observations.weight',
    },
    //blood pressure
    {
      type: 'text',
      placeholder: 'Enter blood pressure',
      label: `Patient's BP`,
      required: false,
      key: 'bloodPressure',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter blood pressure',
      validationValue: 'Please enter valid blood pressure',
      validators: [this.validateBloodPressure],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'mmHg',
      editAccessKey:'Observations.BP'
    },
    //pulse
    {
      type: 'number',
      placeholder: `Enter pulse rate`,
      label: `Patient's Pulse`,
      required: false,
      key: 'pulse',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter pulse rate',
      validationValue: 'Please enter pulse rate',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'bpm',
      editAccessKey:'Observations.Pulse'
    },
     //add observations
    {
      type: 'textarea',
      placeholder: 'Please Enter any additional observations/findings',
      label: 'Additional Observations',
      required: true,
      key: 'addObservations',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 10000,
      count: '10000 remaining',
      emptyValueMessage: 'Please enter observations/findings',
      validationValue: 'Please enter valid observations/findings',
      validators: [this.valideTextArea],
      focus: false,
      editable: true,
      showontoggle: true,
      showRange: true,
      editAccessKey: 'addObservations'
    },
    {
      type: 'header',
      label: 'TMS Therapy Procedure',
      showontoggle: true,
      editable: false,
    },
    {
      type: 'textarea',
      placeholder: 'Please enter TMS Therapy Procedure',
      label: 'TMS Therapy Procedure',
      required: true,
      key: 'TMSTherapyProcedure',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 10000,
      count: '10000 remaining',
      emptyValueMessage: 'Please enter TMS Therapy Procedure',
      validationValue: 'Please enter valid TMS Therapy Procedure',
      validators: [ this.valideTextArea],
      focus: false,
      editable: true,
      showontoggle: true,
      showRange: true,
      editAccessKey: 'TMSTherapyProcedure'
    },
    {
      type: 'header',
      label: `Patient's measurements:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'number',
      placeholder: 'Enter bridge of nose to mask value',
      label: `Bridge of nose to Mask is:`,
      required: false,
      key: 'bridgeMask',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter Bridge of nose to Mask ',
      validationValue: 'Please enter valid Bridge of nose to Mask ',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'cm',
      editAccessKey:'patientMeasurements.bridgeToMask'
    },
    {
      type: 'number',
      placeholder: 'Enter bridge of nose to inion value',
      label: `Bridge of nose to Inion is:`,
      required: false,
      key: 'bridgeInoin',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter Bridge of nose to Inion',
      validationValue: 'Please enter valid Bridge of nose to Inion',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'cm',
      editAccessKey:'patientMeasurements.bridgeToInoin'
    },
    {
      type: 'number',
      placeholder: 'Enter ear to ear (tragus to tragus) value:',
      label: `Ear to ear (Tragus to Tragus)`,
      required: false,
      key: 'earToear',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter Ear to ear (Tragus to Tragus)',
      validationValue: 'Please enter valid Ear to ear (Tragus to Tragus)',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'cm',
      editAccessKey:'patientMeasurements.earToEar'
    },
    {
      type: 'subheader',
      label: `Mapping was successful at:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'number',
      placeholder: 'Enter mapping power value',
      label: `Power`,
      required: false,
      key: 'mapping',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter Mapping power value',
      validationValue: 'Please enter valid Mapping power value',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: '%',
      editAccessKey:'patientMeasurements.mapping'
    },
    {
      type: 'number',
      placeholder: 'Enter mapping hertz value',
      label: `Hertz`,
      required: false,
      key: 'mappingHertz',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter Mapping hertz value',
      validationValue: 'Please enter valid Mapping hertz value',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Hz',
      editAccessKey:'patientMeasurements.mappingHertz'
    },
    {
      type: 'subheader',
      label: `TMS Begun:` ,
      showontoggle: true,
      editable: false,
    },
    {
      type: 'number',
      placeholder: 'Enter TMS begun power value',
      label: `Run at:`,
      required: false,
      key: 'tmsBegun',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter TMS Begun power value',
      validationValue: 'Please enter valid TMS Begun power value',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: '%',
      editAccessKey:'patientMeasurements.tmsBegun'
    },
    {
      type: 'number',
      placeholder: 'Enter TMS begun protocol value',
      label: `Protocal:`,
      required: false,
      key: 'tmsBegunProtocol',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter TMS Begun protocol value',
      validationValue: 'Please enter valid TMS Begun protocol value',
      validators: [Validators.required],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: '',
      editAccessKey:'patientMeasurements.tmsBegunProtocol'
    },
    {
      type: 'number',
      placeholder: 'Enter TMS begun protocol value',
      label: `Protocol:`,
      required: false,
      key: 'tmsProtocolat',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter TMS Begun protocol value',
      validationValue: 'Please enter valid TMS Begun protocol value',
      validators: [],
      focus: true,
      showontoggle: false,
      className: 'side__by__side placeholder_right',
      placeholderVal: '',
      editAccessKey:'patientMeasurements.tmsProtocolat'
    },
    {
      type: 'textarea',
      placeholder: 'Please enter Recommendations',
      label: 'Recommendations',
      required: true,
      key: 'recommendations',
      labelImage: '',
      value: '',
      controlType: 'textarea',
      maxLength: 15000,
      count: '15000 remaining',
      emptyValueMessage: 'Please enter Recommendations',
      validationValue: 'Please enter Recommendations',
      validators: [this.valideTextArea],
      focus: false,
      editable: true,
      showontoggle: true,
      showRange: true,
      editAccessKey: 'recommendations'
    },
    {
      type: 'number',
      placeholder: 'Please enter Duration of the therapy',
      label: `Duration of the therapy`,
      required: false,
      key: 'duration',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter Duration of the therapy',
      validationValue: 'Please enter valid Duration of the therapy',
      validators: [],
      focus: true,
      showontoggle: true,
      className: 'side__by__side placeholder_right',
      placeholderVal: 'Minutes',
      editAccessKey:'durationOfTherapy'
    },
    {
      type: 'radio',
      placeholder: '',
      label: 'Return Visit',
      labelImage: '',
      required: false,
      key: "returnVisit",
      value: '',
      editable: true,
      controlType: 'custom-radio',
      emptyValueMessage: 'Please select one option',
      className: 'radio-btn-horizontal formarray-radio',
      validationValue: '',
      validators: [],
      fields: [
        {
          label: 'Yes',
          value: 'Yes',
        },
        {
          label: 'No',
          value: 'No',
        },
      ],
      showontoggle: true,
      editAccessKey: 'returnVisit'
    },
    {
      type: 'text',
      placeholder: 'Please enter when to return visit.',
      label: `When`,
      required: false,
      key: 'when',
      labelImage: '',
      value: '',
      controlType: 'inputText',
      editable: true,
      emptyValueMessage: 'Please enter when',
      validationValue: 'Please enter value',
      validators: [],
      focus: true,
      showontoggle: true,
      editAccessKey:'when'
    },
    {
      type: 'header',
      label: 'TMS THERAPY PATIENT SCORE REPORT SHEET',
      showontoggle: true,
      editable: false,
    },

    // sideEffects
    {
      type: 'checkbox-slider',
      key: 'showscorehistory',
      checkboxlabel: 'Do you want to show Score History section',
      controlType: 'checkbox-slider',
      value: false,
      leftLable: true,
      isToggle: true,
      editable: true,
      showontoggle: true,
      editAccessKey: 'sideEffects'
    },
    {
      type: 'formArray',
      key: 'scoreHistoryTable',
      formInputs: [[
        {
          type: 'close-icon',
          showontoggle: true,
          showClose: true,
          editable: false,
          closeIconText: 'Score History'
        },
        {
          type: 'calendar',
          placeholder: 'Enter evaluation date',
          label: 'Evaluation Date',
          required: false,
          key: 'scoreEvaluationDate',
          isDob: false,
          labelImage: 'user',
          value: '',
          isFutureDate: true,
          controlType: 'calendar',
          emptyValueMessage: 'Please select a valid date',
          validationValue: 'Please select valid date',
          validators: [],
          editable: true,
          focus: true,
          showontoggle: true,
          className: 'side__by__side',
        },
        {
          type: 'text',
          placeholder: 'Enter of evaluation name',
          label: 'Name of Evaluation',
          required: false,
          key: 'evaluationName',
          value: 'PHQ-9',
          controlType: 'inputText',
          editable: true,
          className: 'side__by__side placeholder_right',
          emptyValueMessage: 'Please enter Evaluation name',
          validationValue: 'Please enter valid Evaluation name',
          validators: [],
          focus: true,
          showontoggle: true,
        },
        {
          type: 'text',
          placeholder: 'Enter score',
          label: 'Score',
          required: false,
          key: 'score',
          value: '',
          controlType: 'inputText',
          emptyValueMessage: 'Please enter score',
          validationValue: 'Please enter valid Score',
          validators: [],
          className: 'side__by__side placeholder_right',
          editable: true,
          showontoggle: true,
        }]
      ],
      controlType: 'formArray',
      formtag: 'TMS_NOTES',
      selected: false,
      editable: true,
      showontoggle: true,
    },
    {
      type: 'addnew',
      placeholder: '',
      label: '',
      key: 'scoreTableRowAddNew',
      controlType: 'addnew',
      labeltext: 'Add another row',
      formtag: 'TMS_NOTES',
      editable: true,
      showontoggle: true,
    },
    
  ]

  tmsQuestionnaireFrom = {
    formGroup: null,
    formInputs: this.tmsQuestionnaireFromConfig
  };
  hamQuestionnaireForm = {
    formGroup: null,
    formInputs: this.hamQuestionnaireFormConfig
  };
  gadQuestionnaireForm = {
    formGroup: null,
    formInputs: this.gadQuestionnaireFormConfig
  };
  phQuestionnaireForm = {
    formGroup: null,
    formInputs: this.phQuestionnaireFormConfig
  };
  contactUsForm = {
    formGroup: null,
    formInputs: this.contactUsFormConfig
  };
  telemedicineConsentForm = {
    formGroup: null,
    formInputs: this.telemedicineConsentFormConfig
  };
  teletherapyConsentForm = {
    formGroup: null,
    formInputs: this.teletherapyConsentFormConfig
  }
  teletherapyFeddbackConsentForm = {
    formGroup: null,
    formInputs: this.teleFeedbackFormConfig
  }
  medicationConsentForm = {
    formGroup: null,
    formInputs: this.medicationConsentFormConfig
  };
  subSetenceAgreementConsentForm = {
    formGroup: null,
    formInputs: this.subSetenceAgreementConsentFormConfig
  }
  subSentenceTreatmentConsentForm = {
    formGroup: null,
    formInputs: this.subSentenceTreatmentConsentFormConfig
  }
  subConsentForm = {
    FormGroup: null,
    formInputs: this.subConsentFormConfig
  }
  signupForm = {
    formGroup: null,
    formInputs: this.signupFormInputsConfig
  };

  loginForm = {
    formGroup: null,
    formInputs: this.loginFormInputsConfig
  };

  onlyPasswordForm = {
    formGroup: null,
    formInputs: this.onlyPasswordFormInputsConfig
  };

  onlyExistingPasswordForm = {
    formGroup: null,
    formInputs: this.onlyExistingPasswordFormInputsConfig
  };

  resetPasswordForm = {
    formGroup: null,
    formInputs: this.resetPasswordFormInputsConfig
  };
  userProfileForm = {
    formGroup: null,
    formInputs: this.userProfileFormInputsConfig
  };
  userProfileEditForm = {
    formGroup: null,
    formInputs: this.userProfileFormEditInputsConfig
  };
  registerForm = {
    formGroup: null,
    formInputs: this.registerFormInputsConfig
  };
  resheduleForm = {
    formGroup: null,
    formInputs: this.resheduleFormConfig
  };
  forgotPasswordForm = {
    formGroup: null,
    formInputs: this.forgotPasswordFormInputsConfig
  };

  forgotPasswordDOBForm = {
    formGroup: null,
    formInputs: this.forgotPasswordDOBFormInputsConfig
  };

  forgotPasswordFormWithCode = {
    formGroup: null,
    formInputs: this.forgotPasswordWithCodeFormInputsConfig
  };

  resendMailForm = {
    formGroup: null,
    formInputs: this.resendMailFormInputsConfig
  };
  resendVerificationMailForm = {
    formGroup: null,
    formInputs: this.resendVerificationMailFormInputsConfig
  };

  basicInformationForm = {
    formGroup: null,
    formInputs: this.basicInformationFormConfig
  };

  insuranceDetailForm = {
    formGroup: null,
    formInputs: this.insuranceDetailFormConfig
  };

  medicalHistoryForm = {
    formGroup: null,
    formInputs: this.medicalHistoryFormConfig
  };

  miscellaenousForm = {
    formGroup: null,
    formInputs: this.miscellaenousFormConfig
  };

  newPatientSubmitForm = {
    formGroup: null,
    formInputs: this.newPatientSubmitFormConfig
  };

  newUserBasicInformationForm = {
    formGroup: null,
    formInputs: this.newUserBasicInformationFormConfig
  };

  newUserInsuranceDetailForm = {
    formGroup: null,
    formInputs: this.newUserInsuranceDetailFormConfig
  };

  newUserMedicalHistoryForm = {
    formGroup: null,
    formInputs: this.newUserMedicalHistoryFormConfig
  };

  newUserMiscellaenousForm = {
    formGroup: null,
    formInputs: this.newUserMiscellaenousFormConfig
  };

  newUserNewPatientSubmitForm = {
    formGroup: null,
    formInputs: this.newUserNewPatientSubmitFormConfig
  };

  existingUserBasicInformationForm = {
    formGroup: null,
    formInputs: this.existingUserBasicInformationFormConfig
  };

  existingUserInsuranceDetailForm = {
    formGroup: null,
    formInputs: this.existingUserInsuranceDetailFormConfig
  };

  existingUserMedicalHistoryForm = {
    formGroup: null,
    formInputs: this.existingUserMedicalHistoryFormConfig
  };

  existingUserMiscellaenousForm = {
    formGroup: null,
    formInputs: this.existingUserMiscellaenousFormConfig
  };

  existingUserNewPatientSubmitForm = {
    formGroup: null,
    formInputs: this.existingUserNewPatientSubmitFormConfig
  };

  metalHealthDetailForm = {
    formGroup: null,
    formInputs: this.metalHealthDetailFormConfig
  };
   autoFillDataForm ={
    FormGroup: null,
    formInputs: this.autoFilledDataFormConfig
   };
   autoFillMetalDataForm ={
    FormGroup: null,
    formInputs: this.autoFilledMetalDataFormConfig
   }
   autoFillTelemedicineForm = {
    FormGroup: null,
    formInputs: this.autoFillTelemedicineDataFormConfig,
   }
   autoFillMediacationDataForm ={
    FormGroup: null,
    formInputs: this.autoFilledMedicationDataFormConfig
   }
  adhdQuestionnaireForm: IFormType = {
    formGroup: null,
    formInputs: this.adhdQuestionnaireFormConfig
  }

  subsentenceConsentForm: IFormType ={
    formGroup: null,
    formInputs: this.subsentenceConsentFormCongfig
  }

  feedbackForm = {
    formGroup: null,
    formInputs: this.feedbackFormConfig
  };

  cancelfeedbackForm = {
    formGroup: null,
    formInputs: this.cancelfeedbackFormConfig
  };

  hippaForm = {
    formGroup: null,
    formInputs: this.hippaFormConfig
  };
  employeeCreation = {
    formGroup: null,
    formInputs: this.employeeCreationConfig
  };
  uploadFiles = {
    formGroup: null,
    formInputs: this.uploadConfig
  };
  appointmentCreation = {
    formGroup: null,
    formInputs: this.newAppointmentConfig
  };
  bookSlotsData = {
    formGroup: null,
    formInputs: this.bookSlotConfig
  };
  resheduleSlot = {
    formGroup: null,
    formInputs: this.resheduleSlotConfig
  };
  blockedSlot = {
    formGroup: null,
    formInputs: this.blockedSlotConfig
  };
  createLocation = {
    formGroup: null,
    formInputs: this.createLocationConfig
  };
  createLocationService = {
    formGroup: null,
    formInputs: this.createLocationServiceConfig
  };
  createLocationPopUP = {
    formGroup: null,
    formInputs: this.createLocationConfigPopup
  };


  tmsFeedbackForm = {
    formGroup: null,
    formInputs: this.TMSFeedbackFormConfig
  };

  ketamineFeedbackForm = {
    formGroup: null,
    formInputs: this.KETAMINEFeedbackFormConfig
  };

  emailTemplateEdit: IFormType = {
    formGroup: null,
    formInputs: this.emailTemplateEditConfig
  };

  ketamineEnrollmentForm: IFormType = {
    formGroup: null,
    formInputs: this.KETAMINEEnrollmentFormConfig
  };

  ketamineMonitoringForm: IFormType = {
    formGroup: null,
    formInputs: this.KETAMINEMonitoringFormConfig
  };

  ketamineMedicationForm: IFormType = {
    formGroup: null,
    formInputs: this.ketamineMedicationFormConfig
  };
  ketamineTreatmentSessionForm: IFormType = {
    formGroup: null,
    formInputs: this.ketamineTreatmentSessionFormConfig
  };
  requestTherapistInfoForm :IFormType = {
    formGroup: null,
    formInputs: this.requestTherapistInfoFormConfig
  };

  tmsConsentConsentForm: IFormType = {
    formGroup: null,
    formInputs: this.tmsConsentConsentFormConfig
  };
  ketamineConsentConsentForm: IFormType = {
    formGroup: null,
    formInputs: this.ketamineConsentConsentFormConfig
  };
  informationRequestForm: IFormType = {
    formGroup: null,
    formInputs: this.informationRequestFormConfig
  };
  medicationFeedbackForm: IFormType = {
    formGroup: null,
    formInputs: this.medicationFeedbackFormConfig
  };
  medicalSummaryForm: IFormType = {
    formGroup: null,
    formInputs: this.medicalSummaryFormConfig
  }
  //mednotes

  mednoteFollowUpTwentyFive: IFormType = {
    formGroup: null,
    formInputs: this.mednoteFollowUpTwentyFiveConfig
  }
  mednoteFollowUpFortyFive: IFormType = {
    formGroup: null,
    formInputs: this.mednoteFollowUpFortyFiveConfig
  }
  mednotesEval: IFormType = {
    formGroup: null,
    formInputs:this.mednotesEvalConfig
  }
  tmsNotes: IFormType = {
    formGroup: null,
    formInputs:this.tmsNoteFormConfig
  }
  
  constructor(private formBuilder: FormBuilder) {
    this.primaryProviders.push({
      label: 'Other',
      value: 'other'
    });
    this.secondaryproviders.push({
      label: 'Other',
      value: 'other'
    });
  }

  /**
   * To initialise the form
   * @param formType type of form
   */
  initForm(formType) {
    // LoginForm
    this[formType.type].formGroup = this.toReactiveForm(this[formType.config]);
    this[formType.type].formInputs = this[formType.config];
    // console.log(this[formType.type], "formGroup and Inputs")
    return this[formType.type];

  }

  /**
   * Can be chaned according to user (dynamic form data)
   * @param configData type of form which can be manipulated dynamically
   */
  dynamicInitForm(configData, configTag) {
    // console.log(configData, configTag, "dynamicFormData1")
    this[configTag.type].formGroup = this.toReactiveForm(configData);
    this[configTag.type].formInputs = configData;
    // console.log(configData, configTag, "dynamicFormData2")
    return this[configTag.type];
  }

  /**
   * for creating small forms with less than 3 fields
   * @param formJson config data
   */
  createNewForm(formJson) {
    formJson.forEach((element) => {
      if (element.validatorsData) {
        element.validatorsData.forEach((elem) => {
          let rule = null;
          if (elem === 'required') {
            rule = Validators.required;
          } else {
            rule = this[elem];
          }
          element.validators.push(rule);
        });
      }
    });


    let formData = {
      formGroup: this.toReactiveForm(formJson),
      formInputs: formJson
    };
    return formData;
  }

  /**
   * To convert form data to reactive form
   * @param inputs {Array} - array of input fields
   */
  toReactiveForm(inputs: Array<{}>) {
    let group = {};
    Array.prototype.forEach.call(inputs, (input, i) => {
      if (input['type'] !== 'header') {
        if (input['controlType'] === 'formGroup') {
          group[input['key']] = this.toReactiveForm(input['formInputs']);
        } else if (input['controlType'] === 'formArray' || input['controlType'] === 'formArray1') {
          // if(inputs[i-1]['value'] == false){
          let formArray = this.formBuilder.array([]);
          // Array.prototype.forEach.call(input["formInputs"], formGroup => {
          //   let fGroup = this.toReactiveForm(formGroup);
          // formArray.push(fGroup);
          // });
          input['formInputs'].forEach(formGroup => {
            formGroup.forEach(each => {
              if (inputs[i - 1]['value'] === true) {
                each['validators'] = [];
              } else {
                each['validators'] = this.setValidator(each);
              }
            });
            let fGroup:any = this.toReactiveForm(formGroup);
            formArray.push(fGroup);
          });
          group[input['key']] = formArray;
          // }
        } else if (input['controlType'] === 'submitButton') {
          // Don't add to form Group
        } else {
          group[input['key']] = [(input['value'] === undefined || input['value'] == null) ? '' : input['value'], input['validators'] ? this.getValidators(inputs, input) : []];
        }
      }
    });
    return this.formBuilder.group(group);

  }
  getValidators(inputs, field) {
    if (field.key === 'policyHoldersUploadFront' || field.key === 'policyHoldersUploadBack') {
      if (inputs[1].value === 39 || inputs[1].value === 1) {
        return [this.validateFileUpload];
      } else {
        if (field.required) {
          return [Validators.required, this.validateFileUpload];
        } else {
          return [this.validateFileUpload];
        }
      }
    } else if (field.key === 'secondaryPolicyHoldersUploadFront' || field.key === 'secondaryPolicyHoldersUploadBack') {
      if (inputs[3].value === 39 || inputs[3].value === 1) {
        return [this.validateFileUpload];
      } else {
        if (field.required) {
          return [Validators.required, this.validateFileUpload];
        } else {
          return [this.validateFileUpload];
        }
      }
    } else {
      return field['validators'];
    }
  }
  setValidator(field) {
    if (field.type === 'text') {
      if (field.required) {
        return [Validators.required, Validators.minLength(1), this.valideTextArea];
      } else {
        return [];
      }
    } else if (field.type === 'textarea') {
      if (field.required) {
        return [Validators.required, Validators.minLength(1), this.valideTextArea];
      } else {
        return [];
      }
    } else if (field.type === 'calendar') {
      if (field.key === 'prescriptionEndDate') {
        if (field.required) {
          return [this.validateEndDate];
        } else {
          return [];
        }
      } else if (field.key === 'prescriptionStartDate') {
        if (field.required) {
          return [Validators.required, this.validateStartDate];
        } else {
          return [];
        }
      } else {
        if (field.required) {
          return [Validators.required];
        } else {
          return [];
        }
      }
    } else if (field.type === 'radio') {
      if (field.required) {
        return [Validators.required];
      } else {
        return [];
      }
    }
  }

  /**
   * To get the values from submitted form data
   * @param form form data
   */
  getValuesFromFrom(form) {
    let resultant = {};
    for (const formField in form.formData.controls) {
      if (form.formData.controls.hasOwnProperty(formField)) {
        const eachField = form.formData.controls[formField];
        resultant[formField] = eachField.value;
      }
    }
    return resultant;
  }



  /**
   * @desc To validate data
   * @param {FormControl} control
   * @returns null or object
   */
  validateMobile(control: FormControl): any {
    var regex = /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/;
    var found = regex.test(control.value);
    return found ? null : { mobile: true };
  }

  validateBloodPressure(control: FormControl){
    // if value is  empty  do not return validation error
    if(!control.value){ return null}
    //if (control.value !== '' && control.value !== undefined) {
      const bpRegexp: RegExp = /^\d{2,3}\/\d{2,3}$/; 
      var found = bpRegexp.test(control.value);
      return found ? null : { name: true } ;
   // } 
    
  }
  // Validation for Checkbox
  validateCheckBox(control: FormControl) {
    if (control.value) {
      var found = control.value;
      return found ? null : { checkbox: true };
    } else {
      return null;
    }
  }


  // Validation for LoginPin
  validateLoginPin(control: FormControl): any {
    var regex = /^[0-9]{6}$/;
    var found = regex.test(control.value);
    return found ? null : { otp: true };
  }

  // validation for Text area
  valideTextArea(control: FormControl): any {
// return null
    // it is to check whitespace between the text
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var regex = /^[^.\s]/;
      var found = control.value.length > 0 && regex.test(control.value);
      return found ? null : {name:true};
    } else {
      return null;
    }
  }

  validateAlphaSpecialCharaters(control: FormControl) {
    if (control.value !== '' && control.value !== undefined) {
      var regex = /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i;
      var found = regex.test(control.value);
      return found ? null : { relationship: true };
    } else {
      return null;
    }
  }

  // validation for Name
  validateName(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined) {
      const nameRegexp: RegExp = /^(?!\s*$)^[^-\s][A-Za-z0-9_.',\- ()]+$/;
      // /[!@#$%^&*()+\=\[\]{};:"\\|~`<>\/?]/
      // var regex = /^[a-zA-Z0-9 ]{0,}[a-zA-Z0-9 ][a-zA-Z0-9 ]{0,}/g;
      var found = nameRegexp.test(control.value);
      return found ? null : { name: true } ;
    } else {
      return null;
    }
  }

   // validation for Name with '-'
  validateNameUpdated(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined) {
      const nameRegexp: RegExp = /^(?!\s*$)^[^-\s][A-Za-z0-9_\-.,' ]+$/;
      // /[!@#$%^&*()+\=\[\]{};:"\\|~`<>\/?]/
      // var regex = /^[a-zA-Z0-9 ]{0,}[a-zA-Z0-9 ][a-zA-Z0-9 ]{0,}/g;
      var found = nameRegexp.test(control.value);
      return found ? null : { name: true } ;
    } else {
      return null;
    }
  }


  // validation for file Upload
  validateFileUpload(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined && control.value != null) {
      let name = control.value;
      if (control.value.indexOf('https') !== -1 && control.value.indexOf('AWSAccessKeyId') !== -1) {
        name = decodeURIComponent(name);
        name = name.substring(
          name.lastIndexOf('__name__') + 8,
          name.lastIndexOf('?')
        );
      }
      var allowedExtensions = new Array('jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif');
      var fileExtension = name.split('.').pop().toLowerCase(); // split function will split the filename by dot(.), and pop function will pop the last element from the array which will give you the extension as well. If there will be no extension then it will return the filename.
      var valid = allowedExtensions.includes(fileExtension);
      return valid ? null : { file: true };
    } else {
      return null;
    }
  }

  // validation for only alphabets
  validateAplha(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined) {
      var regex = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
      var found = regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  // validation for alpha numeric
  validateAplhaNumeric(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined) {
      var regex = /^[a-zA-Z0-9 \'\-]+$/;
      var found = regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  // validation for first password
  validateFirstPassword(control: FormControl) {
    let group = control.parent;
    if (group && group.controls['newConfirmPassword'].value.length) {
      var password = group.controls['newPassword'].value;
      var passwordConfirmation = group.controls['newConfirmPassword'].value;
      group.controls['newConfirmPassword'].updateValueAndValidity();
      return password === passwordConfirmation ? null : { passwordsEqual: true };
    }
  }

  // validation for Confirm password
  validateConfirmPassword(control: FormControl) {
    if (control.value != null && control.value !== undefined) {
      var conPass = control.value;
      var pass = control.root.get('newPassword');
      if (pass) {
        var password = pass.value;
        if (conPass !== '' && password !== '') {
          if (conPass !== password) {
            return { passwordValidity: true };
          } else {
            return null;
          }
        }
      }
    }
  }

  // validation for change password
  changeConfirmPassword(control: FormControl) {
    if (control.value !== null && control.value !== undefined) {
      var conPass = control.value;
      var pass = control.root.get('userProfileReset');
      if (pass) {
        var password = pass.value;
        if (conPass !== '' && password !== '') {
          if (conPass !== password) {
            return { passwordValidity: true };
          } else {
            return null;
          }
        }
      }
    }
  }

  // validation for reset password
  resetConfirmPassword(control: FormControl) {
    if (control.value !== null && control.value !== undefined) {
      var conPass = control.value;
      var pass = control.root.get('reset');
      if (pass) {
        var password = pass.value;
        if (conPass !== '' && password !== '') {
          if (conPass !== password) {
            return { passwordValidity: true };
          } else {
            return null;
          }
        }
      }
    }
  }

  // validation for Email
  validateEmail(control: FormControl): any {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value !== '' && control.value !== undefined) {
      var found = regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }


  // validation for password
  validatePassword(control: FormControl): any {
    var regex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
    var found = regex.test(control.value);
    return found ? null : { name: true };
  }
  validatePIN(control: FormControl): any {
    let regex = /^(\d{4}|\d{6})$/;
    let found = regex.test(control.value);
    return found ? null : { name: true };
  }
  validateAadhar(control: FormControl): any {
    let regex = /^(\d{12})$/;
    let found = regex.test(control.value);
    return found ? null : { name: true };
  }
  validatePan(control: FormControl): any {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    let found = regex.test(control.value);
    return found ? null : { name: true };
  }
  validateStartDate(control: FormControl) {
    var startDate = control.value;
    if (startDate === '') {
      return null;
    } else {

      if (typeof (startDate) === 'string' && startDate.includes('/')) {
        // startDate = startDate.split('/');
        // startDate = `${startDate[1]}/${startDate[0]}/${startDate[2]}`
      } else {
        startDate = startDate;
      }
      startDate = new Date(startDate);
      var endDate = (control.parent !== undefined) ? control.parent.get('prescriptionEndDate').value : '';
      if (typeof (endDate) === 'string' && endDate.includes('/')) {
        // endDate = endDate.split('/');
        // endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`
      } else {
        endDate = endDate;
      }
      endDate = new Date(endDate);
      var found = endDate < startDate;
      if (found) {
        if (control.parent !== undefined) {
          control.parent.get('prescriptionEndDate').setErrors({ date: true });
        }
      } else {
        if (control.parent !== undefined) {
          control.parent.get('prescriptionEndDate').setErrors(null);
        }
      }
    }
    return null;
  }
  validateEndDate(control: FormControl): any {
    var endDate = control.value;
    if (endDate === '') {
      return null;
    } else {

      if (typeof (endDate) === 'string' && endDate.includes('/')) {
        // endDate = endDate.split('/');
        // endDate = `${endDate[1]}/${endDate[0]}/${endDate[2]}`
      } else {
      }
      endDate = endDate;
      endDate = new Date(endDate);
      var startDate = (control.parent !== undefined) ? control.parent.get('prescriptionStartDate').value : '';
      if (typeof (startDate) === 'string' && startDate.includes('/')) {
        // startDate = startDate.split('/');
        // startDate = `${startDate[1]}/${startDate[0]}/${startDate[2]}`
      } else {
      }
      startDate = startDate;
      startDate = (startDate !== '') ? new Date(startDate) : '';
      var found = startDate < endDate;
      return found ? null : { date: true };
    }
  }

  // validation for Signature
  validateSignature(control: FormControl): any {
    var signature = control.value;
    // console.log(this, "service this value")
    var found = this.signaturValues.every(sign => sign === signature);
    return found ? null : { name: true };
  }
  // validation for ZIP
  validateZIP(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined) {
      var regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      var found = regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  // validation for code
  validateCode(control: FormControl): any {
    var regex = /(^[0-9]*$)/;
    var found = regex.test(control.value);
    return found ? null : { name: true };
  }

  // validation for Name
  validateUrl(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined) {
      var regex = /^https?\:\/\/(\.)?goo\.gl\/maps\/+[A-Z0-9]/i;
      var found = regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  // validation for time input
  validateTimeValue(control: FormControl): IValidateField {
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var found = control.value.length > 0 && control.value > 0 && control.value < 13;
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  // validation for repeat frequency
  valideteFrequencyValue(control: FormControl): IValidateField {
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var found = control.value.length > 0 && control.value > 0 && control.value < 1000;
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  // Validate BP value
  validateBPValue(control: FormControl): IValidateField {
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var found = parseInt(control.value, 10);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

//validate non zero formate
validateNonZero(control: FormControl): IValidateField {
  if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
    var regex = /^[0-9]*[1-9][0-9]*$/;
    var found = regex.test(control.value);
    return found ? null : { name: true };
  } else {
    return null;
  }
}

  //validate 12hours time formate
  validateTime(control: FormControl):IValidateField{
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var regex = /^(0?[1-9]|1[012]):[0-5][0-9]$/;
      var found = regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }
  endTime(control: FormControl) {
    if (control.value !== null && control.value !== undefined && control.value !=''  && !this.validateTime(control)) {
      if(control.root.get('startTime')){
        var startTime:string = this.convertTime12to24(control.root.get('startTime').value,control.root.get('startTimeSuffix').value);
        var endTime:string = this.convertTime12to24(control.value,control.root.get('endTimeSuffix').value);
        var startTimeInMinutes: number = this.convertHoursToMinutes(startTime);
        var endTimeInMinutes:number = this.convertHoursToMinutes(endTime);
        var found:number = endTimeInMinutes-startTimeInMinutes;
        if(found>0){
          if (control.parent.get('startTime').hasError('timeValidity')) {
            delete control.parent.get('startTime').errors.timeValidity;
            control.parent.get('startTime').updateValueAndValidity();
          }
          return null;
        }else{
          return {timeValidity:true};
        }
      }
    }
  }
  startTime(control: FormControl) {
    if (control.value !== null && control.value !== undefined && control.value !='' && !this.validateTime(control)) {
      if(control.root.get('endTime')){
        var startTime:string = this.convertTime12to24(control.value,control.root.get('startTimeSuffix').value);
        var endTime:string = this.convertTime12to24(control.root.get('endTime').value,control.root.get('endTimeSuffix').value);
        var startTimeInMinutes: number = this.convertHoursToMinutes(startTime);
        var endTimeInMinutes:number = this.convertHoursToMinutes(endTime);
        var found:number = endTimeInMinutes-startTimeInMinutes;
        if(found>0){
          if (control.parent.get('endTime').hasError('timeValidity')) {
            delete control.parent.get('endTime').errors.timeValidity;
            control.parent.get('endTime').updateValueAndValidity();
          }
          return null;
        }else{
          return {timeValidity:true};
        }
      }
    }
  }

  startTimeSuffix(control: FormControl) {
    if (control.value !== null && control.value !== undefined && control.value !='' && control.root.get('startTime')) {
      var controlErrors = control.parent.get('startTime').errors ? (control.parent.get('startTime').errors.name ? true : null) : null
      if(!controlErrors){
          var startTime:string = this.convertTime12to24(control.root.get('startTime').value,control.value);
          var endTime:string = this.convertTime12to24(control.root.get('endTime').value,control.root.get('endTimeSuffix').value);
          var startTimeInMinutes: number = this.convertHoursToMinutes(startTime);
          var endTimeInMinutes:number = this.convertHoursToMinutes(endTime);
          var found:number = endTimeInMinutes-startTimeInMinutes;
          if (!(found>0)) {
            if (control.parent !== undefined) {
              control.parent.get('startTime').setErrors({ timeValidity: true });
            }
          } else {
            if (control.parent !== undefined) {
              if (control.parent.get('startTime').hasError('timeValidity')) {
                delete control.parent.get('startTime').errors.timeValidity;
                control.parent.get('startTime').updateValueAndValidity();
              }
              if (control.parent.get('endTime').hasError('timeValidity')) {
                delete control.parent.get('endTime').errors.timeValidity;
                control.parent.get('endTime').updateValueAndValidity();
              }
            }
        }
      }
    }
  }

  endTimeSuffix(control: FormControl) {
    if (control.value !== null && control.value !== undefined && control.value !='' && control.root.get('endTime') ) {
      var controlErrors = control.parent.get('endTime').errors ? (control.parent.get('endTime').errors.name ? true : null) : null
      if(!controlErrors){
        var startTime:string = this.convertTime12to24(control.root.get('startTime').value,control.root.get('startTimeSuffix').value);
        var endTime:string = this.convertTime12to24(control.root.get('endTime').value,control.value);
        var startTimeInMinutes: number = this.convertHoursToMinutes(startTime);
        var endTimeInMinutes:number = this.convertHoursToMinutes(endTime);
        var found:number = endTimeInMinutes-startTimeInMinutes;
        if (!(found>0)) {
          if (control.parent !== undefined) {
            control.parent.get('endTime').setErrors({ timeValidity: true });
          }
        } else {
          if (control.parent !== undefined) {
            if (control.parent.get('startTime').hasError('timeValidity')) {
              delete control.parent.get('startTime').errors.timeValidity;
              control.parent.get('startTime').updateValueAndValidity();
            }
            if (control.parent.get('endTime').hasError('timeValidity')) {
              delete control.parent.get('endTime').errors.timeValidity;
              control.parent.get('endTime').updateValueAndValidity();
            }
          }
        }
      }
    }
  }

   /**
   * 
   * @param time :01:30 PM
   * @returns :'13:30:00'
   */
    convertTime12to24(time: string, modifier: string){
      let [hours, minutes] =(time)?time.split(':'):'00:00';
      if (hours === '12') {
        hours = '00'
      }
      if (modifier === 'PM') {
        hours = (parseInt(hours, 10) + 12) + '';
      }
      hours = (parseInt(hours)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      minutes = (parseInt(minutes)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      return `${hours}:${minutes}:00`;
    }

  /**
   * TO validate the time gap.
   * @param control form control
   */
  validateTimeGap(control: FormControl){
    if (control.value !== null && control.value !== undefined && control.value !='') {
      if(control.root.get('startTime') && control.root.get('endTime')){
        var startTime:string = this.convertTime12to24(control.root.get('startTime').value,control.root.get('startTimeSuffix').value);
        var endTime:string = this.convertTime12to24(control.root.get('endTime').value,control.root.get('endTimeSuffix').value);
        var startTimeInMinutes: number = this.convertHoursToMinutes(startTime);
        var endTimeInMinutes:number = this.convertHoursToMinutes(endTime);
        var found:number = endTimeInMinutes-startTimeInMinutes;
        if(found > 0){
          this.timeGapValidationMessage = this.timeGapValidationMessage.replace(/\d+/g,found+'');
        }
        this.ketamineTreatmentSessionFormConfig[16].validationValue1 = this.timeGapValidationMessage
        if(found <= control.value){
          return null;
        }else{
          return {timeValidity:true};
        }
      }
    }
  }

    /**
  * 
  * @param timeHour :'12:00:00'
  * @returns :12*60+0=800
  */
  convertHoursToMinutes(timeHour:string){
    let timeArray: Array<string> = timeHour.split(':');
    return parseInt(timeArray[0])*60+parseInt(timeArray[1]);
  }

  /**
   * validation for height in inches.
   * @param control 
   */
  validateHeightInches(control: FormControl): IValidateField {
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var found = control.value.length > 0 && control.value >= 0 && control.value <= 11;
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

   /**
    * validation for height in feet.
    * @param control 
    */
   validateHeightFt(control: FormControl): IValidateField {
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var found = control.value.length > 0 && control.value > 0 && control.value <= 10;
      return found ? null : { name: true };
    } else {
      return null;
    }
  }

  /**
    * validation for therapist name.
    * @param control 
    */
   validateTherapistName(control: FormControl): IValidateField {
    let therapistNameList = ['prayaga', 'rama', 'dr. prayaga', 'dr. rama', 'dr. rama prayaga', 'rama prayaga','prayaga rama','prayagarama','ramaprayaga'];
    if (control && control.value !== '' && control.value !== undefined && control.value !== null) {
      var found = control.value.length > 0 && !therapistNameList.includes(control.value.toLowerCase());
      return found ? null : { tname: true };
    } else {
      return null;
    }
  }

  /**
   * Get the current date formatted.
   */
  currentDate(): string{
    let date = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF,new Date()));
    var month: string = '' + (date.getMonth() + 1);
    var day: string = '' + date.getDate();
    var year: string = '' + date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [month, day, year].join('/');
  }

  /**
   * Validate files uploaded.
   */
  validateFilesUpload(control: FormControl): any {
    let valid = control.value && control.value.length;
    return valid ? null : { file: true };
  }
  
  /**
   * To validate the therapist and prescriber email
   * @param control 
   */
  validateTherapistEmail(control: FormControl): IValidateField {
    let emailList = this.therapistEmailList
      var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control && control.value !== '' && control.value !== undefined && control.value !== null && regex.test(control.value)) {
        var found = control.value.length > 0 && !emailList.includes(control.value);
        return found ? null : { tname: true };
      } else {
        return null;
      }
  }

  /**
   * To validate the therapist and prescriber email
   * @param list 
   */
  validateTherapistEmailValue(list?): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let emailList = this.therapistEmailList ? this.therapistEmailList : list;
      var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control && control.value !== '' && control.value !== undefined && control.value !== null && regex.test(control.value)) {
        var found = control.value.length > 0 && !emailList.includes(control.value);
        return found ? null : { tname: true };
      } else {
        return null;
      }
    }
  }

  /**
   * validation for Confirm email.
   * @param control 
   */
  validateConfirmEmail(control: FormControl) {
    if (control.value != null && control.value !== undefined) {
      var conMail = control.value;
      var mail = control.root.get('email');
      if (mail) {
        var mailVal = mail.value;
        if (conMail !== '' && mailVal !== '') {
          if (conMail !== mailVal) {
            return { passwordValidity: true };
          } else {
            return null;
          }
        }
      }
    }
  }

    /**
   * validation for  state.
   * @param control 
   */
  validateState(control: FormControl){
    if (control.value != null && control.value !== undefined) {
      let state = control.value;
      if(stateDropDownList.includes(state)){
        return null;
      }
      else{
        return {name:true}
      }
    }
  }

}