import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dashboard-dropdown',
  templateUrl: './dashboard-dropdown.component.html',
  styleUrls: ['./dashboard-dropdown.component.scss']
})
export class DashboardDropdownComponent implements OnInit {

  locationList = [];
  doctorsList = [];
  currentDate: Date | Date[] = new Date(); // the current date or a range of dates for the calendar view
  minDateValue = new Date();
  // maxDateValue = new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate());
  maxDateValue = new Date(new Date().setDate(new Date().getDate() + 39));
  selectedLocation: string; // appointment dashboard grid metadata locations id
  selectedDoctor: number; // appointment dashboard grid metadata providers(consultant details) id
  showCurrentdateIndex: number; 
  maxDateIndex: number;
  isTypeOfCalendar: boolean = false;
  selectedCalendarType = 'day';
  typeOfCalendar = [
    {
      label: 'Day',
      value: 'day',
    },
    {
      label: 'Week',
      value: 'week',
    },
  ];
  calendarViewType = 'single';
  @Output() calendarType: EventEmitter<any> = new EventEmitter();
  @Output() selectedDropdownValues: EventEmitter<any> = new EventEmitter();
  backwardEnable: boolean = true;
  forwardDisable: boolean = false;
  maxDate = new Date(new Date().setDate(new Date().getDate() + 38));
  @Input() set locationAndProvidersData(value: any) {
    if (value) {
      this.locationList = this.massageLocations(value.locations);
      this.doctorsList = this.massageDoctorsData(value.providers);
      this.selectedDoctor = this.doctorsList[0].value;
      this.selectedLocation = this.locationList[0].value;
    }
  }
  isLocationShow = false;
  @Input() set removeLocation(value) {
    if (value) {
      this.isLocationShow = value;
    }
  }
  constructor() { }

  ngOnInit() {
  }

  // massaging function for doctorsData
  massageDoctorsData(doctors) {
    return doctors.map(doc => {
      return {
        ...doc,
        label: doc.providerName,
        value: doc.providerId,
      };
    });
  }

  // massaging function for locations data
  massageLocations(locations) {
    return locations.map(each => {
      return {
        ...each,
        label: each.locationName,
        value: each.locationId,
      };
    });
  }

  showTypeCalendar(e, flag) {
    if (e) {
      this.isTypeOfCalendar = flag;
    }
  }

  selectedRadio(each, e) {
    if (this.selectedCalendarType === 'day') {
      this.calendarViewType = 'single';
      this.currentDate = new Date();
    } else if (this.selectedCalendarType === 'week') {
      this.calendarViewType = 'range';
      this.select(new Date());
    }
    this.calendarType.emit(this.selectedCalendarType);
  }
  enableBackBtn() {
    if (this.currentDate > new Date()) {
      this.backwardEnable = false;
    } else {
      this.backwardEnable = true;
    }
  }
  disableForwardBtn() {
    if (this.currentDate > this.maxDate) {
      this.forwardDisable = true;
    } else {
      this.forwardDisable = false;
    }
  }
  selectForwardDate() {
    if (this.selectedCalendarType === 'day') {
      this.currentDate = new Date(this.currentDate as Date);
      this.currentDate.setDate(this.currentDate.getDate() + 1);
      this.enableBackBtn();
      this.disableForwardBtn();
    } else {
      this.currentDate = new Date(this.currentDate[0]);
      this.currentDate.setDate(this.currentDate.getDate() + 1);
      this.enableBackBtn();
      this.disableForwardBtn();
      this.select(this.currentDate);
    }
    this.selectedDropdownValues.emit({ date: this.currentDate, location: this.selectedLocation, doctor: this.selectedDoctor });
  }
  selectBackDate() {
    if (this.selectedCalendarType === 'day') {
      this.currentDate = new Date(this.currentDate as Date);
      this.currentDate.setDate(this.currentDate.getDate() - 1);
      this.enableBackBtn();
      this.disableForwardBtn();
    } else {
      this.currentDate = new Date(this.currentDate[0]);
      this.currentDate.setDate(this.currentDate.getDate() - 1);
      this.enableBackBtn();
      this.disableForwardBtn();
      this.select(this.currentDate);
    }
    this.selectedDropdownValues.emit({ date: this.currentDate, location: this.selectedLocation, doctor: this.selectedDoctor });
  }

  viewByDivClick(e) {
    if (e) {
      e.stopPropagation();
    }
  }

  getLocationValue(event) {
    console.log(event);
    this.selectedLocation = event.value;
    this.selectedDropdownValues.emit({ date: this.currentDate, location: this.selectedLocation, doctor: this.selectedDoctor });
  }
  getDoctor(event) {
    this.selectedDoctor = event.value;
    this.selectedDropdownValues.emit({ date: this.currentDate, location: this.selectedLocation, doctor: this.selectedDoctor });
  }

  // Week days setting function
  select(evt) {
    if (this.selectedCalendarType === 'week') {
      this.currentDate = [];
      let start = new Date(evt);
      this.currentDate[0] = start;
      let end = new Date(start);
      end.setDate(start.getDate() + 6);
      this.currentDate[1] = end;
    } else {
      this.enableBackBtn();
      let date = new Date(evt);
      this.currentDate = date;
    }
    this.selectedDropdownValues.emit({ date: this.currentDate, location: this.selectedLocation, doctor: this.selectedDoctor });
  }

}
