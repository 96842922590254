import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { AdminAccessService } from '@app/common/services/admin-access.service';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'app-global-search-input',
  templateUrl: './global-search-input.component.html',
  styleUrls: ['./global-search-input.component.scss']
})
export class GlobalSearchInputComponent implements OnInit {
  @Input() FilteredObj: any; // separate object for storing searched results
  // tslint:disable-next-line:ban-types
  @Input() SearchText: String; // Storing Searched keyword
  @Input() Overlay: any; // Overlay params
  permissions = [];
  permissionsDone = false;
  constructor(
    private router: Router,
    private http: HttpService,
    private adminAccessService: AdminAccessService
  ) { this.getAdminPermissions(); }

  ngOnInit() {
  }
  // function for redirecting to patient/appoinment page by ID on overlay
  getById(...params) {
    this.Overlay.hide();
    setTimeout(() => {
      this.router.navigateByUrl(ROUTE_PATHS.ADMIN, { skipLocationChange: false }).then(() => {
        if (params[2] === true) {
          this.router.navigate([`${ROUTE_PATHS.ADMIN}/${params[1]}/${params[0]}`]);
        } else {
          this.router.navigate([`${ROUTE_PATHS.ADMIN}/${params[1]}/${params[2]}/${params[0]}`]);
        }
      });
    }, 100);
  }

  // function for redirecting to all patients/appoinments page on overlay
  getAll(...params) {
    this.Overlay.hide();
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${params[0]}`], { queryParams: { search: this.SearchText } });
  }

  // getting permissions based on user id
  getAdminPermissions() {
  
    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        // tslint:disable-next-line:one-variable-per-declaration
        this.permissions = [];
        res.data.permissions.forEach(obj => {
          if (!this.permissions.includes(obj.permissionCategory)) {
            this.permissions.push(obj.permissionCategory);
          }
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }


}
