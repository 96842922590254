import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DEFAULT_PAGINATION_LIMIT, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { AdminAccessService } from '@app/common/services/admin-access.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  // pagination data
  breadCrumbData = {
    link: '/admin',
    linkText: 'Employee List'
  };
  employeeListCount = 0;
  isShowScreen = false; // While call api hiding the layout
  employeeListPerPageCount: any = [];  // employeeListPerPageCount
  paginationData: any; // Pagination data
  // Initail  playload value
  PAYLOAD = {
    pagination: {
      page: 1,
      limit: DEFAULT_PAGINATION_LIMIT
    },
  };
  cancelPopupShowHide: boolean = false;
  // Empty list Messages
  employeeListEmptyData = {
    className: 'employee-list',
    icon: 'profile',
    message: 'There are no employees data'
  };
  activeEmpId: any;
  adminPermissions = {
    EMPLOYEE_CREATE: true,
    EMPLOYEE_EDIT: true
  };
  permissionsDone = false;
  permissionsArr = [];
  constructor(
    private observable: ObservableHelperService,
    private http: HttpService,
    private router: Router,
    private adminAccessService: AdminAccessService
  ) {

  }

  ngOnInit() {
    // Initail Payload
    this.getAdminPermissions();
    this.getEmployeeList(this.PAYLOAD);
  }
  // Admin Employee API call
  getEmployeeList(bodyData) {
    this.isShowScreen = false;
    const payload = {
      type: 'POST',
      url: 'employee',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.employeeListPerPageCount = this.massageAppointmentsData(res.data.list);
        console.log(res);
        this.employeeListCount = res.data.pagination.totalItems;
        this.paginationData = this.massagePaginationData(res.data);
        this.isShowScreen = true;
      } else {
        console.log('Response', res);
        if (res.error.message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        } else { this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting employee list'); }
      }

    });
  }

  // This will do massaging for pagination Data
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list
    };
  }

  // This is a massaging function for Employee Data
  massageAppointmentsData(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        ...each,
        employeeName: (!each.firstName && !each.lastName) ? '-' :
          (each.firstName) + ' ' + (each.lastName),
        userProfileUrl: each.profile,
        employeeNameId: each.empId,
        employeeDesignation: each.designation,
        employeeLocation: each.locationData,
        employeePhoneNumber: each.mobile,
      };
    });
  }

  // Pagination Change Event
  pageChangedEvent(data) {
    this.PAYLOAD = {
      pagination: {
        page: data.currentPage,
        limit: data.limit,
      }
    };
    this.getEmployeeList(this.PAYLOAD);
  }

  // This will navigate to create New Employee page
  createNew() {
    setTimeout(() => {
      this.router.navigateByUrl(ROUTE_PATHS.ADMIN, { skipLocationChange: false }).then(() =>
        this.router.navigate([`${ROUTE_PATHS.ADMIN + '/' + ROUTE_PATHS.EMPLOYEE_CREATION}`]))
    }, 100);
  }

  // displayPopup
  displayPopup(empId) {
    this.cancelPopupShowHide = true;
    this.activeEmpId = empId;
  }

  // popUpCancel
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
  }

  // popUpClick event
  popUpClick(event, status) {
    this.cancelPopupShowHide = false;
    // tslint:disable-next-line:no-unused-expression
    status ? this.archiveAPICall('active') : null;
  }

  // Archive API call
  archiveAPICall(status) {
    const payloadData = {
      type: 'PUT',
      url: 'empArchive',
      isDeveloper: true,
      pathVariables: [this.activeEmpId, status],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getEmployeeList(this.PAYLOAD);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, `Successfully ${status === 'active' ? 'activated' : 'archived'} the employee.`);
      } else {
        let message = 'Failed to update employee archive status. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // getting permissions based on user id
  getAdminPermissions() {

    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let tempPerms = ['EMPLOYEE_CREATE', 'EMPLOYEE_EDIT'];
        res.data.permissions.forEach(obj => {
          this.permissionsArr.push(obj.permissionName);
        });
        if (!this.permissionsArr.includes('EMPLOYEE_LIST')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        tempPerms.forEach(perm => {
          if (!this.permissionsArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
    });
    this.permissionsDone = true;
  }
}
