import { Component, OnInit, ViewChild } from '@angular/core';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@app/common/services/data.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_STATUSES, HTTPMethods, appTypeEnums, convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { IStepperItemData, ISelectedPatientDetails } from '@app/common/constants/typeInterfaces.constants';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';

@Component({
  selector: 'app-appointment-wrapper',
  templateUrl: './appointment-wrapper.component.html',
  styleUrls: ['./appointment-wrapper.component.scss']
})
export class AppointmentWrapperComponent implements OnInit {
  // input for step indicator
  stepDetails = [
    {
      label: 'Appointment',
      id: 'appointment',
      img: 'Appointment',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'HIPAA',
      id: 'hippa_notice',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Health Details',
      id: 'health_details',
      img: 'Health Details',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Medication Consent',
      id: 'medication_consent',
      img: 'Medication Consent',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },

    {
      label: 'Feedback',
      id: 'feedback',
      img: 'Feedback',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    }
  ];
  // tslint:disable-next-line: no-inferrable-types
  currentFormIndex: number = 0;
  isMobile: any;
  // tslint:disable-next-line: no-inferrable-types
  saveFormData: boolean = false;
  patientMMForms = {}
  selectedUserId: any;
  currentStep = {
    label: 'Appointment',
    id: 'appointment',
    img: 'Appointment',
    visited: true,
    notVisisted: false,
    completed: false,
    onPage: true
  };
  formTags: any = ['HIPPA', 'MENTALHEALTHDETAIL', 'MEDICATION', 'TELEMEDICINE', 'FEEDBACK', 'TMSFEEDBACK', 'KETAMINEFEEDBACK', 'KETAMINEENROLLMENT', 'KETAMINEMONITORING', 'KETAMINECONSENT', 'TMSCONSENT'];
  formTags1: any = ["AUTOFILLDETAILS", "AUTOFILLMETALDETAILS", "AUTOFILLMEDICATIONDETAILS", "AUTOFILLTELEMEDECINEDETAILS"]
  form: any = [];
  form1: any = [];
  formBackUp: any = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    []
  ];
  formBackUp1: any = [
    [],
    [],
    [],
    []
  ];
  // tslint:disable-next-line: no-inferrable-types
  userLogedIn: boolean = false;
  jsonData: any = [
    {},
    {},
    {},
    {},
    {},
    {}
  ];
  availabiltyData: any;
  timeRange = {
    start: '06:30:00',
    end: '11:55:00'
  };
  locationId: any = 'ALX';
  appointmentId: any = 1;
  slotId: number;
  begin: string;
  end: string;
  selectedSlot: any = {
    time: null,
    date: null,
    status: 'selected'
  };
  appointmentDetailObject = {
    appointmentId: null,
    appointmentTypeId: null,
    locationType: null,
    locationId: null,
    slotId: null,
    timeRange: null,
    selectedSlot: null,
    timeIndex: null,
    dateIndex: null,
    startDate: null
  };
  pageSlots: number;
  dateIndex: number;
  dateSlotsVisible: number;
  iseditForm = true;
  params: any;
  confrimAppointmentId: any;
  firstKetamineAppt: boolean = false;
  ketamineEnr: IStepperItemData = {
    label: 'Ketamine Enrollment',
    id: 'ketamine_enrollment',
    img: 'Ketamine_Enrolment',
    visited: false,
    notVisisted: true,
    completed: false,
    onPage: false
  };
  subtance: IStepperItemData = {
    label: 'Control Substance Consent',
    id: 'substance_consent',
    img: 'Ketamine_Enrolment',
    visited: false,
    notVisisted: true,
    completed: false,
    onPage: false
  };
  //stepper details for INFN Form
  infn: IStepperItemData = {
    label: 'INFN Form',
    id: 'infn_form',
    img: 'Ketamine_Enrolment',
    visited: false,
    notVisisted: true,
    completed: false,
    onPage: false
  };
  //stepper details for feedback
  feedback: IStepperItemData = {
    label: 'Feedback',
    id: 'feedback',
    img: 'Feedback',
    visited: false,
    notVisisted: true,
    completed: false,
    onPage: false
  }
  //stepper details for ketamine Monitoring
  ketamineMntr: IStepperItemData = {
    label: 'Ketamine Monitoring',
    id: 'ketamine_monitoring',
    img: 'Ketamine_Monitioring',
    visited: false,
    notVisisted: true,
    completed: false,
    onPage: false
  };
  appointmentTypeId: number;
  user: Record<string, any>;
  patientId: any;
  MMForms: any = {};
  patientDataList: any;

  constructor(
    private http: HttpService,
    private router: Router,
    private dataService: DataService,
    private formService: CustomFormHandlerService,
    private observable: ObservableHelperService,
    private cognitoService: CognitoService,
    private route: ActivatedRoute
  ) {
    this.params = this.route.snapshot.queryParams;
    this.cognitoService.getUser().then(user => {
      this.user = user;
      this.userLogedIn = Boolean(user && user.email);
      if (Object.keys(this.params).length > 0 && this.user) {
        if (this.params.appointmentId && this.user.userId) {
          let payload = {
            type: HTTPMethods.GET,
            url: API_URLS.GET_PATIENT_LIST,
            isDeveloper: true,
            pathVariables: [user.userId],
          };
          this.http.makeHttpRequest(payload).subscribe(res => {
            console.log("get patient trigger 1 ", res.data);
            let pat = res.data.patients
            res.data.patients.forEach(i => {
              if (i.patientId == this.params.patientId) {
                // Populate the MMForms object with relevant patient data
                this.MMForms = { 
                  lastINFNDate: i.lastINFNDate, // Date of the last INFN form submission
                  lastFeedbackDate: i.lastFeedbackDate, // Date of the last feedback submission
                  appointmentCreateTime: i.appointmentCreateTime, // Time when the appointment was created
                  timeStamp: res.data.timeStamp, // Timestamp of the response
                  lastTmsFeedbackDate: i.lastTmsFeedbackDate, // Date of the last TMS feedback submission
                  // controlSubstance: i.iscontrolSubstance, // Indicates if the patient is on controlled substances
                  // lastAction: i.lastAction // The last action taken on the patient's record
                }
                //controlSubstance: i.iscontrolSubstance
                this.getAppointmentDetails(this.params.appointmentId, this.user.userId, this.MMForms);
              }
            })
          })

        }
      }
    });

    // this.userLogedIn = JSON.parse(window.localStorage.getItem('isLogedIn'));
    if (Object.keys(this.params).length > 0) {
      this.appointmentTypeId = this.params.appointmentTypeId;
      let step = {
        label: 'Telemedicine Consent',
        id: 'telemedicine_consent',
        img: 'Telemedicine_Consent',
        visited: false,
        notVisisted: true,
        completed: false,
        onPage: false
      };
      if (this.params.locationType === 'online' && Number(this.params.appointmentTypeId) !== appTypeEnums.therepy) {
        this.stepDetails.splice(this.stepDetails.length - 1, 0, step);
      } else {
        this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'telemedicine_consent');
      }

      // Add Ketamine enrollment and monitoring forms.
      this.changeConsentFormName(this.params.appointmentTypeId);
      if (this.params.appointmentTypeId == appTypeEnums.ktm) {
        if (this.firstKetamineAppt) {
          this.stepDetails.splice(this.stepDetails.length - 1, 0, this.ketamineEnr);
        }
        this.stepDetails.splice(this.stepDetails.length - 1, 0, this.ketamineMntr);
      } else {
        this.stepDetails = this.stepDetails.filter(ele => (ele.id !== 'ketamine_enrollment' && ele.id !== 'ketamine_monitoring'));
      }

      this.stepDetails = this.getUnique(this.stepDetails, 'id');
      this.getPreAvailabiltySlots(this.params);
    }
  }
  getPreAvailabiltySlots(data) {
    let time = Number(data.appointmentTime.slice(0, 2));
    let beginTime;
    let endTime;
    let value;
    if (time >= 6 && time <= 11) {
      beginTime = (Number(data.appointmentTypeId) !== 4) ? '06:30:00' : '06:30:00'; // 07:00:00
      endTime = '11:55:00';
      value = 1;
    } else if (time >= 12 && time <= 15) {
      beginTime = '12:00:00';
      endTime = '15:55:00';
      value = 2;

    } else if (time >= 16 && time <= 17) {
      beginTime = '16:00:00';
      endTime = '17:55:00';
      value = 3;
    } else if (time >= 18 && time <= 19) {
      beginTime = '18:00:00';
      endTime = '19:55:00';
      value = 4;
    } else if (time >= 20 && time <= 24) {
      beginTime = '20:00:00';
      endTime = '23:30:00';
      value = 5;
    }
    this.iseditForm = false;
    this.confrimAppointmentId = data.appointmentId;
    let params = {
      appointmentTypeId: data.appointmentTypeId,
      locationId: data.locationId,
      beginTime,
      endTime,
      days: (Number(data.appointmentTypeId) === 3 || Number(data.appointmentTypeId) === 4) ? 64 : 40,
      metadata: true,
    };
    console.log(params);
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params,
      isAuthToken: true
      // body: data
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        let showScreen;
        this.availabiltyData = res.data;
        if (this.availabiltyData) {
          showScreen = true;
        }
        this.availabiltyData.showScreen = showScreen;
        console.log(res);
        let availability = res.data.availability.calendar;
        this.customizeSchedular(window.innerWidth, availability.length);
        let dateIndex;
        let timeIndex;
        let timeSlots;
        availability.forEach((row, index) => {
          if (row.date === data.appointmentDate) {
            dateIndex = index;
            row.slots.forEach((slot, slotIndex) => {
              if (slot.time === data.appointmentTime) {
                timeIndex = slotIndex;
              }
            });
            timeSlots = row.slots.length;
          }
        });
        if (dateIndex === (availability.length - 1 - (Math.floor(availability.length / (this.dateSlotsVisible - 1)) - 1))) {
          dateIndex = dateIndex - (this.dateSlotsVisible - 1);
        }
        let dateStart: any = dateIndex === this.dateSlotsVisible - 1 ? 0 : (dateIndex - (dateIndex % (this.dateSlotsVisible - 1))) / (this.dateSlotsVisible - 1);
        if (timeSlots > 9) {
          timeSlots = 9;
        }
        timeIndex = (timeIndex - (timeIndex % (timeSlots))) / (timeSlots);
        let appointmentDate: any = this.getLocalDate(data.appointmentDate);
        dateIndex = this.dateSlotsVisible === 3 && Math.ceil(dateIndex / this.dateSlotsVisible) !== dateIndex / this.dateSlotsVisible ? Math.ceil(dateIndex / this.dateSlotsVisible) : Math.ceil(dateIndex / this.dateSlotsVisible) - 1;
        if (availability.length - 1 === dateStart * (this.dateSlotsVisible - 1)) {
          dateStart = dateStart - 1;
        }
        let startedReschedule = (availability[dateStart * (this.dateSlotsVisible - 1)].date);
        let shedularData: any = {
          dateArray: this.getLocalDate(startedReschedule),
          dateIndex: dateStart,
          timeIndex
        };
        this.selectedSlot.time = data.appointmentTime;
        this.selectedSlot.date = `${data.appointmentDate}T${data.appointmentTime}`;
        this.appointmentDetailObject.appointmentTypeId = data.appointmentTypeId;
        this.appointmentDetailObject.locationType = data.locationId;
        this.appointmentDetailObject.appointmentId = data.appointmentTypeId;
        this.appointmentDetailObject.locationId = this.getLocationId(res.data.metadata, data.locationId, data.appointmentTypeId);
        this.appointmentDetailObject.slotId = this.getSlotId(data.appointmentTime);
        this.appointmentDetailObject.selectedSlot = this.selectedSlot;
        this.appointmentDetailObject.timeRange = this.getTimeRange();
        this.appointmentDetailObject.timeIndex = shedularData.timeIndex;
        this.appointmentDetailObject.dateIndex = shedularData.dateIndex;
        this.appointmentDetailObject.startDate = shedularData.dateArray;
        this.dataService.setData(this.dataService.dataObject.PRE_APPOINTMENT, this.appointmentDetailObject);
        this.iseditForm = true;
      } else {
        let message = 'Failed to fetch availability data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  getLocalDate(date) {
    let newDate: any = new Date(date);
    let offset = new Date().getTimezoneOffset();
    newDate = new Date(newDate.getTime() + (offset * 60 * 1000));
    return newDate;
  }
  customizeSchedular(innerWidth, days) {
    let width = innerWidth;
    if (width > 1024) {
      this.dateSlotsVisible = 8;
    } else if (width <= 1024 && width > 768) {
      this.dateSlotsVisible = 6;
    } else if (width <= 786 && width > 550) {
      this.dateSlotsVisible = 5;
    } else if (width <= 550) {
      this.dateSlotsVisible = 4;
    }
  }
  getLocationId(data, locId, appointmentId) {
    if (data && typeof (data) === 'object') {
      let index;
      let appointments;
      appointments = data.appointmentTypes.filter(eachApp => eachApp.appointmentTypeId === Number(appointmentId));
      console.log(appointments);
      appointments[0].locations.forEach((element, ind) => {
        if (element === locId) {
          index = ind;
        }
      });
      return index;
    }
  }
  getSlotId(time) {
    if ('06:30:00' <= time && time <= '11:55:00') {
      this.slotId = 0;
      this.begin = '06:30:00';
      this.end = '11:55:00';
      return this.slotId;
    } else if ('12:00:00' <= time && time <= '15:55:00') {
      this.slotId = 1;
      this.begin = '12:00:00';
      this.end = '15:55:00';
      return this.slotId;
    } else if ('16:00:00' <= time && time <= '17:55:00') {
      this.slotId = 2;
      this.begin = '16:00:00';
      this.end = '17:55:00';
      return this.slotId;
    } else if ('18:00:00' <= time && time <= '19:55:00') {
      this.slotId = 3;
      this.begin = '18:00:00';
      this.end = '19:55:00';
      return this.slotId;
    } else if ('20:00:00' <= time && time <= '23:30:00') {
      this.slotId = 4;
      this.begin = '20:00:00';
      this.end = '23:30:00';
      return this.slotId;
    }
  }
  getTimeRange() {
    this.timeRange.start = this.begin;
    this.timeRange.end = this.end;
    return this.timeRange;
  }

  ngOnInit() {
    console.log('appointment-wrapper-ngOnInit');
    this.formService.KETAMINEEnrollmentFormConfig[2].formInputs = this.formService.KETAMINEEnrollmentFormConfig[2].formInputs.slice(0, 1)
    this.formService.KETAMINEEnrollmentFormConfig[2].formInputs[0].forEach(element => {
      element.value = '';
    });

    let beforeLoginData = JSON.parse(window.localStorage.getItem('beforLogin'));
    if (beforeLoginData != null) {
      this.observable.setAppointmentAddPatient(true);
    }
    let beforeLoginTypeData = JSON.parse(window.localStorage.getItem('beforLoginTypeData'));
    if (beforeLoginTypeData != null) {
      this.dataService.setData(this.dataService.dataObject.PRE_APPOINTMENT, beforeLoginTypeData);
    }
    let beforeLoginAppointmentData = JSON.parse(window.localStorage.getItem('APPOINTMENT_DETAIL'));
    if (beforeLoginAppointmentData != null) {
      this.dataService.setData(this.dataService.dataObject.APPOINTMENT_DETAIL, beforeLoginAppointmentData);
    }
    window.localStorage.removeItem('previousUrl');
    window.localStorage.removeItem('beforLogin');
    window.localStorage.removeItem('beforLoginTypeData');
    window.localStorage.removeItem('APPOINTMENT_DETAIL');
    let preData = this.dataService.getData(this.dataService.dataObject.PRE_APPOINTMENT);
    this.timeRange = (preData !== undefined) ? preData.timeRange : this.timeRange;
    this.appointmentId = (preData !== undefined) ? preData.appointmentTypeId : this.appointmentId;
    this.locationId = (preData !== undefined) ? preData.locationType : this.locationId;
    let data = this.observable.getAppointmentAddPatient();
    let appointmentFormData = (data.value) ? this.dataService.getPreviousData(this.dataService.dataObject.APPOINTMENT_DETAIL) : this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);

    if (appointmentFormData && appointmentFormData.typeAppointmentData !== undefined) {
      this.appointmentTypeId = appointmentFormData.appointmentTypeId;
      let step = {
        label: 'Telemedicine Consent',
        id: 'telemedicine_consent',
        img: 'Telemedicine_Consent',
        visited: false,
        notVisisted: true,
        completed: false,
        onPage: false
      };
      let selectedLocId = appointmentFormData.typeAppointmentData[1];
      let onlineLocationFilter = selectedLocId.fields.filter(loc => loc.value.toLowerCase() === selectedLocId.value.toLowerCase());
      if (onlineLocationFilter[0].locationType === 'online' && appointmentFormData.appointmentTypeId !== appTypeEnums.therepy) {
        this.stepDetails.splice(this.stepDetails.length - 1, 0, step);
      } else {
        this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'telemedicine_consent');
      }
      this.stepDetails = this.getUnique(this.stepDetails, 'id');

      // Add Ketamine enrollment and monitoring forms.
      this.changeConsentFormName(appointmentFormData.appointmentTypeId);
      if (appointmentFormData.appointmentTypeId == appTypeEnums.ktm) {
        if (this.firstKetamineAppt) {
          this.stepDetails.splice(this.stepDetails.length - 1, 0, this.ketamineEnr);
        }
        this.stepDetails.splice(this.stepDetails.length - 1, 0, this.ketamineMntr);
      } else {
        this.stepDetails = this.stepDetails.filter(ele => (ele.id !== 'ketamine_enrollment' && ele.id !== 'ketamine_monitoring'));
      }
    }
    this.patientId = this.dataService.patientDetails
    // if (this.dataService.patientDetails === undefined) {
    //   this.cognitoService.getUser().then(user => {
    //     let payload = {
    //       type: HTTPMethods.GET,
    //       url: API_URLS.GET_PATIENT_LIST,
    //       isDeveloper: true,
    //       pathVariables: [user.userId],
    //     };
    //     this.http.makeHttpRequest(payload).subscribe(res => {
    //       console.log("get patient trigger 2 ", res.data);
    //       if (this.http.isSuccessfulResponse(res)) {
    //         this.patientId = res.data.patients[0].patientId;
    //         if (Object.keys(this.params).length === 0) {
    //           this.getAvailabiltySlots();
    //         }
    //       }
    //     });
    //   })
    // } else {
    //   if (Object.keys(this.params).length === 0) {
    //     this.patientId = this.dataService.patientDetails
    //     this.getAvailabiltySlots();
    //   }
    // }

    this.cognitoService.getUser().then(user => {
      let payload = {
        type: HTTPMethods.GET,
        url: API_URLS.GET_PATIENT_LIST,
        isDeveloper: true,
        pathVariables: [user.userId],
      };
      
      this.http.makeHttpRequest(payload).subscribe(res => {
    
        if(this.dataService.patientDetails === undefined){
          this.patientId =res.data.patients[0].patientId;
        }
        if (this.http.isSuccessfulResponse(res)) {
          this.patientDataList = res.data;
          console.log("get patient trigger 2 ", res.data);

          res.data.patients.forEach(patientData => {
            let patientId = patientData.patientId
            //infn
            if (patientData.lastINFNDate === null) {
              Object.assign(this.patientMMForms, { [patientId]: { showINFN: true } });
            } else {
              let LastINFN = (patientData.lastINFNDate.split("T"))[0];
              let dateDiff = ((new Date(res.data.timeStamp)).valueOf() - (new Date(LastINFN)).valueOf()) / 1000 / 60 / 60 / 24;
              Object.assign(this.patientMMForms, { [patientId]: { showINFN: (dateDiff > 15) ? true : false } });
            }
            //feedback_MM
            if (patientData.lastFeedbackDate === null) {
              Object.assign(this.patientMMForms[patientId], { showFeedback: true })
            } else {
              let LastFeedback = (patientData.lastFeedbackDate.split("T"))[0];
              let dateDiff = ((new Date(res.data.timeStamp)).valueOf() - (new Date(LastFeedback)).valueOf()) / 1000 / 60 / 60 / 24;
              Object.assign(this.patientMMForms[patientId], { showFeedback: (dateDiff > 120) ? true : false });
            }
            //controlSubstance for consumer first time appointment booking or cancel the admin appointment
            // currently, this code is commented out need to be client confirmation
            // Admin appointment control substance form logic: 
            // patientData.appointmentCreateTime === null || (patientData.lastAction === "CANCEL" && patientData.appointmentCreateTime && patientData.iscontrolSubstance === true)
            if (patientData.appointmentCreateTime === null) {
              Object.assign(this.patientMMForms[patientId], { showSubstance: true })
            } else {
              Object.assign(this.patientMMForms[patientId], { showSubstance: false })
            }
            //feedback_tms
            if (patientData.lastTmsFeedbackDate === null) {
              Object.assign(this.patientMMForms[patientId], { showTmsFeedback: true })
            } else {
              let LastTmsFeedback = (patientData.lastTmsFeedbackDate.split("T"))[0];
              let dateDiff = ((new Date(res.data.timeStamp)).valueOf() - (new Date(LastTmsFeedback)).valueOf()) / 1000 / 60 / 60 / 24;
              Object.assign(this.patientMMForms[patientId], { showTmsFeedback: (dateDiff > 30) ? true : false });
            }

          });
          this.observable.setCurrentPage('appointment');
          this.formTags.forEach((element) => {
            this.form.push(this.formService.initForm(this.formService.formTypes[element]));
          });
          this.formTags1.forEach((element) => {
            this.form1.push(this.formService.initForm(this.formService.formTypes[element]));
          });
          if (this.form1) {
            this.form1.forEach((element, index) => {
              element.formInputs.forEach(ele => {
                this.formBackUp1[index].push({ ...ele });
              });
            });
          }
          if (this.form) {
            this.form.forEach((element, index) => {
              element.formInputs.forEach(ele => {
                this.formBackUp[index].push({ ...ele });
              });
            });
          }
          this.currentStep = this.stepDetails[this.currentFormIndex];
          if (Object.keys(this.params).length === 0) {
            this.getAvailabiltySlots();
          }
        }
      });
    })
  }

 
  // This functionality for removing duplicates from an array
  // @param arr :array of objects
  // @param comp :string
  //
  // this.getUnique(discoveryList,'status')
  getUnique(arr, comp) {
    // store the comparison  values in array
    let unique = arr.map(e => e[comp])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }

  getAvailabiltySlots() {
    let params = {
      patientId: this.patientId,
      appointmentTypeId: this.appointmentId,
      locationId: this.locationId,
      beginTime: this.timeRange.start,
      endTime: this.timeRange.end,
      // startDate: this.formatDate(new Date()),
      days: 40,
      metadata: true
    };
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      dontAthourized: true,
      params,
      isAuthToken: true
      // body: data
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        // if(true === res.data.availability.contolSubstance  ){
        //   this.stepDetails.splice(this.stepDetails.length - 1, 0, this.subtance);
        // } else {
        //   this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'telemedicine_consent');
        //   this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'substance_consent');
        // }

        let showScreen;
        this.availabiltyData = res.data;
        if (this.availabiltyData) {
          let locId = this.availabiltyData.availability.locationId;
          let showTeleConsentForm = false;
          this.availabiltyData.metadata.locations.every(loc => {
            if (loc.locationId.toLowerCase() === locId.toLowerCase() && loc.locationType === 'online') {
              showTeleConsentForm = true;
              return false;
            }
            return true;
          });
          let step = {
            label: 'Telemedicine Consent',
            id: 'telemedicine_consent',
            img: 'Telemedicine_Consent',
            visited: false,
            notVisisted: true,
            completed: false,
            onPage: false
          };
          if (showTeleConsentForm) {
            this.stepDetails.splice(this.stepDetails.length - 1, 0, step);
          } else {
            this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'telemedicine_consent');
          }
          this.stepDetails = this.getUnique(this.stepDetails, 'id');
          showScreen = true;
        }
        this.availabiltyData.showScreen = showScreen;
        console.log(this.availabiltyData, 'avalabityData');
      } else {
        let message = 'Failed to fetch availability data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  ngOnDestroy(): void {
    this.dataService.clearData();
    this.resetFormValues();
  }
  resetFormValues() {
    this.formTags.forEach((element, index) => {
      this.formService[this.formService.formTypes[element].config] = this.formBackUp[index];
    });
    this.formTags1.forEach((element, index) => {
      this.formService[this.formService.formTypes[element].config] = this.formBackUp1[index];
    });
  }
  changeStepper(event) {
    let step = {
      label: 'Telemedicine Consent',
      id: 'telemedicine_consent',
      img: 'Telemedicine_Consent',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    };
    let type = event.location;
    this.appointmentTypeId = event.apptType;
    this.selectedUserId = (event.patientID) ? event.patientID : this.patientId;

    // Add Ketamine enrollment and monitoring forms.
    this.changeConsentFormName(event.apptType)
    if (event.apptType == appTypeEnums.ktm) {
      if (this.firstKetamineAppt) {
        this.stepDetails.splice(this.stepDetails.length - 1, 0, this.ketamineEnr);
      }
      this.stepDetails.splice(this.stepDetails.length - 1, 0, this.ketamineMntr);
    } else {
      this.stepDetails = this.stepDetails.filter(ele => (ele.id !== 'ketamine_enrollment' && ele.id !== 'ketamine_monitoring'));
    }
    //for MM
       /**
     * Checks if the current appointment type is one of the following:
     * - Medication Management
     * - TMS (Transcranial Magnetic Stimulation)
     * - Ketamine Treatment
     * - Therapy
     * 
     * If true, it proceeds to handle the constol substance form logic.
     * 
     *  currently, this code is commented out need to be client confirmation
     *  Admin appointment control substance form for all treatments logic: 
     * this.appointmentTypeId === appTypeEnums.medicationManagement || appTypeEnums.tms || appTypeEnums.ktm || appTypeEnums.therepy
     */
    if (this.appointmentTypeId === appTypeEnums.medicationManagement) {
      //substance
      if (this.patientMMForms[this.selectedUserId].showSubstance) {
        if (!(this.stepDetails.some(obj => obj.id === 'substance_consent'))) {
          if (this.stepDetails.some(obj => obj.id === 'feedback') && this.stepDetails.some(obj => obj.id === 'infn_form')) {
            this.stepDetails.splice(this.stepDetails.length - 2, 0, this.subtance);
          }
          else if (this.stepDetails[this.stepDetails.length - 1].id === "feedback" || this.stepDetails[this.stepDetails.length - 1].id === "infn_form") {
            this.stepDetails.splice(this.stepDetails.length - 1, 0, this.subtance);
          }
          else {
            this.stepDetails.splice(this.stepDetails.length, 0, this.subtance)
          }
        }
      } else {
        this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'telemedicine_consent');
        this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'substance_consent');
      }

      //infn
      if (this.patientMMForms[this.selectedUserId].showINFN) {
        if (!(this.stepDetails.some(obj => obj.id === 'infn_form'))) {
          if (this.stepDetails[this.stepDetails.length - 1].id === "feedback" || this.stepDetails[this.stepDetails.length - 1].id === "infn_form") {
            this.stepDetails.splice(this.stepDetails.length - 1, 1, this.infn);
          }
          else {
            this.stepDetails.splice(this.stepDetails.length, 1, this.infn)
            console.log(this.stepDetails, 'step5');
          }
        }
      } else { //neither inf nor feedback
        this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'infn_form');
        this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'feedback');
      }

      //feedback
      if (this.patientMMForms[this.selectedUserId].showFeedback) {
        if (!(this.stepDetails.some(obj => obj.id === 'feedback'))) {
          this.stepDetails.splice(this.stepDetails.length, 0, this.feedback);
        }
      }

    } else {
      if (this.stepDetails[this.stepDetails.length - 1].id !== "feedback") {
        this.stepDetails.splice(this.stepDetails.length, 0, this.feedback)
      }
      else {
        this.stepDetails.splice(this.stepDetails.length - 1, 1, this.feedback)
      }
      this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'infn_form');
    }
    // when location type in online and appt. type is not therapy
    if (type.locationType === 'online' && event.apptType !== appTypeEnums.therepy) {
      if (this.stepDetails.some(obj => obj.id === 'feedback') && this.stepDetails.some(obj => obj.id === 'infn_form')) {
        this.stepDetails.splice(this.stepDetails.length - 2, 0, step);
      }
      else if (this.stepDetails[this.stepDetails.length - 1].id === "feedback" || this.stepDetails[this.stepDetails.length - 1].id === "infn_form") {
        this.stepDetails.splice(this.stepDetails.length - 1, 0, step);
      }
      else {
        this.stepDetails.splice(this.stepDetails.length, 0, step)
      }
    }
    else {
      this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'telemedicine_consent');
    }
    // TMS 
    if (this.appointmentTypeId == appTypeEnums.tms) {
      if (!this.patientMMForms[this.selectedUserId].showTmsFeedback) {
        console.log("step 1", this.stepDetails);
        this.stepDetails = this.stepDetails.filter(ele => ele.id !== 'feedback');
        console.log("step 2", this.stepDetails);
      }
    }
    this.stepDetails = this.getUnique(this.stepDetails, 'id');
  }

  stepIndicatorClicked(index: number): void {
    if (index > this.currentFormIndex) {

      this.saveFormData = true;
      this.saveForm();
    } else {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = index;
      this.currentStep = this.stepDetails[index];
      this.stepDetails.forEach((each, ind) => {
        if (ind >= this.currentFormIndex) {
          each.completed = false;
        }
      });
      this.stepDetails[index].onPage = true;
    }
  }

  forwardClicked() {
    this.saveForm();
  }
  backClicked() {
    this.back();
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  back() {
    if (this.currentFormIndex === 0) {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = 0;
      this.setCurrentForm(this.currentFormIndex);
    } else {
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
  }
  setCurrentForm(index) {
    this.currentStep = this.stepDetails[index];
    this.stepDetails[index].completed = false;
    this.stepDetails[index].onPage = true;
  }
  getActionText() {
    return this.currentFormIndex < (this.stepDetails.length - 1) ? 'Next' : 'Submit';
  }
  getFormData(event) {
    if (event.status && !this.stepDetails[this.currentFormIndex].completed) {
      this.jsonData[this.currentFormIndex] = event.status;
      this.stepDetails[this.currentFormIndex].completed = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.saveFormData = false;
    } else {
      if (this.stepDetails[this.currentFormIndex].completed && this.currentFormIndex !== this.stepDetails.length - 1) {
        this.stepDetails[this.currentFormIndex].completed = false;
      }
    }
    if (event.step == 1) {
      if (this.stepDetails[this.currentFormIndex].completed) {
        this.currentFormIndex++;
        this.currentStep = this.stepDetails[this.currentFormIndex];
        this.stepDetails[this.currentFormIndex].onPage = true;
        this.stepDetails[this.currentFormIndex].notVisisted = false;
        this.stepDetails[this.currentFormIndex].visited = true;
        this.scrollTop();
      }
    }
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }
  getStepIndex(event) {
    this.stepIndicatorClicked(event);
  }
  saveForm() {
    this.saveFormData = true;
    if (this.currentFormIndex === (this.stepDetails.length - 1)) {
      this.currentStep = this.stepDetails[this.currentFormIndex];
      this.scrollTop();
      window.setTimeout(() => {
        this.navigateToDetails();
      }, 10);
    } else {
      // this.saveFormData = true
      window.setTimeout(() => {
        if (this.stepDetails[this.currentFormIndex].completed) {
          this.currentFormIndex++;
          this.currentStep = this.stepDetails[this.currentFormIndex];
          this.stepDetails[this.currentFormIndex].onPage = true;
          this.stepDetails[this.currentFormIndex].notVisisted = false;
          this.stepDetails[this.currentFormIndex].visited = true;
          if (this.stepDetails[this.currentFormIndex].id == "ketamine_enrollment") {
            this.ketamineEnr.visited = true;
            this.ketamineEnr.notVisisted = false;
            this.ketamineEnr.onPage = true;
          } else if (this.stepDetails[this.currentFormIndex].id == "ketamine_monitoring") {
            this.ketamineMntr.visited = true;
            this.ketamineMntr.notVisisted = false;
            this.ketamineMntr.onPage = true;
          }
          this.scrollTop();
        }
      }, 5);
    }
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }
  navigateToDetails() {

    let isBelowThreshold = (currentValue) => currentValue.completed === true;
    if (this.stepDetails.every(isBelowThreshold)) {
      console.log(this.jsonData, 'appointmentForm data');
      let finalData = this.jsonData[1].value;
      this.jsonData.forEach((row, index) => {
        if (index > 1) {
          finalData = Object.assign({}, finalData, row.value);
        }
      });
      let appointmentDetails = this.dataService.data;
      finalData.patientId = appointmentDetails.appointmentDetail.appoinmentData[1].value;
      finalData.appointmentTypeId = appointmentDetails.appointmentDetail.typeAppointmentData[0].value;
      finalData.locationId = appointmentDetails.appointmentDetail.typeAppointmentData[1].value;
      finalData.appointmentDate = appointmentDetails.appointmentDetail.shedularData.selectedSlot.date;
      finalData.appointmentTime = appointmentDetails.appointmentDetail.shedularData.selectedSlot.time;
      finalData.consentorRelation = appointmentDetails.appointmentDetail.appoinmentData[0].value;
      this.cognitoService.getUser().then(user => {
        this.userLogedIn = Boolean(user && user.email);
        finalData.userId = user.userId;
        let appoinmentData = this.formatAppointmentData(finalData);
        let currentDate: any = new Date();
        let diffTime: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
        appoinmentData.timeDiff = diffTime;
        currentDate = currentDate.toLocaleString().split(', ');
        appoinmentData.timestampDate = currentDate[0];
        appoinmentData.timestamp = currentDate[1].substring(0, currentDate[1].length - 6) + currentDate[1].slice(-3);
        console.log(appoinmentData, 'final result of all forms');
        let payload = {
          type: Object.keys(this.params).length > 0 ? HTTPMethods.PUT : HTTPMethods.POST,
          url: Object.keys(this.params).length > 0 ? API_URLS.ADMIN_APPOINTMENT_CONFIRM : API_URLS.APPOINTMENT,
          isDeveloper: true,
          body: appoinmentData,
          pathVariables: [this.confrimAppointmentId]
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res) && res) {
            this.availabiltyData = res.data;
            console.log(res.data, 'avalabityData');
            this.dataService.clearPreviousData();
            if (Object.keys(this.params).length > 0) {
              this.observable.showConfirmation('booked', 'Your appointment has been confirmed!');
            }
            if (finalData.appointmentTypeId === 4) {
              this.observable.showConfirmation('booked', 'Your appointment has been booked!');
            } else {
              this.observable.showConfirmation('booked', 'Your appointment has been confirmed!');
            }
            this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          } else {
            let message = 'Failed to book an appointment. Please try again';
            message = (res.error) ? res.error.message : message;
            this.observable.showToast(TOAST_STATUSES.WARNING, message);
          }
        });
      });
    }
  }

  /**
   * To format the date.
   * @param date Date value
   */
  formatDate(date: string) {
    if (date === '') {
      return '';
    } else {
      var d = new Date(date);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      return [year, month, day].join('-');
    }
  }

  formatAppointmentData(data) {
    console.log("formatAppointmentData", data);
    let formattedData: any = {
      userId: data.userId,
      patientId: data.patientId,
      appointmentTypeId: data.appointmentTypeId,
      locationId: data.locationId,
      appointmentDate: this.formatDate(data.appointmentDate),
      appointmentTime: data.appointmentTime,
      appointmentDetails: {
        appointmentTypeId: data.appointmentTypeId,
        hipaaForm: {
          iAccept: data['iaccepttermscondition '],
        },
        healthDetails: {
          anxiety: data.anxiety,
          depression_derealization: data.deperesion_derealization,
          feeling_guilt: data.feeling_guilt,
          feeling_paranoid: data.feeling_paranoid,
          feeling_sucidal: data.feeling_sucidal,
          insomnia: data.insomnia,
          lack_concentration: data.lack_concentration,
          legal_issue: !data.legal_issue,
          legal_issue_description: data.legal_issue_description,
          libido: data.libido,
          low_interest: data.low_interest,
          medicines_side_effects: !data.medicines_side_effects,
          medicines_side_effects_description: data.medicines_side_effects_description,
          outburst_allergies: !data.outbust_allergies,
          outburst_allergies_description: data.outbust_allergies_description,
          sadness: !data.sadness,
          weight_loss_gain: data.weight_less_gain
        },
        telemedicineConsent: {
          iamResponsible: data.iamResponsible,
          iAccept: data.iAccept,
          iAcknowledge: data.iAcknowledge,
          iUnderstand: data.iunderstand,
          telemedicineSignature: data.telemedicine_signature
        },
        therapyConsent: {
          iamResponsible: data.iamResponsible,
          iAccept: data.iAccept,
          iAcknowledge: data.iAcknowledge,
          iUnderstand: data.iunderstand,
          telemedicineSignature: data.telemedicine_signature
        },
        teleTherapyConsent: {
          iamResponsible: data.iamResponsible,
          iAccept: data.iAccept,
          iAcknowledge: data.iAcknowledge,
          iUnderstand: data.iunderstand,
          telemedicineSignature: data.telemedicine_signature
        },
        ketamineEnrollment: {
          treatedPreviously: (this.firstKetamineAppt && this.appointmentTypeId == appTypeEnums.ktm) ? !data.treated_previously : undefined,
          ketamineEnrollMedication: this.massageMedicationData(data.ketamine_enr_medication),
          ketamineEnrollAccept: data.ketamine_enr_accept,
          ketamineEnrollDate: (this.firstKetamineAppt && this.appointmentTypeId == appTypeEnums.ktm) ? this.formatDate(new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date())).toString()) : undefined,
          ketamineEnrollSign: data.ketamine_enr_sign
        },
        ketamineMonitoring: {
          patientKetamineMonitoringConsent: data.ketamine_mnt_agree
        }
      }
    };
    var patientFeedback;
    var consentFormData;
    var infnFormData;
    // Medication management feedback
    if (data.appointmentTypeId == appTypeEnums.medicationManagement) {
      consentFormData = {
        consentorRelation: data.consentorRelation,
        consentFor: data.medication_confirmation,
        consentSignature: data.medication_signature,
        hospitalizedPastMonth: !data.mmhospitalizedPastMonth,
        hospitalizedReason: data.mmhospitalizedReason,
        hospitalName: data.mmhospitalName
      };
      //this.stepDetails[this.stepDetails.length - 2].label === "INFN Form"
      if (this.stepDetails.some(obj => obj.id === 'infn_form')) {
        let infnData = this.jsonData[this.stepDetails.indexOf(this.infn)].value
        infnFormData = {
          questions: this.formService.informationRequestFormConfig.map(eachField => {
            return {
              question: eachField.label,
              answer: infnData[eachField.key]
            };
          })
        };
      }
      if (this.stepDetails[this.stepDetails.length - 1].label === "Feedback") {
        patientFeedback = {
          alternate_treatment: !data.alternate_treatment,
          convenience_clinic_location: data.convenience_clinic_location,
          email_communicate: !data.email_communicate,
          feel_satisfied: data.feel_satisfied,
          hipaa_compliant: !data.hippa_compliant,
          medication_management: data.medication_management,
          meet_doctor: data.meet_doctor,
          operation_timing: data.operation_timing,
          rating: data.rating,
          sideeffects_from_meds: !data.sideeffects_from_meds,
          sideeffects_from_meds_description: data.sideeffects_from_meds_description,
          waiting_room: data.waiting_room,
          suggestions: data.suggestion_box
        };
      }
    } else if (data.appointmentTypeId == appTypeEnums.ktm) {
      consentFormData = {
        consentorRelation: data.consentorRelation,
        consentFor: data.ktmmedication_confirmation,
        consentSignature: data.ktmmedication_signature,
        hospitalizedPastMonth: !data.ktmhospitalizedPastMonth,
        hospitalizedReason: data.ktmhospitalizedReason,
        hospitalName: data.ktmhospitalName
      };
      // Ketamine feedback
      patientFeedback = {
        reponse_to_ketamine: data.reponse_to_ketamine,
        sideeffects_during_ketamine: !data.sideeffects_during_ketamine,
        sideeffects_during_ketamine_description: data.sideeffects_during_ketamine_description,
        sideeffects_after_ketamine: !data.sideeffects_after_ketamine,
        sideeffects_after_ketamine_description: data.sideeffects_after_ketamine_description,
        monitored_throughout_session: !data.monitored_throughout_session,
        monitored_throughout_session_description: data.monitored_throughout_session_description,
        feel_satisfied: data.feel_satisfied,
        rating: data.rating,
        fully_understand: data.fully_understand,
        literature_information: data.literature_information,
      };
    } else if (data.appointmentTypeId == appTypeEnums.tms) {
      consentFormData = {
        consentorRelation: data.consentorRelation,
        consentFor: data.tmsmedication_confirmation,
        consentSignature: data.tmsmedication_signature,
        hospitalizedPastMonth: !data.tmshospitalizedPastMonth,
        hospitalizedReason: data.tmshospitalizedReason,
        hospitalName: data.tmshospitalName
      };
      // TMS feedback
      patientFeedback = {
        reponse_to_TMS: data.reponse_to_TMS,
        sideeffects_during_TMS: !data.sideeffects_during_TMS,
        sideeffects_during_TMS_description: data.sideeffects_during_TMS_description,
        sideeffects_after_TMS: !data.sideeffects_after_TMS,
        sideeffects_after_TMS_description: data.sideeffects_after_TMS_description,
        monitored_throughout_session: !data.monitored_throughout_session,
        monitored_throughout_session_description: data.monitored_throughout_session_description,
        feel_satisfied: data.feel_satisfied,
        rating: data.rating
      };
    }
    else {
      consentFormData = {
        consentorRelation: data.consentorRelation,
        consentFor: data.medication_confirmation,
        consentSignature: data.medication_signature,
        hospitalizedPastMonth: !data.mmhospitalizedPastMonth,
        hospitalizedReason: data.mmhospitalizedReason,
        hospitalName: data.mmhospitalName
      };
      patientFeedback = {
        alternate_treatment: !data.alternate_treatment,
        convenience_clinic_location: data.convenience_clinic_location,
        email_communicate: !data.email_communicate,
        feel_satisfied: data.feel_satisfied,
        hipaa_compliant: !data.hippa_compliant,
        medication_management: data.medication_management,
        meet_doctor: data.meet_doctor,
        operation_timing: data.operation_timing,
        rating: data.rating,
        sideeffects_from_meds: !data.sideeffects_from_meds,
        sideeffects_from_meds_description: data.sideeffects_from_meds_description,
        waiting_room: data.waiting_room,
        suggestions: data.suggestion_box
      };
    }
    if (this.stepDetails[this.stepDetails.length - 1].label === "Feedback") {
      formattedData.appointmentDetails["patientFeedback"] = patientFeedback;
    }
    if (this.stepDetails.some(obj => obj.id === 'infn_form')) {
      formattedData.appointmentDetails["InformationResquestForm"] = infnFormData;
    }
    formattedData.appointmentDetails["medicationConsent"] = consentFormData;
    if (data.appointmentTypeId == appTypeEnums.therepy) {
      formattedData.appointmentDetails["medicationConsent"] = {};
      formattedData.appointmentDetails["telemedicineConsent"] = {};
      formattedData.appointmentDetails["therapyConsent"] = {};
    }
    if (data.appointmentTypeId == appTypeEnums.medicationManagement) {
      formattedData.appointmentDetails["teleTherapyConsent"] = {};
      if (!data.hasOwnProperty('telemedicine_signature')) {
        formattedData.appointmentDetails["telemedicineConsent"] = {};
      } else {
        formattedData.appointmentDetails["telemedicineConsent"] = {};
      }
      formattedData.appointmentDetails["therapyConsent"] = {};
    }
    if (data.appointmentTypeId == appTypeEnums.tms) {
      formattedData.appointmentDetails["teleTherapyConsent"] = {};
      formattedData.appointmentDetails["telemedicineConsent"] = {};
      formattedData.appointmentDetails["therapyConsent"] = {};
    }
    if (data.appointmentTypeId == appTypeEnums.ktm) {
      formattedData.appointmentDetails["teleTherapyConsent"] = {};
      formattedData.appointmentDetails["telemedicineConsent"] = {};
      formattedData.appointmentDetails["therapyConsent"] = {};
    }
    if (!data.iAccept) {
      delete formattedData.telemedicineConsent;
    }
    return formattedData;
  }


  cancel() {
    this.router.navigate([`myaccount`]);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * to massage the medication data.
   * @param data medication data
   */
  massageMedicationData(data) {
    if (data && Array.isArray(data)) {
      data.forEach(each => {
        for (const key in each) {
          if (Object.prototype.hasOwnProperty.call(each, key)) {
            if (key === 'prescriptionStartDate' || key === 'prescriptionEndDate' || key === 'lastPrescriptionDate') {
              each[key] = this.formatDate(each[key]);
            }
          }
        }
      });
      return data;
    }
  }

  /**
   * Check if the appt is patient's first ketamine.
   * @param patientData Selected patient data
   */
  patientDetails(patientData: ISelectedPatientDetails) {
    this.firstKetamineAppt = patientData.isketamineEnrollmentRequired;
    // Add Ketamine enrollment and monitoring forms.
    if (this.appointmentTypeId == appTypeEnums.ktm) {
      if (this.firstKetamineAppt) {
        var ketamineEnr = this.stepDetails.find(element => element.id == "ketamine_enrollment")
        if (!ketamineEnr) {
          this.stepDetails.splice(this.stepDetails.length - 2, 0, this.ketamineEnr);
        }
      } else {
        this.stepDetails = this.stepDetails.filter(ele => (ele.id !== 'ketamine_enrollment'));
      }
    } else {
      this.stepDetails = this.stepDetails.filter(ele => (ele.id !== 'ketamine_enrollment' && ele.id !== 'ketamine_monitoring'));
    }
  }

  /**
   * To change the consent form label.
   * @param appointmentTypeId appt type
   */
  changeConsentFormName(appointmentTypeId: number) {
    this.stepDetails.forEach(element => {
      if (element.id == 'medication_consent') {
        if (appointmentTypeId == appTypeEnums.medicationManagement) {
          element.label = 'Medication Consent';
        } else if (appointmentTypeId == appTypeEnums.ktm) {
          element.label = 'Ketamine Consent';
        } else if (appointmentTypeId == appTypeEnums.tms) {
          element.label = 'TMS Consent';
        }
        else if (appointmentTypeId == appTypeEnums.therepy) {
          element.label = 'Therapy Consent';
        }
        else {
          element.label = 'Medication Consent';
        }
      }
    });

  }

  /**
   * To get the appointment details.
   * @param appointmentId 
   * @param userId 
   */
  getAppointmentDetails(appointmentId: string, userId: string, MMF: any) {

    let payload = {
      type: HTTPMethods.GET,
      url: 'getSingleAppointment',
      isDeveloper: true,
      pathVariables: [appointmentId, userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        console.log("getSingleAppointment", res);
        console.log("MMF", MMF);

        let loc = res.data.location;
        let apptType = res.data.appointmentTypeId
        let pID = res.data.patientId
        //infn
        if (MMF.lastINFNDate === null) {
          Object.assign(this.patientMMForms, { [pID]: { showINFN: true } });
        } else {
          let LastAppt = (MMF.lastINFNDate.split("T"))[0];
          let dateDiff = ((new Date(MMF.timeStamp)).valueOf() - (new Date(LastAppt)).valueOf()) / 1000 / 60 / 60 / 24;
          Object.assign(this.patientMMForms, { [pID]: { showINFN: (dateDiff > 15) ? true : false } });
        }
        //feedback
        if (MMF.lastFeedbackDate === null) {
          Object.assign(this.patientMMForms[pID], { showFeedback: true })
        } else {
          let LastFeedback = (MMF.lastFeedbackDate.split("T"))[0];
          let dateDiff = ((new Date(MMF.timeStamp)).valueOf() - (new Date(LastFeedback)).valueOf()) / 1000 / 60 / 60 / 24;
          Object.assign(this.patientMMForms[pID], { showFeedback: (dateDiff > 120) ? true : false });
        }
        //feedback_tms
        if (MMF.lastTmsFeedbackDate === null) {
          Object.assign(this.patientMMForms[pID], { showTmsFeedback: true })
        } else {
          let LastTmsFeedback = (MMF.lastTmsFeedbackDate.split("T"))[0];
          let dateDiff = ((new Date(res.data.timeStamp)).valueOf() - (new Date(LastTmsFeedback)).valueOf()) / 1000 / 60 / 60 / 24;
          Object.assign(this.patientMMForms[pID], { showTmsFeedback: (dateDiff > 30) ? true : false });
        }
        if (res.data.appointmentDetails != null && res.data.appointmentDetails.hasOwnProperty('InformationResquestForm')) {
          if (!this.isEmptyObj(res.data.appointmentDetails.InformationResquestForm)) {
            Object.assign(this.patientMMForms, { [pID]: { showINFN: true } });
          }
        }

        // currently, this code is commented out need to be client confirmation
        //controlSubstance for admin create appointment
        // if( MMF.controlSubstance){
        //   Object.assign(this.patientMMForms[pID], {showSubstance:true})
        // } else {
        //   Object.assign(this.patientMMForms[pID], {showSubstance:false})
        // }
        let patientInfo = { location: loc, apptType: apptType, patientID: pID }
        console.log("patientInfo", patientInfo);
        this.changeStepper(patientInfo)
        let apptStatus = res.data.appointmentStatus ? res.data.appointmentStatus : ""
        if (apptStatus !== 'P') {
          let message = this.getAppointmentStatus(apptStatus);
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
          this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
        }
      } else {
        let message = TOAST_MESSAGES.APPOINTMENT_DETAILS_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  isEmptyObj = function (obj: any) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
  /**
   * To get the appointment status.
   * @param appoinmnetStatus 
   */
  getAppointmentStatus(appoinmnetStatus) {
    let status = TOAST_MESSAGES.APPOINTMENT_CONFIRMED;
    switch (appoinmnetStatus) {
      case 'N':
        status = 'The Appointment is already confirmed';
        break;
      case 'A':
        status = 'The Appointment is already confirmed';
        break;
      case 'C':
        status = 'The Appointment is completed';
        break;
      case 'B':
        status = 'The Appointment is marked as absent';
        break;
      case 'I':
        status = 'The Appointment is completed';
        break;
      case 'D':
        status = 'The Appointment is cancelled';
        break;
    }
    return status;
  }
}
