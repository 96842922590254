import { Injectable } from '@angular/core';
import { Profile } from '@app/pages/admin/models/profileModel';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from './http.service'; // Ensure HttpService is imported

@Injectable({
  providedIn: 'root'
})
export class AdminProfileService {

  // Initializing a BehaviorSubject to store and manage profile data state
  private profileSubject = new BehaviorSubject<Profile | null>(null);

  // Exposing the profile data as an observable for other components to subscribe to
  public profile$ = this.profileSubject.asObservable();

  // Injecting the HttpService dependency for making HTTP requests
  constructor(private http: HttpService) { }

  /**
  * Retrieve the access data.
  * Checks if data is already available in the BehaviorSubject (cached);
  * if so, returns it. Otherwise, it makes an API call to fetch it.
  */
  getProfile(): Observable<Profile> {
    // Check if we already have profile data in the BehaviorSubject
    if (this.profileSubject.value !== null) {
      // If profile count is already cached, return it as an observable
      return of(this.profileSubject.value);
    }

    // If not, make the API call
    const payloadData = {
      type: 'GET',
      url: 'adminProfile',
      isDeveloper: true,
    };

    return this.http.makeHttpRequest(payloadData).pipe(
      tap(res => {
        if (this.http.isSuccessfulResponse(res)) {
          const profileData = res;  // Adjust this based on your API response structure
          this.profileSubject.next(profileData); // Update BehaviorSubject with new data or updated data
        }
      })
    );
  }

}