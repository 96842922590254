<section class="preview">
  <div class="body-container">

    <!-- heading of monthly medication management 25mins  -->
    <!-- <ng-container *ngIf="dataForPreview.mednoteType === 'MEDNOTE25'">
          <div class="mednote-header">
            <div class="pdf-heading">New Stress Clinic</div>
            <div>5194 Dawes Ave, Alexandria VA 22311/ 1325 18th St, NW Suite 210, WDC 20036</div>
            <div>Ph: 703-820-1900/ 202-386-6199</div>
          </div>
        </ng-container> -->
    <!-- noteType === 'Monthly Medication Management' -->

    <!-- heading of monthly medication management 25mins  -->
    <!-- <ng-container *ngIf="dataForPreview.mednoteType === 'MEDNOTE45' ">
          <div class="mednote-header">
            <div class="pdf-heading">New Stress Clinic</div>
            <div>5194 Dawes Ave, Alexandria VA 22311</div>
            <div>Ph: 703-820-1900</div>
          </div>
        </ng-container> -->
    <!-- -->

    <!-- heading of evaluation note -->
    <ng-container>
      <section class="eval-header">
        <h1 class="header-title"> NEW STRESS CLINIC</h1>

        <div class="header-address">
          <div class="header-address-details">
            <p>1325 18TH Street NW DC SUITE 210</p>
            <p>WASHINGTON, D.C. 20036</p>
          </div>
          <div class="header-address-details">
            <p>5194 DAWES AVE</p>
            <p>ALEXANDRIA, VA 22311</p>
          </div>
        </div>

        <div class="header-contacts">
          <div>Phone: (703) 820-1900</div>
          <div>Fax: (866) 528-6229</div>
        </div>

        <p class="header-subtitle"  *ngIf="noteType != 'Monthly Medication Management'">PSYCHIATRIC DIAGNOSTIC ASSESSMENT</p>
        <p class="header-subtitle"  *ngIf="noteType == 'Monthly Medication Management'">PROGRESS NOTE</p>
      </section>
    </ng-container>

    <!-- Basic Information (mednotes)-->
    <div class="field-group " *ngIf="noteType === 'Monthly Medication Management'">

      <div class="field-item Med-Info">
        <div class="field-item-header subtitle-text">Patient Name</div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text"> {{dataForPreview.PatientName}} </div>
      </div>
      <div class="field-item Med-Info">
        <div class="field-item-header subtitle-text">Date of Birth</div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text"> {{this.dob}} </div>
      </div>

      <!-- Commented out as per client requirement to remove the "Duration of Visit" field from the mednotes PDF on 11-11-2024 -->

      <!-- <div class="field-item Med-Info" *ngIf="dataForPreview.DurationOfVisit !== ''">
        <div class="field-item-header subtitle-text">Duration of Visit</div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text"> {{dataForPreview.DurationOfVisit}} </div>
      </div> -->
      
      <div class="field-item Med-Info" *ngIf="dataForPreview.Code !== ''">
        <div class="field-item-header subtitle-text">Code</div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text"> {{dataForPreview.Code}} </div>
      </div>
      <div class="field-item Med-Info">
        <div class="field-item-header subtitle-text">Date</div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text"> {{this.evaluationDate}} </div>
      </div>
      <div class="field-item Med-Info">
        <div class="field-item-header subtitle-text">Chief Complaint</div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text"> {{dataForPreview.ChiefComplainDetails}} </div>
      </div>

    </div>

    <!-- Basic Information (Evaluation note)-->
    <ng-container *ngIf="noteType === 'Evaluation'">
      <div class="basicInfo-groupOne">
        <div class="basicInfo-groupOne-detail">
          <p class="field-item-header1">Evaluation Date: </p>
          <p>{{this.evaluationDate}}</p>
        </div>
        <div class="basicInfo-groupOne-detail">
          <p>Location:</p>
          <p>{{dataForPreview.location}}</p>
        </div>
      </div>



      <div class="section-header">Patient's Basic Information</div>
      <section class="basicInfo">
        <div class="basicInfo-left">
          <div class="field-item">
            <div class="field-item-header">Last Name </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{dataForPreview.PatientInfo.lastName}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">First Name </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{dataForPreview.PatientInfo.firstName}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">MI </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{(dataForPreview.PatientInfo.MI) ? dataForPreview.PatientInfo.MI : '-'}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">AGE </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{dataForPreview.PatientInfo.age}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">Sex </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{dataForPreview.PatientInfo.sex}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">Code </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{dataForPreview.Code}}</div>
          </div>
        </div>
        <div class="basicInfo-right">
          <div class="field-item">
            <div class="field-item-header">DOB </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{this.dob}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">Race </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{(dataForPreview.PatientInfo.race) ? dataForPreview.PatientInfo.race : '-'}}
            </div>
          </div>
          <div class="field-item">
            <div class="field-item-header">Employer </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{(dataForPreview.PatientInfo.Employer) ? dataForPreview.PatientInfo.Employer :
              '-'}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">Education </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{(dataForPreview.PatientInfo.Education) ? dataForPreview.PatientInfo.Education
              : '-'}}</div>
          </div>
          <div class="field-item">
            <div class="field-item-header">Siblings </div>
            <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{(dataForPreview.sibling) ? dataForPreview.sibling : '-'}}</div>
          </div>
        </div>
      </section>
    </ng-container>

    <!-- Reasons to visist (Evaluation note) -->
    <div class="field-group" *ngIf="noteType === 'Evaluation'">
      <div class="line-separater"></div>
      <p class="section-header">Reason(s) for Visit?</p>
      <div class="align-items1">
        <ng-container *ngFor="let Reason of dataForPreview.ReasonForVisit | keyvalue">
          <div class="selected-symptoms">
            <img *ngIf="Reason.value" class="checkbox-img"
              src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
            <img *ngIf="!Reason.value" class="checkbox-img"
              src="https://new-tele-doc-assets.s3.amazonaws.com/assets/unchecked1.png">
            <p>{{ Reason.key }}</p>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Chief Complaint -->
    <div class="field-group" *ngIf="noteType === 'Evaluation'">
      <div class="section-header">Chief Complaint</div>
      <div class="text-content"> {{dataForPreview.chiefComplaint}} </div>
    </div>

    <!-- Infn and doctor's -->
    <div class="empty-box">
      <div class="Subjective-header">Subjective Assesment with the client during the Session</div>

      <div class="text-content" id="doctorNotes"> {{dataForPreview.ChiefComplaintDescription.DoctorNotes}} </div>

      <div class="text-content"
        *ngIf="dataForPreview.ChiefComplaintDescription.hasOwnProperty('InformationResquestForm')">
        <ng-container
          *ngFor="let infn of dataForPreview.ChiefComplaintDescription.InformationResquestForm.questions; let i=index">
          <span class="text-content">{{infn.answer}} </span>
        </ng-container>
      </div>

    </div>

    <!-- Purpose of visit -->
    <div class="field-group" *ngIf="noteType === 'Evaluation'">
      <div class="line-separater"></div>
      <p class="section-header">PURPOSE OF VISIT:</p>
      <p class="text-content">{{dataForPreview.PurposeOfVisit}}</p>
    </div>

    <!-- Patient's Additional Information for Doctor's Notes -->
    <ng-container *ngIf="noteType === 'Monthly Medication Management'">
      <div class="field-group" >
        <div class="Subjective-header">Patient's Additional Information for Doctor's Notes</div>

        <div class="field-item">
          <div class="field-item-header">Therapy Details</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.therapy_details}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Pertinent Information</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.pertinent_information}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Non-Psych Meds</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.non_psych_meds}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Past history of alcohol/ marijuana/ cocain</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.past_historyOf_alcohol}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Hospitalization Information</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.hospitalization_info}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Legal Issue</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.legal_issue}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Sucidal</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.sucidal}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Paranoid</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.paranoid}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Libido</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.libido}}</div>
        </div>
        <div class="field-item">
          <div class="field-item-header">Lack of sleep/ insomina</div>
          <div class="field-item-header-colon"> : </div>
          <div class="field-item-text">{{dataForPreview.AdditionalInfo.lack_of_sleep}}</div>
        </div>
      </div>
    </ng-container>

    <!-- Observations(Mednotes) -->
    <ng-container *ngIf="noteType === 'Monthly Medication Management'">

      <div class="section-header">Objective</div>
      <!-- <div class="observation-objects gap-between" >
              <div class="object-value">
                  <div class="object-item-header">Vitals:</div>
              </div>
              <div class="object-value">
                  <div class="object-item-header">Weight: </div>
                  <div class="vital-text"> {{dataForPreview.Objective.Vitals.Weight}} lbs</div>
              </div>
              <div class="object-value">
                  <div class="object-item-header">Height: </div>
                  <div class="vital-text"> {{dataForPreview.Objective.Vitals.Height}}" </div>
              </div>
              <div class="object-value">
                  <div class="object-item-header">BP: </div>
                  <div class="vital-text"> {{dataForPreview.Objective.Vitals.BP}} mmHg</div>
              </div>
              <div class="object-value">
                  <div class="object-item-header">PR: </div>
                  <div class="vital-text"> {{dataForPreview.Objective.Vitals.PR}} </div>
              </div>
              <div class="object-value">        
                <div class="object-item-header">Pulse: </div>        
                <div class="object-item-text">{{dataForPreview.Objective.Vitals.Pulse}} bpm</div>      
              </div>
          </div> -->
      <div class="field-item-head">Vitals:</div>
      <div class="field-item">
        <div class="field-item-header">Weight</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.Weight}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">Height</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.Height}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">BP</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.BP}}</div>
      </div>
      <!-- <div class="field-item">
            <div class="field-item-header">PR:</div>
            <div class="field-item-text">{{Obs.PR}}</div>
          </div> -->
      <div class="field-item">
        <div class="field-item-header">Pulse</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.Pulse}}</div>
      </div>
    </ng-container>

    <!-- FINDINGS/OBSERVATIONS (Evaluation note) -->
    <div class="field-group" *ngIf="noteType === 'Evaluation'">
      <p class="section-header">FINDINGS/OBSERVATIONS</p>
      <!-- <div class="observation-objects">        
            <div class="object-item-header">Vitals</div>                
            <div class="object-value">        
              <div class="object-item-header">Weight: </div>        
              <div class="object-item-text">{{dataForPreview.Observations.weight}} lbs</div>      
            </div>           
            <div class="object-value">        
              <div class="object-item-header">Height: </div>        
              <div class="object-item-text">{{dataForPreview.Observations.height}}"</div>      
            </div>      
              <div class="object-value">        
                <div class="object-item-header">BP: </div>        
                <div class="object-item-text">{{dataForPreview.Observations.BP}} mmHg</div>      
              </div>  
              <div class="object-value">        
                <div class="object-item-header">Pulse: </div>        
                <div class="object-item-text">{{dataForPreview.Observations.Pulse}} bpm</div>      
              </div>
          </div> -->
      <div class="field-item">
        <div class="field-item-header">Weight</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.Weight}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">Height</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.Height}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">BP</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.BP}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">Pulse</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{Obs.Pulse}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">Alertness</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{dataForPreview.Observations.Alertness}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">Ambulatory</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{dataForPreview.Observations.Ambulatory}}</div>
      </div>
      <div class="field-item">
        <div class="field-item-header">Mood</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{dataForPreview.Observations.mood}}</div>
      </div>

      <div class="field-item">
        <div class="field-item-header">Additional Observations</div>
        <div class="field-item-header-colon"> : </div>
        <div class="field-item-text">{{dataForPreview.Observations.addObservations}}</div>
      </div>
    </div>
    <div class="line-separater"></div>

    <!-- Symptoms -->
    <ng-container *ngIf="noteType === 'Evaluation'">
      <div class="section-header">SYMPTOMS</div>
      <div *ngFor="let symptom of keys_symptoms">
        <ng-container *ngIf="this.dataForPreview.symptoms[symptom]">
          <div class="each-section">
            <p class="subtitle-text">{{ symptom }}</p>
            <div class="align-items1">
              <div *ngFor="let options_key of dataForPreview.symptoms[symptom] | keyvalue">
                <div class="selected-symptoms">
                  <img *ngIf="options_key.value" class="checkbox-img"
                    src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                  <img *ngIf="!options_key.value" class="checkbox-img"
                    src="https://new-tele-doc-assets.s3.amazonaws.com/assets/unchecked1.png">
                  <div class="accept-text">{{ options_key.key }}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="line-separater"></div>
    </ng-container>


    <!--- mental status examination-->
    <div *ngIf="this.dataForPreview && this.dataForPreview.MentalStatusExam">
      <ng-container>
        <p class="section-header">Mental Status Examination</p>

        <div *ngFor="let key of objectKeysMSE">
          <ng-container *ngIf="this.dataForPreview.MentalStatusExam[key]">
            <div *ngIf="key !== 'Hallucinations'" class="each-section">
              <p class="subtitle-text">{{ key }}</p>

              <div class="align-items1">
                <div *ngFor="let symptom of dataForPreview.MentalStatusExam[key] | keyvalue">
                  <div class="selected-symptoms">
                    <img class="checkbox-img" *ngIf="symptom.value"
                      src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                    <img class="checkbox-img" *ngIf="!symptom.value"
                      src="https://new-tele-doc-assets.s3.amazonaws.com/assets/unchecked1.png">
                    <div class="accept-text">{{ symptom.key }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="key === 'Hallucinations' && this.dataForPreview.MentalStatusExam[key].length > 0">
              <span class="subtitle-text">{{ key }}</span>

              <div class="align-items1">
                <div class="selected-symptoms">
                  <div><img class="accept-img" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                  </div>
                  <div class="accept-text">{{ key }}</div>
                </div>
                <div *ngFor="let issue of this.dataForPreview.MentalStatusExam[key]">
                  <div class="accept-text">{{ issue }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- <div *ngFor="let key of stringKeysMSE">
              <span class="section-header" *ngIf ="this.dataForPreview.MentalStatusExam[key] != ''" >{{ key }}</span>
              <div class="field-item bottom" *ngIf ="this.dataForPreview.MentalStatusExam[key] != ''">
                <div class="field-item-header">{{ key }} </div>
                <div class="field-item-text rowDisplay">
                  <img class="checkbox" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                  <p>{{ this.dataForPreview.MentalStatusExam[key] }}</p>
                </div>
              </div>
            </div> -->
      </ng-container>
    </div>
    <!---------- radion status examination------>
    <div *ngIf="this.dataForPreview && this.dataForPreview.RadioButtonValue">
      <ng-container>
        <div *ngFor="let key of objectKeysRadio">
          <ng-container *ngIf="this.dataForPreview.RadioButtonValue[key]">
            <div *ngIf="key !== 'Hallucinations'" class="each-section">
              <p class="subtitle-text">{{ key }}</p>

              <div class="align-items1">
                <div *ngFor="let symptom of dataForPreview.RadioButtonValue[key] | keyvalue">
                  <div class="selected-symptoms">
                    <img class="checkbox-img" *ngIf="symptom.value"
                      src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                    <img class="checkbox-img" *ngIf="!symptom.value"
                      src="https://new-tele-doc-assets.s3.amazonaws.com/assets/unchecked1.png">
                    <div class="accept-text">{{ symptom.key }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="key === 'Hallucinations' && this.dataForPreview.RadioButtonValue[key].length > 0">
              <span class="subtitle-text">{{ key }}</span>

              <div class="align-items1">
                <div class="selected-symptoms">
                  <div><img class="accept-img" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                  </div>
                  <div class="accept-text">{{ key }}</div>
                </div>
                <div *ngFor="let issue of this.dataForPreview.MentalStatusExam[key]">
                  <div class="accept-text">{{ issue }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- <div *ngFor="let key of stringKeysMSE">
              <span class="section-header" *ngIf ="this.dataForPreview.MentalStatusExam[key] != ''" >{{ key }}</span>
              <div class="field-item bottom" *ngIf ="this.dataForPreview.MentalStatusExam[key] != ''">
                <div class="field-item-header">{{ key }} </div>
                <div class="field-item-text rowDisplay">
                  <img class="checkbox" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                  <p>{{ this.dataForPreview.MentalStatusExam[key] }}</p>
                </div>
              </div>
            </div> -->
      </ng-container>
    </div>
    <!-- PMP, side effects, Allergies -->
    <ng-container *ngIf="noteType === 'Monthly Medication Management'">
      <p class="subtitle-text"> PMP: See attachment.</p>
      <p class="subtitle-text">Informed consent signed for use of Psychotropic medication.</p>
      <!-- ConsentSigned -->
      <div class="main-heading-labelContent">
        <span class="main-heading-label section-header">Side-Effects</span>
        <span class="main-heading-Content">{{dataForPreview.sideEffects_description}}</span>
        <!-- <span class="line" *ngIf="dataForPreview.sideEffects === false" >No Side-Effects noticed</span> -->

      </div>

      <div class="main-heading-labelContent">
        <span class="main-heading-label section-header">Allergies</span>
        <span class="main-heading-Content">{{dataForPreview.allergies_description}}</span>
        <!-- <span class="line" *ngIf="dataForPreview.allergies === false" >No Allergies noticed</span> -->
      </div>

      <div class=" section-header" *ngIf="medType === '45 Minutes'">Procedure/Type of Visit</div>
      <p class="field-group"> {{dataForPreview["Procedure/Type of Visit"]}}</p>

      <div class="section-header" *ngIf="medType === '45 Minutes'">Potential Side Effects</div>
      <p class="field-group"> {{dataForPreview["Potential Side Effects"]}}</p>
    </ng-container>

    <!-- from Allergeries to MilitaryHx -->
    <ng-container *ngIf="noteType === 'Evaluation'">
      <div class="line-separater"></div>
      <div class="section-header">ALLERGIES:</div>
      <p class="text-content">{{dataForPreview.allergies}}</p>

      <div class="section-header">PAST PSYCHIATRIC Hx:</div>
      <p class="text-content">{{dataForPreview.PastPsychiatricHx}}</p>

      <div class="field-item">
        <div class="sub-section-header field-item-header">Emotional Abuse </div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text">{{(dataForPreview.emotionalAbuse) ? dataForPreview.emotionalAbuse : '-'}}</div>
      </div>

      <div class="field-item">
        <div class="sub-section-header field-item-header">Physical Abuse </div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text">{{(dataForPreview.physicalAbuse) ? dataForPreview.physicalAbuse : '-'}}</div>
      </div>

      <div class="field-item field-group">
        <div class="sub-section-header field-item-header ">Suicide Attempt </div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text">{{(dataForPreview.suicideAttempts) ? dataForPreview.suicideAttempts : '-'}}</div>
      </div>

      <div class="section-header">MEDICATIONS:</div>
      <p class="text-content">{{dataForPreview.pastMedications}}</p>

      <div class="section-header">PAST MEDICAL Hx:</div>
      <p class="text-content">{{dataForPreview.pastMedicalHx}}</p>
      
      <div class="section-header">PAST SURG HX.</div>
      <p class="text-content">{{dataForPreview.pastSurgHx}}</p>

      <div class="section-header">SUBSTANCE ABUSE Hx: </div>
      <p class="text-content">{{dataForPreview.substanceAbsueHx}}</p>

      <div class="section-header">FAMILY PSYCH Hx:</div>
      <p class="text-content">{{dataForPreview.familyHx}}</p>

      <div class="section-header">SOCIAL Hx: </div>

      <div class="field-item">
        <div class="sub-section-header field-item-header ">Education </div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text">{{(dataForPreview.Education_soicalHx) ? dataForPreview.Education_soicalHx : '-'}}
        </div>
      </div>
      <div class="field-item">
        <div class=" sub-section-header field-item-header ">Siblings </div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text">{{(dataForPreview.Siblings) ? dataForPreview.Siblings : '-'}}</div>
      </div>
      <div class="field-item">
        <div class=" sub-section-header field-item-header ">Employed </div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text">{{(dataForPreview.Employed) ? dataForPreview.Employed : '-'}}</div>
      </div>
      <div class="field-item field-group">
        <div class="sub-section-header field-item-header ">Tobacco Use </div>
        <div class="field-item-header-colon">:</div>
        <div class="field-item-text">{{(dataForPreview.TobaccoUse) ? dataForPreview.TobaccoUse : '-'}}</div>
      </div>

      <div class="section-header">Development Hx: </div>
      <p class="text-content">{{dataForPreview.DevelopmentHx}}</p>

      <div class="section-header">Military Hx: </div>
      <p class="text-content">{{dataForPreview.MilitaryHx}}</p>

      <div class="section-header">Legal Hx: </div>
      <p class="text-content">{{dataForPreview.legalHx}}</p>

    </ng-container>

    <!-- Assessment and Axises -->
    <div class="line-separater"></div>
    <!-- *ngIf="noteType === 'Evaluation'" -->
    <p class="section-header">DIAGNOSIS</p>
    <table class="axises">
      <tr>
        <td class="axises_cell" *ngIf="noteType === 'Monthly Medication Management'"> <span class="bold">Assessment:
          </span>{{dataForPreview.Assessment}} </td>
        <td class="axises_cell"> <span class="bold">Axis_I: </span> {{selectedAxisI}} </td>
        <td class="axises_cell"> <span class="bold">Axis_II: </span> {{dataForPreview.Axis_II}} </td>
        <td class="axises_cell"> <span class="bold">Axis_III: </span> {{dataForPreview.Axis_III}}</td>
      </tr>
      <tr>
        <td class="axises_cell" *ngIf="noteType === 'Monthly Medication Management'"></td>
        <td class="axises_cell"> <span class="bold">Axis_IV: </span> {{dataForPreview.Axis_IV}} </td>
        <td class="axises_cell"> <span class="bold">Axis_V: </span> {{dataForPreview.Axis_V}} </td>
        <td class="axises_cell"></td>
      </tr>
    </table>

    <!-- Plan and Medication -->
    <!-- <div class="line-separater"></div> -->
    <div class="empty-box">

      <div class="sub-section-header" *ngIf="noteType === 'Monthly Medication Management' && medType === '45 Minutes'">
        Co-ordination of care: </div>
      <p class="field-group" *ngIf="noteType === 'Monthly Medication Management' && medType === '45 Minutes'">
        {{(dataForPreview.Plan.Coordination) ? dataForPreview.Plan.Coordination : '-'}}</p>

      <div class="sub-section-header">Medications:</div>
      <ng-container *ngIf="noteType === 'Monthly Medication Management'">
        <ul class="medication_bullets" *ngFor="let med of dataForPreview.Plan.Medications">
          <li>{{med.medicine}} - {{med.dosage}}</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="noteType === 'Evaluation'">
        <ul class="medication_bullets" *ngFor="let med of dataForPreview.Medications">
          <li>{{med.medicine}} - {{med.dosage}}</li>
        </ul>
      </ng-container>

      <div class="sub-section-header">Plan: </div>
      <p *ngIf="noteType === 'Monthly Medication Management'" class="text-content">{{dataForPreview.Plan.Plan}}</p>
      <p *ngIf="noteType === 'Evaluation'" class="text-content">{{dataForPreview.Plan}}</p>

      <p *ngIf="noteType === 'Monthly Medication Management'" class="text-content">{{dataForPreview.Plan.Duration}}</p>
    </div>

    <!-- Signature and date -->
    <img class="sign" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/signature.jpeg" alt="sign">
    <div class="signature">
      <p>Rama S. Prayaga, M.D.</p>
      <p>Date: {{this.evaluationDate}}</p>
    </div>


  </div>

</section>