<div class="appointment-creation">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="newpatientform-background">
    </div>
    <div class="appointment-creation__content">
        <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        <div class="appointment-creation__form" *ngIf="isshowScreen">
            <!--custom form start-->
            <app-custom-form [formData]="formInputs" (submitEvent)="appointmentFormSubmit($event)"
                (cancelFormEvent)="cancelButtonEvent($event)" (searchResult)="searchReslutEvent($event)"
                [filteredSearchList]="searchResultList" (searchTextClick)="getPatientId($event)"
                (calenderSelectedValue)="getdropDownData($event)" (clickedRadio)="treatmentEvent($event)">
            </app-custom-form>
        </div>
    </div>
</div>
<!-- Block patient Ui -->
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup-content-block" *ngIf="action ==='active'">
        <div class="confirm-message">This patient is currently archived. Please activate the patient before proceeding.</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style" (click)="archiveAPICall();cancelPopupShowHide = false">Activate</div>
        </div>
    </div>
    <div class="popup-content-block"
        *ngIf="action === 'unblock' && blockedServices.includes(appointmentTypeName[0].name) && blockedServices.length !== treatmentsCount + 1">
        <div class="confirm-message">
            <p>This patient has  been blocked  for {{appointmentTypeName[0].name}} previously. Do you want to proceed ?</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun({cancelpopup: false},true,'proceed')">
                Proceed</div>
        </div>
    </div>
    <div class="popup-content-block" *ngIf="action === 'unblock' && blockedServices.length === treatmentsCount + 1">
        <div class="confirm-message">
            <p>This patient has been blocked for all the treatments. <br> Please unblock minimum 1 treatment to continue booking an appointment.</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun({cancelpopup: false},true,'unblock')">
                Unblock</div>
        </div>
    </div>
    <div class="popup-content-block"
        *ngIf="action === 'lastValid'">
        <div class="confirm-message">
            <p>{{lastValidMessage}}</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun({cancelpopup: false},true,'lastValid')">
                Proceed</div>
        </div>
    </div>
    <div class="popup-content-block"
        *ngIf="action === 'modifyShow'">
        <div class="confirm-message">
            <p>This patient need to modify the intake form as patient details have been submitted 2 years back.<br/> Do you want to proceed ?</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun({cancelpopup: false},true,'modifyShow')">
                Proceed</div>
        </div>
    </div>
    <div class="popup-content-block"
    *ngIf="action === 'profileUpdate'">
    <div class="confirm-message">
        <p>This patient need to modify the intake form as there were no appointments booked by this patient in the past 8 months.<br/> Do you want to proceed ?</p>
    </div>
    <div class="btn-actions">
        <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
        <div class="common-btn-style"
            (click)="cancelPopupShowHideStatusFun({cancelpopup: false},true,'profileUpdate')">
            Proceed</div>
    </div>
</div>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='blockPatientPopupShowHideStatusFun($event)' *ngIf="blockPatientPopupShowHide">
    <app-block-patient (submitEvent)="blockPatientSubmit($event);blockPatientPopupShowHide=false" [filterInputs]="forminputs" [filterData]="rawFilterData"></app-block-patient>
</app-common-popup>
<!-- End block patient end -->
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideTeleStatusFun($event)' *ngIf="cancelPopupShowHideTele">
    <div class="popup-content-block"
        *ngIf="patientsDetails.details.isOffline === 1 && offLinePatientStatus === true">
        <div class="confirm-message">
            <p>{{patientName}} is an offline patient, you can't book the appointments in {{locationName}}, please select another location</p>
        </div>
    </div>
</app-common-popup>