import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder , Validators } from '@angular/forms';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { AnyLengthString } from 'aws-sdk/clients/comprehendmedical';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-create-new-invoice',
  templateUrl: './create-new-invoice.component.html',
  styleUrls: ['./create-new-invoice.component.scss']
})
export class CreateNewInvoiceComponent implements OnInit {

  generateForm = this.createInvoice.group({
    patientName: ['', Validators.required],
    amount: ['', [Validators.required, Validators.min(1)]],
    service: ['', Validators.required],
  });
  @Output() invoiceAction: EventEmitter<any> = new EventEmitter();
  amount: any;
  service: any;
  patientName: any;
  patientID: any;
  formSubmitAttempt = false;
  patientsList: any = [];
  overlay: any;
  textEmpty = true; // checking whether search box is empty or not
  // change the value subject
  inputChanged: Subject<any> = new Subject<any>();
  constructor(private createInvoice: FormBuilder, private http: HttpService, private observable: ObservableHelperService) {
    // for patient_search
      this.inputChanged
      .pipe(debounceTime(1000))
      .pipe(distinctUntilChanged())
      .subscribe(newValue => {
        this.getPatientList(newValue);
      });
  }

  ngOnInit() {
  }

  patientInput(e: any, op: any) {
    let val = e.target.value;

    if (val !== undefined) {
        this.patientName = val;  
        this.textEmpty = val === '';  
        if (e.key === 'Enter' || e.type === 'click') {
            e.preventDefault(); 
            if (this.patientName !== '') {
                let payload = {
                    search: this.patientName,
                    notType: 'pending'
                };
                this.inputChanged.next(payload);
            }
        }
        if (val === '') {
            this.textEmpty = true;
        } else {
            op.hide();
            this.overlay = op;
        }
    }
}


  // API for calling patient list
  getPatientList(bodyData) {
    if (!this.textEmpty) {
      const payloadData = {
        type: 'POST',
        url: 'adminPatientSearch',
        isDeveloper: true,
        body: bodyData,
      };
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.patientsList = [];
          this.patientsList = [...res.data];
          this.patientsList = this.patientsList.filter(each => each.removedFlag === 0);
          this.overlay.show('target');
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting patientList data');
        }
      });
    }
  }
  // clicking on SVG
  iconClick(event, overlay) {
    this.overlay = overlay;
    let payload = {
      search: this.patientName,
      notType: 'pending'
    };
    this.inputChanged.next(payload);
  }
  // clicking on patient
  patientClick(event, patientDetails) {
    this.patientName = patientDetails.firstName + ' ' + patientDetails.lastName;
    this.patientID = patientDetails.patientId;
    this.overlay.hide();
    this.textEmpty = true;
  }

  // for restricting two decimals after period
  onKeyPressMethod(event) {
    let RE = /^(\d*\.?\d{0,1})$/;
    let value: any = event.target.value;
    console.log(RE.test(value));
    if (!RE.test(value)) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  // amount sync validation
  amountInput(e: any) {
    this.amount = e.target.value;
    console.log(this.amount);
  }

  // outside click
  onClickedOutside(event, overlay) {
    if (overlay.render) { overlay.hide(); }
  }

  // Generate Invoice actions funtionalties
  actionField(event, btn) {
    if (this.generateForm.valid && this.amount > 0 && this.patientID !== undefined && this.patientID !== '') {
      this.formSubmitAttempt = false;
      this.invoiceAction.emit({btnType: btn, patientID: this.patientID, amount: this.amount, service: this.service, type: 'patient_invoice'});
      this.generateForm.reset();
    } else {
    this.formSubmitAttempt = true;
    }
  }

}
