// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl: 'http://localhost:3000',
  // serverUrl: 'https://5mbgmpkq90.execute-api.us-east-1.amazonaws.com/naveen',
  // serverUrl: 'https://uj1yykxif5.execute-api.us-east-1.amazonaws.com/staging',
  // serverUrl: 'https://65y7bx46sj.execute-api.us-east-1.amazonaws.com/test',
  serverUrl: 'https://api.dev.newteledoc.com',
  paymentRedirectUrl:'https://test.authorize.net/payment/payment',
  authenticationNeeded: false,
  blogUrl: 'https://blogdev.newteledoc.com',
  token: ''
};


export const poolData = {
  UserPoolId: 'us-east-1_adW6b9U1A',
  ClientId: '3gp5j5doii1j6gsrdlg683ct1q'
  // UserPoolId: 'us-east-1_Hurv45Fxi',
  // ClientId: '6b9skvca74jlf92tkm93dr1der'
};
export const admin = {
  email: 'officemanager@newstressclinic.com'
};

// export const poolData = {
//   UserPoolId: 'us-east-1_FnO5ujiiZ',
//   ClientId: '4078o3blcjcnnromcg6th p64qo'
// }
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
