import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonAppModule } from '@app/common-app.module';

import { AppRoutingModule } from './app-routing.module';


// Custom component
import { AppComponent } from './app.component';
// Custom module
import { ThrobberComponent } from './common/components/throbber/throbber.component';
import { InvoicePayComponent } from './pages/pages/invoice-consumer/invoice-pay/invoice-pay.component';
import { TmsRedirectComponent } from './tms-redirect/tms-redirect.component';



@NgModule({
  declarations: [
  AppComponent,
  ThrobberComponent,
  InvoicePayComponent,
  TmsRedirectComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonAppModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
