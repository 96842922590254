import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  name: any = 'Jon';
  @Output() showHideCancelPopup = new EventEmitter();
  showHideCancelPopupStatus: boolean = true;
  radioForm: any;
  saveFormData: boolean = false;
  form: any;
  removeUser: boolean = false;
  contactUsBody: {};

  constructor(private formService: CustomFormHandlerService, private http: HttpService, private observableHelperService: ObservableHelperService) { }

  ngOnInit() {
    this.form = this.formService.initForm(this.formService.formTypes.CONTACTUS);
  }

  showHideCancelPopupStatusChange() {
    this.showHideCancelPopupStatus = !this.showHideCancelPopupStatus;
    this.showHideCancelPopup.emit({ cancelpopup: this.showHideCancelPopupStatus, removeUser: this.removeUser });
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }
  saveForm() {
    this.saveFormData = true;
  }
  getFormData(data) {
    if (data.valid) {
      console.log(data);
      let formData = data.value;
      this.contactUsBody = {
        submittedAt: new Date().valueOf(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
        fields: [
          {name: 'name', value: formData.name},
          {name: 'email', value: formData.email},
          {name: 'message', value: formData.message},
          {name: 'IacceptTermsConditions', value: formData.iacceptTermsConditions}
        ],
        context: {
          hutk: '9970c73472b9c359e72e603a4ccb329a', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
          pageUri: 'https://newteledoc.com',
          pageName: 'https://newteledoc.com'
        }
      };


      this.cancelAppointment(this.contactUsBody);
      this.removeUser = true;
      console.log(this.contactUsBody);
      this.form.formGroup.reset();
    }
  }
  cancelAppointment(data) {
    let payload = {
      type: 'POST',
      url: 'https://api.hsforms.com/submissions/v3/integration/submit/8360452/869f1047-5c5f-4da9-96c0-5dd99978169f',
      isExternal: true,
      body: JSON.stringify(this.contactUsBody)
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.showHideCancelPopupStatusChange();
      this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Your message has been successfully sent. We will contact you very soon!');
      if (this.http.isSuccessfulResponse(res)) {
        console.log(res);
      } else {
        // this.observableHelperService.showToast(TOAST_STATUSES.ERROR, "Unable to update record");
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.form.formGroup.reset();
    });
  }
}
