import { formatDate } from '@angular/common';
/** 
 * which is not part of Angular's public API and can change between versions. 
 * Since Angular 13 removed or refactored some of its internal structures, this particular import may no longer be accessible or necessary.
 */
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DEFAULT_PAGINATION_LIMIT, TOAST_STATUSES, convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, TELEMEDICINE_MEDICATION } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { getActionsList } from '../common/constants/appointment-actions';
import { AdminAccessService } from '@app/common/services/admin-access.service';
import { AdminInvoiceFilterService } from '@app/common/services/admin-invoice-filter.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  fromData: any;
  offLinePatientStatus = false;
  assignBlockSlotBody: any;
  rightSidePanelUpcommingInfoShowHideStatus = false; // Right side panel information list
  sideBarVisible = false; // Side bar dsiplay value
  patientDetailsUIStatus = false; // patient ui show hide flag
  showHideDataFlag = true;
  rightSidePanelFlag = false;
  blockSlotConformationPopup = false;
  noOfBookedCount = 0;
  addNewBookingButton = false;
  blockSlotEditAPIFlag = false;
  rightSidePanelFinalData = {
    slotsData: [],
    appoinmnetData: [],
    locationName: ''
  };
  sideBarTitle = 'Block for TMS the slot';
  blokSlotUseId: any;
  slotsavailability = [];
  rightSideAppointmentsData: any;
  isMarkasPaid = false;
  todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  rightsideAppointmentList: any;
  gridType: any = 'day';
  // Block Slots varibles
  blockDetailsStaus = false;
  blockedSlotsObj: any;
  apiLoaderFlag = false;
  formUI = false;
  action: string;
  assignBlockedData: any;
  assignBlockedUIFlag = false;
  trementsArray: any;
  blockedServices: any;
  appointmentTypeName: any;
  bookSlotBody: any;
  timeDurationForShedule: any;
  teleAppCancelPopupShowHideStatusFlag: boolean = false;
  slectedSlotData = {
    blockedSlotStartDate: '2020-11-17',
    blockedSlotEndDate: '2020-11-17',
    treatments: 1,
    startTime: '06:35:00',
    endTime: '06:40:00',
    showActions: [{
      id: 1,
      name: 'Edit',
      icon: 'edit'
    },
    {
      id: 2,
      name: 'delete',
      icon: 'delete'
    },
    ],
    doctor: [{
      isSelected: true,
      name: 'Dr. Rama Prayaga, M.D.',
      picture: 'https://new-tele-doc-assets.s3.amazonaws.com/assets/prayaga.png',
      providerId: 1,
      location: 'Telemedicine',
      patientId: 342,
      timeSlotFrom: '06:35:00',
      timeSlotTo: '06:40:00',
      treatment: 'Medication Management'
    }]
  };
  // Bloced slots varibles end
  // Reshedule varibles
  resheduleSlotsStaus = false;
  resheduleSetdata = false;
  reshedulePatientDataFlag = false;
  resheduleDateValue: any;
  changeTheDropTimevalue: any;
  dectorId: any;
  appointmentLocations: any;
  // reshedule varibles end

  // Block status varibles
  blockSlotInputs: any;
  patientUserId: any; // Patient user id
  patientNeedModify: any; // Patient modify status
  lastVisitData: any; // payload for last visit after modify status popup
  searchInputText = ''; // auto search initial value
  patientsList: any; // Patients list
  totalListOfItems = DEFAULT_PAGINATION_LIMIT;
  searchFieldName: any; // Seach query
  searchResultList: any; // Final patients list
  appointmentTypeId = 1; // Initial Appointments id
  dropDownTimevalue = this.formatDate(); // Getting todays date and assigning to dropdownvalue
  locationId = 'ALX'; // Initial location id value
  locationType = 'offline'; // Initial locationType
  locationName = 'Alexandria Office'; // Initial locationType
  bookedAppointmentName = 'TMS';
  bookAdminSheduleUI = false;
  appointmentTime = '11:00:00'; // Appointment time form url
  isBookSlotSetdata = false;
  isBlockSlotActionStatus = false;
  metaData: any;
  getFilterData: any;
  reshedulePatientID = 3;
  INITIAL_PAYLOAD = {
    pagination: {
      page: 1,
      limit: this.totalListOfItems,
    },
    search: this.searchInputText,
  };
  patientId: any; // Patient id
  patientsDetails: any; // Patient details
  // Availability initial data
  params = {
    beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
    endTime: '23:55:00',
    startDate: this.dropDownTimevalue,
    days: 1,
    metadata: true,
    appointmentTypeId: this.appointmentTypeId,
    locationId: this.locationId
  };
  selectedRadioBtn = '06:30:00-11:55:00';
  viewBy: any = 'day';
  // End the block status varibles
  radiobtnsData = [];
  queryParams: any;
  rawFilterData: any;
  locationAndProvidersData: any;
  appointemntId: any;
  currentDate = new Date();
  beginTime: string = '06:30:00';
  endTime: string = '23:55:00';
  providerId: number = 1;
  dateArray: string[] = [];
  isLocation: boolean = false;
  treatments: any;

  // Appointments details varibles
  appointmentId: any = 127;
  treatmentsCount: any;
  dashboardAppointmentDetails: any;
  appointmentDetailsActions: any;
  moreActions: { id: number; name: string; icon: string; }[];
  showActions: { id: number; name: string; icon: string; }[];
  generateInvoicePopupShowHide: boolean = false;
  blockPatientPopupShowHide = false; // Block UI status flag
  blockPatientApplyStatus = false; // Block Status Flag
  rawTreatmentFilterData: any;
  showAPILoader = false;
  cancelPopupShowHide: boolean = false;
  adminBookSlotCancelPopupShowHide: boolean = false;
  cancelAppointmentData: any;
  copayAmount: any;
  dueAmount: any;
  totalAmout: any;
  makeAsPaidPopupShowHide: boolean = false;
  isBeforeActions: boolean = false;
  allAppts: any;
  censusCount: any;
  // Form inputs
  blockPatientForminputs: any = [
    {
      type: 'header',
      label: 'text',
      label1: 'text',
      showontoggle: true,
      editable: true,
      key: 'patient_name_id',
      className: 'patient-id',
    },

    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'treatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Medmanagement',
          value: 'Medmanagement',
          isSelected: false
        },
        {
          name: 'Tele Medicine',
          value: 'Tele Medicine',
          isSelected: false
        },
        {
          name: 'TMS',
          value: 'TMS',
          isSelected: false
        },
        {
          name: 'Therapy',
          value: 'Therapy',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      valueable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Save',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: '',
      showontoggle: true,
      disabled: true
    }
  ];
  emptyAppointmentsListMessages = {
    className: 'appointmentsList',
    icon: 'noTreatment',
    message: 'There are no treatments for this provider'
  };
  timestamp: any;
  isShowScreen: boolean = false;
  patientName: string;
  lastValidMessage: any;
  selectedDate: any;
  currentTime: any = new Date();
  todaySelectedDate: any;
  adminPermissions = {
    APPOINTMENT_CREATE: true,
    APPOINTMENTS: true,
    EMPLOYEES: true,
    PATIENTS: true,
    LOCATIONS: true,
    INVOICES: true
  };
  permissionsDone: boolean = false;
  patientNeedProfileUpdate: boolean = false;
  @Input() set removeLocation(value: any) {
    console.log(this.removeLocation, 'removeLocation')
    if (value) {
      this.isLocation = value;
      this.locationId = location.href.split('/').pop();
    }
  }
  constructor(
    private router: Router,
    private formServices: CustomFormHandlerService,
    private http: HttpService,
    private HelperService: ObservableHelperService,
    private route: ActivatedRoute,
    private observable: ObservableHelperService,
    private adminAccessService: AdminAccessService,
    private adminInvoiceFilterService: AdminInvoiceFilterService
  ) {
    this.getAdminPermissions();
    this.currentTime = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date()));
    this.currentTime = this.currentTimeLine(this.currentTime);
  }

  ngOnInit() {
    this.checkingSidebarState(this.sideBarVisible);
    this.initLoad();
    this.queryParams = this.route.queryParams;
    if (this.queryParams.value.isReshedule === 'true') {
      this.resheduleOnRediection(this.queryParams.value);
    }
    this.getDashboardGridDataAPI(true);
    this.getBookedAppts(true);

  }

  // This functionality will set the current time line
  currentTimeLine(date) {
    let hours: any = date.getHours();
    let minute: any = date.getMinutes();
    hours = hours === 0 ? 12 : hours; // if it is 0, then make it 12
    let ampm = 'AM';
    ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours > 12 ? hours - 12 : hours; // if more than 12, reduce 12 and set am/pm flag
    hours = ('' + hours).slice(-2); // pad with 0
    minute = ('0' + date.getMinutes()).slice(-2); // pad with 0
    let time = `${hours}:${minute} ${ampm}`;
    return time;
  }

  selectedRadio(e) {
    let from = e.label.split('-')[0];
    let amPm = e.label.split('-')[1].split(' ')[1];
    let time = `${from} ${amPm}`;
    var dom = document.getElementById(`${time}`);
    dom.scrollIntoView();
    window.scroll(0, 0);
    this.beginTime = e.begin;
    this.endTime = e.end;
    // this.getDashboardGridDataAPI(false);
  }

  calendarType(gridtype) {
    if (gridtype) {
      if (gridtype === 'day') {
        this.dateArray = [];
        this.dateArray.push(this.todayDate);
        this.getDashboardGridDataAPI(false);
      } else {
        this.getDashboardGridDataAPI(false);
      }
      this.viewBy = gridtype;
      this.selectedRadioBtn = '06:30:00-11:55:00';
    }
  }
  initLoad() {
    this.dateArray.push(this.currentDate.toISOString().split('T')[0]);
  }

  selectedDropdownValues(dropData) {
    if (dropData) {
      this.locationId = dropData.location;
      this.providerId = dropData.doctor;
      if (Array.isArray(dropData.date)) {
        this.dateArray = [];
        dropData.date = this.getDaysArray(dropData.date[0], dropData.date[1]);
        dropData.date.forEach(dateObj => {
          this.dateArray.push(formatDate(new Date(dateObj), 'yyyy-MM-dd', 'en'));
        });
      } else if (typeof (dropData.date) === 'object') {
        this.dropDownTimevalue = formatDate(new Date(dropData.date), 'yyyy-MM-dd', 'en');
        this.selectedDate = new Date(dropData.date).toLocaleDateString();
        this.todaySelectedDate = new Date(this.todayDate).toLocaleDateString();
        this.dateArray = [];
        this.dateArray.push(this.dropDownTimevalue);
      }
      this.getDashboardGridDataAPI(false);
    }
  }

  // Generating dates between two dates
  getDaysArray(start, end) {
    let arr = [];
    let dt;
    for (dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  // Get getDashboardGridDataAPI call
  getDashboardGridDataAPI(isInitial) {
    if (this.isLocation) {
      this.locationId = location.href.split('/').pop();
    }
    let gridBody = {
      date: this.dateArray,
      locationId: this.locationId,
      beginTime: this.beginTime,
      endTime: this.endTime,
      providerId: this.providerId
    };
    const payloadData = {
      type: 'POST',
      url: 'admindashboardGrid',
      isDeveloper: true,
      body: gridBody,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      console.log(res, 'admindashboardGrid')
      if (this.http.isSuccessfulResponse(res)) {
        this.isShowScreen = true;
        this.getBookedAppts(res.data[0]);
        this.getRightSidePanelData(res.data[0]);
        this.metaData = res.data[0].metaData;
        res.data.forEach((data, i) => {
          if (data.metaData.leftOverAppts.length > 0) {
            let leftOverAppts = [];
            leftOverAppts = [...data.metaData.leftOverAppts];
            leftOverAppts = leftOverAppts.map(each => {
              let dur: any; let timeGap: any;
              data.treatments.every(treatment => {
                if (treatment.appointmentTypeId === each.appointmentTypeId) {
                  dur = treatment.slots.length && treatment.slots[0].duration ? treatment.slots[0].duration : data.metaData.apttypes.filter(app => app.appointmentTypeId === treatment.appointmentTypeId)[0].duration;
                  timeGap = treatment.slots.length && treatment.slots[0].gap ? treatment.slots[0].gap : 0;
                  return false;
                }
                return true;
              });
              return {
                appId: each.appointmentTypeId,
                available: false,
                duration: dur,
                gap: timeGap,
                list: [{ appointmentId: each.appointmentId, begin: each.appointmentTime, end: this.getEndTime(each.appointmentTime, dur), firstName: each.patients.firstName, lastName: each.patients.lastName }],
                time: each.appointmentTime,
                unavailabilityGrpId: ''
              };
            });
            leftOverAppts = leftOverAppts.map(appt => {
              let count = 0; let ar = [];
              // tslint:disable-next-line:no-shadowed-variable
              leftOverAppts.forEach((app, i) => {
                if (appt.time === app.time) {
                  count = count + 1;
                  ar.push(app.list[0]);
                  if (count > 1) {
                    leftOverAppts.splice(i, 1);
                  }
                }
              });
              return { ...appt, list: [...ar] };
            });
            res.data[i].treatments = res.data[i].treatments.map(service => {
              let filteredSlots = [];
              leftOverAppts.forEach(slot => {
                if (slot.appId === service.appointmentTypeId) {
                  filteredSlots.push(slot);
                }
              });
              console.log(service.slots);
              let updatedSlots = service.slots.concat(filteredSlots);
              updatedSlots = updatedSlots.sort((a: any, b: any) => this.getTime(a.time) - this.getTime(b.time));
              return { ...service, slots: [...updatedSlots] };
            });
          }
        });
        this.treatments = this.massageTreatments(res.data);
        this.radiobtnsData = this.filteringSlots(res.data[0].metaData.slots);
        if (isInitial) {
          this.locationAndProvidersData = this.massageDropDownData(res.data[0].metaData);
          this.locationId = this.locationAndProvidersData.locations[0].locationId;
          this.dectorId = this.locationAndProvidersData.providers[0].providerId;
        }
      } else {
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting availability data');
      }
    });
  }

  // Get Right side panel data
  getRightSidePanelData(res) {

    var getMetaData = res.metaData.apttypes;
    // if (res.date === this.todayDate) {
    this.rightSidePanelFinalData.locationName = res.metaData.selectedLocation.locationName;
    this.slotsavailability = [];
    var totalSlotsavailabilityCount = 0;
    var totalSlotsBookedyCount = 0;
    var maxAppCount = 0;
    res.treatments.forEach(treatment => {
      let treatmentsArray = {};
      let location = this.rightSidePanelFinalData.locationName;
      let apptName = treatment.appointmentName;
      let newPats = res.metaData.latestPatientIds;
      treatmentsArray['name'] = treatment.appointmentName;
      treatmentsArray['booked'] = this.allAppts.length > 0 ? this.getCensusCount(location, apptName, 'booked') : 0;
      treatmentsArray['pending'] = this.allAppts.length > 0 ? this.getCensusCount(location, apptName, 'pending') : 0;
      treatmentsArray['newPatients'] = this.allAppts.length > 0 && newPats.length > 0 ? this.getCensusCount(location, apptName, 'newPatients', newPats) : 0;
      treatmentsArray['cancelled'] = this.allAppts.length > 0 ? this.getCensusCount(location, apptName, 'cancelled') : 0;
      treatmentsArray['confirmed'] = this.allAppts.length > 0 ? this.getCensusCount(location, apptName, 'confirmed') : 0;
      treatment.slots.forEach(eachSlot => {
        // if (eachSlot.available === true) {
        maxAppCount = eachSlot.maxApptCount;
        totalSlotsavailabilityCount++;
        totalSlotsBookedyCount = totalSlotsBookedyCount + eachSlot.list.length;
        // }
      });
      treatmentsArray['total'] = totalSlotsavailabilityCount * maxAppCount;
      // treatmentsArray['booked'] = totalSlotsBookedyCount;
      this.slotsavailability.push(treatmentsArray);
      totalSlotsavailabilityCount = 0;
      totalSlotsBookedyCount = 0;
    });
    // }
    this.getAppointmentsData(getMetaData, res.date);
  }


  getAppointmentsData(appointmentList, date) {
    // Get the starting time today
    var getTodaysStartingTime: any;
    let curHour = this.currentTime.split(' ')[0].split(':')[0];
    let curMins = this.currentTime.split(' ')[0].split(':')[1];
    let isAmPm = this.currentTime.split(' ')[1];
    if (this.currentTime !== undefined && this.selectedDate === undefined || this.selectedDate === this.todaySelectedDate) {
      getTodaysStartingTime = formatDate(new Date(new Date(new Date(date).setHours(isAmPm === 'AM' ? curHour : curHour + 12, curMins, 0))), 'yyyy-MM-ddTHH:mm:ss', 'en');
    } else {
      getTodaysStartingTime = formatDate(new Date(new Date(new Date(date).setHours(0, 0, 0))), 'yyyy-MM-ddTHH:mm:ss', 'en');
    }
    // Get the end time today
    var getTodaysEndingTime = formatDate(new Date(new Date(new Date(date).setHours(23, 59, 59))), 'yyyy-MM-ddTHH:mm:ss', 'en');
    // Next 7 days  date
    var upcoming = {
      filter: {
        viewby: ['appointmentDate'],
        time: {
          startTime: getTodaysStartingTime,
          endTime: getTodaysEndingTime,
        },
      },
      pagination: { page: 1, limit: 100 },
      search: '',
      timezone: this.getTimeZone().toString()
    };
    const payload = {
      type: 'POST',
      url: 'adminappointment',
      isDeveloper: true,
      body: upcoming,
      params: { type: 'upcomming' }
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.rightSideAppointmentsData = this.massageAppointmentList(appointmentList, res.data.list);
        this.rightSidePanelFinalData.slotsData = this.slotsavailability;
        this.rightSidePanelFinalData.appoinmnetData = this.rightSideAppointmentsData;
        this.rightSidePanelFinalData['selectedDate'] = this.selectedDate;
        this.rightSidePanelFinalData['todayDate'] = this.todaySelectedDate;
        this.rightSidePanelFlag = true;
        this.timestamp = res.data.timestamp;
      } else {
        let message = 'Failed to fetch Appointments list data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Massage the Appointments List
  massageAppointmentList(allList, data) {
    var finalList = [];
    data.forEach(list => {
      allList.forEach(eachAppointment => {
        if (eachAppointment.appointmentTypeId === list.appointmentTypeId) {
          let endDate = this.timeAmPmConvertion(this.addMinutes(list.appointmentTime, eachAppointment.duration));
          list.appointmentTime = this.timeAmPmConvertion(list.appointmentTime);
          let obj = { ...list };
          obj['endDate'] = endDate;
          obj['name'] = list.patient.firstName + ' ' + list.patient.lastName;
          finalList.push(obj);
        }
      });
    });

    return finalList;
  }
  //  Get timeZone
  getTimeZone() {
    var offset = new Date().getTimezoneOffset();
    var o = Math.abs(offset);
    return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
  }
  massageTreatments(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        ...each,
        treatments: this.massageAppointments(each.treatments, this.metaData)
      };
    });
  }
  massageAppointments(treatment, meta) {
    if (!treatment && !Array.isArray(treatment)) { return []; }
    return treatment.map(treat => {
      return {
        ...treat,
        duration: this.getDuration(treat, meta)
      };
    });
  }
  getDuration(treat, meta) {
    let appointment;
    appointment = meta.apttypes.filter(eachtype => eachtype.appointmentTypeId === treat.appointmentTypeId);
    return appointment[0].duration;
  }
  // time slots getting from API call
  filteringSlots(metaSlots) {
    return metaSlots.map(slot => {
      return {
        ...slot,
        label: this.getLable(slot.begin, slot.end),
        value: `${slot.begin}-${slot.end}`,
        img: slot.slotName,
        ...this.getHeightAndWidth(slot.slotName),
        id: 'radio'
      };
    });
  }

  getHeightAndWidth(data) {
    if (data === 'Morning') {
      return {
        width: '27',
        height: '16'
      };

    } else if (data === 'Mid-day') {
      return {
        width: '25',
        height: '25'
      };
    } else if (data === 'Afternoon') {
      return {
        width: '27',
        height: '23'
      };
    } else if (data === 'Evening') {
      return {
        width: '20',
        height: '19'
      };
    } else if (data === 'Night') {
      return {
        width: '20',
        height: '19'
      };
    }
  }
  // converting begin,end to time labels
  getLable(start, end) {
    if (this.viewBy === 'day') {
      let from;
      let to;
      from = start.split(':');
      to = end.split(':');
      if (from[0] < 12) {
        // tslint:disable-next-line: radix
        return `${parseInt(from[0])}:${from[1]}-${parseInt(to[0])}:${parseInt(to[1])} AM`;
      } else {
        if (from[0] === '12') {
          return `${from[0]}:${from[1]}-${to[0] - 12}:${to[1]} PM`;
        } else {
          return `${from[0] - 12}:${from[1]}-${to[0] - 12}:${to[1]} PM`;
        }
      }
    } else {
      let from;
      let to;
      from = start.split(':');
      to = end.split(':');
      if (from[0] < 12) {
        // tslint:disable-next-line: radix
        return `${parseInt(from[0])}:${from[1]}-${parseInt(to[0])}:${parseInt(to[1])} AM`;
      } else {
        if (from[0] === '12') {
          return `${from[0]}:${+from[1] + 30}-${to[0] - 12}:${to[1]} PM`;
        } else {
          return `${from[0] - 12}:${+from[1] + 30}-${to[0] - 12}:${to[1]} PM`;
        }
      }
    }
  }

  // massaging location and providers data
  massageDropDownData(data) {
    let dropDownData = {
      locations: data.locations,
      providers: data.providers
    };
    return dropDownData;
  }

  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }

  checkingSidebarSetStatus(data) {
    this.sideBarVisible = data;
    this.checkingSidebarState(data);
  }

  navigateAppointments() {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`]);
  }

  // Appointments detatils UI on dashboard
  appointmentDetails() {
    this.getAppointmentDetails();
  }


  // hide side panel
  hideTheSideBar() {
    this.sideBarVisible = false; // side panel scroll visible
    this.showHideDataFlag = true; // left side content toggle flag
    this.rightSidePanelUpcommingInfoShowHideStatus = false; //
    this.patientDetailsUIStatus = false; // patient data ui side overlay satatus flag
    this.blockDetailsStaus = false; // blocked slot UI flag
    this.formUI = false; // all side overlay flags
    this.reshedulePatientDataFlag = false; // reshedule flag
    this.isBlockSlotActionStatus = false; // Hide the block slot Ui
    this.blockDetailsStaus = false;
    this.blockSlotConformationPopup = false;
    this.formServices.bookSlotConfig.forEach(eachFiled => {
      if (eachFiled.controlType === 'lastVisit') {
        eachFiled.showontoggle = false;
      }
    });
  }

  // Shedule UI on dashboard
  bookSlot() {
    this.sideBarTitle = 'Book ' + this.bookedAppointmentName + ' slot for';
    this.bookAdminSheduleUI = true;
    this.blockSlotInputs = this.formServices.initForm(this.formServices.formTypes.BOOKSLOT);
    this.getAllTreatmentsDataLength();
    this.getsAvailabilityData(this.params);
    this.isBlockSlotActionStatus = true;
    this.resheduleDateValue = this.dropDownTimevalue;
    this.changeTheDropTimevalue = this.appointmentTime;
  }

  //  checking the side bar status
  showHideSlideBarUI(showSideBarUIStatus) {
    this.sideBarVisible = true;
    switch (showSideBarUIStatus) {
      case 'appointmentList':
        this.rightSidePanelUpcommingInfoShowHideStatus = true;
        break;
      case 'appointmentDetails':
        this.patientDetailsUIStatus = true;
        break;
      case 'blockedDetails':
        this.blockDetailsStaus = true;
        break;
      case 'formUI':
        this.formUI = true;
        break;
    }
  }

  // Formating todays date
  formatDate() {
    var d = new Date();
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  // Get permmistions API Call
  getsAvailabilityData(params, isDefault?) {
    const payloadData = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      donotShowLoader: this.apiLoaderFlag ? true : false,
      params
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.resheduleSetdata = true;
        this.metaData = res.data.metadata;
        this.locationId = params.locationId;
        this.metaData.locations.every(loc => loc.locationId.toLowerCase() === this.locationId.toLowerCase() ? (this.locationType = loc.locationType, this.locationName = loc.locationName, false) : true);
        this.setValuesFields(res.data.metadata);
        this.massageTimeSlats(res.data, isDefault, params);
        if (this.apiLoaderFlag === false) {
          this.showHideSlideBarUI('formUI');
        }
      } else {
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting availability data');
      }
    });
  }

  // Setting locations and trements data for the form fields and form controls
  setValuesFields(data) {
    this.blockSlotInputs.formInputs.forEach(field => {
      if (field.key === 'date' && (this.isBookSlotSetdata === true || this.resheduleSetdata === true)) {
        field.value = this.calendarDateFormate(this.dropDownTimevalue);
      }
    });
    this.isBookSlotSetdata = false;
  }

  // Calender date formate
  calendarDateFormate(date) {
    let dates = date.split('-');
    return dates[1] + '/' + dates[2] + '/' + dates[0];
  }
  calendarTimeFormate(time) {
    let times = time.split(':');
    return times[0] + ':' + times[1];
  }
  timeAmPmConvertion(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    var index = time.indexOf(time[3]);
    if (index !== -1) {
      time.splice(index, 1);
    }
    return time.join(''); // return adjusted time or original string
  }
  D(J) { return (J < 10 ? '0' : '') + J; }
  addMinutes(time, minsToAdd) {

    var piece = time.split(':');

    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    // tslint:disable-next-line:no-bitwise
    return this.D(mins % (24 * 60) / 60 | 0) + ':' + this.D(mins % 60);
  }

  // Massaging the time slots data
  massageTimeSlats(data, isDefault?, params?) {
    let appointmentTime = '';
    if (data) {
      let allSlats = [];
      let slots = data.availability.calendar[0].slots;
      var timeDuration;
      var startTime;
      var endTime;
      var slotsFilterStatus = true;
      let allLocations = [];
      let getDropDownSelectTimeArray = [];
      let getDropDownSelectTime = slots.forEach(eachSlot => {
        if (eachSlot.available === true) {
          getDropDownSelectTimeArray.push(eachSlot);
        }
      });
      let getAppointmentTime = (this.appointmentTime === 'null') ? getDropDownSelectTimeArray.length > 0 ? getDropDownSelectTimeArray[0].time : '' : this.appointmentTime;
      data.metadata.appointmentTypes.forEach(eachAppointment => {
        if (eachAppointment.appointmentTypeId === this.appointmentTypeId) {
          this.bookedAppointmentName = eachAppointment.appointmentTypeName;
          timeDuration = eachAppointment.duration;
          this.timeDurationForShedule = timeDuration;
          this.appointmentLocations = eachAppointment.locations;
        }
      });
      data.metadata.locations.forEach(eachLocation => {
        this.appointmentLocations.forEach(locationId => {
          if (locationId === eachLocation.locationId) {
            allLocations.push({
              ...eachLocation,
              value: eachLocation.locationId,
              label: eachLocation.locationName,
            });
          }
        });
      });
      let subTextTitle = this.bookAdminSheduleUI ? 'Book ' : 'Reshedule ';
      this.sideBarTitle = subTextTitle + this.bookedAppointmentName + ' slot for';
      let selectedTime = this.timeAmPmConvertion(getAppointmentTime);
      let endSelectedTime = this.timeAmPmConvertion(this.addMinutes(getAppointmentTime, timeDuration));
      let selectedTimeObj = {
        label: selectedTime + '  ' + '-' + '  ' + endSelectedTime + '(' + ' ' + timeDuration + 'min )',
        value: getAppointmentTime
      };
      if (!isDefault) {
        allSlats.push(selectedTimeObj);
      }
      slots.forEach(eachSlot => {
        let slotObj = {};
        if (eachSlot.available === slotsFilterStatus) {
          startTime = this.timeAmPmConvertion(eachSlot.time);
          endTime = this.timeAmPmConvertion(this.addMinutes(eachSlot.time, timeDuration));
          let optionLabel = startTime + '  ' + '-' + '  ' + endTime + '(' + ' ' + timeDuration + 'min )';
          slotObj['label'] = optionLabel;
          slotObj['value'] = eachSlot.time;
          allSlats.push(slotObj);
          if (eachSlot.time === getAppointmentTime) {
            appointmentTime = startTime;
            getAppointmentTime = '';
          }
        }
      });
      allSlats = this.getUnique(allSlats, 'value');
      if (allSlats.length > 0) {
        this.blockSlotInputs.formInputs.forEach(field => {
          if (field.key === 'timeSlots') {
            field.list = [];
            field.list = allSlats;
            if (field.list.length > 0) {
              field.value = field.list[0].value;
            } else {
              this.blockSlotInputs.formGroup.controls['timeSlots'].setErrors({ required: true });
            }
          } else if (field.key === 'locations') {
            field.list = [];
            field.list = allLocations;
            if (field.list.length > 0) {
              field.value = params.locationId;
              if (this.queryParams.value.offline === '1') {
                if (field.controlType === 'dropdown') {
                  // tslint:disable-next-line:only-arrow-functions
                  field.list = field.list.filter(function (obj) {
                    return obj.locationType !== 'online';
                  });
                }
              }
            }

          }
        });
      } else {
        this.blockSlotInputs.formInputs.forEach(field => {
          if (field.key === 'timeSlots') {
            field.list = [];
            field.list = allSlats;
            if (field.list.length > 0) {
              field.value = field.list[0].value;
            } else {
              let slotObj = {};
              slotObj['label'] = 'There are no available slots. Please select another date';
              slotObj['value'] = '';
              slotObj['disabled'] = true;
              field.list = [slotObj];
            }
          } else if (field.key === 'locations') {
            field.list = [];
            field.list = allLocations;
            if (field.list.length > 0) {
              field.value = params.locationId;
            }
          }

        });
        this.blockSlotInputs.formGroup.controls['timeSlots'].setErrors({ name: true });
      }
    }
  }


  // This functionality for removing duplicates from an array
  // @param arr :array of objects
  // @param comp :string
  //
  // this.getUnique(discoveryList,'status')
  getUnique(arr, comp) {
    // store the comparison  values in array
    let unique = arr.map(e => e[comp])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }

  // Shedule appointment final data
  appointmentFormSubmit(data) {
    this.fromData = data;
    let currentDate: any = new Date();
    let timeDiff: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
    currentDate = currentDate.toLocaleString().split(', ');
    let timestampDate = currentDate[0];
    let timestamp = currentDate[1].substring(0, currentDate[1].length - 6) + currentDate[1].slice(-3);
    if (this.bookAdminSheduleUI && !this.assignBlockedUIFlag) {
      this.bookSlotBody = {
        appointmentDate: formatDate(new Date(data.value.date), 'yyyy-MM-dd', 'en'),
        appointmentTime: data.value.timeSlots,
        appointmentTypeId: this.appointmentTypeId,
        locationId: this.locationId,
        patientId: data.value.patientSearch.id,
        timeDiff,
        timestamp,
        timestampDate,
        userId: this.patientUserId,
      };
      if (this.patientNeedProfileUpdate) {
        this.action = 'profileUpdate';
        this.adminBookSlotCancelPopupShowHide = true;
      } else if (this.patientNeedModify) {
        this.action = 'modifyShow';
        this.adminBookSlotCancelPopupShowHide = true;
        this.lastVisitData = data;
      } else {
        this.checkLastVist(this.bookSlotBody, data);
      }
    } else if (!this.bookAdminSheduleUI && this.assignBlockedUIFlag) {
      this.assignBlockSlotBody = {
        fromDate: formatDate(new Date(data.value.fromDate), 'yyyy-MM-dd', 'en'),
        toDate: formatDate(new Date(data.value.toDate), 'yyyy-MM-dd', 'en'),
        fromTime: (typeof (data.value.fromTime) === 'object') ? formatDate(new Date(data.value.fromTime), 'HH:mm:ss', 'en') : data.value.fromTime + ':00',
        toTime: (typeof (data.value.toTime) === 'object') ? formatDate(new Date(data.value.toTime), 'HH:mm:ss', 'en') : data.value.toTime + ':00',
        treatment: data.value.treatments,
        location: this.locationId
      };
      if (this.noOfBookedCount !== 0) {
        this.blockSlotConformationPopup = true;
        this.noOfBookedCount = 0;
      } else {
        this.blockSlotApiCall(this.assignBlockSlotBody);
      }
    } else {
      let rescheduleBody = {
        userId: this.patientUserId,
        type: 'RESCHEDULE',
        // appointmentTypeId: this.appointmentTypeId,
        locationId: data.value.locations,
        locationType: this.locationType,
        appointmentDate: formatDate(new Date(data.value.date), 'yyyy-MM-dd', 'en'),
        appointmentTime: data.value.timeSlots,
        reason: {
          reason: data.value.reason_for_reschedule,
          details: data.value.reasonDetails
        },
        timeDiff
      };
      this.rescheduleAppointment(rescheduleBody);
    }
  }

  //  Reshedule appointment API call
  rescheduleAppointment(rechecduleData) {
    let payload = {
      type: 'PUT',
      url: 'adminReshedule',
      isDeveloper: true,
      pathVariables: [this.appointemntId],
      body: rechecduleData
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, ' Your appointment has been rescheduled!!');
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`]);
        this.blockSlotInputs.formGroup.reset();
        this.checkingSidebarState(false);
        this.hideTheSideBar();
      } else {
        let message = 'Failed to rescheduled an appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Block slot API call
  blockSlotApiCall(bodyData) {
    var payloadData;
    if (this.blockSlotEditAPIFlag) {
      payloadData = {
        type: 'PUT',
        url: 'deleteBlockSlot',
        isDeveloper: true,
        body: bodyData,
        pathVariables: [this.blokSlotUseId]
      };
    } else {
      payloadData = {
        type: 'POST',
        url: 'blockSlot',
        isDeveloper: true,
        body: bodyData,
      };
    }
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.hideTheSideBar();
        this.blockSlotInputs.formGroup.reset();
        if (this.blockSlotEditAPIFlag) {
          this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully updated the blocked slot');
        } else {
          this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Selected time slot blocked successfully');
          this.blokSlotUseId = res.data.id;
        }
        this.assignBlockedUIFlag = false;
        this.blockSlotEditAPIFlag = false;
        this.getDashboardGridDataAPI(false);
        this.blockDetails();
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in blocking the slot';
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

 /**
   * Fetches the admin profile information from the admin profile service
   * and updates the treatments count and filter data.
   * If the fetch is successful, it also adds a specific treatment type
   * to the beginning of the treatment list.
   */
  getAllTreatmentsDataLength() {
    // Fetches the admin invoice information from admin invoice service
    this.adminInvoiceFilterService.getInvoice().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = res.data.treatment.length;
        this.getFilterData = res.data;
        this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
      } else {
        // Error
        let message = 'Failed to fetch filter data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  adminCancelPopupShowHideStatusFun(status, show?, label?) {
    this.adminBookSlotCancelPopupShowHide = status.cancelpopup;
    if (show && label === 'unblock') {
      this.blockSlotInputs.formInputs.forEach(each => {
        if (each.key === 'patient_name_id') {
          each.label = `${this.patientsDetails.details.firstName} ${this.patientsDetails.details.lastName}`;
          each.label1 = `${this.patientsDetails.details.patientId}`;
        }
      });
      this.getBlockPatientDetails();
    } else if (show && label === 'proceed') {
      this.createAppontment(this.bookSlotBody);
    } else if (show && label === 'lastValid') {
      this.proceedAction();
    } else if (show && (label === 'modifyShow' || label == 'profileUpdate')) {
      this.checkLastVist(this.bookSlotBody, this.lastVisitData);
    }
  }

  teleAppCancelPopupShowHideStatusFun(status, show?, label?) {
    this.teleAppCancelPopupShowHideStatusFlag = status.cancelpopup;
    if (this.offLinePatientStatus === true) {
      this.offLinePatientStatus = false;
      this.blockSlotInputs.formInputs.forEach(field => {
        if (field.key === 'patientSearch') {
          field.value = '';
          this.blockSlotInputs.formGroup.controls[field.key].setValue('');
          this.blockSlotInputs.formGroup.controls[field.key].patchValue('');
        }
      });
    }
  }

  proceedAction() {
    this.blockedServices = this.patientsDetails.details.blockStatus.map(each => each.name);
    this.appointmentTypeName = this.metaData.appointmentTypes.filter(apptType => apptType.appointmentTypeId === this.appointmentTypeId);
    this.appointmentTypeName = this.appointmentTypeName.map(each => {
      return {
        ...each,
        name: (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && this.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} Online` : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && this.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName}` : each.appointmentTypeName
      };
    });
    if (this.blockedServices.length === 0 || !this.blockedServices.includes(this.appointmentTypeName[0].name)) {
      this.createAppontment(this.bookSlotBody);
    } else {
      this.action = 'unblock';
      this.adminBookSlotCancelPopupShowHide = true;
    }
  }

  checkLastVist(bodyData, data) {
    const payloadData = {
      type: 'POST',
      url: 'adminLastValid',
      isDeveloper: true,
      body: bodyData,
      // donotShowLoader: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      this.bookAdminSheduleUI = true;
      if (res.status === 'success') {
        this.action = 'unblock';
        this.blockedServices = this.patientsDetails.details.blockStatus.map(each => each.name);
        this.appointmentTypeName = this.metaData.appointmentTypes.filter(apptType => apptType.appointmentTypeId === this.appointmentTypeId);
        this.appointmentTypeName = this.appointmentTypeName.map(each => {
          return {
            ...each,
            name: (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && bodyData.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} Online` : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && bodyData.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} In Office` : each.appointmentTypeName
          };
        });
        if (this.blockedServices.length === 0 || !this.blockedServices.includes(this.appointmentTypeName[0].name)) {
          this.createAppontment(bodyData);
        } else {
          this.adminBookSlotCancelPopupShowHide = true;
        }
      } else {
        this.lastValidMessage = res.error.message + ' Do you want to proceed?';
        this.action = 'lastValid';
        this.adminBookSlotCancelPopupShowHide = true;
      }
    });
  }

  // Cancel button actions
  cancelButtonEvent(data) {
    // Click on block a slot state start
    if (this.isBlockSlotActionStatus) {
      this.hideTheSideBar();
      this.sideBarVisible = true;
      this.blockedSlotStatus();
      this.assignBlockedData = {
        fromDate: this.resheduleDateValue,
        toDate: this.resheduleDateValue,
        fromTime: this.changeTheDropTimevalue,
        toTime: this.addMinutes(this.changeTheDropTimevalue, this.timeDurationForShedule),
        treatment: [this.appointmentTypeId],
        location: this.locationId
      };
      // Click on block a slot state end
    } else {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`]);
    }
  }

  // Archive API call
  archiveAPICall() {
    const payloadData = {
      type: 'PUT',
      url: 'patientArchive',
      isDeveloper: true,
      pathVariables: [this.patientId, 'active'],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully activated patient status');
      } else {
        let message = 'Failed to fetch patient data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Create new appointment
  createAppontment(bodyData) {
    const payloadData = {
      type: 'POST',
      url: 'createAppointment',
      isDeveloper: true,
      body: bodyData
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.checkingSidebarSetStatus(false);
        this.dateArray = [];
        this.dateArray.push(bodyData.appointmentDate);
        this.getDashboardGridDataAPI(false);
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Created new appointment successfully');
        this.getDashboardGridDataAPI(false);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in creating new appointment';
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // auto search event
  searchReslutEvent(data) {
    // this.searchInputText = data.event.query;
    this.searchInputText = data.event.target.value;
    this.searchFieldName = data.key;
    let body = {
      search: this.searchInputText,
      notType: 'pending'
    };

    if (this.searchInputText !== undefined) {
      this.getPatientsList(body);
    } else {
      this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Please enter any patient name to search.');
      console.log(body);
    }
  }

  // Get patients list API call
  getPatientsList(bodyData) {
    const payloadData = {
      type: 'POST',
      url: 'adminPatientSearch',
      isDeveloper: true,
      body: bodyData,
      appointmentTypeId: this.appointmentTypeId,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.patientsList = this.massagePatientList(res.data);
        this.flterData(this.searchInputText);
      } else {
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting patientList data');
      }
    });
  }

  // MassagePatientList  Data
  massagePatientList(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.filter(each => each.removedFlag === 0).map(each => {
      return {
        nameOnly: (!each.firstName && !each.lastName) ? '-' :
          (each.firstName) + ' ' + (each.lastName),
        name: (!each.firstName && !each.lastName && !each.patientId) ? '-' :
          (each.firstName) + ' ' + (each.lastName) + '(' + each.patientId + ')',
        id: each.patientId,
        status: each.status,
      };
    });
  }


  // Setting data to auto search
  flterData(searchQuery) {
    let filtered: any[] = [];
    let query = searchQuery;
    this.blockSlotInputs.formInputs.forEach(field => {
      if (field.key === this.searchFieldName) {
        field.results = this.patientsList;
        field.results.forEach((eachResult, index) => {
          let searchResult = eachResult;
          filtered.push(searchResult);
          let matchText = searchResult.nameOnly.toLowerCase();
        });
      }
    });
    this.searchResultList = filtered;
  }

  // Click event on date dropdown
  getdropDownData(data) {
    if (data.field === 'fromTime' || data.field === 'toTime') {
    } else {
      this.dropDownTimevalue = formatDate(new Date(data), 'yyyy-MM-dd', 'en');
      let dataBody = {
        beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
        endTime: '23:55:00',
        startDate: this.dropDownTimevalue,
        days: 1,
        metadata: true,
        appointmentTypeId: this.appointmentTypeId,
        locationId: this.locationId
      };
      this.getsAvailabilityData(dataBody);
    }
  }
  getDateAvailability(data) {
    if (data.field) {
      this.dropDownTimevalue = formatDate(new Date(data.value), 'yyyy-MM-dd', 'en');
    } else {
      this.dropDownTimevalue = formatDate(new Date(data), 'yyyy-MM-dd', 'en');
    }
    let dataBody = {
      beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
      endTime: '23:55:00',
      startDate: this.dropDownTimevalue,
      days: 1,
      metadata: true,
      appointmentTypeId: this.appointmentTypeId,
      locationId: this.locationId
    };
    this.getsAvailabilityData(dataBody, true);
  }
  // Block status fun end

  // get the patient data on patient dropdown options
  getPatientId(data) {
    this.getPatientDetails(data, this.blockSlotInputs.formInputs);
  }

  // Get getPatientDetails API call
  getPatientDetails(id, formInputs) {
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [id]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.patientsDetails = this.massagePatientDetails(res.data);
        this.patientUserId = this.patientsDetails.details.userId;
        this.patientNeedModify = this.patientsDetails.details.longTimeUpdate;
        this.patientNeedProfileUpdate = this.patientsDetails.details.isInactive;
        this.patientId = this.patientsDetails.details.patientId;
        this.patientsDetails.details.blockStatus = this.patientsDetails.details.blockStatus.map(each => {
          return {
            ...each,
            name: (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && each.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} Online` : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && each.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName}` : each.appointmentTypeName,
          };
        });
        this.patientName = (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName) + ' ';
        this.sendLastVisistData(this.patientsDetails, formInputs);
        this.offLineStatus();
        if (this.patientsDetails.details.status === 'archive') {
          this.adminBookSlotCancelPopupShowHide = true;
          this.action = 'active';
        }
        if (this.resheduleSlotsStaus) {
          this.reshedulePatientDataFlag = true;
          this.resheduleSlotsStaus = false;
        }
      } else {
        // Error
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting  patientsDetails data');
      }
    });
  }

  massagePatientDetails(data) {
    if (!data && typeof (data) !== 'object') { return {}; }
    return data = {
      ...data,
      pastDue: data.details.pastdue ? `$ + ${data.details.pastdue}` : '---',
    };
  }

  // Setting data to last visit component
  sendLastVisistData(patientDetails, fromInputs) {
    if (patientDetails) {
      fromInputs.forEach(input => {
        if (input.controlType === 'lastVisit') {
          input.value = patientDetails;
          input.showontoggle = true;
        }
      });
    }
  }

  // reshedule blocks fun start
  reshedule() {
    this.resheduleSlotsStaus = true;
    this.blockSlotInputs = this.formServices.initForm(this.formServices.formTypes.RESHEDULESLOT);
    this.appointmentTime = '02:00:00';
    let dataBody = {
      beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
      endTime: '23:55:00',
      startDate: this.dropDownTimevalue,
      days: 1,
      metadata: true,
      appointmentTypeId: this.appointmentTypeId,
      locationId: this.locationId
    };
    this.getsAvailabilityData(dataBody);
    this.getPatientDetails(this.reshedulePatientID, this.blockSlotInputs.formInputs);
  }
  resheduleOnRediection(data) {
    this.resheduleSlotsStaus = true;
    this.blockSlotInputs = this.formServices.initForm(this.formServices.formTypes.RESHEDULESLOT);
    this.patientId = data.patientId;
    this.appointemntId = data.appointmentId;
    this.dropDownTimevalue = data.appointmentDate;
    this.appointmentTime = data.appointmentTime;
    this.appointmentTypeId = Number(data.appointmentTypeId);
    this.locationId = data.locationId;
    let dataBody = {
      beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
      endTime: '23:55:00',
      startDate: this.dropDownTimevalue,
      days: 1,
      metadata: true,
      appointmentTypeId: this.appointmentTypeId,
      locationId: data.locationId
    };
    this.getsAvailabilityData(dataBody);
    this.getPatientDetails(this.patientId, this.blockSlotInputs.formInputs);
  }


  getLocationAvailability(data) {
    if (data && data.value && data.key !== 'timeSlots') {
      this.locationId = data.value;
      let dataBody = {
        beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
        endTime: '23:55:00',
        startDate: this.dropDownTimevalue,
        days: 1,
        metadata: true,
        appointmentTypeId: this.appointmentTypeId,
        locationId: this.locationId
      };
      // tslint:disable-next-line:no-unused-expression
      this.locationId.includes(':') ? null : this.getsAvailabilityData(dataBody, true);
    }
  }

  // Bloked the slot actions
  blockedSlotEvent(data) {
    if (data.actionId === 1) {
      this.hideTheSideBar();
      this.sideBarVisible = true;
      this.assignBlockedData = {
        fromDate: this.blockedSlotsObj.beginDate,
        toDate: this.blockedSlotsObj.endDate,
        fromTime: this.blockedSlotsObj.beginTime,
        toTime: this.blockedSlotsObj.endTime,
        treatment: this.getBlockedTreatmentsList(),
        location: this.blockedSlotsObj.locationId
      };
      this.blockSlotEditAPIFlag = true;
      this.blockedSlotStatus();
    } else {
      this.deleteBlockSlotAPICall();
    }
  }
  // Massage the treatments array
  getBlockedTreatmentsList() {
    let trementsArray = [];
    this.blockedSlotsObj.treatments.forEach(eachTreatment => {
      trementsArray.push(eachTreatment.appointmentTypeId);
    });
    return trementsArray;
  }
  // Apply the blocked status
  blockedSlotStatus() {
    this.sideBarTitle = 'Blocked slots';
    this.assignBlockedUIFlag = true;
    this.bookAdminSheduleUI = false;
    this.getFilterDataForBlockSlot();
  }

  // Admin get filterData API call
  getFilterDataForBlockSlot() {
    const payloadData = {
      type: 'GET',
      url: 'filterData',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getFilterLocationsList(res.data);
        this.blockSlotInputs = this.formServices.initForm(this.formServices.formTypes.BLOCKEDSLOT);
        this.assignValuesToBlokedData(this.assignBlockedData);
        this.showHideSlideBarUI('formUI');
      } else {
        // Error

      }
    });
  }

  // Assigning filter data to filterComponent
  getFilterLocationsList(data) {
    this.formServices.blockedSlotConfig.forEach(element => {
      if (element.key === 'treatments') {
        element.options = [];
        data['treatment'].forEach(eachloaction => {
          let treatmentObj = {};
          treatmentObj['id'] = eachloaction.appointmentTypeId,
            treatmentObj['name'] = eachloaction.appointmentTypeName,
            treatmentObj['isSelected'] = false,
            element.options.push(treatmentObj);
        });
      }
    });
  }

  assignValuesToBlokedData(data) {
    this.formServices.blockedSlotConfig.forEach(input => {
      if (input.key === 'fromDate') {
        input.value = this.calendarDateFormate(data.fromDate);
      }
      if (input.key === 'toDate') {
        input.value = this.calendarDateFormate(data.toDate);
      }
      if (input.key === 'treatments') {
        this.trementsArray = data.treatment;
        let selectedTreatmentsList = [];
        input.options.forEach(eachOption => {
          this.trementsArray.forEach(eachId => {
            eachOption.editable = true;
            if (eachId === eachOption.id) {
              eachOption.isSelected = true;
              selectedTreatmentsList.push(eachOption.id);
            }
          });
        });
        this.blockSlotInputs.formGroup.controls['treatments'].patchValue(selectedTreatmentsList);
      }
      if (input.key === 'fromTime') {
        input.value = this.calendarTimeFormate(data.fromTime);
        this.blockSlotInputs.formGroup.controls['fromTime'].patchValue(this.calendarTimeFormate(data.fromTime));
      }
      if (input.key === 'toTime') {
        input.value = this.calendarTimeFormate(data.toTime);
        this.blockSlotInputs.formGroup.controls['toTime'].patchValue(this.calendarTimeFormate(data.toTime));
      }
    });
  }
  // Bloked the slot actions


  // Appointmnet details actions
  // Get appointment details API call
  getAppointmentDetails() {
    const payloadData = {
      type: 'GET',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [this.appointmentId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getTreatmentsDataLength(res.data);
      } else {
        // Error
      }
    });
  }

  /**
   * Fetches the treatments data length and updates the dashboard appointment details.
   * It retrieves the admin invoice information and processes the treatment data.
   * 
   * @param appointmentDate - The date of the appointment to be processed.
   */
  getTreatmentsDataLength(appoinmentDate) {
    // Fetches the admin invoice information from admin invoice service
    this.adminInvoiceFilterService.getInvoice().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = Object.keys(res.data.treatment).length;
        this.getFilterData = res.data;
        this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
        this.dashboardAppointmentDetails = appoinmentDate;
        this.dashboardAppointmentDetails.appointmentInfo.status = this.massageAppointmentStatus(this.dashboardAppointmentDetails.appointmentInfo, this.dashboardAppointmentDetails.timestamp, this.dashboardAppointmentDetails.patientInfo);
        this.dashboardAppointmentDetails.appointmentInfo.doctor = this.massageDoctorsData(this.dashboardAppointmentDetails.appointmentInfo.doctor);
        this.sideBarVisible = true;
        this.patientDetailsUIStatus = true;
        this.showHideSlideBarUI('appointmentDetails');
      } else {
        let message = 'Failed to get treatments details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // This will massage Doctors data
  massageDoctorsData(doctor) {
    if (doctor) {
      return {
        ...doctor,
        status: '',
        isSelected: true,
      };
    }
  }

  // This will massage the appointment status
  massageAppointmentStatus(data, timestamp, patient) {
    if (data) {
      this.appointmentDetailsActions = this.massageActionList(data.treatment, data.status, data.invoiceStatus, 'appointment_details', patient);
      this.showActions = this.appointmentDetailsActions.slice(0, 2);
      this.moreActions = this.appointmentDetailsActions.slice(2);
      let status = data.status;
      // '2020-11-19T00:00:00.000Z'
      let appointmentStartDate: any = `${data.appoinmentDate}T${data.timeSlotFrom}`;
      let appointmentEndDate: any = `${data.appoinmentDate}T${data.timeSlotTo}`;
      appointmentStartDate = new Date(appointmentStartDate).valueOf();
      appointmentEndDate = new Date(appointmentEndDate).valueOf();
      // let currentDate: any = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}T00:00:00:000Z`;
      let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, timestamp)).valueOf();
      if (status === 'A') {
        let removableActions = ['send_reminder', 'reschedule_appointment', 'cancel_appointment'];
        if (currentDate < appointmentStartDate) {
          return 'Active';
        } else if (currentDate >= appointmentStartDate && currentDate <= appointmentEndDate) {
          return 'Inprogress';
        } else {
          this.appointmentDetailsActions = this.appointmentDetailsActions.filter(action => !removableActions.includes(action.id));
          this.showActions = this.appointmentDetailsActions.slice(0, 4);
          this.moreActions = this.appointmentDetailsActions.slice(4);
          return 'Appointment completed';
        }
      } else {
        return this.getAppointmentStatus(status);
      }
    }
  }

  // Get appointment status
  getAppointmentStatus(appoinmnetStatus) {
    let status = '';
    switch (appoinmnetStatus) {
      case 'N':
        status = 'Pending payment';
        break;
      case 'P':
        status = 'Pending';
        break;
      case 'A':
        status = 'Appointment confirmed';
        break;
      case 'C':
        status = 'Appointment completed';
        break;
      case 'B':
        status = 'Mark as absent';
        break;
      case 'I':
        status = 'Invoice generated';
        break;
      case 'D':
        status = 'Appointment cancelled';
        break;
    }
    return status;
  }

  massageActionList(appointmentTypeName, appointmentStatus, invoiceStatus, page, appointment) {
    let actions;
    actions = getActionsList(appointmentTypeName, appointmentStatus, invoiceStatus, page),
      actions.forEach(eachAction => {
        if (eachAction.id === 'block_patient') {
          if (appointment.blockStatus.length === this.treatmentsCount + 1) {
            eachAction.label = 'Unblock';
          } else {
            eachAction.label = 'Block Patient';
          }
        }
      });
    return actions;
  }

  // This is closing for block patient the popup
  blockPatientPopupShowHideStatusFun(status) {
    this.blockPatientPopupShowHide = status.cancelpopup;
  }
  // Get getPatientDetails API call
  getBlockPatientDetails() {
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getBlockTreatmentsData(res.data.details.blockStatus);
        // this.getTreatmentsDataLength(res.data);
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Admin get filterData API call
  getBlockTreatmentsData(blockstatus) {
    this.rawTreatmentFilterData = this.massageTreatmentsFilterData(this.getFilterData, blockstatus);
    this.blockPatientPopupShowHide = true;
    // this.checkingSidebarSetStatus(true);
  }

  // Massage the filter data
  massageTreatmentsFilterData(data, blockstatus) {
    if (data && typeof (data) === 'object') {
      data.treatment = data.treatment.map(doc => {
        doc['isSelected'] = false;
        doc['locationId'] = (doc.locationId) ? doc.locationId : null;
        doc['name'] = (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} Online` : (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} In Office` : doc.appointmentTypeName;
        blockstatus.forEach(each => {
          if (each.appointmentTypeId === doc.appointmentTypeId && each.locationId === doc.locationId) {
            doc['isSelected'] = true;
          }
        });
        return {
          ...doc,
          value: doc.appointmentTypeId,
        };
      });
      return data;
    } else {
      // Error
    }
  }

  // Block patient Save action
  blockPatientSubmit(data) {
    // Block API call
    this.blockTheUserAPI(data);
  }

  // Block API PUT Call
  blockTheUserAPI(bodyData) {
    const payloadData = {
      type: 'PUT',
      url: 'patientActions',
      isDeveloper: true,
      body: bodyData,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (Number(res.data.patientId) === this.patientId) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient blocked status');
          this.formServices.bookSlotConfig.forEach(eachFiled => {
            if (eachFiled.controlType === 'lastVisit') {
              eachFiled.showontoggle = false;
            }
          });
          this.updateBlockPatientDetails();
        }
      } else {
        // Error
        let message = 'Failed to uplodate patient block status. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Get getPatientDetails API call
  updateBlockPatientDetails() {
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.blockPatientApplyStatus = true;
        this.blockPatientPopupShowHide = false;
        this.blockSlotInputs.formGroup.controls['patientSearch'].patchValue('');
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  sendReminder(appointmentData) {
    let payload = {
      type: 'GET',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [appointmentData.appointmentInfo.appointmentId + '/reminder'],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.showAPILoader = true;
        this.getAppointmentDetails();
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Appointment schedule reminder email has been sent to your registered email id. Please check');
      } else {
        let message = 'Failed to absent the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }


  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.checkingSidebarState(this.cancelPopupShowHide);
    this.showAPILoader = true;
    this.getAppointmentDetails();
  }

  // generateInvoice($event)
  generateInvoice(data) {
    if (data) {
      if (data.data) { this.copayAmount = data.data.copayAmt; }
      if (data.data) { this.dueAmount = data.data.dueAmt; }
      if (data.data) { this.totalAmout = data.total; }
      if (data.btnType === 'makeAsPaid') {
        this.makeAsPaidPopupShowHide = true;
        this.isBeforeActions = true;
        // this.observable.setInvoiceGenerate(true);
      } else if (data.btnType === 'makePayment') {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
      } else if (data.btnType === 'sendCustomer') {
        this.isBeforeActions = true;
        // this.observable.setInvoiceGenerate(true);
        // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
      } else if (data.btnType === 'delete') {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
      }
    }
  }

  // Invoice List icon action catching click
  invoiceListClick(data) {
    if (data.statusText === 'ungenerated') {
      this.generateInvoicePopupShowHide = true;
    } else if (data.statusText === 'markaspaid') {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    } else if (data.statusText === 'resend') {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    }
  }

  // This is closing for generate invoice the popup
  generateInvoicePopupShowHideStatusFun(status) {
    this.generateInvoicePopupShowHide = status.cancelpopup;
  }

  // get the date value for the reShedule Date
  reScheduleDateChangeEvent(data) {
    this.resheduleDateValue = formatDate(new Date(data.value), 'yyyy-MM-dd', 'en');
    this.dropDownTimevalue = this.resheduleDateValue;
    this.appointmentTime = 'null';
    if (this.bookAdminSheduleUI === true) {
      this.params = {
        beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
        endTime: '23:55:00',
        startDate: this.dropDownTimevalue,
        days: 1,
        metadata: true,
        appointmentTypeId: this.appointmentTypeId,
        locationId: this.locationId
      };
      this.getsAvailabilityData(this.params);
    }
  }

  //  Get dropdown time value
  reScheduTeimeChangeEvent(data) {
    this.changeTheDropTimevalue = data;
  }

  // block slot details page
  blockDetails() {
    this.blockDetsilsAPICall();
  }

  // Block details API call
  deleteBlockSlotAPICall() {
    const payloadData = {
      type: 'DELETE',
      url: 'deleteBlockSlot',
      isDeveloper: true,
      pathVariables: [this.blokSlotUseId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Deleted successfully blocked slots');
        this.assignBlockedUIFlag = false;
        this.hideTheSideBar();
        this.getDashboardGridDataAPI(false);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in deleting the block the slot';
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Block details API call
  blockDetsilsAPICall() {
    const payloadData = {
      type: 'GET',
      url: 'getBlockSlotDetails',
      isDeveloper: true,
      pathVariables: [this.blokSlotUseId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.sideBarVisible = true;
        this.blockDetailsStaus = true;
        this.blockedSlotsObj = res.data;
        this.locationId = res.data.locationId;
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in geting slot details';
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  //  calendarSlotClick
  calendarSlotClick(data) {
    if (data.unavailabilityGrpId && data.available === false) {
      this.blockSlotEditAPIFlag = false;
      this.blokSlotUseId = data.unavailabilityGrpId;
      this.blockDetails();
    } else {
      if (data.type === 'day' && !data.unavailabilityGrpId) {
        this.noOfBookedCount = data.list.length;
        this.gridType = data.type;
        if (data.available === true && data.maxApptCount !== data.list.length) {
          this.addNewBookingButton = true;
        } else {
          this.addNewBookingButton = false;
        }
        this.dropDownTimevalue = data.date;
        let appointmentsListLength = data.list.length;
        if (data.available === true && (appointmentsListLength === 0)) {
          this.appointmentTypeId = Number(data.appointmentTypeId);
          this.appointmentTime = data.time;
          this.params = {
            beginTime: Number(this.appointmentTypeId) !== 4 ? '06:30:00' : '07:00:00',
            endTime: '23:55:00',
            startDate: this.dropDownTimevalue,
            days: 1,
            metadata: true,
            appointmentTypeId: this.appointmentTypeId,
            locationId: this.locationId
          };
          this.bookSlot();
        } else if ((data.available === true || data.available === false) && (appointmentsListLength !== 0)) {
          this.noOfBookedCount = data.list.length;
          this.sideBarVisible = true;
          this.showHideDataFlag = false;
          this.showHideSlideBarUI('appointmentList');
          this.rightsideAppointmentList = data;
        } else {

        }
      } else if (data.type === 'week' && data.available === true) {
        this.gridType = data.type;
        this.rightsideAppointmentList = data;
        this.sideBarVisible = true;
        this.showHideDataFlag = false;
        this.showHideSlideBarUI('appointmentList');
      } else {
      }
    }
  }

  // Right side data
  bookEmptySlot(data) {
    this.hideTheSideBar();
    this.sideBarVisible = true;
    this.appointmentTypeId = Number(data.appointmentTypeId);
    this.appointmentTime = data.time;
    this.changeTheDropTimevalue = data.time;
    this.resheduleDateValue = data.date;
    if (data.list.length === data.maxApptCount) {
      this.blockedSlotStatus();
      this.metaData.apttypes.forEach(appt => {
        if (appt.appointmentTypeId === this.appointmentTypeId) {
          this.timeDurationForShedule = appt.duration;
        }
      });
      this.assignBlockedData = {
        fromDate: this.resheduleDateValue,
        toDate: this.resheduleDateValue,
        fromTime: this.changeTheDropTimevalue,
        toTime: this.addMinutes(this.changeTheDropTimevalue, this.timeDurationForShedule),
        treatment: [this.appointmentTypeId],
        location: this.locationId
      };
    } else {
      this.bookSlot();
    }
  }


  // Blocked card click emiter  event
  blockedAppointmentCardClickEmiiter(data) {
  }

  // Close the block slot conformation popup
  blockConformationPopUp(status) {
    this.blockSlotConformationPopup = status.cancelpopup;
  }

  // Confromation submition
  blockStatusConform() {
    this.blockSlotApiCall(this.assignBlockSlotBody);
  }

  offLineStatus() {
    if (this.patientsDetails) {
      if (this.patientsDetails.details.isOffline === 1) {
        if (this.locationType === 'online') {
          this.teleAppCancelPopupShowHideStatusFlag = true;
          this.offLinePatientStatus = true;
        }
      }
    }
  }

  // getting permissions based on user id
  getAdminPermissions() {
 
    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let tempPermArr = ['APPOINTMENTS', 'EMPLOYEES', 'PATIENTS', 'LOCATIONS', 'INVOICES'];
        // tslint:disable-next-line:one-variable-per-declaration
        let permArr = [], permCatArr = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
          if (!permCatArr.includes(obj.permissionCategory)) {
            permCatArr.push(obj.permissionCategory);
          }
          if (permArr.includes('INVOICE_MARKASPAID')) {
            this.isMarkasPaid = true;
          }
          else {
            this.isMarkasPaid = false
          }
        });
        if (!permArr.includes('APPOINTMENT_CREATE') || !permArr.includes('APPOINTMENT_EDIT')) {
          this.adminPermissions.APPOINTMENT_CREATE = false;
        }
        tempPermArr.forEach(perm => {
          if (!permCatArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
        console.log(this.adminPermissions);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }
  // getting all appts based on selected date
  getBookedAppts(resp) {
    // tslint:disable-next-line:radix
    let date = resp.date.split('-');
    let selectedDateStartTime: any; let selectedDateEndTime: any;
    // tslint:disable-next-line:radix
    selectedDateStartTime = formatDate(new Date(parseInt(date[0]), (parseInt(date[1]) - 1), parseInt(date[2]), 0, 0, 0, 0).setHours(0, 0, 0), 'yyyy-MM-ddTHH:mm:ss', 'en');
    // tslint:disable-next-line:radix
    selectedDateEndTime = formatDate(new Date(parseInt(date[0]), (parseInt(date[1]) - 1), parseInt(date[2]), 23, 59, 59, 0).setHours(23, 59, 59), 'yyyy-MM-ddTHH:mm:ss', 'en');
    // body for all appts call
    let bodyData = {
      filter: {
        viewby: ['appointmentDate'],
        time: {
          startTime: selectedDateStartTime,
          endTime: selectedDateEndTime,
        },
      },
      pagination: { page: 1, limit: 100 },
      search: '',
      timezone: this.getTimeZone().toString()
    };
    const payload = {
      type: 'POST',
      url: 'adminappointment',
      isDeveloper: true,
      body: bodyData,
      params: { type: 'all' }
    };
    this.allAppts = [];
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.allAppts = [...res.data.list];
        // this.getRightSidePanelData(resp);
      } else {
        let message = 'Failed to fetch Appointments list data. Please try again';
        message = (res.error) ? res.error.message : message;
        console.log(message);
        // this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // getting count based on location and treatment name
  getCensusCount(loc, app, status, newPatIds?) {
    let apptList = [];
    apptList = [...this.allAppts];
    if (status === 'booked') {
      apptList = apptList.filter(appt => appt.locationName.toLowerCase() === loc.toLowerCase() && appt.appointmentTypeName.toLowerCase() === app.toLowerCase());
      return apptList.length;
    } else if (status === 'pending') {
      // tslint:disable-next-line:no-shadowed-variable
      apptList = apptList.filter(appt => appt.locationName.toLowerCase() === loc.toLowerCase() && appt.appointmentTypeName.toLowerCase() === app.toLowerCase() && appt.appointmentStatus === 'P');
      return apptList.length;
    } else if (status === 'newPatients') {
      // tslint:disable-next-line:no-shadowed-variable
      apptList = apptList.filter(appt => appt.locationName.toLowerCase() === loc.toLowerCase() && appt.appointmentTypeName.toLowerCase() === app.toLowerCase() && newPatIds.includes(appt.patient.patientId) && appt.appointmentStatus !== 'B' && appt.appointmentStatus !== 'D' && appt.appointmentStatus !== 'P');
      return apptList.length;
    } else if (status === 'cancelled') {
      // tslint:disable-next-line:no-shadowed-variable
      apptList = apptList.filter(appt => appt.locationName.toLowerCase() === loc.toLowerCase() && appt.appointmentTypeName.toLowerCase() === app.toLowerCase() && appt.appointmentStatus === 'D');
      return apptList.length;
    } else if (status === 'confirmed') {
      // tslint:disable-next-line:no-shadowed-variable
      apptList = apptList.filter(appt => appt.locationName.toLowerCase() === loc.toLowerCase() && appt.appointmentTypeName.toLowerCase() === app.toLowerCase() && appt.appointmentStatus !== 'P' && appt.appointmentStatus !== 'D');
      return apptList.length;
    }
  }

  // converting time
  getTime(time) {
    let hour = time.split(':')[0];
    let min = time.split(':')[1];
    let date = new Date().setHours(hour, min, 0, 0);
    return date;
  }
  // updating end time
  getEndTime(time, dur) {
    let hour = time.split(':')[0];
    let mins = time.split(':')[1];
    // tslint:disable-next-line:radix
    hour = parseInt(hour);
    // tslint:disable-next-line:radix
    mins = parseInt(mins);
    mins = mins + dur;
    hour = mins === 60 ? hour + 1 : hour;
    return `${hour < 10 ? '0' + hour : hour}:${mins < 10 ? '0' + mins : mins}:00`;
  }
}
