import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DEFAULT_PAGINATION_LIMIT, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { notificationsTabs } from '../common/constants/tabs';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF } from '@app/common/constants/util.constant';
import { AdminAccessService } from '@app/common/services/admin-access.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  filtersTabText = []; // Filers Tab text
  getQueryParameter: any; // Get Query Parameter
  isShowScreen = false;
  selectedTabIndex: any = 0; // Tab initail index value
  // Bread crumb data
  breadCrumbData = {
    link: '',
    linkText: 'Notifications'
  };
  // page click data
  curPage: any = 1;
  curLimit: any = DEFAULT_PAGINATION_LIMIT;
  // notifications
  notRead: any = [0, 1];
  notAlert: any = [0, 1];
  radioValue = 'all';
  notificationsTotalListPerPage = []; // Notifications total list initial
  paginationData: any; // Pagination data
  emptyNotificationsListMessages: any;
  notCount: any;
  permissionsArr = [];
  adminPermissions = {
    APPOINTMENTS: true,
    PATIENTS: true,
    INVOICES: true
  };
  permissionsDone = false;
  constructor(
    private observable: ObservableHelperService,
    private http: HttpService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private adminAccessService: AdminAccessService
  ) {
    this.getAdminPermissions();
    this.activeRoute.queryParams.subscribe(params => {
      // this.intailRedirection(this.router.url);
      this.getQueryParameter = params;
      this.setCurrentTabisSelected();
      // tslint:disable-next-line:no-unused-expression
      this.getQueryParameter.type === 'critical' ? this.notAlert = [1] : null;
      this.getNotificationsList(this.setPayload(this.curPage, this.curLimit, this.notRead, this.notAlert));
      // this.tabsDateFilters(this.INITIAL_PAYLOAD);
      this.emptyNotificationsListMessages = this.emptyMessagedata(this.getQueryParameter.type);
    });
  }

  ngOnInit() {
  }

  // setting payload for notifications
  setPayload(p, l, r, a) {
    return {
      pagination: {
        page: p,
        limit: l
      },
      read: r,
      alert: a
    };
  }

  // This  will assign the which tab is selected
  selectedTab(tab) {
    if (tab && typeof (tab) === 'object') {
      this.filtersTabText.forEach(each => {
        if (each.id === tab.id) {
          this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.NOTIFICATIONS}`], { queryParams: { type: each.routingLabel } });
          each.id === 0 ? this.notAlert = [0, 1] : this.notAlert = [1];
        }
      });
      this.curPage = 1, this.curLimit = DEFAULT_PAGINATION_LIMIT;
      this.getNotificationsList(this.setPayload(this.curPage, this.curLimit, this.notRead, this.notAlert));
    }
  }

  // scroll to top when on every api initialized
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }

  // setting the notification type(all/critical)
  setCurrentTabisSelected() {
    this.filtersTabText = [];
    notificationsTabs.forEach(data => {
      if (data.routingLabel === this.getQueryParameter.type) {
        data.selected = true;
      } else {
        data.selected = false;
      }
      this.filtersTabText.push(data);
    });
  }

  // Notifications API call
  getNotificationsList(bodyData) {
    this.isShowScreen = false;
    const payload = {
      type: 'POST',
      url: 'notifications',
      isDeveloper: true,
      body: bodyData,
      // params: (this.isFilterData) ? {} : { type: this.listType }
      // params: { type: this.listType }
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.notCount = res.data.pagination.totalItems;
        this.notificationsTotalListPerPage = this.massageNotificationsData(res.data.list);
        // this.notificationsTotalListPerPage = this.sortNotificationsByTime(this.notificationsTotalListPerPage);
        this.paginationData = this.massagePaginationData(res.data);
      } else {
        // console.log('Response', res);
        this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting Notifications');
      }
      this.isShowScreen = true;
      this.scrollTop(); // scrolling to top on every API call
    });
  }

  // massage the notifications
  massageNotificationsData(data) {
    // console.log(data)
    if (!data && !Array.isArray(data)) { return []; }
    let message;
    return data.map(each => {
      if (each.notificationType === 'patient') {
        message = each.notificationAction === 'block_patient' ? 'is blocked.' : 'is created.';
        return {
          ...each,
          notificationTime: convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date(each.notificationTime)),
          alert: (each.alert === 1) ? 'critical' : '',
          readStatus: (each.readStatus === 0) ? 'read' : 'unread',
          notificationsFullName: each.firstName + ' ' + each.lastname,
          notificationsSub: '(PAT' + each.patientId + ') ' + message,
        };
      } else if (each.notificationType === 'appointment') {
        message = (each.notificationAction === 'new_appointment' || each.notificationAction === 'new_telemedicine_appointment') ? 'booked an appointment' : each.notificationAction === 'reschedule_appointment' ? 'rescheduled the appointment' : each.notificationAction === 'confirm_appointment' ? 'confirmed the appointment' : 'cancelled the appointment';
        return {
          ...each,
          notificationTime: convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date(each.notificationTime)),
          alert: (each.alert === 1) ? 'critical' : '',
          readStatus: (each.readStatus === 0) ? 'read' : 'unread',
          notificationsFullName: each.firstName + ' ' + each.lastname,
          notificationsSub: ' ' + message + '(APT' + each.appointmentId + ').',
        };
      } else if (each.notificationType === 'invoice') {
        message = each.notificationAction === 'telemedicine_payment_received' ? ' for Telemedicine' : '';
        return {
          ...each,
          notificationTime: convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date(each.notificationTime)),
          alert: (each.alert === 1) ? 'critical' : '',
          readStatus: (each.readStatus === 0) ? 'read' : 'unread',
          notificationsFullName: each.firstName + ' ' + each.lastname,
          notificationsSub: each.firstName + ' ' + each.lastname + message + ' successfully made payment for (INV' + each.invoiceId + ').'
        };
      }
    });
  }

  // This will do massaging for pagination Data to fix the page number and limit
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list
    };
  }

  // This will sort the appointments ---- this is not using
  /*sortNotificationsByTime(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.sort((a, b) => {
      let aDate = a.appointmentDate;
      let bDate = b.appointmentDate;
      let aTime = a.appointmentTime;
      let bTime = b.appointmentTime;
      console.log(aTime + ' | ' + bTime);
      if (aDate === bDate) {
        return (aTime < bTime) ? -1 : (aTime > bTime) ? 1 : 0;
      } else {
        return (aDate < bDate) ? -1 : 1;
      }
    });
  }*/

  // View By filters (all/read/unread)
  viewByFilters(data) {
    data === 'read' ? this.notRead = [0] : data === 'unread' ? this.notRead = [1] : this.notRead = [0, 1];
    this.router.navigate(['/admin/notifications'], { queryParams: data === 'all' ? { type: this.getQueryParameter.type } : { type: this.getQueryParameter.type, readStatus: data } });
    this.curPage = 1, this.curLimit = DEFAULT_PAGINATION_LIMIT;
    this.getNotificationsList(this.setPayload(this.curPage, this.curLimit, this.notRead, this.notAlert));
  }

  // pagination event (page/limit change)
  pageChangedEvent(data) {
    this.curPage = data.currentPage;
    this.curLimit = data.limit;
    this.getNotificationsList(this.setPayload(this.curPage, this.curLimit, this.notRead, this.notAlert));
  }

  // Switch case for the notification type icons to display empty message in UI
  emptyMessagedata(notificationType) {
    let emptyDataObj = {};
    switch (notificationType) {
      case 'all':
        emptyDataObj = {
          className: 'appointmentsList',
          icon: 'appointment-empty-data',
          message: 'There are no notifications'
        };
        break;
      case 'critical':
        emptyDataObj = {
          className: 'appointmentsList',
          icon: 'appointment-empty-data',
          message: 'There are no critical notifications'
        };
        break;
    }
    return emptyDataObj;
  }

  // each notification click redirection
  notificationsClick(data) {
    // console.log(data)
    if (data) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${data.InvType ? data.Type + '/' + data.InvType : data.Type}/${data.Id}`]);
    }
  }
  // getting permissions based on user id
  getAdminPermissions() {
   
    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let tempPerms = ['APPOINTMENTS', 'PATIENTS', 'INVOICES'];
        this.permissionsArr = [];
        res.data.permissions.forEach(obj => {
          if (!this.permissionsArr.includes(obj.permissionCategory)) {
            this.permissionsArr.push(obj.permissionCategory);
          }
        });
        tempPerms.forEach(perm => {
          if (!this.permissionsArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
    });
    this.permissionsDone = true;
  }
}
