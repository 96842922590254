<div class="cancel-popup">
    <div class="cancel-popup-main">
        <img class="onbaord-form-bg" alt="background-leaf" src="assets/images/pngs/onboard-card-bg.png" loading="lazy"/>
        <div class="cancel-popup-content">
            <div class="cancel-popup-title">
                Cancel your Appointment
            </div>
            <div class="account-user-name">Hello {{cancelData.patient.firstName | titlecase}} {{cancelData.patient.lastName | titlecase}}</div>
            <div class="cancel-reason">
                <app-custom-form [formData]="form" [checkFormInfo]="saveFormData ? resetSaveFormParity() : false" (submitEvent)="getFormData($event)"></app-custom-form>
            </div>
            <div class="notes-title">
                <p>Note : A $25.00 cancellation charge maybe be applicable if appointment is cancelled within 24hr range of the rescheduled date.</p>
            </div>
            <div class="btn-actions">
                <div class="cancel-btn" (click)="showHideCancelPopupStatusChange()">Cancel</div>
                <div class="common-btn-style" (click)="saveForm()">Save</div>
            </div>
        </div>
        <div class="close">
            <app-svg [name]="'cancel-appointment'" (click)="showHideCancelPopupStatusChange()"></app-svg>
        </div>
    </div>
</div>