import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboard-form-wrapper',
  templateUrl: './onboard-form-wrapper.component.html',
  styleUrls: ['./onboard-form-wrapper.component.scss']
})
export class OnboardFormWrapperComponent implements OnInit {


  @Input() isAppointment;
  formScreenAnalyser: any = screen_state.login;
  isAdmin: any = false;
  forgotPasswordPath: string = 'fp';

  constructor(private router: Router) {
    this.isAdmin = router.url.includes('admin');
   }

  ngOnInit() {
    console.log(this.formScreenAnalyser);
  }



  loginRegisterSwitcher(event) {
    this.formScreenAnalyser = event;
    if (event === 'login') {
      this.router.navigate(['login']);
    }
  }

  getBacktoLogin() {
    this.formScreenAnalyser = 'login';
    this.router.navigate(['login']);
  }

  /**
   * To show new password/ dob fields.
   * @param event 
   */
  setFormType(event){
    console.log(event);
    this.formScreenAnalyser = event.name;
    this.forgotPasswordPath = event.path;
  }

}


enum screen_state {
  login = 'login',
  register = 'register',
  forgot_password = 'forgot_password',
  reset_password = 'reset_password'
}
