import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '@app/common/services/cognito.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-my-patient-form-overlay',
  templateUrl: './my-patient-form-overlay.component.html',
  styleUrls: ['./my-patient-form-overlay.component.scss']
})
export class MyPatientFormOverlayComponent implements OnInit {
  @Output() showHideMyAccountPopup = new EventEmitter();
  showHideMyAccountPopupStatus: boolean = true;
  listOfPatients: any;
  userData: any;
  userDetails: any;
  constructor(private cognitoService: CognitoService, private router: Router, private observableHelperService: ObservableHelperService, private http: HttpService) { }

  ngOnInit() {
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.getPatientsList();
      console.log('user Details', this.userDetails);
    });
  }

  // To get patient List

  getPatientsList() {
    let payload = {
      type: 'GET',
      url: 'getPatient',
      isDeveloper: true,
      pathVariables: [this.userDetails.userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.userData = res.data;
        this.listOfPatients = this.userData.patients;
      }
    });
  }

  showHideMyAccountPopupStatusChange() {
    this.showHideMyAccountPopupStatus = !this.showHideMyAccountPopupStatus;
    this.showHideMyAccountPopup.emit(this.showHideMyAccountPopupStatus);
  }
  redirectingToAddPatientForm() {
    this.showHideMyAccountPopupStatusChange();
    this.observableHelperService.setAddPatientClicked(true);
    this.router.navigate(['/new-patient'], { queryParams: { newForm: true } });
  }
  viewOrEditMode(id, mode) {
    this.showHideMyAccountPopupStatusChange();
    (mode === 'view') ?
      this.router.navigate([`/new-patient`], { queryParams: { id, view: true } }) :
      this.router.navigate([`/new-patient`], { queryParams: { id, edit: true } }) ;
  }
}
