import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { poolData } from '@src/environments/environment';
import Amplify, { Auth } from 'aws-amplify';

import { CognitoService } from './cognito.service';
import { HttpService } from './http.service';
import { ObservableHelperService } from './observable-helper.service';
import { API_URLS } from '../constants/api-urls.constant';
import { ROUTE_PATHS } from '../constants/routing.constant';
import { TOAST_MESSAGES } from '../constants/toast-messages.constant';
import { HTTPMethods, TOAST_STATUSES } from '../constants/util.constant';

Amplify.configure({
  Auth: {

    // REQUIRED - Amazon Cognito Region
    // region: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: poolData.UserPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: poolData.ClientId

    // cookieStorage: {
    //     // OPTIONAL - Cookie expiration in days
    //     expires: 1
    // }
  }
});
@Injectable({
  providedIn: 'root'
})
export class AdminCogntioService {

  constructor(
    private router: Router,
    private observableHelperService: ObservableHelperService,
    private http: HttpService,
    private cognitoService: CognitoService,
  ) { }


  async forgotPassword(data, callback) {
    let userDetails;
    data.userName = data.userName.toLowerCase();
    let payload = {
      type: HTTPMethods.POST,
      url: API_URLS.COGNITO_GET_USER,
      isDeveloper: true,
      body: { Username: data.userName }
    };
    this.http.makeHttpRequestHelper(payload).subscribe(async res => {
      if (this.http.isSuccessfulResponse(res)) {
        userDetails = res.data;
        if (userDetails.UserStatus !== 'UNCONFIRMED' && userDetails.UserStatus !== 'FORCE_CHANGE_PASSWORD') {
          try {
            this.observableHelperService.toggleThrobber(true);
            await Auth.forgotPassword(data.userName);
            callback({ forgotPasswordPhase: 'post' });
            this.observableHelperService.toggleThrobber(false);
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Reset password link sent to your registered mail successfully');
          } catch (error) {
            this.observableHelperService.toggleThrobber(false);
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, error.message);

            console.log('error sending code: ', error);
          }
        } else {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Please verify your email ');
          if (userDetails.UserStatus === 'FORCE_CHANGE_PASSWORD') {
            callback({ forgotPasswordPhase: 'needAdminVerify' });
          } else {
            callback({ forgotPasswordPhase: 'needVerify' });
          }
          this.observableHelperService.toggleThrobber(false);
        }
      } else {
        let message = 'User does not exist. Please register';
        message = (res.message) ? res.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        this.observableHelperService.toggleThrobber(false);
      }
    });
  }

  async completeNewPassword(data, callback) {
    try {
      await Auth.completeNewPassword(data.user, data.password, {});
      let user = await Auth.currentAuthenticatedUser();
      const params = {
        // UserPoolId: poolData.UserPoolId,
        Username: data.user.username,
        UserAttributes: [
          {
            Name: 'email_verified',
            Value: 'true'
          }
        ],
      };
      let payload = {
        type: HTTPMethods.POST,
        url: API_URLS.COGNITO_UPDATE_ATTRIBUTES,
        isDeveloper: true,
        body: params
      };
      this.http.makeHttpRequest(payload).subscribe(res => {
        if (this.http.isSuccessfulResponse(res)) {
          callback();
        } else {
          let message = 'Failed to update the details. Please try again';
          message = (res.message) ? res.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
          callback();
        }
      });
      // await client.adminUpdateUserAttributes(params).promise();
    } catch (err) {
      console.log(err);
    }
  }

  async resendConfirmationCode(data, callBack) {
    let payload = {
      type: HTTPMethods.POST,
      url: API_URLS.COGNITO_GET_USER,
      isDeveloper: true,
      body: { Username: data.userName }
    };
    this.http.makeHttpRequestHelper(payload).subscribe(async res => {
      if (this.http.isSuccessfulResponse(res)) {
        {
          let userDetails = res.data;
          if (userDetails.UserStatus === 'FORCE_CHANGE_PASSWORD') {
            try {
              this.observableHelperService.toggleThrobber(true);

              let dataUser = {
                ClientMetadata: {
                },
                DesiredDeliveryMediums: ['EMAIL'],
                ForceAliasCreation: false,
                TemporaryPassword: this.cognitoService.getPassword(),
                MessageAction: 'RESEND',
                Username: data.userName,
                UserPoolId: poolData.UserPoolId
              };
              let createPayload = {
                type: HTTPMethods.POST,
                url: API_URLS.COGNITO_CREATE_USER,
                isDeveloper: true,
                body: dataUser
              };
              this.http.makeHttpRequest(createPayload).subscribe(response => {
                if (this.http.isSuccessfulResponse(response)) {
                  console.log(response);
                } else {
                  console.log(response.message);
                }
              });
              this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Verification link resent successfully');
              callBack();
              this.observableHelperService.toggleThrobber(false);
            } catch (err) {
              this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Failed to send verification link to your mail');
              this.observableHelperService.toggleThrobber(false);
            }
          } else {
            try {
              this.observableHelperService.toggleThrobber(true);
              await Auth.resendSignUp(data.userName);
              this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Verification link resent successfully');
              callBack();
              this.observableHelperService.toggleThrobber(false);

            } catch (err) {
              this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Failed to send verification link to your mail');
              this.observableHelperService.toggleThrobber(false);

              console.log('error resending code: ', err);
            }
          }
        }
      } else {
        let message = 'User does not exist. Please register';
        message = (res.message) ? res.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });

  }


  /**
     * Update user profile - admin 
     * @param profileData Updated profile data
     * @param id User id
     * @param callback callback
     */
  async userProfileUpdateAdmin(profileData, id, callback) {
    this.observableHelperService.toggleThrobber(true);
    try {
      const params = {
        // UserPoolId: poolData.UserPoolId,
        Username: id,
        UserAttributes: [
          {
            Name: 'email',
            Value: profileData.email
          }
        ],
      };
      let payload = {
        type: HTTPMethods.POST,
        url: API_URLS.COGNITO_UPDATE_ATTRIBUTES,
        isDeveloper: true,
        body: params
      };
      this.http.makeHttpRequest(payload).subscribe(res => {
        if (this.http.isSuccessfulResponse(res)) {
          this.userGlobalSignOut(id, (returnValue, errMessage) => {
            if (returnValue) {
              callback(true);
            } else {
              callback(false, errMessage);
            }
          });
        } else {
          let message = 'Failed to update the details. Please try again';
          message = (res.error.message) ? res.error.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
          //callback();
        }
      });
  

      this.observableHelperService.toggleThrobber(false);
    } catch (error) {
      debugger;
      this.observableHelperService.toggleThrobber(false);
      if (error.code === 'AliasExistsException') {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, error.message);

      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UNABLE_TO_UPDATE_PROFILE);
      }
    }
  }


  async userGlobalSignOut(id, callback) {
    this.observableHelperService.toggleThrobber(true);
    try {
      const params = {
        // UserPoolId: poolData.UserPoolId,
        Username: id,
      };
      let payload = {
        type: HTTPMethods.POST,
        url: API_URLS.COGNITO_GLOBAL_SIGNOUT,
        isDeveloper: true,
        body: params
      };
      this.http.makeHttpRequest(payload).subscribe(res => {
        if (this.http.isSuccessfulResponse(res)) {
          callback(res);
        } else {
          let message = 'Failed to update the details. Please try again';
          message = (res.message) ? res.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
          callback();
        }
      });
 
      this.observableHelperService.toggleThrobber(false);
    } catch (error) {
      this.observableHelperService.toggleThrobber(false);
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, error.message);
    }
  }


  async signOut(callback) {
    try {
      await Auth.signOut();
      this.cognitoService.user = '';
      this.cognitoService.username = '';
      this.cognitoService.password = '';
      callback();
    } catch (error) {
      console.log('error signing out: ', error);
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, 'Falied to Logout');

    }
  }
}
