import { Location } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {
  @Input() breadCrumbData: any;
  constructor(private observable: ObservableHelperService, private router: Router, private locaiton: Location) { }

  ngOnInit() {
  }
  breadCrumbClick(link) {
    if (link !== '') {
      this.router.navigate([`${link}`]);
      // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    } else {
      this.locaiton.back();
    }

  }
}
