import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

import { ROUTE_PATHS } from './common/constants/routing.constant';
import { CognitoService } from './common/services/cognito.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'New Tele Doc';
  toggleFooter: boolean = true;
  currentURL: any = [];
  userLogedIn: boolean = false;
  getLogInUser: boolean = false;
  urlCheck: any;
  checkpoint: number;
  isShow: any = true;
  webAppFooter: any;
  constructor(private oberservableHelperService: ObservableHelperService, private router: Router, private route: ActivatedRoute, private cognitoService:CognitoService) {
    this.cognitoService.getUser().then(user => {
    this.userLogedIn = Boolean(user && user.email);
    });
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.urlCheck = event.url;
        this.currentURL = event.url.split('/');
        this.userLogedIn = JSON.parse(window.localStorage.getItem('isLogedIn'));
        this.checkingUserLogin(this.currentURL[1],this.currentURL[2]);
        if (this.isShow.value && this.urlCheck === '/' && !this.isTransission) {
          document.body.classList.add('body-hide-scroll');
        } else {
          document.body.classList.remove('body-hide-scroll');
        }
      }
    });
  }
  isTransission: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    let currentScroll = window.pageYOffset;
    if (currentScroll > this.checkpoint) {
      this.isTransission = true;
      if (this.urlCheck) {
        setTimeout(() => {
          this.oberservableHelperService.setBannerImage(false);
        }, 2000);
      }
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.oberservableHelperService.documentClickedTarget.next(event.target);
  }

  ngOnInit() {
    this.isShow = this.oberservableHelperService.getBanerImage();
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', window.location.href);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          canonicalLink.setAttribute('href', window.location.href);
        }
      });
    }
  }
  Methods() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.ngOnInit();
  }
  checkingUserLogin(URL,SUBURL?) {

    let isLogin = URL.indexOf('login') !== -1 ? true : false;
    let isAdmin = URL.indexOf('admin') !== -1 ? true : false;
    if (isLogin || URL === 'new-patient' || URL === 'profile' || isAdmin) {
      this.toggleFooter = false;
      if(isAdmin){
        this.webAppFooter = true;
      }
      else{
      this.webAppFooter = false;
      }
    } else {
      this.toggleFooter = true;
      this.webAppFooter = false;
    }
   if(!this.userLogedIn){
    this.toggleFooter = true;
    this.webAppFooter = false;
   }
  else{
      this.webAppFooter = true;
    this.toggleFooter = false;
  }
  if(URL=='login' || URL === 'profile' || (URL === 'admin' && SUBURL === 'login')){
    this.webAppFooter= false;
    this.toggleFooter = false;
  }
    
    let websiteUrls = ['/services', '/treatments', '/aboutus', '/blog', '/login', '/admin/login'];
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < websiteUrls.length; index++) {
      this.urlCheck = this.urlCheck.split('?')[0];

    }
  }
  ngAfterContentInit(): void {
    // Called after ngOnInit when the component's or directive's content has been initialized.
    // Add 'implements AfterContentInit' to the class.
    this.checkpoint = window.pageYOffset;

  }
  onActivate(event) {
  }
  onBannerScroll(event) {
    let currentScroll = event.currentTarget.scrollTop;
    if (currentScroll > this.checkpoint) {
      this.isTransission = true;
      if (this.urlCheck) {
        document.body.classList.remove('body-hide-scroll');
        setTimeout(() => {
          this.oberservableHelperService.setBannerImage(false);
        }, 2000);
      }
    }
  }
}
