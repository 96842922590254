import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonHeaderComponent } from '@app/common/components/common-header/common-header.component';
import { SvgComponent } from '@app/common/components/svg/svg.component';
import { GlobalSearchInputComponent } from '@app/pages/admin/global-search-input/global-search-input.component';
import { ClickOutsideModule } from 'ng-click-outside';

// prime
import { BarRatingModule } from 'ngx-bar-rating';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {GMapModule} from 'primeng/gmap';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

// carousel

import { SidebarModule } from 'primeng/sidebar';

// Custom pipes
import { AppointmentBookingSchedularComponent } from './common/components/appointment-booking-schedular/appointment-booking-schedular.component';
import { AppointmentDetailCardComponent } from './common/components/appointment-detail-card/appointment-detail-card.component';
import { BookingSlotComponent } from './common/components/booking-slot/booking-slot.component';
import { BreadCrumbComponent } from './common/components/breadcrumb/breadcrumb.component';
import { CancelBookingComponent } from './common/components/cancel-booking/cancel-booking.component';
import { CarouselBulletsComponent } from './common/components/carousel-bullets/carousel-bullets.component';
import { CustomDatePipe } from './common/pipes/custom-date.pipe';
import { CustomNumberPipe } from './common/pipes/custom-number.pipe';
import { CustomeTimeAmPmPipe } from './common/pipes/custome-time-am-pm.pipe';
import { DateFormatPipe } from './common/pipes/date-format.pipe';
import { EllipsisPipe } from './common/pipes/ellipsis.pipe';


// tslint:disable-next-line: max-line-length
import { CommonFooterComponent } from './common/components/common-footer/common-footer.component';
import { CustomFormComponent } from './common/components/custom-form/custom-form.component';
import { FiltersComponent } from './common/components/filters/filters.component';
import { KetamineCardComponent } from './common/components/ketamine-card/ketamine-card.component';
import { KnowMoreComponent } from './common/components/know-more/know-more.component';
import { MyPatientFormOverlayComponent } from './common/components/my-patient-form-overlay/my-patient-form-overlay.component';
import { PageBannerComponent } from './common/components/page-banner/page-banner.component';
import { PaginationComponent } from './common/components/pagination/pagination.component';
import { ScrollToTopComponent } from './common/components/scroll-to-top/scroll-to-top.component';
import { SectionHeaderComponent } from './common/components/section-header/section-header.component';
import { StepIndicatorComponent } from './common/components/step-indicator/step-indicator.component';
import { ToastComponent } from './common/components/toast/toast.component';

import {InputMaskModule} from 'primeng/inputmask';
import {RatingModule} from 'primeng/rating';

import { CheckBoxesGroupComponent } from './common/components/check-boxes-group/check-boxes-group.component';
import { CommonPopupComponent } from './common/components/common-popup/common-popup.component';
import { ContactUsComponent } from './common/components/contact-us/contact-us.component';
import { EmptyListDataComponent } from './common/components/empty-list-data/empty-list-data.component';
import { FaqsContentComponent } from './common/components/faqs-content/faqs-content.component';
import { LastVisitBlockComponent } from './common/components/last-visit-block/last-visit-block.component';
import { ListSearchComponent } from './common/components/list-search/list-search.component';
import { MultipleUploadsComponent } from './common/components/multiple-uploads/multiple-uploads.component';
import { NotificationCardComponent } from './common/components/notification-card/notification-card.component';
import { OnboardFormWrapperComponent } from './common/components/onboard-form-wrapper/onboard-form-wrapper.component';
import { PatientCardComponent } from './common/components/patient-card/patient-card.component';
import { CustomTimePipe } from './common/pipes/custom-time.pipe';
import { FormatTimePipe } from './common/pipes/format-time.pipe';
import { PatientDocumentsRequestComponent } from './pages/admin/patient-documents-request/patient-documents-request.component';
import { PatientDocumentsRequestListComponent } from './pages/admin/patient-documents-request-list/patient-documents-request-list.component';
import { AppointmentDetailComponent } from './pages/AppointmentBooking/appointment-detail/appointment-detail.component';
import { AppointmentPageComponent } from './pages/AppointmentBooking/appointment-page/appointment-page.component';
import { MyAccountComponent } from './pages/my-account/my-account/my-account.component';
import { ForgotPasswordComponent } from './pages/onboard/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/onboard/login/login.component';
import { RegisterComponent } from './pages/onboard/register/register.component';
import { ResetPasswordComponent } from './pages/onboard/reset-password/reset-password.component';
import { RequestDocumentComponent } from './pages/request-documents/request-document/request-document/request-document.component';

import { FileUploadModule } from 'primeng/fileupload';

import { CopyrightFooterComponent } from './common/components/copyright-footer/copyright-footer.component';
import { GeneratePdfComponent } from './common/components/generate-pdf/generate-pdf.component';
import { MedicalSummaryFormComponent } from './common/components/medical-summary-form/medical-summary-form.component';
import { MultipleDocumentRequestViewComponent } from './common/components/multiple-document-request-view/multiple-document-request-view.component';
import { UserProfileAdminComponent } from './common/components/user-profile-admin/user-profile-admin.component';
import { RequestDocumentModule } from './pages/request-documents/request-document/request-document.module';
import { RequestDocumentDetailsComponent } from './pages/request-documents/request-document-details/request-document-details.component';

const IMPORTS_EXPORTS_COMPONENTS = [
  SvgComponent,
  CommonHeaderComponent,
  CommonFooterComponent,
  ToastComponent,
  CustomFormComponent,
  CommonHeaderComponent,
  StepIndicatorComponent,
  AppointmentDetailCardComponent,
  KetamineCardComponent,
  KnowMoreComponent,
  SectionHeaderComponent,
  BookingSlotComponent,
  CarouselBulletsComponent,
  ScrollToTopComponent,
  PageBannerComponent,
  AppointmentBookingSchedularComponent,
  CancelBookingComponent,
  PatientCardComponent,
  MyPatientFormOverlayComponent,
  FaqsContentComponent,
  CommonPopupComponent,
  OnboardFormWrapperComponent,
  LoginComponent,
  RegisterComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  AppointmentPageComponent,
  AppointmentDetailComponent,
  MyAccountComponent,
  ContactUsComponent,
  BreadCrumbComponent,
  FiltersComponent,
  PaginationComponent,
  ListSearchComponent,
  EmptyListDataComponent,
  CheckBoxesGroupComponent,
  MultipleUploadsComponent,
  LastVisitBlockComponent,
  NotificationCardComponent,
  GlobalSearchInputComponent,

  CopyrightFooterComponent,
  MultipleDocumentRequestViewComponent,
  PatientDocumentsRequestComponent,
  PatientDocumentsRequestListComponent,
  UserProfileAdminComponent,
  GeneratePdfComponent,
  MedicalSummaryFormComponent

];
const IMPORTS_EXPORTS_PIPES = [
  CustomDatePipe,
  DateFormatPipe,
  CustomNumberPipe,
  CustomTimePipe,
  EllipsisPipe,
  CustomeTimeAmPmPipe,
  FormatTimePipe

];
@NgModule({
  declarations: [
    ...IMPORTS_EXPORTS_COMPONENTS,
    ...IMPORTS_EXPORTS_PIPES,
    RequestDocumentDetailsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ClickOutsideModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    OverlayPanelModule,
    TooltipModule,
    SlickCarouselModule,
    CalendarModule,
    DropdownModule,
    CheckboxModule,
    GMapModule,
    RatingModule,
    BarRatingModule,
    InputMaskModule,
    SidebarModule,
    AutoCompleteModule,
    FileUploadModule
  ],
  exports: [
    ...IMPORTS_EXPORTS_COMPONENTS,
    ...IMPORTS_EXPORTS_PIPES,

  ]
})
export class CommonAppModule { }
