// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DEFAULT_PAGINATION_LIMIT, TOAST_STATUSES, HTTPMethods, TELEMEDICINE_MEDICATION } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { IGetMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { AdminAccessService } from '@app/common/services/admin-access.service';
import { AdminInvoiceFilterService } from '@app/common/services/admin-invoice-filter.service';

@Component({
  selector: 'app-patient-wrapper',
  templateUrl: './patient-wrapper.component.html',
  styleUrls: ['./patient-wrapper.component.scss']
})
export class PatientWrapperComponent implements OnInit {
  isOpenSearch = true; // Auto Search open Flag
  searchPlaceHolderText = 'Search by Patient,Phone,Email & id'; // Search Place holder
  patientNameId: any = ''; // Sending the Patient Name and ID to the block status UI
  treatmentsCount: any; // getting the all treatments count
  archiveStatus: any; // ArchiveStatus
  rawFilterData: any; // Sending data to Block status UI
  patientId: any; // Patient ID
  blockPatientApplyStatus = false; // Block Status Flag
  isShowScreen = false; // Is show UI flag
  patientsList: any = []; // Intail patients List
  blockPatientPopupShowHide = false; // Block UI status flag
  outsideScroll = false; // Scroll status flag
  searchInputText = ''; // Search input text
  paginationData = {}; // Pagination data
  pageNumber: any = 1;
  getFilterData: any;
  filterData: any;
  actionPatientName = '';
  patientListCount = 0;
  patientData: any = {};
  pageLimit: any = DEFAULT_PAGINATION_LIMIT;
  searchValue: string = '';
  totalUpdatedData: any;
  currentFormIndex: number = 0;
  selectTreatmentData: any;
  sendFinalData: any;
  currentStep = {
    label: 'Selected Treatment',
    id: 'selectedTreatment',
    img: 'HIPPA Form',
    visited: true,
    notVisisted: false,
    completed: false,
    onPage: true
  };
  stepDetails = [
    {
      label: 'Selected Treatment',
      id: 'selectedTreatment',
      img: 'HIPPA Form',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'Selected Locations',
      id: 'selectedLocations',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    }
  ];
  saveFormData: boolean = false;
  hideSubmit: boolean = false;
  // Intail payload data
  INITIAL_PAYLOAD = {
    pagination: {
      page: this.pageNumber,
      limit: this.pageLimit
    },
    search: this.searchInputText,
  };
  // Empty List data
  emptyAppointmentsListMessages = {
    className: 'appointmentsList',
    icon: 'patient',
    message: 'There are no patients'
  };
  // Bread crumb status
  breadCrumbData = {
    link: '/admin',
    linkText: 'Patient List'
  };
  // Form inputs
  forminputs: any = [
    {
      type: 'header',
      label: 'text',
      label1: 'text',
      showontoggle: true,
      editable: true,
      key: 'patient_name_id',
      className: 'patient-id',
    },

    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'treatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Medmanagement',
          value: 'Medmanagement',
          isSelected: false
        },
        {
          name: 'Tele Medicine',
          value: 'Tele Medicine',
          isSelected: false
        },
        {
          name: 'TMS',
          value: 'TMS',
          isSelected: false
        },
        {
          name: 'Therapy',
          value: 'Therapy',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      valueable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Save',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: '',
      showontoggle: true,
    }
  ];
  // Form inputs
  patientFilterInputs = [
    {
      type: 'groupCheckbox',
      categoryLabel: 'Online Patient Status',
      controlType: 'groupCheckbox',
      key: 'onlinePatientStatus',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Offline Patient Status',
      controlType: 'groupCheckbox',
      key: 'offlinePatientStatus',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Apply',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
      disabled: false
    }];
  sideBarVisible: boolean = false;
  appyliedFilterObj: { filter: any; search: string; pagination: { page: number; limit: number; }; };
  patientFilterData: any;
  permissionsDone = false;
  adminPermissions = {
    PATIENT_CREATE: true,
    PATIENT_EDIT: true,
    APPOINTMENT_CREATE: true,
    USER_LIST: true,
  };
  displayDocumentsRequestPopup: any;
  documentsRequest: any[];
  displayLabDocumentsRequestPopup: boolean;
  labDocumentsRequest: any[];
  displayEditUserPopup: boolean = false;
  constructor(
    private router: Router,
    private http: HttpService,
    private observable: ObservableHelperService,
    private activeRoute: ActivatedRoute,
    private adminAccessService: AdminAccessService,
    private adminInvoiceFilterService: AdminInvoiceFilterService
  ) {
    this.activeRoute.queryParams.subscribe(params => {
      if (params.search) {
        this.searchInputText = params.search
        this.searchValue = params.search;
      }
    });
  }

  ngOnInit() {
    // Seting outside scroll visible status
    this.checkingSidebarState(this.outsideScroll);
    // Get patients list data
    this.INITIAL_PAYLOAD.search = this.searchInputText
    this.getPatientsList(this.INITIAL_PAYLOAD);
    this.getPatientFilterData();
  }
  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }
  // Checcking side bar staus
  checkingSidebarSetStatus(data, type?) {
    if (type === 'sidePanel') {
      this.sideBarVisible = data;
    } else {
      this.outsideScroll = data;
    }
    this.checkingSidebarState(data);
  }

  saveForm() {
    if (this.currentFormIndex === 1) {
      if (this.totalUpdatedData != undefined) {
        let sendData = [];
        this.totalUpdatedData.forEach(each => {
          let LocaitonIds = [];
          each.locationName.forEach(e => {
            if (e.isSelected === true) {
              LocaitonIds.push(e.loctionId)
              sendData.push({
                appointmentTypeId: each.appointmentTypeId,
                appointmentTypeName: each.appointmentTypeName,
                locationId: e.loctionId
              });
            }
          })
        });
        this.sendFinalData = { 'treatment': sendData }
      }
      else {
        let totalUpdatedData1 = this.selectTreatmentData;
        let sendData = [];
        totalUpdatedData1.treatment.forEach(each => {
          each.locationId.forEach(e => {
            sendData.push({
              appointmentTypeId: each.appointmentTypeId,
              appointmentTypeName: each.appointmentTypeName,
              locationId: e
            });
          })
        });
        this.sendFinalData = { 'treatment': sendData };
        console.log(this.sendFinalData);
      }

    }
    let rewSelectedData = []
    if (this.selectTreatmentData == undefined) {
      this.rawFilterData.treatment.forEach(each => {
        if (each.isSelected) {
          rewSelectedData.push(each)
        }
      });
      this.selectTreatmentData = { 'treatment': rewSelectedData };
    }


    this.saveFormData = true;
    if (this.currentFormIndex === (this.stepDetails.length - 1)) {
      this.currentStep = this.stepDetails[this.currentFormIndex];
      this.scrollTop();
      // window.setTimeout(() => {
      //   this.navigateToDetails();
      // }, 10);
    } else {
      window.setTimeout(() => {
        if (this.stepDetails[this.currentFormIndex]) {
          this.currentFormIndex++;
          this.currentStep = this.stepDetails[this.currentFormIndex];
          this.stepDetails[this.currentFormIndex].onPage = true;
          this.stepDetails[this.currentFormIndex].notVisisted = false;
          this.stepDetails[this.currentFormIndex].visited = true;
          this.scrollTop();
        }
      }, 5);
    }
    // setTimeout(() => {
    //   if (document.getElementsByClassName('invalid-feedback')[0]) {
    //     document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
    //   }
    // }, 2);
    if (this.currentFormIndex == 1) {
      this.blockPatientSubmit(this.sendFinalData);
    }
  }
  // Get patients list API call
  getPatientsList(bodyData) {
    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    const payloadData = {
      type: 'POST',
      url: 'patientList',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.patientListCount = res.data.pagination.totalItems;
        this.getTreatmentsDataLength(res);
      } else {
        // Error
        let message = 'Failed to fetch treatments list. Please try again';
        message = (res.error) ? res.error.message : message;
        if (message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        } else {
          //this.observable.showToast(TOAST_STATUSES.ERROR, message); 
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Failed to fetch patients data.');
          this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`]);
          bodyData.search = "";
          this.searchInputText = "";
          this.getPatientsList(bodyData);
        }
      }
    });
  }
  getStepIndex(event) {
    this.stepIndicatorClicked(event);
  }
  getActionText() {
    return this.currentFormIndex < (this.stepDetails.length - 1) ? 'Next' : 'Submit';
  }
  stepIndicatorClicked(index: number): void {
    if (index < 1) { this.hideSubmit = false; }
    if (index > this.currentFormIndex) {
      this.saveFormData = true;
      this.saveForm();
    } else {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = index;
      this.currentStep = this.stepDetails[index];
      this.stepDetails.forEach((each, ind) => {
        if (ind >= this.currentFormIndex) {
          each.completed = false;
        }
      });
      this.stepDetails[index].onPage = true;
    }
  }
  setCurrentForm(index) {
    this.currentStep = this.stepDetails[index];
    this.stepDetails[index].completed = false;
    this.stepDetails[index].onPage = true;
  }
  forwardClicked() {
    this.saveForm();
  }
  backClicked() {
    this.back();
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }
  back() {
    if (this.currentFormIndex === 0) {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = 0;
      this.setCurrentForm(this.currentFormIndex);
    } else {
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.hideSubmit = false;
    this.selectTreatmentData = undefined;
  }
  cancel(status) {
    if (this.currentFormIndex != 0) {
      this.selectTreatmentData = undefined;
      this.stepDetails[this.currentFormIndex].notVisisted = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.stepDetails[this.currentFormIndex].visited = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.blockPatientPopupShowHide = false;
    this.checkingSidebarSetStatus(false);
  }
  // Deleting patient
  deletePatient(patientData) {
    if (patientData) {
      const payloadData = {
        type: 'DELETE',
        url: 'deletePatient',
        isDeveloper: true,
        body: patientData
      };
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.getPatientsList(this.appyliedFilterObj);
          let message = 'Successfully deleted the patient data.';
          this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
        } else {
          // Error
          let message = 'Failed to delete patient data. Please try again';
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }
  }
  // This emit filter form Data
  filterDataEvent(data) {
    this.filterData = data;
    this.appyliedFilterObj = {
      filter: data,
      search: this.searchInputText,
      pagination: {
        page: 1,
        limit: DEFAULT_PAGINATION_LIMIT
      },
    };
    this.getPatientsList(this.appyliedFilterObj);
  }

  // Get All Treatments Data length
  getPatientFilterData() {
    const payloadData = {
      type: 'GET',
      url: 'patientFilter',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.patientFilterData = this.massagePatientFilterData(res.data);
      } else {
        // Error
        let message = 'Failed to fetch patient filter data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Massage the filter data
  massagePatientFilterData(data) {
    if (data && typeof (data) === 'object') {
      data.offlinePatientStatus = data.offlinePatientStatus.map((doc, index) => {
        var singleObj = { isSelected: false };
        singleObj['name'] = doc;
        singleObj['value'] = doc;
        return singleObj;
      });
      data.onlinePatientStatus = data.onlinePatientStatus.map((doc, index) => {
        var singleObj = { isSelected: false };
        singleObj['name'] = doc;
        singleObj['value'] = doc;
        return singleObj;
      });
      return data;
    } else {
      // Error
    }
  }

   /**
   * Fetches the treatments data length and updates the patient details.
   * @param patientData - The data of the patient to be processed.
   */
  getTreatmentsDataLength(patientData) {
    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    // Fetches the admin invoice information from admin invoice service
    this.adminInvoiceFilterService.getInvoice().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = Object.keys(res.data.treatment).length;
        this.getFilterData = res.data;
        // this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
        this.patientsList = this.massagePatientList(patientData.data.list);
        this.paginationData = this.massagePaginationData(patientData.data);
        this.checkingSidebarState(false);
      } else {
        // Error
        let message = 'Failed to fetch patient lits data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.isShowScreen = true;
      this.getAdminPermissions();
    });
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      // tslint:disable-next-line:only-arrow-functions
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
  // MassagePatientList  Data
  massagePatientList(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        ...each,
        blockedStatus: this.getActions(each),
        blockedServices: each.blockedServices.map(eachTreatment => {
          return {
            ...eachTreatment,
            name: eachTreatment.appointmentTypeName,
          };
        }),
        editText: 'Edit',
        archiveStatus: this.displayArchiveStatus(each.status),
        patientName: (!each.firstName && !each.lastName) ? '-' :
          (each.firstName) + ' ' + (each.lastName),
      };
    });
  }

  displayArchiveStatus(archiveStatus) {
    let status = '';
    if (archiveStatus === 'active') {
      status = 'Archive Patient';
    } else if (archiveStatus === 'archive') {
      status = 'Active Patient';
    }
    return status;
  }
  getActions(data) {
    let blockedStatusLabel;
    if (data.blockedServices.length === this.treatmentsCount + 1) {
      blockedStatusLabel = 'UnBlock';
    } else {
      blockedStatusLabel = 'Block Patient';
    }
    return blockedStatusLabel;
  }

  // This will do massaging for pagination Data
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list
    };
  }
  // Pagination output
  pageChangedEvent(data) {
    if (data) {
      this.pageNumber = data.currentPage;
      this.pageLimit = data.limit;
      let pageClickedData = {
        pagination: {
          page: data.currentPage,
          limit: data.limit,
        },
        search: this.searchInputText,
      };
      this.blockPatientApplyStatus = false;
      this.getPatientsList(pageClickedData);
    }
  }
  // Search filter
  searchFilter(e) {
    if (e.replace(/\s/g, '').length > 0 || e == '') {
      this.searchInputText = e;
      this.INITIAL_PAYLOAD.search = this.searchInputText;
      this.blockPatientApplyStatus = false;
      this.getPatientsList(this.INITIAL_PAYLOAD);
      if (this.searchInputText == '') {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`]);
      } else {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`], { queryParams: { search: this.searchInputText } });
      }
    }

  }
  createNew() {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.CREAT_PATIENT}`]);
    // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
  }

  /**
   * This will navigate to users list page
   */
  manageUserAccounts() {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.USER_LIST}`]);
  }

  // This will do filter functionality
  filterAppointments() { // TODO @samad
    this.sideBarVisible = !this.sideBarVisible;
    this.checkingSidebarState(this.sideBarVisible);
    let filterSidePanel = document.querySelectorAll('.p-sidebar-width')[1];
    filterSidePanel.scrollTo(0, 0);
  }

  // Patien list actions
  patientListActions(data) {
    this.patientData = data.patientData;
    this.patientData.poc = {
      id: "",
      firstName: "",
      lastName: "",
      mail: ""
    }
    let actionText = data.optionText;
    this.actionPatientName = this.toTitleCase(data.patientData.patientName);
    this.patientId = data.patientData.patientId;
    actionText = actionText.toLowerCase().replace(/ /g, '');
    if (actionText === 'blockpatient' || actionText === 'unblock') {
      this.forminputs.forEach(each => {
        if (each.key === 'patient_name_id') {
          each.label = data.patientData.patientName;
          each.label1 = data.patientData.patientId;
        }
      });
      this.getTreatmentsData(data.patientData.blockedServices);
    } else if (actionText === 'archivepatient' || actionText === 'activepatient') {
      this.checkingSidebarState(true);
      this.archiveStatus = (actionText === 'archivepatient') ? 'archive' : 'active';
      this.archiveAPICall();
    } else if (actionText === 'docs') {
      this.displayDocumentsRequestPopup = true;
      this.getDocumentRequest();
    } else if (actionText === 'poc') {
      this.getPatientDetails(this.patientData.patientId);
    } else if (actionText === 'labdocs') {
      this.displayLabDocumentsRequestPopup = true;
      this.getLabDocumentRequest();
    }
  }
  closeDocumentRequestPopup(status) {
    this.displayDocumentsRequestPopup = status.isPopup;
  }

  // This is for getting request documents
  getDocumentRequest() {
    this.documentsRequest = [];
    const payloadData = {
      type: 'GET',
      url: 'adminRequestDocuments',
      isDeveloper: true,
      pathVariables: ['DOCUMENT_REQUEST']
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (res) {
        this.documentsRequest = res.data;
      }
    });
  }

  blockPatientSubmited(data) {
    this.selectTreatmentData = data;
  }
  // Block patient Save action
  blockPatientSubmit(data) {
    this.hideSubmit = false;
    if (this.currentFormIndex != 0) {
      this.blockTheUserAPI(data);
      this.selectTreatmentData = undefined;
      this.totalUpdatedData = undefined;
      this.stepDetails[this.currentFormIndex].notVisisted = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.stepDetails[this.currentFormIndex].visited = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
      this.blockPatientPopupShowHide = false;
    }

  }
  changedLocation(data) {
    this.totalUpdatedData = data;
  }
  // Block API PUT Call
  blockTheUserAPI(bodyData) {
    const payloadData = {
      type: 'PUT',
      url: 'patientActions',
      isDeveloper: true,
      body: bodyData,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.checkingSidebarSetStatus(false);
        if (Number(res.data.patientId) === this.patientId) {
          this.blockPatientApplyStatus = true;
          let blockAPIBody = {
            filter: this.filterData,
            pagination: {
              page: this.pageNumber,
              limit: this.pageLimit
            },
            search: this.searchInputText,
          };
          this.getPatientsList(blockAPIBody);
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient ' + `${this.actionPatientName}` + ' blocked status');
        } else {
          // Error
          let message = 'Failed to send patient block status . Please try again';
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      }
    });
  }
  // Archive API call
  archiveAPICall() {
    const payloadData = {
      type: 'PUT',
      url: 'patientArchive',
      isDeveloper: true,
      pathVariables: [this.patientId, this.archiveStatus],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (Number(res.data.patientId) === this.patientId) {
          this.blockPatientApplyStatus = true;
          let bodyData = {
            filter: this.filterData,
            pagination: {
              page: this.pageNumber,
              limit: this.pageLimit
            },
            search: this.searchInputText,
          };
          this.getPatientsList(bodyData);
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient ' + `${this.actionPatientName}` + ' ' + `${this.archiveStatus}` + ' status');

        } else {
          let message = 'Failed to fetch patient data. Please try again';
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      } else {
        // Error
      }
    });
  }
  documentsRequestPopupStatusFun(status) {
    this.displayDocumentsRequestPopup = status.cancelpopup;
  }
  // This is closing for block patient the popup
  blockPatientPopupShowHideStatusFun(status) {
    if (this.currentFormIndex != 0) {
      this.selectTreatmentData = undefined;
      this.stepDetails[this.currentFormIndex].notVisisted = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.stepDetails[this.currentFormIndex].visited = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.blockPatientPopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  // Admin get filterData API call
  getTreatmentsData(blockstatus) {
    this.rawFilterData = this.massageFilterData(this.getFilterData, blockstatus);
    this.blockPatientPopupShowHide = true;
    this.checkingSidebarSetStatus(true);
  }
  // Massage the filter data
  massageFilterData(data, blockstatus) {
    if (data && typeof (data) === 'object') {
      data.treatment = data.treatment.map(doc => {
        doc['isSelected'] = false;
        doc['locationId'] = (doc.locationId) ? doc.locationId : null;
        doc['name'] = doc.appointmentTypeName;
        let locationIds = [];
        blockstatus.forEach(each => {
          let locationsid;
          if (each.appointmentTypeId === doc.appointmentTypeId) {
            doc['isSelected'] = true;
            locationsid = (each.locationId) ? each.locationId : null;
            locationIds.push(locationsid);
          }
        });
        doc['locationId'] = locationIds;
        return {
          ...doc,
          value: doc.appointmentTypeId,
        };
      });
      return data;
    } else {
      // Error
    }
  }

  // getting permissions based on user id
  getAdminPermissions() {
 
    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let permissions = ['PATIENT_CREATE', 'PATIENT_EDIT', 'APPOINTMENT_CREATE', 'USER_LIST'];
        let permArr = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
        });
        if (!permArr.includes('PATIENT_LIST')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        permissions.forEach(perm => {
          if (!permArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
        console.log(this.adminPermissions);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }

  /**
   * Navigate to user profile edit - admin side
   */
  showEditForm() {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.CREAT_PATIENT}`], { queryParams: { id: this.patientData.patientId, edit: true, userId: this.patientData.userId, patientName: this.patientData.firstName, lastName: this.patientData.lastName, status: this.patientData.status, isOffile: this.patientData.isOffline } });
  }

  /**
   * To show the user profile edit form. - admin side  
   */
  showUserProfile() {
    if (this.patientData.poc != null) {
      this.displayEditUserPopup = true;
      this.checkingSidebarState(true);
    } else {
      this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UNABLE_TO_FETCH_POC);
    }
  }

  /**
  * For displaying edit request popup- admin side
  * @param status 
  */
  editUserPopupStatusFun(status) {
    if (this.patientData.poc != null) {
      this.displayEditUserPopup = status.cancelpopup;
      this.checkingSidebarState(status.cancelpopup);
    } else {
      this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UNABLE_TO_FETCH_POC);
    }
  }

  /**
  * For displaying edit request popup - admin side
  * @param status 
  */
  closeEditUserPopup(status) {
    this.displayEditUserPopup = status.isPopup;
    this.checkingSidebarState(status.isPopup);
  }

  /**
   * To close the profile edit popup after action. - admin side
   * @param event submit event
   */
  submitUserProfie(event) {
    this.closeEditUserPopup(true);
  }

  /**
   * Get PatientDetails API call - admin side
   * @param id selected patient id 
   */
  getPatientDetails(id) {
    const payloadData = {
      type: HTTPMethods.GET,
      url: API_URLS.PATIENT_DETAILS,
      isDeveloper: true,
      pathVariables: [id],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (res.data.details.pointofContact != null) {
          this.patientData.poc = {
            firstName: res.data.details.pointofContact.firstName,
            lastName: res.data.details.pointofContact.lastName,
            mail: res.data.details.pointofContact.email,
            id: res.data.details.pointofContact.userId
          }
          this.displayEditUserPopup = true;
          this.checkingSidebarState(true);
        } else {
          this.patientData.poc = null;
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UNABLE_TO_FETCH_POC);
        }
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        if (message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      }
    });
  }

  /**
   * This is for getting request documents
   */
  getLabDocumentRequest() {
    this.labDocumentsRequest = [];
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.ADMIN_REQ_DOC,
      isDeveloper: true,
      pathVariables: ['LAB_REQUEST']
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (res) {
        this.labDocumentsRequest = res.data;
      }
    });
  }

  /**
   * Close lab doc popup.
   * @param status 
   */
  closeLabDocumentRequestPopup(status) {
    this.displayLabDocumentsRequestPopup = status.isPopup;
  }

  /**
   * Show/close lab doc popup.
   * @param status 
   */
  labDocumentsRequestPopupStatusFun(status) {
    this.displayLabDocumentsRequestPopup = status.cancelpopup;
  }
}