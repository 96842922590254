import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { Observable } from 'rxjs';

interface QuickLink {
  id: number;
  label: string;
  value: string;
  icon: string;
  className: string;
  url: string;
  permissionName: string[];
  queryParam?: string;
}
@Component({
  selector: 'app-dash-board-right-panel',
  templateUrl: './dash-board-right-panel.component.html',
  styleUrls: ['./dash-board-right-panel.component.scss']
})
export class DashBoardRightPanelComponent implements OnInit {

  @Input() adminPermissions: { [key: string]: boolean }; // Represents the permissions where the key is the permission name and the value indicates if it is granted (true) or not (false)
  // Array of quick links, each with its own properties and type definitions
  quickLinks: QuickLink[] = [
    {
      id: 0,
      label: 'Appointments',
      value: 'appointments',
      icon: 'calendar-color',
      className: 'appointments',
      url: 'appointments',
      permissionName: ['APPOINTMENTS']
    },
    {
      id: 1,
      label: 'Invoices',
      value: 'invoices',
      icon: 'admininvoice',
      className: 'invoices',
      url: 'invoices',
      permissionName: ['INVOICES']
    },
    {
      id: 2,
      label: 'Patients',
      value: 'patients',
      icon: 'patients-color',
      className: 'patients',
      url: 'patients',
      permissionName: ['PATIENTS']
    },
    {
      id: 3,
      label: 'Locations',
      value: 'locations',
      icon: 'location-color',
      className: 'locations',
      url: 'locations',
      permissionName: ['LOCATIONS']
    },
    {
      id: 4,
      label: 'Employees',
      value: 'employees',
      icon: 'employees-color',
      className: 'employees',
      url: 'employee',
      permissionName: ['EMPLOYEES']
    },
    {
      id: 5,
      label: 'Send Medication mail',
      value: 'send-mail',
      icon: 'detail-color',
      className: 'sendemail',
      url: 'send-mail',
      queryParam: 'medication',
      permissionName: ['PATIENTS', 'APPOINTMENTS']
    },
    {
      id: 6,
      label: 'Send TMS/ Ketamine follow up mail',
      value: 'send-followup',
      icon: 'detail-color',
      className: 'sendemail',
      url: 'send-mail',
      queryParam: 'followup',
      permissionName: ['PATIENTS', 'APPOINTMENTS']
    }
  ];
  @Input() showHideData: boolean = false;
  @Input() removeLocation;
  @Input() appointmentsMetaData: any;
  scheduledCount: number = 0;  pendingCount: number = 0; cancelledCount: number = 0; newPatientsCount: number = 0; confirmedCount: number = 0;
  constructor(private router: Router, private observable: ObservableHelperService) { }

  ngOnInit() {
    this.quickLinks = this.quickLinks.map(obj => {
      let permission = obj.permissionName.every(ele => this.adminPermissions[ele])
      return {...obj, permission: permission};
    });
    // tslint:disable-next-line:no-unused-expression
    this.appointmentsMetaData;
  }

  redirection(id) {
    id = Number(id);
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${id}`]);
  }
  appointmentsRedirection() {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`]);
  }
  // Switch case for the appointment type icons
  getingAppointmentTypeSvgName(appointmentType) {
    let appointmentTypeIconName = '';
    switch (appointmentType) {
      case 'Medication Management':
        appointmentTypeIconName = 'medication-management';
        break;
      case 'Ketamine Treatment':
        appointmentTypeIconName = 'ketamine-treatment';
        break;
      case 'TMS':
        appointmentTypeIconName = 'adminTms';
        break;
      case 'Psychological Therapy':
        appointmentTypeIconName = 'psychological-therapy';
        break;
      default:
        appointmentTypeIconName = 'treatement';
        break;
    }
    return appointmentTypeIconName;
  }
  quickList(data) {
    if(data.queryParam == 'followup'){
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${data.url}`], { queryParams: { followup: true }});
    } else if(data.queryParam == 'medication'){
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${data.url}`], { queryParams: { medication: true }});
    } else {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${data.url}`]);
    }
  }

  // census Display overlay
  censusDisplay(appt, op, tar) {
    this.pendingCount = appt.pending;
    this.cancelledCount = appt.cancelled;
    this.newPatientsCount = appt.newPatients;
    this.confirmedCount = appt.confirmed;
    op.show(tar);
  }
}
