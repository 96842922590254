import { Injectable } from '@angular/core';
import { NotificationCount } from '@app/pages/admin/models/notificationCountModel';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { HttpService } from './http.service'; // Adjust the path if needed


@Injectable({
  providedIn: 'root'
})
export class AdminNotificationService {

  // Initializing a BehaviorSubject to store and manage notification data state
  private notificationCountSubject = new BehaviorSubject<NotificationCount | null>(null);

  // Exposing the notification data as an observable for other components to subscribe to
  public notificationCount$ = this.notificationCountSubject.asObservable();

  // Injecting the HttpService dependency for making HTTP requests
  constructor(private http: HttpService) { }

  /**
   * Retrieve the notification data.
   * Checks if data is already available in the BehaviorSubject (cached);
   * if so, returns it. Otherwise, it makes an API call to fetch it.
   */
  getNotificationCount(): Observable<NotificationCount> {
    // Check if we already have notification data in the BehaviorSubject
    if (this.notificationCountSubject.value !== null) {
      // If notification count is already cached, return it as an observable
      return of(this.notificationCountSubject.value);
    }

    // If not, make the API call
    const payloadData = {
      type: 'GET',
      url: 'notificationsCount',
      isDeveloper: true
    };

    // Make the HTTP request and handle the response
    return this.http.makeHttpRequest(payloadData).pipe(
      tap(res => {
        if (this.http.isSuccessfulResponse(res)) {
          const notificationCount = res;  // Adjust this based on your API response structure
          this.notificationCountSubject.next(notificationCount); // Update BehaviorSubject with new data or updated data
        }
      }),
    );
  }
}
