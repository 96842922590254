import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'app-block-location',
  templateUrl: './block-location.component.html',
  styleUrls: ['./block-location.component.scss']
})
export class BlockLocationComponent implements OnInit {
  eachTreatmentLocations: any;
  eachLocations:boolean = false;
  @Input() selectedTreatmentData: any;
  @Output() changeLocationEvent: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpService) { }

  ngOnInit() {
    this.getAvailabiltySlots();
    console.log(this.selectedTreatmentData, 'in component');
  }
  getAvailabiltySlots() {
    let params = {
      appointmentTypeId: '1',
      locationId: 'ALX',
      beginTime: '06:30:00',
      endTime: '23:30:00',
      days: 1,
      metadata: true
    };
    // console.log(params);
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params,
      isAuthToken: true
      // body: data
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.filterLocations(res.data);
      }
    });
  }
filterLocations(data){
    let metaData = data.metadata.appointmentTypes
    let metaLocations =data.metadata.locations;
    let selectdeCompareData= [];
    this.selectedTreatmentData.treatment.forEach(each => { 
      metaData.forEach(e => { 
      if(e.appointmentTypeId === each.appointmentTypeId){
          selectdeCompareData.push(e);
      }
  });
  });
  selectdeCompareData=selectdeCompareData.map(each => {
    const locationName=[];
    each.locations.forEach(ee => {metaLocations.forEach(e =>{
      if(ee===e.locationId){
    locationName.push({
        loctionName: e.locationName,
        loctionId: e.locationId,
        isSelected: false,
      });
      return;
  }
});
  });
  return {...each,locationName};
});
this.selectedTreatmentData.treatment.forEach(each => {
  each.locationId.forEach(ee => { selectdeCompareData.forEach(e => {
    if(each.appointmentTypeId === e.appointmentTypeId){
      e.locationName.forEach(data => {
        if(data.loctionId === ee){
          data.isSelected = true;
        }
      });
    }
  });
  });
});
this.eachTreatmentLocations = selectdeCompareData;
if(this.eachTreatmentLocations.length > 0){
  this.eachLocations = true;
}
  }
optionSelect(event, taskId, categoryId) {
    this.eachTreatmentLocations[categoryId].locationName[taskId].isSelected = event.checked;
    this.changeLocationEvent.emit(this.eachTreatmentLocations);
  }
}
