export const FORM_KEYS = {
    REPONSE_TO_KETAMINE: 'reponse_to_ketamine',
    SIDEEFFECTS_DURING_KETAMINE: 'sideeffects_during_ketamine',
    SIDEEFFECTS_DURING_KETAMINE_DESCRIPTION: 'sideeffects_during_ketamine_description',
    SIDEEFFECTS_AFTER_KETAMINE: 'sideeffects_after_ketamine',
    SIDEEFFECTS_AFTER_KETAMINE_DESCRIPTION: 'sideeffects_after_ketamine_description',
    MONITORED_THROUGHOUT_SESSION: 'monitored_throughout_session',
    MONITORED_THROUGHOUT_SESSION_DESCRIPTION: 'monitored_throughout_session_description',
    FEEL_SATISFIED: 'feel_satisfied',
    RATING: 'rating',
    LITERATURE_INFORMATION: 'literature_information',
    FULLY_UNDERSTAND: 'fully_understand',
    SIDEEFFECTS_AFTER_TMS_DESCRIPTION: 'sideeffects_after_TMS_description',
    SIDEEFFECTS_AFTER_TMS: 'sideeffects_after_TMS',
    SIDEEFFECTS_DURING_TMS_DESCRIPTION: 'sideeffects_during_TMS_description',
    SIDEEFFECTS_DURING_TMS: 'sideeffects_during_TMS',
    REPONSE_TO_TMS: 'reponse_to_TMS',
    IS_THERAPIST_EMAIL_ID: 'isTherapistEmailId',
};

export const EMAIL_EDIT_KEYS = {
    TEMPLATE_EDIT_FREQUENCY: 'template_edit_frequency',
    TEMPLATE_EDIT_REP_FREQUENCY: 'template_edit_rep_frequency',
    TEMPLATE_EDIT_TIME_FORMAT: 'template_edit_time_format',
    TEMPLATE_EDIT_TIME: 'template_edit_time',
    TEMPLATE_EDIT_HEADER: 'template_edit_header',
    TEMPLATE_EDIT_SUMMARY: 'template_edit_summary',
    TEMPLATE_INFO_SEND_TIME: 'template_info_send_time'
};

// Form keys - Ketamine enrollment form.
export const KETAMINE_ENROLLMENT_KEYS = {
    TREATED_PREVIOUSLY: 'treated_previously',
    KETAMINE_ENR_LABEL: 'ketamine_enr_label',
    KETAMINE_ENR_HEADER: 'ketamine_enr_header',
    KETAMINE_ENR_MEDICATION: 'ketamine_enr_medication',
    KETAMINE_ENR_MEDICINE: 'medicine',
    KETAMINE_ENR_DOSAGE: 'dosage',
    KETAMINE_ENR_MED_TYPE: 'medicationType',
    KETAMINE_ENR_STARTDATE: 'prescriptionStartDate',
    KETAMINE_ENR_ENDDATE: 'prescriptionEndDate',
    KETAMINE_ENR_DISCONTINUED: 'discontinued',
    KETAMINE_ENR_DISCONTINUED_RSN: 'discontinuedReasion',
    KETAMINE_ENR_ADDNEW: 'ketamine_enr_addnew',
    KETAMINE_ENR_ACK: 'ketamine_enr_ack',
    KETAMINE_ENR_AGREE: 'ketamine_enr_agree',
    KETAMINE_ENR_ACCEPT: 'ketamine_enr_accept',
    KETAMINE_ENR_DATE: 'ketamine_enr_date',
    KETAMINE_FORMTAG: 'KETAMINEENROLLMENT',
    KETAMINE_ENR_SIGN: 'ketamine_enr_sign'
};

// Form keys - Ketamine monitoring form.
export const KETAMINE_MONITORING_KEYS = {
    KETAMINE_MNT_AGREE: 'ketamine_mnt_agree'
};

// Form keys - Requesting therapist information.
export const REQUEST_THERAPIST_DETAILS_KEYS = {
    CONSULTING_THERAPIST: 'isTherapist',
    THERAPIST_NAME: 'therapistName',
    THERAPIST_NOTES: 'therapistNotes',
    THERAPIST_COMMENTS: 'comments'
};
export const INTAKE_FORM_KEYS = {
    MEDICAL_DISORDERS: 'anyMedicalDisorders',
    PAST_HISTORY: 'pastHistoryofAlchohol',
    NON_PSYCH_MEDS: 'nonPsychMeds',
    ANY_OPIOIDS: 'anyOpiods',
    MEDICAL_DISORDERS_DESC: 'anyMedicalDisordersExplain',
    PAST_HISTORY_DESC: 'pastHistoryofAlchoholExplain',
    NON_PSYCH_MEDS_DESC: 'nonPsychMedsExplain',
    ANY_OPIOIDS_DESC: 'anyOpiodsExplain',
    PREV_PRESCRIBER_NAME: 'prescriberName',
    PREV_PRESCRIBER_PH: 'prescriberPhone',
    PREV_PRESCRIBER_EMAIL: 'prescriberEmail',
    PREV_PRESCRIBER_ADDR: 'prescriberAddress',
    HOSPITALIZED_BEFORE: 'patientHospitalized',
    HOSPITAL_NAME: 'nameOfHospital',
    YEAR: 'patientHospitalizedDate',
    REASON: 'patientHospitalizedReason',
    PREVIOUS_PRESC: 'previousPrescriber',
    HEADER: 'header',
    PHARMACY_STATE: 'pharmacyState',
    THERAPIST_NAME: 'therapistName',
    THERAPIST_EMAIL: 'therapistMail',
    IS_THERAPIST_DOCS: 'patientHaveTherapistNotes',
    THERAPIST_DOCS: 'therapistDocs',
    SEND_THERAPIST: 'sendTherapist',
    IS_PRESCRIBER_DOCS: 'patientHavePrescriberNotes',
    PRESCRIBER_DOCS: 'prescriberDocs',
    SEND_PRESCRIBER: 'sendPrescriber',
    PATIENT_SIGN: 'patientSign',
    LAB_WORKS_DOCS:'LabWorkDocsUpload'

};
export const TMS_CONSENT_FORM = {
    TMS_CONSENT_AGREE: 'tmsmedication_confirmation',
    TMS_CONSENT_HOSPITALIZED_BEFORE: 'tmshospitalizedPastMonth',
    TMS_CONSENT_HOSP_NAME: 'tmshospitalName',
    TMS_CONSENT_REASON: 'tmshospitalizedReason',
    TMS_CONSENT_DATE: 'tmstmsConsentDate',
    TMS_CONSENT_SIGNATURE: 'tmsmedication_signature'
};

export const MM_CONSENT_FORM = {
    MM_CONSENT_HOSPITALIZED_BEFORE: 'mmhospitalizedPastMonth',
    MM_CONSENT_HOSP_NAME: 'mmhospitalName',
    MM_CONSENT_REASON: 'mmhospitalizedReason',
};

export const KETAMINE_CONSENT_FORM = {
    KETAMINE_CONSENT_AGREE: 'ktmmedication_confirmation',
    KETAMINE_CONSENT_HOSPITALIZED_BEFORE: 'ktmhospitalizedPastMonth',
    KETAMINE_CONSENT_HOSP_NAME: 'ktmhospitalName',
    KETAMINE_CONSENT_REASON: 'ktmhospitalizedReason',
    KETAMINE_CONSENT_DATE: 'ktmketamineConsentDate',
    KETAMINE_CONSENT_SIGNATURE: 'ktmmedication_signature',
};

export const MEDICAL_SUMMARY_FORM = {
    NAME: 'name',
    DOB: 'dob',
    GENDER: 'gender',
    PCP: 'pcp',
    ADDRESS: 'address',
    ID: 'patientId',
    SUBJECTIVE: 'subjective',
    ALLERGIES: 'allergies',
    MEDICATIONS: 'medications',
    OCCUPATION: 'occupation',
    TOBACCO:'tobacco',
    ALCOHOL: 'alcohol',
    GENERAL: 'general',
    MS: 'ms',
    NEURO: 'neuro',
    PSYCH: 'psych',
    ASSESSMENT: 'assessmentAndPlan',
    QUERY: 'query'
};
