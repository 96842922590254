import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WPAPIService {

  // endpoint = 'http://localhost/new-tele-doc/blog/wp-json/wp/v2'; // this is for local
  // endpoint = 'http://localhost/newteledoc-website/blog/wp-json/wp/v2'; // this is for testing
  // endpoint = 'http://demo.divami.com/newteledoc-website/blog/wp-json/wp/v2'; // this is for demo
  // endpoint = 'http://staging.newteledoc.com/blog/wp-json/wp/v2'; // this is for staging
  endpoint = 'https://newteledoc.com/blog/wp-json/wp/v2'; // this is for live

  constructor(private http: HttpClient) { }

  posts(query = null) {
    return this.http.get(`${this.endpoint}/posts?${query}&_embed=true`, {
      observe: 'response'
    });
  }
  postDetails(id) {
    return this.http.get(`${this.endpoint}/posts/${id}?&_embed=true`, {
      observe: 'response'
    });
  }
}
