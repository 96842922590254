import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { AdminAccessService } from '@app/common/services/admin-access.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { environment } from '@src/environments/environment';

// declare var stripe: any;

@Component({
  selector: 'app-invoice-details-wrapper',
  templateUrl: './invoice-details-wrapper.component.html',
  styleUrls: ['./invoice-details-wrapper.component.scss']
})
export class InvoiceDetailsWrapperComponent implements OnInit {
  paymentRedirectUrl = environment.paymentRedirectUrl;
  breadCrumbData = {
    link: '/admin/invoices',
    linkText: 'Invoice Details'
  };
  isShowScreen = true;
  invoiceDetails: any;
  invoiceDetailsActions = [
    {
      id: 0,
      name: 'Generate Invoice',
      icon: 'generate-invoice-color',
      permissionName: 'INVOICE_EDIT'
    },
    {
      id: 1,
      name: 'Patient Details',
      icon: 'Patient Details',
      permissionName: 'PATIENT_DETAILS'
    }
  ];

  generateInvoicePopupShowHide: boolean = false;
  makeAsPaidPopupShowHide: boolean = false;
  invoiceId: any;
  invoiceType: any;
  isBeforeActions = false;
  copayAmount: any;
  dueAmount: any;
  totalAmout: any;
  isMarkasPaid = false;
  isAfterActions = true;
  amountGenerated: any;
  invoiceStatus: string;
  appointmentId: any;
  permissionsDone = false;
  adminPermissions = {
    INVOICE_EDIT: true
  };
  paymentToken: string;
  constructor(
    private http: HttpService,
    private router: Router,
    private observable: ObservableHelperService,
    private route: ActivatedRoute,
    private adminAccessService: AdminAccessService
  ) {
    let url = (router.url.indexOf('?') !== -1) ? router.url.substring(0, router.url.indexOf('?')) : router.url;
    this.invoiceId = url.split('/').pop();
    let urlArr = url.split('/');
    this.invoiceType = urlArr[(urlArr.length - 2)];
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0 && params.paymentDone) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Payment is successfully done.');
        const removedParams = { ...params };
        delete removedParams.paymentDone;
        this.router.navigate([], { queryParams: removedParams });
      }
    });
    // this.invoiceId = router.url.split('/').pop();
  }

  ngOnInit() {
    this.getAdminPermissions();
    this.getInvoiceDetails();
  }

  ngAfterContentInit(): void {
    // Called after ngOnInit when the component's or directive's content has been initialized.
    // Add 'implements AfterContentInit' to the class.
  }
  // Get getInvoiceDetails API call
  getInvoiceDetails() {
    this.isShowScreen = false;
    const payloadData = {
      type: 'GET',
      url: 'invoiceDetails',
      isDeveloper: true,
      pathVariables: [this.invoiceId, this.invoiceType]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.invoiceDetails = this.massageInvoiceDetails(res.data);
        console.log(res, this.invoiceDetails);
      } else {
        if (res.error.message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        } else if (res.error.message === 'Unable to find Invoice') {
          this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          this.router.navigate(['/admin/invoices']);
        }
      }
      this.isShowScreen = true;
    });
  }


  massageInvoiceDetails(data) {
    if (!data && typeof (data) !== 'object') { return {}; } else if (data.invoiceStatus.toLowerCase() === 'paid' || data.invoiceStatus.toLowerCase() === 'unpaid') {
      this.isBeforeActions = true;
      data.invoiceStatus.toLowerCase() === 'unpaid' ? this.isAfterActions = true : this.isAfterActions = false;
      this.copayAmount = data.copay;
      this.dueAmount = data.pay;
      let total: any;
      total = (data.copay + data.pay);
      total = total.toFixed(2);
      this.totalAmout = total;
    }

    return data = {
      ...data,
      appointmentStatus: this.massageAppointmentStatus(data),
      doctor: [
        {
          name: data.providerName,
          status: '',
          isSelected: true,
          picture: data.providerImage
        },
      ]
    };
  }


  // This will massage the appointment status
  massageAppointmentStatus(data) {
    let status = data.appointmentStatus;
    // "2020-11-19T00:00:00.000Z"
    let appointmentDate = new Date(data.appointmentDate).valueOf();
    let currentDate: any = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}T00:00:00:000Z`;
    currentDate = new Date(currentDate).valueOf();
    console.log(currentDate, appointmentDate);
    switch (status) {
      case 'A':
        return 'Active';
        break;
      case 'B':
        return 'Absent';
        break;
      case 'C':
        return 'Completed';
        break;
      case 'D':
        return 'Cancelled';
        break;
      case 'I':
        return 'Invoice Generated';
        break;
      case 'N':
        return 'Pending Payment';
        break;
      default:
        return status;
        break;
    }
  }

  // This is invoice details action click functionalities
  actionsClicked(action) {
    console.log(action);
    if (action) {
    }
    if (action && action.id === 0) {
      this.invoiceStatus = 'ungenerated';
      this.appointmentId = action.appId;
      this.generateInvoicePopupShowHide = true;
    } else if (action.id === 1) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}/${this.invoiceDetails.patientId}`]);
    }
  }


  // This is closing for generate invoice the popup
  generateInvoicePopupShowHideStatusFun(status) {
    this.generateInvoicePopupShowHide = status.cancelpopup;
  }

  // This is closing for make as paid popup
  makeAsPaidPopupShowHideStatusFun(status) {
    this.makeAsPaidPopupShowHide = status.cancelpopup;
  }


  // generateInvoice($event)
  generateInvoice(data) {
    if (data) {
      // tslint:disable-next-line:one-variable-per-declaration
      let method, callUrl, bodyData, pathVariable, message; let callAPI = false;
      data.values ? this.amountGenerated = {
        copay: data.values.copayAmt,
        due: data.values.dueAmt,
        pay: (data.values.copayAmt + data.values.dueAmt),
        comment: data.values.comments
        // tslint:disable-next-line:no-unused-expression
      } : null;
      if (data.btnType === 'sendCustomer' || (data.btnType === 'submit' && this.invoiceStatus && this.invoiceStatus === 'ungenerated')) {
        method = 'POST';
        callUrl = 'generateInvoice';
        data.btnType === 'sendCustomer' ?
          bodyData = {
            type: 'NEW',
            appointmentId: this.appointmentId,
            status: 'unpaid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay
            pay: this.amountGenerated.due,
            invoiceType: this.invoiceType,
            comment: this.amountGenerated.comment
          } : bodyData = {
            type: 'NEW_PAID',
            appointmentId: this.appointmentId,
            status: 'paid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay,
            pay: this.amountGenerated.due,
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: this.invoiceType,
            comment: this.amountGenerated.comment
          };
        message = data.btnType === 'sendCustomer' ? 'Invoice has been sent to the customer successfully.' : 'Invoice has been marked as paid successfully.';
        this.makeAsPaidPopupShowHide = false;
        this.generateInvoicePopupShowHide = false;
        callAPI = true;
      } else if (data.btnType === 'makePayment') {
        // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
        let reqBody: any;
        if (this.invoiceDetails.invoiceStatus === 'unpaid') {
          reqBody = {
            id: this.invoiceId,
            invoiceType: this.invoiceType
          };
        } else {
          reqBody = {
            type: 'NEW',
            appointmentId: this.appointmentId,
            copay: this.copayAmount,
            pay: this.dueAmount,
          };
        }
        let payload = {
          type: 'POST',
          url: 'checkoutSession',
          isDeveloper: true,
          body: reqBody
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          } else {
            this.paymentToken = res.result.response.token;
            let inputElement = document.getElementById("paymentvalue") as HTMLInputElement;
            // Set the value of the input element
            console.log('inputElement', inputElement)
            inputElement.value = this.paymentToken;
            // Get the form element
            let form = document.getElementById('formAuthorizeNetPage') as HTMLFormElement;
            console.log("formformformformformformform");

            form.method = 'POST';
            form.submit();
            console.log("formformformformform SUBMITTED");

          }
          // stripe.redirectToCheckout({ sessionId: res.id });
        });
      } else if (data.btnType === 'markAsPaid') {
        this.generateInvoicePopupShowHide = false;
        this.makeAsPaidPopupShowHide = true;
      } else if (data.btnType === 'resend') {
        method = 'GET';
        callUrl = 'invoiceResend';
        pathVariable = this.invoiceId;
        message = 'Invoice has been re-sent to the customer successfully.';
        callAPI = true;
      } else if (data.btnType === 'delete') {
        method = 'DELETE';
        callUrl = 'invoiceDelete';
        pathVariable = this.invoiceId;
        message = 'Invoice has been deleted successfully.';
        callAPI = true;
      } else if (data.btnType === 'submit') {
        method = 'PUT';
        callUrl = 'invoiceMarkasPaid';
        if (this.invoiceType === 'document_request_invoice') {
          bodyData = {
            status: 'paid',
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: this.invoiceType,
            requestId: this.invoiceDetails.requestId
          };
        }
        else {
          bodyData = {
            status: 'paid',
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: this.invoiceType
          };
        }
        pathVariable = this.invoiceId;
        message = 'Invoice has been marked as paid successfully.!';
        this.makeAsPaidPopupShowHide = false;
        callAPI = true;
      }
      if (callAPI) {
        // API Call for all
        callAPI = false;
        const payload = {
          type: method,
          url: callUrl,
          isDeveloper: true,
          pathVariables: [pathVariable ? pathVariable : null],
          body: bodyData ? bodyData : null,
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            console.log('Response', res);
            data.btnType === 'delete' ? this.router.navigate(['admin/invoices'], { replaceUrl: true }) : this.getInvoiceDetails();
            this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
          } else {
            console.log('Response', res);
            this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          }
        });
      }
    }
  }
  // getting permissions based on user id
  getAdminPermissions() {

    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let permArr = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
        });
        if (permArr.includes('INVOICE_MARKASPAID')) {
          this.isMarkasPaid = true;
        }
        else {
          this.isMarkasPaid = false
        }
        if (!permArr.includes('INVOICE_DETAILS')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        if (!permArr.includes('INVOICE_EDIT')) {
          this.adminPermissions['INVOICE_EDIT'] = false;
        }
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }

}
