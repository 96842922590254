import { Injectable } from '@angular/core';
import { Access } from '@app/pages/admin/models/accessModel';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from './http.service'; // Ensure HttpService is imported

@Injectable({
    providedIn: 'root'
})
export class AdminAccessService {

    // Initializing a BehaviorSubject to store and manage access data state
    private accessSubject = new BehaviorSubject<Access | null>(null);

    // Exposing the access data as an observable for other components to subscribe to
    public access$ = this.accessSubject.asObservable();

    // Injecting the HttpService dependency for making HTTP requests
    constructor(private http: HttpService) { }

    /**
    * Retrieve the access data.
    * Checks if data is already available in the BehaviorSubject (cached);
    * if so, returns it. Otherwise, it makes an API call to fetch it.
    */
    getAccess(): Observable<Access> {
        // Check if we already have access data in the BehaviorSubject
        if (this.accessSubject.value !== null) {
            return of(this.accessSubject.value);
        }

        // If not, make the API call
        const payloadData = {
            type: 'GET',
            url: 'getPermissions',
            isDeveloper: true,
        };

        // Make the HTTP request and handle the response
        return this.http.makeHttpRequest(payloadData).pipe(
            tap(res => {
                if (this.http.isSuccessfulResponse(res)) {
                    const accessData = res; // Adjust this based on your API response structure
                    this.accessSubject.next(accessData); // Update BehaviorSubject with new data or updated data
                }
            })
        );
    }

}