import { Component, OnInit, HostListener } from '@angular/core';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit {

  constructor(private observable: ObservableHelperService) { }

  showScrollToTopEvent: boolean = false;
  globalShowScrollToTop: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showEvent();
  }

  ngOnInit() {
    this.observable.isScrollToTopEnabled().subscribe((data) => {
      this.globalShowScrollToTop = data;
      console.log(data);
    });
    this.showEvent();
  }

  showEvent() {
    this.showScrollToTopEvent = (window.scrollY > 100) ? true : false;
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

}
