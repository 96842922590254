import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { IGetMethodPayload, IGetPermissionsPayloadI, IPostMethodPayload, IPutMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { DEFAULT_PAGINATION_LIMIT, HTTPMethods, TELEMEDICINE_MEDICATION, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { AdminInvoiceFilterService } from '@app/common/services/admin-invoice-filter.service';
@Component({
  selector: 'app-user-details-wrapper',
  templateUrl: './user-details-wrapper.component.html',
  styleUrls: ['./user-details-wrapper.component.scss']
})
export class UserDetailsWrapperComponent implements OnInit {

  permissionsDone: boolean = false;
  isShowScreen: boolean = false;
  // pagination data
  breadCrumbData = {
    link: '/admin/users',
    linkText: 'User Details'
  };
  pageNumber: number = 1;
  pageLimit: any = DEFAULT_PAGINATION_LIMIT;

  // Initial Payload
  INITIAL_PAYLOAD = {
    pagination: {
      page: this.pageNumber,
      limit: this.pageLimit
    },
  };
  userDetails: any;
  // patient details actions
  userDetailsActions: any = [
    {
      id: 'reset',
      name: 'Reset Password',
      icon: 'reset-password',
      className: 'reset_password',
      show: true,
      permissionName: 'USER_EDIT'
    },
    {
      id: 'delete',
      name: 'Delete',
      icon: 'delete',
      className: 'delete',
      show: true,
      permissionName: 'USER_EDIT'
    }
  ];
  selectedTabIndex: any = 0; // Tab initial index value
  userId: any;
  adminPermissions = {
    USER_EDIT: true,
    PATIENT_EDIT: true
  };
  treatmentsCount: number;
  getFilterData: any;
  patientsList: any;
  paginationData: any;
  searchInputText: string = '';
  blockPatientApplyStatus = false; // Block Status Flag
  filtersTabText = [{ id: 0, label: 'Patients', selected: true }]; // Filers Tab text
  // Empty List data
  emptyAppointmentsListMessages = {
    className: 'appointmentsList',
    icon: 'patient',
    message: 'There are no patients'
  };
  popupShowHide: boolean = false;
  action: string;
  popupMessage: string;
  patientCount: any;
  deletedPatientData: boolean;

  constructor(
    private http: HttpService,
    private observable: ObservableHelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminInvoiceFilterService: AdminInvoiceFilterService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.userId = params.id;
    });
  }

  ngOnInit() {
    this.getUserDetails(this.INITIAL_PAYLOAD);
  }
  // for tab selection
  // This  will assign the which tab is selected
  selectedTab(tab) {
    if (tab && typeof (tab) === 'object') {
      this.filtersTabText.forEach(each => {
        each.selected = false;
        if (each.id === tab.id) {
          each.selected = true;
        }
      });
      this.selectedTabIndex = tab.id;
    }
  }

  // Get User details API call
  getUserDetails(bodyData) {
    const payloadData: IPostMethodPayload = {
      type: HTTPMethods.POST,
      url: API_URLS.USER_DETAILS,
      isDeveloper: true,
      pathVariables: [this.userId],
      body: bodyData
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.isShowScreen = true;
        this.userDetails = this.massageUserDetails(res.data);
        this.getTreatmentsDataLength(res);
      } else {
        // Error
        let message = 'Failed to fetch users Details. Please try again';
        message = (res.error) ? res.error.message : message;
        if (message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        } else { this.observable.showToast(TOAST_STATUSES.ERROR, message); }
      }
    });
  }

  massageUserDetails(userData) {
    if (!userData && typeof (userData) !== 'object') {
      return {};
    } else {
      return userData = {
        ...userData,
        userName: (!userData.firstName && !userData.lastName) ? '-' :
          (userData.firstName) + ' ' + (userData.lastName),
        status: userData.removedFlag ? 'Deleted' : 'Active'
      };
    }
  }

  // getting permissions based on user id
  getAdminPermissions() {
    const payloadData: IGetPermissionsPayloadI = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PERMISSIONS,
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let permissions = ['USER_EDIT', 'PATIENT_EDIT'];
        let permArr = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
        });
        if (!permArr.includes('PATIENT_LIST')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
          this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
        }
        permissions.forEach(perm => {
          if (!permArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
        console.log(this.adminPermissions);
      } else {
        // Error
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }

  // Pagination output
  pageChangedEvent(data) {
    if (data) {
      this.pageNumber = data.currentPage;
      this.pageLimit = data.limit;
      let pageClickedData = {
        pagination: {
          page: data.currentPage,
          limit: data.limit,
        },
        search: this.searchInputText,
      };
      this.blockPatientApplyStatus = false;
      this.getUserDetails(pageClickedData);
    }
  }

    /**
   * Fetches the treatments data length and updates the patient details.
   * @param patientData - The data of the patient to be processed.
   */
  getTreatmentsDataLength(patientData) {

    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    // Fetches the admin invoice information from admin invoice service
    this.adminInvoiceFilterService.getInvoice().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = Object.keys(res.data.treatment).length;
        this.getFilterData = res.data;
        this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
        this.patientCount = patientData.data.patient.pagination.totalItems;
        this.patientsList = this.massagePatientList(patientData.data.patient.list);
        this.paginationData = this.massagePaginationData(patientData.data);
        this.checkingSidebarState(false);
      } else {
        // Error
        let message = 'Failed to fetch patient lits data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.isShowScreen = true;
      this.getAdminPermissions();
    });
  }

  // This will do massaging for pagination Data
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.patient.pagination.limit,
      currentPage: data.patient.pagination.page,
      totalPages: Math.ceil(data.patient.pagination.totalItems / data.patient.pagination.limit),
      data: data.patient.list
    };
  }

  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }

  /**
   * This will trigger when user details actions clicked
   * @param action :Action iem data
   * @param userData : User Data
   */
  userDetailsActionsTrigger(action, userData) {
    if (action.id === 'reset') {
      this.popupShowHide = true;
      this.popupMessage = 'Do you want to send reset password email for this user?';
      this.action = 'reset';
    } else if (action.id === 'delete') {
      this.popupShowHide = true;
      this.popupMessage = 'On deleting user, the patients, appointments and document requests of this account will get deleted. Do you want to continue?';
      this.action = 'delete';
    }
  }


  cancelPopupShowHideStatusFun(status, isShow?) {
    this.popupShowHide = status.cancelpopup;
    if (this.action && this.action === 'delete' && isShow) {
      this.deleteUser();
    } else if (this.action && this.action === 'reset' && isShow) {
      this.resetPasswordAPI();
    } else if (this.action && this.action === 'delete-patient' && isShow) {
      this.deletePatient(this.deletedPatientData);
    }
  }

  /**
   * This is for to sent reset verification link to the consumer
   */
  resetPasswordAPI() {
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.RESET_USER_PASSWORD,
      pathVariables: [this.userId],
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.RESET_USER_PASSWORD_SUCCESS_MSG);
        this.getUserDetails(this.INITIAL_PAYLOAD);
      } else {
        let message = TOAST_MESSAGES.RESET_USER_PASSWORD_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  /**
   * This is for deleting the user account
   */
  deleteUser() {
    const payloadData: IPutMethodPayload = {
      type: HTTPMethods.PUT,
      url: API_URLS.DELETE_USER,
      pathVariables: [this.userId],
      isDeveloper: true,
      body: {}
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.DELETE_USER_SUCCESS_MSG);
        this.getUserDetails(this.INITIAL_PAYLOAD);
      } else {
        let message = TOAST_MESSAGES.DELETE_USER_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  showDeletePatientPopUp(event) {
    this.deletedPatientData = event.patientData;
    this.popupShowHide = true;
    this.popupMessage = 'On deleting this patient, All the appointments and document requests for this patient will get canceled. Do you want to continue?';
    this.action = 'delete-patient';
  }

  /**
      * This is for deleting the patient
      */
  deletePatient(event) {
    const payloadData: IPutMethodPayload = {
      type: HTTPMethods.PUT,
      url: API_URLS.DELETE_EACH_PATIENT,
      pathVariables: [event.patientId],
      isDeveloper: true,
      body: {}
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.DELETE_PATIENT_SUCCESS_MSG);
        this.getUserDetails(this.INITIAL_PAYLOAD);
      } else {
        let message = TOAST_MESSAGES.DELETE_PATIENT_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // MassagePatientList  Data
  massagePatientList(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        ...each,
        patientRemovedFlag: each.removedFlag,
        // blockedStatus: this.getActions(each),
        // blockedServices: each.blockedServices.map(eachTreatment => {
        //   return{
        //     ...eachTreatment,
        //     name : (eachTreatment.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && eachTreatment.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${eachTreatment.appointmentTypeName} Online` : (eachTreatment.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && eachTreatment.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${eachTreatment.appointmentTypeName} In Office` : eachTreatment.appointmentTypeName,
        //   };
        // }),
        phone: each.phoneNumber,
        editText: 'Edit',
        archiveStatus: this.displayArchiveStatus(each.status),
        patientName: (!each.firstName && !each.lastName) ? '-' :
          (each.firstName) + ' ' + (each.lastName),
      };
    });
  }

  getActions(data) {
    let blockedStatusLabel;
    if (data.blockedServices.length === this.treatmentsCount + 1) {
      blockedStatusLabel = 'UnBlock';
    } else {
      blockedStatusLabel = 'Block Patient';
    }
    return blockedStatusLabel;
  }

  displayArchiveStatus(archiveStatus) {
    let status = '';
    if (archiveStatus === 'active') {
      status = 'Archive Patient';
    } else if (archiveStatus === 'archive') {
      status = 'Active Patient';
    }
    return status;
  }

}
