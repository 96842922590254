import { Component, OnInit } from '@angular/core';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { AdminAccessService } from '@app/common/services/admin-access.service';
@Component({
  selector: 'app-location-wrapper',
  templateUrl: './location-wrapper.component.html',
  styleUrls: ['./location-wrapper.component.scss']
})
export class LocationWrapperComponent implements OnInit {
  // Bread crumb Data
  breadCrumbData = {
    link: '',
    linkText: 'Locations'
  };
  emptyDataObj = {
    className: 'locationList',
    icon: 'location',
    message: 'There are no any Locations'
  };
  isShowScreen = true;
  locationList = [];
  permissionsDone = false;
  adminPermissions = {
    LOCATION_CREATE: true,
    LOCATION_EDIT: true
  };
  constructor(
    private http: HttpService,
    private observable: ObservableHelperService,
    private router: Router,
    private adminAccessService: AdminAccessService
  ) { }

  ngOnInit() {
    this.getAdminPermissions();
    this.getLocationList();
  }

  // TO get location list API call
  getLocationList() {
    this.isShowScreen = false;
    const payloadData = {
      type: 'GET',
      url: 'adminlocationsList',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.locationList = res.data;
        this.locationList = this.sortByAppointmentType(this.locationList);
        console.log(res);
      } else {
        if (res.error.message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
      }
      this.isShowScreen = true;
    });
  }

  addNew() {
    // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.CREATE_LOCATION}`]);
  }

  // This will sort the appointments
  sortByAppointmentType(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.sort((a, b) => {
      if (a.locationType === 'online') {
        return -1;
      } else {
        return 1;
      }
    });
  }

  // getting permissions based on user id
  getAdminPermissions() {
  
    this.adminAccessService.getAccess().subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let permissions = ['LOCATION_CREATE', 'LOCATION_EDIT'];
        let permArr = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
        });
        if (!permArr.includes('LOCATION_LIST')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        permissions.forEach(perm => {
          if (!permArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
        console.log(this.adminPermissions);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }
}
